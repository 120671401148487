import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arpropertyinsurance.scss';

const ARPropertyInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="propertyinsu-tab-imagebannermain-ar">
          <div class="propertyinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="propertyinsu-banner-heading-ar"> تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="propertyinsu-tab-btn-ar">
                <div class="propertyinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="propertyinsu-banner-con-ar">
          <div class="propertyinsu-bdy-ar">
            <div class="propertyinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="propertyinsu-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/property_insurance_ar.png"} alt="تأمين الممتلكات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}

              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="propertyinsu-dlex-rgt-ar">
                <h2 class="pagetitle-ar">تأمين الممتلكات</h2>

                <p class="pg-txt-con">من ناحية الأعمال، ربما تكون العقارات هي الأداة الاستثمارية ذات القيمة الأكبر. تعتبر الأراضي والمباني والأثاث والتجهيزات والآلات وما إلى ذلك ضرورية للشركات وهي تقع ضمن مسؤولية الإدارة تجاه أصحاب المصلحة فيما يتعلق بحماية هذه الأصول من المخاطر والأضرار المحتملة. تقدم شركة كيمجي رامداس لخدمات التأمين خطط التأمين على الممتلكات التي تلبي احتياجات الكيان التجاري على وجه التحديد. فيمكن للإدارة أن تظل مطمئنة في حالة حدوث أي حوادث غير مرغوب فيها. تكون خدمات القيمة المضافة على النحو الذي اقترحته شركة كيمجي رامداس لخدمات التأمين لعملائها الكرام هي كما يلي</p>

                <div class="propertyinsu-keypoint-ar">
                  <div class="propertyinsu-keypoint-item-ar">حلول النافذة الواحدة من خلال مدير العلاقات المخصص لدينا</div>
                  <div class="propertyinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="propertyinsu-keypoint-item-ar">ترتيب شروط أشمل، وبوليصات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>
                  <div class="propertyinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="propertyinsu-keypoint-item-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصة، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>
                  <div class="propertyinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="propertyinsu-keypoint-item-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق التوفير لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>
                  <div class="propertyinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="propertyinsu-kpnt-ar">
              <div class="propertyinsu-kpnt-item-ar">تدقيق التأمين مع عدم وجود أثار مترتبة خاصة ببرنامج التأمين لعملائنا من الشركة</div>
              <div class="propertyinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-kpnt-item-ar">دعم إعادة التأمين للاكتتاب في المشاريع الضخمة بشروط وأسعارًا أكثر شمولًا</div>
              <div class="propertyinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-kpnt-item-ar">معاينة الموقع، معاينة التربة، من خلال فريق هندسي مسؤول عن مخاطر الشبكات</div>
              <div class="propertyinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-kpnt-item-ar">ترتيب اختبار التصوير الحراري للمستودعات / وحدات التصنيع الضخمة والمعقدة</div>
              <div class="propertyinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-kpnt-item-ar">تواصلنا مع الأسواق العالمية للحصول على الشروط الدولية</div>
              <div class="propertyinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-kpnt-item-ar">لدينا فريق مخصص للمطالبات يتمتع بخبرة عملية لتسوية مطالبات الممتلكات الضخمة وذات التفاصيل</div>
              <div class="propertyinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>


            <h3 class="propertyinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين الممتلكات </h3>

            <p class="propertyinsu-txt-con-en">يوفر التأمين على الممتلكات مجموعة واسعة من التغطيات التي تشمل</p>

            <div class="propertyinsu-pins-list-dsk-ar">
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد حوادث الحرائق: تغطي البوليصة أي ضرر ناتج عن الحريق. ومع ذلك، يتم استبعاد الضرر الناجم عن التسخين الطبيعي أو التخمير أو الاحتراق التلقائي</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد حوادث الانفجار: الضرر الناجم عن الانفجار مشمول في هذه البوليصة</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد حوادث الانفجار: الضرر الناجم عن الانفجار مشمول في هذه البوليص </div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد أضرار الطائرات: الحريق وأي ضرر آخر تسببه الطائرات أو غيرها من الأجهزة المحمولة جوًا، والأضرار الناجمة عن الأشياء التي تسقطها الطائرات مغطاة ببوليصة التأمين ضد الحريق</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar"> تغطية ضد الإضراب بسبب أعمال الشغب، الأضرار الكيدية: في حالة تلف الممتلكات المؤمن عليها بسبب الإضراب أو الشغب أو أي نشاط إرهابي، يتم تغطية الخسارة بالتأمين ضد الحريق</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد الكوارث الطبيعية: بصرف النظر عن ما سبق، تشمل هذه البوليصة أيضًا الأضرار الناجمة عن الكوارث الطبيعية بما في ذلك العواصف والزوبعات والأعاصير والفيضانات. يغطي التأمين الحرائق والأوضاع الخطيرة وعواقب الكوارث الطبيعية</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد الانهيارات الأرضية / الانزلاقات الصخرية: ستوفر شركة التأمين الخاصة بكم الحماية بموجب التأمين ضد الحريق في حالة الدمار البارز الذي يلحق بممتلكاتكم أو حتى الحادث لجزء صغير من الموقع حيث توجد الممتلكات عن طريق الانهيارات الأرضية / الانزلاقات الصخرية</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد انفجار المياه / فيضان خزانات المياه (الغمر): تغطي البوليصة الضرر والتلف لممتلكاتكم بسبب انفجار المياه أو فيضان خزانات المياه</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد حريق الأدغال: في الوقت الذي تقوم فيه بإشعال النار في النباتات والشجيرات المتضخمة، فإن من المحتمل أن ينتهي بكم الأمر إلى إتلاف الممتلكات. لا داعي للقلق! حيث أن بوليصة التأمين ضد الحريق التي تمتلكها تغطي هذا أيضًا. ومع ذلك، فإن البوليصة لن تغطي الدمار الناجم عن حرائق الغابات</div>

              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-pins-list-dsk-item-ar">تغطية ضد الإرهاب: تغطية اختيارية</div>
              <div class="propertyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            <h3 class="propertyinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>

            <div class="propertyinsu-pins-list-dksp-ar">
              <div class="propertyinsu-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="propertyinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="propertyinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
            <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="propertyinsu-tab-imagebannermain-ar">
          <div class="propertyinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="propertyinsu-banner-heading-ar"> تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="propertyinsu-tab-btn-ar">
                <div class="propertyinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="propertyinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="propertyinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تأمين الممتلكات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/property_insurance_ar.png"} alt="تأمين الممتلكات" />

              <p class="">من ناحية الأعمال، ربما تكون العقارات هي الأداة الاستثمارية ذات القيمة الأكبر. تعتبر الأراضي والمباني والأثاث والتجهيزات والآلات وما إلى ذلك ضرورية للشركات وهي تقع ضمن مسؤولية الإدارة تجاه أصحاب المصلحة فيما يتعلق بحماية هذه الأصول من المخاطر والأضرار المحتملة. تقدم شركة كيمجي رامداس لخدمات التأمين خطط التأمين على الممتلكات التي تلبي احتياجات الكيان التجاري على وجه التحديد. فيمكن للإدارة أن تظل مطمئنة في حالة حدوث أي حوادث غير مرغوب فيها. تكون خدمات القيمة المضافة على النحو الذي اقترحته شركة كيمجي رامداس لخدمات التأمين لعملائها الكرام هي كما يلي</p>

              <p className="txtimg"> حلول النافذة الواحدة من خلال مدير العلاقات المخصص لدينا <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg"> ترتيب شروط أشمل، وبوليصات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين<img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg"> استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصة، وخدمات تسوية المطالبات وممارسات التأمين الأخرى. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg"> تؤدي الخدمات المجانية والسريعة إلى تحقيق التوفير لعملائنا من حيث ساعات العمل والتكاليف الإدارية<img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">تدقيق التأمين مع عدم وجود أثار مترتبة خاصة ببرنامج التأمين لعملائنا من الشركة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">دعم إعادة التأمين للاكتتاب في المشاريع الضخمة بشروط وأسعارًا أكثر شمولًا</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">معاينة الموقع، معاينة التربة، من خلال فريق هندسي مسؤول عن مخاطر الشبكات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">ترتيب اختبار التصوير الحراري للمستودعات / وحدات التصنيع الضخمة والمعقدة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تواصلنا مع الأسواق العالمية للحصول على الشروط الدولية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">لدينا فريق مخصص للمطالبات يتمتع بخبرة عملية لتسوية مطالبات الممتلكات الضخمة وذات التفاصيل</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / تأمين الممتلكات </h3>

              <p>يوفر التأمين على الممتلكات مجموعة واسعة من التغطيات التي تشمل</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">تغطية ضد حوادث الحرائق: تغطي البوليصة أي ضرر ناتج عن الحريق. ومع ذلك، يتم استبعاد الضرر الناجم عن التسخين الطبيعي أو التخمير أو الاحتراق التلقائي</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد حوادث الانفجار: الضرر الناجم عن الانفجار مشمول في هذه البوليصة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد حوادث الانفجار: الضرر الناجم عن الانفجار مشمول في هذه البوليصة.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد أضرار الطائرات: الحريق وأي ضرر آخر تسببه الطائرات أو غيرها من الأجهزة المحمولة جوًا، والأضرار الناجمة عن الأشياء التي تسقطها الطائرات مغطاة ببوليصة التأمين ضد الحريق</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar"> تغطية ضد الإضراب بسبب أعمال الشغب، الأضرار الكيدية: في حالة تلف الممتلكات المؤمن عليها بسبب الإضراب أو الشغب أو أي نشاط إرهابي، يتم تغطية الخسارة بالتأمين ضد الحريق</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد الكوارث الطبيعية: بصرف النظر عن ما سبق، تشمل هذه البوليصة أيضًا الأضرار الناجمة عن الكوارث الطبيعية بما في ذلك العواصف والزوبعات والأعاصير والفيضانات. يغطي التأمين الحرائق والأوضاع الخطيرة وعواقب الكوارث الطبيعية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد الانهيارات الأرضية / الانزلاقات الصخرية: ستوفر شركة التأمين الخاصة بكم الحماية بموجب التأمين ضد الحريق في حالة الدمار البارز الذي يلحق بممتلكاتكم أو حتى الحادث لجزء صغير من الموقع حيث توجد الممتلكات عن طريق الانهيارات الأرضية / الانزلاقات الصخرية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد انفجار المياه / فيضان خزانات المياه (الغمر): تغطي البوليصة الضرر والتلف لممتلكاتكم بسبب انفجار المياه أو فيضان خزانات المياه</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد حريق الأدغال: في الوقت الذي تقوم فيه بإشعال النار في النباتات والشجيرات المتضخمة، فإن من المحتمل أن ينتهي بكم الأمر إلى إتلاف الممتلكات. لا داعي للقلق! حيث أن بوليصة التأمين ضد الحريق التي تمتلكها تغطي هذا أيضًا. ومع ذلك، فإن البوليصة لن تغطي الدمار الناجم عن حرائق الغابات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد الإرهاب: تغطية اختيارية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}


      {/* MOBILE PAGE STARTS HERE */}
      <div class="propertyinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance-ar"} selected class="active">تأمين الممتلكات</option>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"}>تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="propertyinsu-mob-main-en">
          <h1 class="propertyinsu-banner-heading-ar">تأمين الممتلكات</h1>
          <div class="propertyinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/property_insurance_ar.png"} alt="تأمين الممتلكات" />
          </div>
          <p class="propertyinsu-mob-txt-ar">من ناحية الأعمال، ربما تكون العقارات هي الأداة الاستثمارية ذات القيمة الأكبر. تعتبر الأراضي والمباني والأثاث والتجهيزات والآلات وما إلى ذلك ضرورية للشركات وهي تقع ضمن مسؤولية الإدارة تجاه أصحاب المصلحة فيما يتعلق بحماية هذه الأصول من المخاطر والأضرار المحتملة. تقدم شركة كيمجي رامداس لخدمات التأمين خطط التأمين على الممتلكات التي تلبي احتياجات الكيان التجاري على وجه التحديد. فيمكن للإدارة أن تظل مطمئنة في حالة حدوث أي حوادث غير مرغوب فيها. تكون خدمات القيمة المضافة على النحو الذي اقترحته شركة كيمجي رامداس لخدمات التأمين لعملائها الكرام هي كما يلي:- </p>

          <div class="propertyinsu-pins-list-mob-ar">
            <div class="propertyinsu-pins-list-mob-item-ar">حلول النافذة الواحدة من خلال مدير العلاقات المخصص لدينا</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">ترتيب شروط أشمل، وبوليصات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصة، وخدمات تسوية المطالبات وممارسات التأمين الأخرى.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق التوفير لعملائنا من حيث ساعات العمل والتكاليف الإدارية.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">تدقيق التأمين مع عدم وجود أثار مترتبة خاصة ببرنامج التأمين لعملائنا من الشركة.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">دعم إعادة التأمين للاكتتاب في المشاريع الضخمة بشروط وأسعارًا أكثر شمولًا.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">معاينة الموقع، معاينة التربة، من خلال فريق هندسي مسؤول عن مخاطر الشبكات.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">ترتيب اختبار التصوير الحراري للمستودعات / وحدات التصنيع الضخمة والمعقدة.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">تواصلنا مع الأسواق العالمية للحصول على الشروط الدولية.</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-pins-list-mob-item-ar">لدينا فريق مخصص للمطالبات يتمتع بخبرة عملية لتسوية مطالبات الممتلكات الضخمة وذات التفاصيل</div>

            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>



          <h3 class="propertyinsu-subtitle-ar">التغطية الرئيسية / تأمين الممتلكات </h3>

          <p class="propertyinsu-mob-txt-ar">يوفر التأمين على الممتلكات مجموعة واسعة من التغطيات التي تشمل: - </p>

          <div class="propertyinsu-pins-list-mob-ar">
            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد حوادث الحرائق: تغطي البوليصة أي ضرر ناتج عن الحريق. ومع ذلك، يتم استبعاد الضرر الناجم عن التسخين الطبيعي أو التخمير أو الاحتراق التلقائي.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد حوادث الانفجار: الضرر الناجم عن الانفجار مشمول في هذه البوليصة.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد حوادث الانفجار: الضرر الناجم عن الانفجار مشمول في هذه البوليصة.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد أضرار الطائرات: الحريق وأي ضرر آخر تسببه الطائرات أو غيرها من الأجهزة المحمولة جوًا، والأضرار الناجمة عن الأشياء التي تسقطها الطائرات مغطاة ببوليصة التأمين ضد الحريق.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar"> تغطية ضد الإضراب بسبب أعمال الشغب، الأضرار الكيدية: في حالة تلف الممتلكات المؤمن عليها بسبب الإضراب أو الشغب أو أي نشاط إرهابي، يتم تغطية الخسارة بالتأمين ضد الحريق.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد الكوارث الطبيعية: بصرف النظر عن ما سبق، تشمل هذه البوليصة أيضًا الأضرار الناجمة عن الكوارث الطبيعية بما في ذلك العواصف والزوبعات والأعاصير والفيضانات. يغطي التأمين الحرائق والأوضاع الخطيرة وعواقب الكوارث الطبيعية.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد الانهيارات الأرضية / الانزلاقات الصخرية: ستوفر شركة التأمين الخاصة بكم الحماية بموجب التأمين ضد الحريق في حالة الدمار البارز الذي يلحق بممتلكاتكم أو حتى الحادث لجزء صغير من الموقع حيث توجد الممتلكات عن طريق الانهيارات الأرضية / الانزلاقات الصخرية.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد انفجار المياه / فيضان خزانات المياه (الغمر): تغطي البوليصة الضرر والتلف لممتلكاتكم بسبب انفجار المياه أو فيضان خزانات المياه.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد حريق الأدغال: في الوقت الذي تقوم فيه بإشعال النار في النباتات والشجيرات المتضخمة، فإن من المحتمل أن ينتهي بكم الأمر إلى إتلاف الممتلكات. لا داعي للقلق! حيث أن بوليصة التأمين ضد الحريق التي تمتلكها تغطي هذا أيضًا. ومع ذلك، فإن البوليصة لن تغطي الدمار الناجم عن حرائق الغابات.</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="propertyinsu-pins-list-mob-item-ar">تغطية ضد الإرهاب: تغطية اختيارية</div>
            <div class="propertyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
          </div>
          <h3 class="propertyinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="propertyinsu-mob-whykrlist-ar">
            <div class="propertyinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="propertyinsu-mob-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARPropertyInsurance;