import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../headerEN/headeren";
import FooterEN from "../footerEN/footer";

import './aboutus.scss';

const AboutPage = () => {
  
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  return (
    <div>
      <div class="main-fluid">
        <HeaderEN />
      </div>
      <div class="kr-container-about-en">
        <div class="kr-aboutus-en">
          <div class="kr-about-banner-en">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="kr-banner-titles">
                  <div>
                    <h1 class="abt-title-en">KR Group at a Glance</h1>
                    <p class="abt-banner-txt-en">Khimji Ramdas Group (KR) is a leading business conglomerate known for delivering quality and innovation for over a century. Starting with humble beginnings in 1870, KR grew steadily to become one of the most trusted and respected brand names in the Sultanate of Oman.</p>

                    <p class="abt-sub-title-en">Leading & Trusted business conglomerate in The Sultanate of Oman</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/about_us_img.png"} alt="about us" class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="kr-services-en">
            <h2 class="sev-title-en">KR Group is Committed to Serve</h2>

            <div class="kr-ser-wrp-en">
              <div class="kr-serv-card">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-1.png"} alt="Excellence" />
                <p>Committed to customer service and excellence</p>
              </div>

              <div class="kr-serv-card">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-3.png"} alt="services" />
                <p>Employs more than 5000 <br />employees spread across Oman</p>

              </div>


              <div class="kr-serv-card">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-4.png"} alt="supply chain" />
                <p>Offers world's leading brands in products & services, supported by a high-end distribution and supply chain</p>

              </div>

              <div class="kr-serv-card">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-5.png"} alt="Trading" />
                <p>Diversified group, with in-depth exposures into Trading, Distribution, Retailing, Insurance, Training, Restaurants and Manufacturing</p>

              </div>

              <div class="kr-serv-card">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-6.png"} alt="Turnover" />
                <p>An annual turnover of more <br />than US $1bn.</p>

              </div>

              <div class="kr-serv-card">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-7.png"} alt="SAP" />
                <p>SAP integrated business process</p>

              </div>

            </div>
          </div>
          <div class="kr-milestones-en d-none d-md-block">
            <h3>KR Insurance Milestones</h3>
            <img src={process.env.PUBLIC_URL + "/aboutus_images/aboutus_road_map.png"} alt="Road Map" class="rd-img-en" />
          </div>

          {/* ROAD MAP FOR MOBILE SECTION STARTS HERE */}
          <div class="kr-milestones-mob-en d-block d-sm-none">
            <h3>KR Insurance Milestones</h3>
            <img src={process.env.PUBLIC_URL + "/aboutus_images/mobile_road.png"} alt="Road Map" class="rd-img-en" />
          </div>
          {/* ROAD MAP FOR MOBILE SECTION ENDS HERE */}

          <div class="kr-csr-en">
            <h2 class="kr-csr-title-en">Corporate Social Responsibility</h2>
            <div class="kr-csr-lft-en">
              <p class="csr-txt-en">Since inception, the Khimji Ramdas group has always sought to contribute to the nation’s socio-economic progress by developing innovative solutions that enhance its support to the community, and empower the people to build a vibrant future for themselves.</p>

              <p class="csr-txt-en">Guided by the vision of His Majesty, Sultan Qaboos bin Said, the Khimji Ramdas Group stands committed to playing an effective role in the sustainable development and inclusive growth of all sections of the society through its structured Corporate Social Responsibility (CSR) initiatives and efforts.</p>

              <h6 class="csr-hd-en">ESHRAQA – THE SOCIAL DEVELOPMENT ARM OF KHIMJI RAMDAS</h6>

              <p class="csr-txt-en">Eshraqa in Arabic means the glow from the rays of the dawn or morning sun, signifying new beginnings and hope. Eshraqa will continue building its legacy on the key pillars of Health, Education and Community Wellbeing which today drives its engagement with the Omani society on a not-for-profit objective.</p>

              <p class="csr-lnktxt-en">For more information please visit <a href="https://www.eshraqa.om/" target="_blank">eshraqa.com</a></p>
            </div>
            <div class="kr-csr-rgt-en">
              <img src={process.env.PUBLIC_URL + "/aboutus_images/csr_img.png"} alt="CSR" />
            </div>
             </div>
        </div>
      </div>
            {/* FOOTER STARTS HERE  */}
            <div class="main-fluid">
              <FooterEN />
            </div>
            {/* FOOTER ENDS HERE  */}
    </div>
  );
}

export default AboutPage;