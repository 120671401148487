
import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../headerEN/headeren";
import FooterEN from "../footerEN/footer";
import './contactus.scss';

const ContactPage = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2500);
    return () => clearTimeout(timer);
    
  }, []);
  
  useEffect(() =>
  {
    // FOR DESKTOP 
    var script = document.createElement("script");
    script.id = "ff-script";
    script.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/classic.js?div=ff-compose";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    // FOR TAB 
    var scriptTab = document.createElement("script");
    scriptTab.id = "ff-script";
    scriptTab.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/classic.js?div=ff-compose-Tab";
    scriptTab.defer = true;
    scriptTab.async = true;
    document.body.appendChild(scriptTab);

    // FOR MOBILE 
    var scriptMob = document.createElement("script");
    scriptMob.id = "ff-script";
    scriptMob.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/classic.js?div=ff-compose-Mob";
    scriptMob.defer = true;
    scriptMob.async = true;
    document.body.appendChild(scriptMob);

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  },[])
  const [loader, setLoader] = useState(true)
  return (
    <div class="main-fluid">
      { loader? 
      <div className="loadermain">
        <div class="loader" id="timer"></div>
      </div>
      :null }
      <HeaderEN />
      <div class="container kr-container mb-3">
        <div class="kr-contact-en">

          {/* DESKTOP CONTACT SECTION STARTS HERE  */}
          <div className="dsk-view">
            <div className="contactdkp-en">
              <div className="contactdkp-lft-en">
                <h2 className="contactdkp-lft-title-en">
                  We would be delighted to get in touch with you,<br />
                  Please provide us with the below details
                </h2>
                <div className="contactform-en">
                  {/* <p className="contactfrom-msg-en">AVAILABLE 24 HOURS A DAY</p> */}
                  

                   {/* <div id="ff-compose"></div> */}

                  <iframe id="fraDisabled" width="100%" height="1050" frameborder="0" src="https://formfaca.de/sm/YfF-OQ0zG" onload="disableContextMenu();" onMyLoad="disableContextMenu();">

                  </iframe>
                  
                  {/* <iframe id="fraDisabled" width="528" height="473" src="contactus.js" onload="disableContextMenu();" onMyLoad="disableContextMenu();"></iframe> */}

                </div>
              </div>
              <div className="contactdkp-rgt-en">
                <h2 className="contactdkp-rgt-title-en">WE ARE HERE</h2>
                
                <div className="contact-gmap-en">
                  {/* <p className="contact-gmap-msg-en">MON-FRI 8:30AM - 5PM / PHONE ARE OPEN 24/7</p> */}
                  <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3656.2022821974892!2d58.5398662!3d23.5970773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9e718b31f1b%3A0x8f9b5a9a6f457d55!2sKhimji%20Ramdas%20Insurance%20Services%20LLC!5e0!3m2!1sen!2sin!4v1670304141763!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0" }} allowfullscreen="ture" aria-hidden="false" tabindex="0" ></iframe>
                </div>
                {/* MAP DOWN ADDRESS SECTION STARTS HERE  */}
                  <div className="contactadd-en">
                    <h5 className="office-title-en">
                      <span className="offiimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/location_icon.svg"} alt="Location Icon" /></span>
                      <span className="offitite-en">OFFICE</span>
                    </h5>
                    <p className="office-add-en">
                      Khimji Ramdas Insurance Services LLC <br />
                      (Mezzanine Floor) KR Old Mega Store Building<br />
                      Main Ruwi Road  (Opposite to Haffa House Hotel)<br />
                      Ruwi - Muscat, Sultanate of Oman
                    </p><br />
                    <h5 className="office-title-en">
                      <span className="offiimg-en"></span>
                      <span className="postalitite-en">POSTAL ADDRESS</span>
                    </h5>
                    {/* <h5 className="office-title-en">Postal Address</h5> */}
                    <p className="office-add-en">
                    Khimji Ramdas Insurance Services LLC<br />
                    PO Box - 19; Postal Code - 100,<br />
                    Muscat, Sultanate of Oman
                    </p>
                  </div>   
                {/* MAP DOWN ADDRESS SECTION ENDS HERE  */}

                {/* EMAIL CONTACT SECTON STARTS HERE  */}
                <div className="contactcall-email-en">
                  <h5 className="callemail-title-en">
                  <span className="callimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/call_icon.svg"} alt="Call Icon" /></span>
                  <span className="calltite-en">CALL US</span>
                  </h5>
                  <div className="contactcall-email-sec-en">
                    <a href="tel:+968 98227845" className="contactcallemail-secitem-en">+968 98227845</a>
                    <a href="tel:+ 968 91441394" className="contactcallemail-secitem-en">+ 968 91441394</a>
                </div>
                  <h5 className="email-title-en">EMAIL US</h5>
                  <a href="mailto:insurance@kr.om" className="emlink">insurance@kr.om</a>

                </div>
                {/* EMAIL CONTACT SECTON ENDS HERE  */}

                {/* HOURS SECTION STARTS HERE  */}
                <div className="contact-whrs-en">
                  <h5 className="whrs-title-en">
                  <span className="whrsimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/time_icon.svg"} alt="Time Icon" /></span>
                  <span className="wkhrstite-en">OFFICE HOURS</span>
                  </h5>
                  <div className="contactwhrs-time-en ">
                    <span className="contactwhrs-tmitem-en">Sunday to Thursday: 8:00 AM to 5:00 PM</span>
                    <span className="contactwhrs-tmitem-en">(Lunch: 1:00 PM to 2.00 PM)</span>
                </div>
                </div>

                {/* HOURS SECTION ENDS HERE  */}


              </div>
            </div>
          </div>
          {/* DESKTOP CONTACT SECTION ENDS HERE  */}

          {/* TAB CONTACT SECTION START HERE  */}
          <div className="tab-view">
            <div className="mobcontact-en">
              <h2 className="mobcontact-title-en">
                We would be delighted to get in touch with you, Please provide us with the below details
              </h2>
            {/* <p className="mobcontact-msg-en">AVAILABLE 24 HOURS A DAY</p> */}
              <div className="mobcontact-lft-en">
              <div className="contactform-en">
                {/* <div id="ff-compose-Tab"></div> */}
                <iframe width="100%" height="1050" frameborder="0" src="https://formfaca.de/sm/YfF-OQ0zG"></iframe>
              </div>
            </div>
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1431" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1275" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

            
              <div className="clearfix"></div>
              
              {/* MOBILE ADDRESS SECTION STARTS HERE  */}
              <div className="mobcontactadd-en">
                  <h5 className="moboffice-title-en">
                    <span className="moboffiimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/location_icon.svg"} alt="Location Icon" /></span>
                    <span className="moboffitite-en">OFFICE</span>
                  </h5>
                  <p className="moboffice-add-en">
                    Khimji Ramdas Insurance Services LLC <br />
                    (Mezzanine Floor) KR Old Mega Store Building<br />
                    Main Ruwi Road  (Opposite to Haffa House Hotel)<br />
                    Ruwi - Muscat, Sultanate of Oman
                  </p><br />
                  <h5 className="moboffice-title-en">
                    <span className="moboffiimg-en"></span>
                    <span className="mobpostalitite-en">POSTAL ADDRESS</span>
                  </h5>
                  {/* <h5 className="office-title-en">Postal Address</h5> */}
                  <p className="moboffice-add-en">
                  Khimji Ramdas Insurance Services LLC<br />
                  PO Box - 19; Postal Code - 100,<br />
                  Muscat, Sultanate of Oman
                  </p>
                </div>   
                {/* MOBILE ADDRESS SECTION ENDS HERE  */}
                
                {/* EMAIL CONTACT SECTON STARTS HERE  */}
                <div className="mobcontactcall-email-en">
                <h5 className="mobcallemail-title-en">
                <span className="mobcallimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/call_icon.svg"} alt="Call Icon" /></span>
                <span className="mobcalltite-en">CALL US</span>
                </h5>
                <div className="mobcontactcall-email-sec-en">
                  <a href="tel:+968 98227845" className="mobcontactcallemail-secitem-en">+968 98227845</a>
                  <a href="tel:+ 968 91441394" className="mobcontactcallemail-secitem-en">+ 968 91441394</a>
              </div>
                <h5 className="mobemail-title-en">EMAIL US</h5>
                <a href="mailto:insurance@kr.om" className="mobemlink">insurance@kr.om</a>

              </div>
              {/* EMAIL CONTACT SECTON ENDS HERE  */}

              {/* HOURS SECTION STARTS HERE  */}
              <div className="mobcontact-whrs-en">
                <h5 className="mobwhrs-title-en">
                <span className="mobwhrsimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/time_icon.svg"} alt="Time Icon" /></span>
                <span className="mobwkhrstite-en">OFFICE HOURS</span>
                </h5>
                <div className="mobcontactwhrs-time-en ">
                  <span className="mobcontactwhrs-tmitem-en">Sunday to Thursday: 8:00 AM to 5:00 PM</span>
                  <span className="mobcontactwhrs-tmitem-en">(Lunch: 1:00 PM to 2.00 PM)</span>
              </div>
              </div>
              {/* HOURS SECTION ENDS HERE  */}

              <h2 className="mobgmap-title-en">WE ARE HERE</h2>

              {/* <p className="mobgmap-msg-en">MON-FRI 8:30AM - 5PM / PHONE ARE OPEN 24/7</p> */}
              
              <div className="mobcontact-gmap-en">
                <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3656.2022821974892!2d58.5398662!3d23.5970773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9e718b31f1b%3A0x8f9b5a9a6f457d55!2sKhimji%20Ramdas%20Insurance%20Services%20LLC!5e0!3m2!1sen!2sin!4v1670304141763!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0" }} allowfullscreen="ture" aria-hidden="false" tabindex="0"></iframe>
              </div>

            </div>
          </div>
          {/* TAB CONTACT SECTION ENDS HERE  */}

          {/* MOBILE CONTACT SECTION STARTS HERE  */}
          <div className="mobile-view">
            <div className="mobcontact-en">
              <h2 className="mobcontact-title-en">
                We would be delighted to get in touch with you, Please provide us with the below details
              </h2>
            {/* <p className="mobcontact-msg-en">AVAILABLE 24 HOURS A DAY</p> */}
              <div className="mobcontact-lft-en">
              <div className="contactform-en">
                {/* <div id="ff-compose-Mob"></div> */}
                <iframe width="100%" height="1050" frameborder="0" src="https://formfaca.de/sm/YfF-OQ0zG"></iframe>
              </div>
            </div>
            </div>
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1431" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1275" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}            

            <div className="mobcontact-en">
              {/* MOBILE ADDRESS SECTION STARTS HERE  */}
              <div className="mobcontactadd-en">
                  <h5 className="moboffice-title-en">
                    <span className="moboffiimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/location_icon.svg"} alt="Location Icon" /></span>
                    <span className="moboffitite-en">OFFICE</span>
                  </h5>
                  <p className="moboffice-add-en">
                    Khimji Ramdas Insurance Services LLC <br />
                    (Mezzanine Floor) KR Old Mega Store Building<br />
                    Main Ruwi Road  (Opposite to Haffa House Hotel)<br />
                    Ruwi - Muscat, Sultanate of Oman
                  </p><br />
                  <h5 className="moboffice-title-en">
                    <span className="moboffiimg-en"></span>
                    <span className="mobpostalitite-en">POSTAL ADDRESS</span>
                  </h5>
                  {/* <h5 className="office-title-en">Postal Address</h5> */}
                  <p className="moboffice-add-en">
                  Khimji Ramdas Insurance Services LLC<br />
                  PO Box - 19; Postal Code - 100,<br />
                  Muscat, Sultanate of Oman
                  </p>
                </div>   
                {/* MOBILE ADDRESS SECTION ENDS HERE  */}
                
                {/* EMAIL CONTACT SECTON STARTS HERE  */}
                <div className="mobcontactcall-email-en">
                <h5 className="mobcallemail-title-en">
                <span className="mobcallimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/call_icon.svg"} alt="Call Icon" /></span>
                <span className="mobcalltite-en">CALL US</span>
                </h5>
                <div className="mobcontactcall-email-sec-en">
                  <a href="tel:+968 98227845" className="mobcontactcallemail-secitem-en">+968 98227845</a>
                  <a href="tel:+ 968 91441394" className="mobcontactcallemail-secitem-en">+ 968 91441394</a>
              </div>
                <h5 className="mobemail-title-en">EMAIL US</h5>
                <a href="mailto:insurance@kr.om" className="mobemlink">insurance@kr.om</a>

              </div>
              {/* EMAIL CONTACT SECTON ENDS HERE  */}

              {/* HOURS SECTION STARTS HERE  */}
              <div className="mobcontact-whrs-en">
                <h5 className="mobwhrs-title-en">
                <span className="mobwhrsimg-en"><img src={process.env.PUBLIC_URL + "/contacts_images/time_icon.svg"} alt="Time Icon" /></span>
                <span className="mobwkhrstite-en">OFFICE HOURS</span>
                </h5>
                <div className="mobcontactwhrs-time-en ">
                  <span className="mobcontactwhrs-tmitem-en">Sunday to Thursday: 8:00 AM to 5:00 PM</span>
                  <span className="mobcontactwhrs-tmitem-en">(Lunch: 1:00 PM to 2.00 PM)</span>
              </div>
              </div>
              {/* HOURS SECTION ENDS HERE  */}

              <h2 className="mobgmap-title-en">WE ARE HERE</h2>

              {/* <p className="mobgmap-msg-en">MON-FRI 8:30AM - 5PM / PHONE ARE OPEN 24/7</p> */}
              
              <div className="mobcontact-gmap-en">
                <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3656.2022821974892!2d58.5398662!3d23.5970773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9e718b31f1b%3A0x8f9b5a9a6f457d55!2sKhimji%20Ramdas%20Insurance%20Services%20LLC!5e0!3m2!1sen!2sin!4v1670304141763!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0" }} allowfullscreen="ture" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>
          </div>
          {/* MOBILE CONTACT SECTION ENDS HERE  */}

        </div>
         </div>
         
         {/* FOOTER SECTION STARTS HERE */}
        <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
        
    </div>
  );
}

export default ContactPage;