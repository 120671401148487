import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arhauliersfullvaluecargoinsurance.scss';

const HauliersFullValueCargoInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="hauvalcarinsu-tab-imagebannermain-ar">
          <div class="hauvalcarinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="hauvalcarinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="hauvalcarinsu-tab-btn-ar">
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="hauvalcarinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="hauvalcarinsu-banner-con-ar">
          <div class="hauvalcarinsu-bdy-ar">
            <div class="hauvalcarinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="hauvalcarinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance_ar.png"} alt="تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="hauvalcarinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</h2>
                <p class="pg-txt-con-ar">الغرض من هذه البوليصة هو توفير تغطية تأمينة لشركة النقل / الناقل لفقد أو ضد تلف البضائع التي يتم مناولتها للعملاء بصفتهم متعهد نقل (أو وكيل شحن)</p>

                <p class="pg-txt-con-ar">منتج تأمين خاص يلبي احتياجات الناقلات الصغيرة والمتوسطة الحجم التي تعمل بعقود محددة لنقل البضائع بالشاحنات داخل دول مجلس التعاون الخليجي. وهي توفر تغطية كاملة للقيمة ضد الخسارة أو التلف الذي يلحق بالبضائع أثناء تواجدها في رعاية الشركة المتعهدة بالنقل وحيازتها ومراقبتها</p>

                <p class="pg-txt-con-ar">تطبق أسعار تنافسية على رسوم النقل السنوية المعلنة على الحدود الشهرية</p>

                <p class="pg-txt-con-ar">تغطي البوليصة التأخير الناجم عن انسداد الطرق والظروف الجوية السيئة. كما يغطي الخسارة المسددة حتى استيفاء القيمة الكاملة للبضائع</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 class="hauvalcarinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين على البضائع بالقيمة الكاملة الخاصة بالمتعهدين بالنقل</h3>

            <p class="hauvalcarinsu-txt-con-ar">النقاط الرئيسية في التغطية التأمينية، والمسؤولية القانونية للشركة المتعهدة بالنقل</p>

            <div class="hauvalcarinsu-pins-list-dsk-ar">
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">المسؤوليات عن فقدان أو تلف البضائع</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">الالتزامات الناشئة عن الأخطاء والسهو بما في ذلك التأخير والتسليم غير المصرح به</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">التزامات تجاه الاطراف الثالثة</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">غرامات المخالفات النظامية مثل الجمارك والتلوث والسلامة في العمل</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">تكاليف التحقيق والدفاع والتخفيف</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">تكاليف التخلص بعد وقوع حادث</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">تكاليف الحجر الصحي والتطهير</div>
              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <p class="hauvalcarinsu-txt-con-ar"><strong>تغطي إضافية</strong></p>
              
            <p class="hauvalcarinsu-txt-con-ar"> يمكن أن تشمل التغطية الإضافية لمشغلي الخدمات اللوجستية ما يلي</p>

            <div class="hauvalcarinsu-pins-list-dsk-ar">
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">مخاطر توقف العمل</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">سندات اللجنة البحرية الفيدرالية للناقل المشترك الذي لا يعمل على متن السفن</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">العوارية العامة وضمانات الانقاذ والاشتراكات</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">فقدان أو تلف المعدات المملوكة أو المؤجرة</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">تأمين الممتلكات مثل المباني والمحتويات والآلات ومعدات المناولة</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">البنية التحتية للطرق والسكك الحديدية</div>

              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="hauvalcarinsu-pins-list-dsk-item-ar">التزامات وكالة السفن</div>
              <div class="hauvalcarinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>
            <h3 class="hauvalcarinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>

            <div class="hauvalcarinsu-lft-pins-list-dksp-ar">
              <div class="hauvalcarinsu-lft-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="hauvalcarinsu-lft-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="hauvalcarinsu-lft-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="hauvalcarinsu-lft-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="hauvalcarinsu-tab-imagebannermain-ar">
          <div class="hauvalcarinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="hauvalcarinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="hauvalcarinsu-tab-btn-ar">
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="hauvalcarinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="hauvalcarinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="hauvalcarinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance_ar.png"} alt="تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل" />
              <p>الغرض من هذه البوليصة هو توفير تغطية تأمينة لشركة النقل / الناقل لفقد أو ضد تلف البضائع التي يتم مناولتها للعملاء بصفتهم متعهد نقل (أو وكيل شحن)</p>

              <p>منتج تأمين خاص يلبي احتياجات الناقلات الصغيرة والمتوسطة الحجم التي تعمل بعقود محددة لنقل البضائع بالشاحنات داخل دول مجلس التعاون الخليجي. وهي توفر تغطية كاملة للقيمة ضد الخسارة أو التلف الذي يلحق بالبضائع أثناء تواجدها في رعاية الشركة المتعهدة بالنقل وحيازتها ومراقبتها.</p>

              <p>تطبق أسعار تنافسية على رسوم النقل السنوية المعلنة على الحدود الشهرية</p>

              <p>تغطي البوليصة التأخير الناجم عن انسداد الطرق والظروف الجوية السيئة. كما يغطي الخسارة المسددة حتى استيفاء القيمة الكاملة للبضائع</p>

              <h3 className="tab-sub-title-ar">التغطية الرئيسية / التأمين على البضائع بالقيمة الكاملة الخاصة بالمتعهدين بالنقل</h3>

              <p>النقاط الرئيسية في التغطية التأمينية، والمسؤولية القانونية للشركة المتعهدة بالنقل</p>
              
              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">المسؤوليات عن فقدان أو تلف البضائع</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الالتزامات الناشئة عن الأخطاء والسهو بما في ذلك التأخير والتسليم غير المصرح به</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">التزامات تجاه الاطراف الثالثة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">غرامات المخالفات النظامية مثل الجمارك والتلوث والسلامة في العمل</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تكاليف التحقيق والدفاع والتخفيف</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تكاليف التخلص بعد وقوع حادث</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تكاليف الحجر الصحي والتطهير</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <p><strong>تغطي إضافية</strong></p>
              
              <p> يمكن أن تشمل التغطية الإضافية لمشغلي الخدمات اللوجستية ما يلي</p>
              
              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">مخاطر توقف العمل</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">سندات اللجنة البحرية الفيدرالية للناقل المشترك الذي لا يعمل على متن السفن</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">العوارية العامة وضمانات الانقاذ والاشتراكات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">فقدان أو تلف المعدات المملوكة أو المؤجرة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تأمين الممتلكات مثل المباني والمحتويات والآلات ومعدات المناولة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">البنية التحتية للطرق والسكك الحديدية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">التزامات وكالة السفن</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="hauvalcarinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"} selected class="active">تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="hauvalcarinsu-mob-main-ar">
          <h1 class="hauvalcarinsu-banner-heading-ar">تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</h1>
          <div class="hauvalcarinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance_ar.png"} alt="تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل" />
          </div>

          <p class="hauvalcarinsu-mob-txt-ar">الغرض من هذه البوليصة هو توفير تغطية تأمينة لشركة النقل / الناقل لفقد أو ضد تلف البضائع التي يتم مناولتها للعملاء بصفتهم متعهد نقل (أو وكيل شحن)</p>

          <p class="hauvalcarinsu-mob-txt-ar">منتج تأمين خاص يلبي احتياجات الناقلات الصغيرة والمتوسطة الحجم التي تعمل بعقود محددة لنقل البضائع بالشاحنات داخل دول مجلس التعاون الخليجي. وهي توفر تغطية كاملة للقيمة ضد الخسارة أو التلف الذي يلحق بالبضائع أثناء تواجدها في رعاية الشركة المتعهدة بالنقل وحيازتها ومراقبتها.</p>

          <p class="hauvalcarinsu-mob-txt-ar">تطبق أسعار تنافسية على رسوم النقل السنوية المعلنة على الحدود الشهرية</p>

          <p class="hauvalcarinsu-mob-txt-ar">تغطي البوليصة التأخير الناجم عن انسداد الطرق والظروف الجوية السيئة. كما يغطي الخسارة المسددة حتى استيفاء القيمة الكاملة للبضائع</p>

          <h3 class="hauvalcarinsu-subtitle-ar">التغطية الرئيسية / التأمين على البضائع بالقيمة الكاملة الخاصة بالمتعهدين بالنقل</h3>

          <p class="hauvalcarinsu-mob-txt-ar">النقاط الرئيسية في التغطية التأمينية، والمسؤولية القانونية للشركة المتعهدة بالنقل</p>

          <div class="hauvalcarinsu-pins-list-mob-ar">
            <div class="hauvalcarinsu-pins-list-mob-item-ar">المسؤوليات عن فقدان أو تلف البضائع</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">الالتزامات الناشئة عن الأخطاء والسهو بما في ذلك التأخير والتسليم غير المصرح به</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">التزامات تجاه الاطراف الثالثة</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">غرامات المخالفات النظامية مثل الجمارك والتلوث والسلامة في العمل</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">تكاليف التحقيق والدفاع والتخفيف</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">تكاليف التخلص بعد وقوع حادث</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">تكاليف الحجر الصحي والتطهير</div>
            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <p class="hauvalcarinsu-mob-txt-ar"><strong>تغطي إضافية</strong></p>

          <p class="hauvalcarinsu-mob-txt-ar">يمكن أن تشمل التغطية الإضافية لمشغلي الخدمات اللوجستية ما يلي</p>

          <div class="hauvalcarinsu-pins-list-mob-ar">
            <div class="hauvalcarinsu-pins-list-mob-item-ar">مخاطر توقف العمل</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">سندات اللجنة البحرية الفيدرالية للناقل المشترك الذي لا يعمل على متن السفن</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">العوارية العامة وضمانات الانقاذ والاشتراكات</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">فقدان أو تلف المعدات المملوكة أو المؤجرة</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">تأمين الممتلكات مثل المباني والمحتويات والآلات ومعدات المناولة</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">البنية التحتية للطرق والسكك الحديدية</div>

            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="hauvalcarinsu-pins-list-mob-item-ar">التزامات وكالة السفن</div>
            <div class="hauvalcarinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 class="hauvalcarinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="hauvalcarinsu-mob-whykrlist-ar">
            <div class="hauvalcarinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="hauvalcarinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="hauvalcarinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="hauvalcarinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HauliersFullValueCargoInsurance;