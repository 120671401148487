import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './claimconsultancyservices.scss';

const ClaimConsultancyServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="claimhandinsu-tab-imagebannermain-en">
          <div class="claimhandinsu-banner-con-en">
            <div class="claimhandinsutab-banne-sec-en">
              <h1 class="claimhandinsu-banner-heading-en">Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="claimhandinsu-banner-con-en">
          <div class="claimhandinsu-bdy-en">
            {/* <h2 class="pagetitle-en">Claim Handling Insurance</h2> */}
            <div class="claimhandinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="claimhandinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Claim Consultancy Services</h2>

                <div class="claimhandinsu-keypoint-en">
                  <div class="claimhandinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-en">Dedicated, qualified and experienced team for handling different lines of claims.</div>

                  <div class="claimhandinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-en">Experience of handling transactional and complex claims of our prestigious clients.</div>

                  <div class="claimhandinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-en">Strong relationships with all the Insurers and Loss assessors across GCC region.</div>

                  <div class="claimhandinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-en">Product specific claim approach and commonplace processes.</div>

                  <div class="claimhandinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-en">Technical helps to grasp the policy terms and conditions.</div>

                  <div class="claimhandinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-en">Training programs relying on the clients would like. Periodical sharing of the MIS.</div>
                </div>

                <h3 class="claimhandinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
                <div class="clearfix"></div>

                <div class="claimhandinsu-pins-list-lftdksp-en">
                  <div class="claimhandinsu-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div class="claimhandinsu-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div class="claimhandinsu-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div class="claimhandinsu-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="claimhandinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling.png"} alt="claim handling" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="claimhandinsu-tab-imagebannermain-en">
          <div class="claimhandinsu-banner-con-en">
            <div class="claimhandinsutab-banne-sec-en">
              <h1 class="claimhandinsu-banner-heading-en">Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="claimhandinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Claim Consultancy Services</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling.png"} alt="claim handling" />
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Dedicated, qualified and experienced team for handling different lines of claims.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Experience of handling transactional and complex claims of our prestigious clients.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Strong relationships with all the Insurers and Loss assessors across GCC region.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Product specific claim approach and commonplace processes.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Technical helps to grasp the policy terms and conditions.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Training programs relying on the clients would like. Periodical sharing of the MIS.</p>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

            </div>
          </div>          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="claimhandinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"claim-consultancy-services"} selected class="active">Claim Consultancy Services</option>
            <option value={"service-excellence"}>Service Excellence</option>
            <option value={"in-house-kr-automotive-garage"}>In house KR Automotive Garage</option>
            <option value={"erp-based-solutions"}>ERP Based Solutions</option>
          </select>
        </div>
        <div class="claimhandinsu-mob-main-en">
          <h1 class="claimhandinsu-banner-heading-en">Claim Consultancy Services</h1>
          <div class="claimhandinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling.png"} alt="claim handling" />
          </div>

          <div class="claimhandinsu-pins-list-mob-en">
            <div class="claimhandinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-en">Dedicated, qualified and experienced team for handling different lines of claims.</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-en">Experience of handling transactional and complex claims of our prestigious clients.</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-en">Strong relationships with all the Insurers and Loss assessors across GCC region.</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-en">Product specific claim approach and commonplace processes.</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-en">Technical helps to grasp the policy terms and conditions.</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-en">Training programs relying on the clients would like. Periodical sharing of the MIS.</div>
          </div>

          <h3 class="claimhandinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="claimhandinsu-mob-whykrlist-en">
            <div class="claimhandinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="claimhandinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="claimhandinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="claimhandinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ClaimConsultancyServices;
