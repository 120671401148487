import { Link } from "react-router-dom";

import Slider from "react-slick";

import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";
import HeaderEN from "../headerEN/headeren";
import FooterEN from "../footerEN/footer";
import './home.scss';

const HomePage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const homeslider = {
    dots: true,
    fade: true,
    infinite: true,
    //speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const settings = {
    className: "contact-us-slick-slider",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <div class="main-fluid">
        <HeaderEN />
      </div>
      {/* FIRST SECTION STARTS HERE */}
      <div class="dsk-visable">
        <div class="hm-ft-con-en">
          <div class="hm-ft-con-lft-main-en">
            <h1 class="hm-ft-heading-en">Leading and Trusted Insurance Service Provider in the <span class="omn-title-en">Sultanate of Oman</span></h1>

            <p class="banner-txt-en">We are a Financial Services Authority (FSA) licensed insurance broking company in Oman. We focus on the delivery of best insurance services with wider insurance coverage,  defining risk management programs and bringing cost benefits to our customers. </p>

            <p class="sub-title-en">Our Vision is to drive Oman to the forefront by delivering best insurance practices</p>

          </div>
          <div class="hm-ft-con-rgt-main-en">
            <div class="hm-banner-slider-en">
              <Slider {...homeslider}>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_01.webp"} alt="Property Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_02.webp"} alt="Project Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_03.webp"} alt="Group Health Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_04.webp"} alt="Motor Fleet Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_05.webp"} alt="Cyber Crime Insurance" width="780" height="780" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {/* FIRST SECTION ENDS HERE */}

      {/* TAB FIRST SECTION STARTS HERE */}
      <div class="tab-visable">
        <div class="hm-ft-con-tab-en">
          <div class="hm-ft-con-lft-main-tab-en">
            <h1 class="hm-ft-heading-tab-en">Leading and Trusted Insurance Service Provider in the <span class="omn-title-en">Sultanate of Oman</span></h1>
          </div>
          <div class="hm-ft-con-rgt-main-tab-en">
            <div class="hm-banner-slider-en">
              <Slider {...homeslider}>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_01.webp"} alt="Property Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_02.webp"} alt="Project Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_03.webp"} alt="Group Health Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_04.webp"} alt="Motor Fleet Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/img_05.webp"} alt="Cyber Crime Insurance" width="100%" height="100%" />
                </div>
              </Slider>
            </div>
          </div>

          <div class="hm-ft-con-lft-two-main-tab-en">
            <p class="banner-txt-tab-en">We are a Financial Services Authority (FSA) licensed insurance broking company in Oman. We focus on the delivery of best insurance services with wider insurance coverage,  defining risk management programs and bringing cost benefits to our customers. </p>
            <p class="sub-title-tab-en">Our Vision is to drive Oman to the forefront by delivering best insurance practices</p>
          </div>
        </div>
      </div>
      {/* TAB FIRST SECTION ENDS HERE */}

      {/* FOR MOBILE FIRST SECTION STARTS HERE */}
      <div class="hm-ft-con-mob-en d-block d-sm-none">
        <div class="hm-ft-con-lft-main-mob-en">
          <h1 class="hm-ft-heading-mob-en">Leading and Trusted Insurance Service Provider in the <span class="omn-title-en">Sultanate of Oman</span></h1>
        </div>
        <div class="hm-ft-con-rgt-main-mob-en">
          <div class="hm-banner-slider-en">
            <Slider {...homeslider}>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/mob_img_01.webp"} class="hm-img" alt="Property Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/mob_img_02.webp"} class="hm-img" alt="Project Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/mob_img_03.webp"} class="hm-img" alt="Group Health Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/mob_img_04.webp"} class="hm-img" alt="Motor Fleet Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/mob_img_05.webp"} class="hm-img" alt="Cyber Crime Insurance" width="350" height="350" />
              </div>
            </Slider>
          </div>
        </div>

        <div class="hm-ft-con-lft-two-main-mob-en">
          <p class="banner-txt-mob-en">We are a Financial Services Authority (FSA) licensed insurance broking company in Oman. We focus on the delivery of best insurance services with wider insurance coverage,  defining risk management programs and bringing cost benefits to our customers. </p>

          <p class="sub-title-mob-en">Our Vision is to drive Oman to the forefront by delivering best insurance practices</p>

        </div>
      </div>
      {/* FOR MOBILE FIRST SECTION ENDS HERE */}

      <div class="clearfix"></div>
      {/* SECOND DESKTOP SECTION STARTS HERE */}
      <div class="dsk-visable">
        <div class="hm-sc-con-en">
          <h2 class="we-do-title-en">Our Insurance Solutions and Expertise</h2>
          <div class="hm-sc-en">
            <div class="hm-sc-rgt-en">
              <div class="hm-sc-we-do-en">
                <div class="hm-sc-list-en">
                  <div class="hm-sc-list-img-item-one-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="specialized" /></div>
                  <div class="hm-sc-list-txt-item-en">
                    <p>We are a specialized insurance team who works closely with the customers to understand their insurance & risk management requirements.</p>
                  </div>
                </div>
                <div class="hm-sc-list-en">
                  <div class="hm-sc-list-img-item-two-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="terms icon" /></div>
                  <div class="hm-sc-list-txt-item-en">
                    <p>We deal with all the insurers in Oman. On behalf of our customers, our team arranges wider & competitive insurance terms.</p>
                  </div>
                </div>
                <div class="hm-sc-list-en">
                  <div class="hm-sc-list-img-item-two-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="insurance services" /></div>
                  <div class="hm-sc-list-txt-item-en">
                    <p>We deliver insurance services and assist our customers in the settlement of insurance claims (if any) with an intent to exceed our customer's expectations.</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="hm-sc-lft-en">
              <div class="expertises-sec-en">
                <a href="/property-insurance" class="expertises-card-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="Business Insurance" /></div>
                  <div class="expe-insnm-en">Business Insurance</div>
                </a>
                <a href="/motor-insurance" class="expertises-card-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="Personal Insurance" /></div>
                  <div class="expe-insnm-en">Personal Insurance</div>
                </a>

                <a href="/risk-inspection-services" class="expertises-card-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="Valued Services" /></div>
                  <div class="expe-insnm-en">Valued Services</div>
                </a>
                {/* <a href="/omanbr/claim-consultancy-services" class="expertises-card-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" /></div>
                  <div class="expe-insnm-en">Customer Services</div>
                </a> */}
                <a href="/report-a-claim" class="expertises-card-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" /></div>
                  <div class="expe-insnm-en">Customer Services</div>
                </a>
              </div>
            </div>
            {/* <div class="d-block d-sm-none">
            <h1>Mobile section </h1>
          </div> */}
          </div>
        </div>
      </div>
      {/* SECOND SECTION ENDS HERE */}

      {/* SECOND TAB SECTION STARTS HERE */}
      <div class="tab-visable">
        <div class="hm-sc-tabcon-en">
          <h2 class="we-do-title-en">Our Insurance Solutions and Expertise</h2>

          <div className="hm-sc-tabinc-en">
            <a href="/property-insurance" class="hm-sc-tabinc-item-en">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="Business Insurance" /></div>
              <div class="expe-insnm-en">Business Insurance</div>
            </a>
            <a href="/motor-insurance" class="hm-sc-tabinc-item-en">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="Personal Insurance" /></div>
              <div class="expe-insnm-en">Personal Insurance</div>
            </a>
            <a href="/risk-inspection-services" class="hm-sc-tabinc-item-en">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="Valued Services" /></div>
              <div class="expe-insnm-en">Valued Services</div>
            </a>
            {/* <a href="/omanbr/claim-consultancy-services" class="hm-sc-tabinc-item-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" /></div>
                  <div class="expe-insnm-en">Customer Services</div>
            </a> */}
            <a href="/report-a-claim" class="hm-sc-tabinc-item-en">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" /></div>
              <div class="expe-insnm-en">Customer Services</div>
            </a>
          </div>

          <div class="hm-sc-wedotabcon-en">
            <div class="hm-sc-wedotabconlist-en">
              <div class="hm-sc-listimg-item-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="specialized insurance" /></div>
              <div class="hm-sc-listtxt-item-en">
                We are a specialized insurance team who works closely with the customers to understand their insurance & risk management requirements.
              </div>
            </div>
            <div class="hm-sc-wedotabconlist-en">
              <div class="hm-sc-listimg-item-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="our customers" /></div>
              <div class="hm-sc-listtxt-item-en">
                We deal with all the insurers in Oman. On behalf of our customers, our team arranges wider & competitive insurance terms.
              </div>
            </div>
            <div class="hm-sc-wedotabconlist-en">
              <div class="hm-sc-listimg-item-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="settlement insurance" /></div>
              <div class="hm-sc-listtxt-item-en">
                We deliver insurance services and assist our customers in the settlement of insurance claims (if any) with an intent to exceed our customer’s expectations.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SECOND TAB SECTION ENDS HERE */}

      {/* SECOND MOBILE SECTION STARTS HERE */}
      <div class="mobile-view">
        <div class="hm-sc-con-en">
          <h2 class="we-do-title-en">Our Insurance Solutions and Expertise</h2>
          <div class="hm-sc-en">
            <div class="hm-sc-rgt-en">
              <div class="hm-sc-we-do-en">
                <div class="hm-sc-list-en">
                  <div class="hm-sc-list-img-item-one-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="specialized insurance" width="71" height="60" /></div>
                  <div class="hm-sc-list-txt-item-en">
                    <p>We are a specialized insurance team who works closely with the customers to understand their insurance & risk management requirements.</p>
                  </div>
                </div>
                <div class="hm-sc-list-en">
                  <div class="hm-sc-list-img-item-two-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="insurance terms" width="55" height="62" /></div>
                  <div class="hm-sc-list-txt-item-en">
                    <p>We deal with all the insurers in Oman. On behalf of our customers, our team arranges wider & competitive insurance terms.</p>
                  </div>
                </div>
                <div class="hm-sc-list-en">
                  <div class="hm-sc-list-img-item-two-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="insurance services" width="55" height="62" /></div>
                  <div class="hm-sc-list-txt-item-en">
                    <p>We deliver insurance services and assist our customers in the settlement of insurance claims (if any) with an intent to exceed our customer's expectations.</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="hm-sc-lft-en">
              <div class="expertises-sec-mob-en">
                <a href="/property-insurance" class="expertises-card-mob-en">
                  <div class="expe-insimg-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="Business Insurance" width="35" height="34" /></div>
                  <div class="expe-insnm-mob-en">Business Insurance</div>
                </a>
                <a href="/motor-insurance" class="expertises-card-mob-en">
                  <div class="expe-insimg-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="Personal Insurance" width="35" height="34" /></div>
                  <div class="expe-insnm-mob-en">Personal Insurance</div>
                </a>

                <a href="/risk-inspection-services" class="expertises-card-mob-en">
                  <div class="expe-insimg-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="Valued Services" width="35" height="34" /></div>
                  <div class="expe-insnm-mob-en">Valued Services</div>
                </a>
                <a href="/report-a-claim" class="expertises-card-mob-en">
                  <div class="expe-insimg-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" width="35" height="34" /></div>
                  <div class="expe-insnm-mob-en">Customer Services</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SECOND SECTION ENDS HERE */}

      <div class="main-fluid">
        <div class="kr-container-bdy-en">
          {/* TAB AND DEKSTOP DISPLAY SECTION STARTS HERE */}
          <div class="d-none d-lg-block">
            <div class="addvalue-en">
              <div class="addvalue-item-head-en">
                <h3 class="addvalu-title-en">Adding Value to our Client's Insurance Program</h3>
              </div>

              {/* <div> */}

              <div class="addvalue-cards-en">
                <div class="addvalue-item-en" >
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img01.webp"} alt="claims settlement" width="429" height="354" />
                  <div class="bxone-en">We bring convenience for the top management to decide on their insurance program and facilitate claims settlement</div>
                </div>
                <div class="addvalue-item-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img02.webp"} alt="value cost" width="432" height="357" />
                  <div class="bxtwo-en">We delivered wider terms and save costs up to 30% for our associated clients</div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* TAB AND DEKSTOP DISPLAY SECTION ENDS HERE */}
          {/* MOBILE DISPLAY SECTION STARTS HERE */}
          <div class="d-block d-sm-none">
            <div class="addvalue-mob-en">
              <h3 class="addvalu-title-en">Adding Value to our Client's Insurance Program</h3>
              <div class="clearfix"></div>
              <div class="addvalue-cards-mob-en">
                <div class="addvalue-item-mob-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img01.webp"} class="img-fluid" alt="claims settlement" width="279" height="230" />
                  <div class="mb-bxone-en">We bring convenience for the top management to decide on their insurance program and facilitate claims settlement</div>
                </div>
                <div class="addvalue-item-mob-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img02.webp"} class="img-fluid" alt="value cost" width="279" height="231" />
                  <div class="mb-bxtwo-en">We delivered wider terms and save costs up to 30% for our associated clients</div>
                </div>
              </div>
            </div>
          </div>
          {/* MOBILE DISPLAY SECTION ENDS HERE */}
          {/* DESKTOP DISPLAY STARTS HERE */}
          <div className="dsk-visable">
            <div class="hm-contactus-en">
              <h2 class="hm-contact-title-en">Contact Us</h2>
              <div class="hm-link-txt-en"><a href="mailto:insurance@kr.om">insurance@kr.om</a>, <a href="tel:968 93879145">+968 98227845</a></div>
              <p class="hm-contact-txt-en">Our team would be delighted to work closely on your organization's insurance requirements and deliver best insurance services</p>
              <div class="contact-persons-en">
                <Slider {...settings}>
                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/03_vikram.webp"} class="slideimg" alt="Vikram Aditya" width="245" height="378" />
                      <p class="person-name-en">Vikram Aditya</p>
                      <p class="deg-htem-en">Head of Insurance Services</p>
                    </div>
                  </div>
                  {/* NEW IMAGES SLIDE STARTS HERE */}
                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/01_abbas.webp"} class="slideimg" alt="Abbas Al Sajwany" width="245" height="378" />
                      <p class="person-name-en">Abbas Al Sajwany</p>
                      <p class="deg-htem-en">Senior Manager - Corporate Consultancy Group</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/09_qais_ali_thani_ai_sulaimi.webp"} class="slideimg" alt="Qais Ali Thani AI Sulaimi" width="245" height="378" />
                      <p class="person-name-en">Qais Ali Thani AI Sulaimi</p>
                      <p class="deg-htem-en">Assistant Manager - Motor Claims</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/08_dr_sagar_jagtap.png"} class="slideimg" alt="Sagar Jagtap" width="245" height="378" />
                      <p class="person-name-en">Dr. Sagar Jagtap</p>
                      <p class="deg-htem-en">Head - Medical & Life Claim</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/04_ghalib.webp"} class="slideimg" alt="Ghalib Al Siyabi" width="245" height="378" />
                      <p class="person-name-en">Ghalib Al Siyabi</p>
                      <p class="deg-htem-en">Assistant Manager - Corporate Solutions Group</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/05_sawsan.webp"} class="slideimg" alt="Sawsan Abdul Aziz Al Alawi" width="245" height="378" />
                      <p class="person-name-en">Sawsan Abdul Aziz Al Alawi</p>
                      <p class="deg-htem-en">Manager - Services & Receivables</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/06_mehtab.webp"} class="slideimg" alt="Alam Syed Mahetab" width="245" height="378" />
                      <p class="person-name-en">Alam Syed Mahetab</p>
                      <p class="deg-htem-en">Senior Lead - Corporate Solutions Group</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/07_arun_kumar.webp"} class="slideimg" alt="Arun Kumar Gupta" width="245" height="378" />
                      <p class="person-name-en">Arun Kumar Gupta</p>
                      <p class="deg-htem-en">Head-Claims & Risk Management Services</p>
                    </div>
                  </div>


                  {/* NEW IMAGES SLIDE ENDS HERE */}

                </Slider>
              </div>
            </div>
          </div>
          {/*  DESKTOP DISPLAY ENDS HERE */}
          {/* MOBILE DISPLAY STARTS HERE */}
          <div className="mobile-view">
            <div class="hm-contactus-en">
              <h2 class="hm-contact-title-en">Contact Us</h2>
              <div class="hm-link-txt-en"><a href="mailto:insurance@kr.om">insurance@kr.om</a>, <a href="tel:968 93879145">+968 98227845</a></div>
              <p class="hm-contact-txt-en">Our team would be delighted to work closely on your organization's insurance requirements and deliver best insurance services</p>
              <div class="contact-persons-en">
                <Slider {...settings}>
                  {/* NEW IMAGES SLIDE STARTS HERE */}


                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/03_vikram.webp"} class="slideimg ftslide-img" alt="Vikram Aditya" width="245" height="100%" />
                      <p class="person-name-en">Vikram Aditya</p>
                      <p class="deg-htem-en">Head of Insurance Services</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/01_abbas.webp"} class="slideimg ftslide-img" alt="Abbas Al Sajwany" width="245" height="100%" />
                      <p class="person-name-en">Abbas Al Sajwany</p>
                      <p class="deg-htem-en">Senior Manager - Corporate Consultancy Group</p>
                    </div>
                  </div>

                  {/* <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/02_hamed.webp"}  class="slideimg ftslide-img" alt="Hamed Al-Hanaai" width="245" height="100%" />
                      <p class="person-name-en">Hamed Al-Hanaai</p>
                      <p class="deg-htem-en">Assistant Manager - Corporate Consultancy Group</p>
                    </div>
                  </div> */}

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/09_qais_ali_thani_ai_sulaimi.webp"} class="slideimg ftslide-img" alt="Qais Ali Thani AI Sulaimi" width="245" height="100%" />
                      <p class="person-name-en">Qais Ali Thani AI Sulaimi</p>
                      <p class="deg-htem-en">Assistant Manager - Motor Claims</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/08_dr_sagar_jagtap.png"} class="slideimg ftslide-img" alt="Dr Sagar Jagtap" width="245" height="100%" />
                      <p class="person-name-en">Dr. Sagar Jagtap</p>
                      <p class="deg-htem-en">Head - Medical & Life Claim</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/04_ghalib.webp"} class="slideimg ftslide-img" alt="Ghalib Al Siyabi" width="245" height="100%" />
                      <p class="person-name-en">Ghalib Al Siyabi</p>
                      <p class="deg-htem-en">Assistant Manager - Corporate Solutions Group</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/05_sawsan.webp"} class="slideimg ftslide-img" alt="Sawsan Abdul Aziz Al Alawi" width="245" height="100%" />
                      <p class="person-name-en">Sawsan Abdul Aziz Al Alawi</p>
                      <p class="deg-htem-en">Manager - Services & Receivables</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/06_mehtab.webp"} class="slideimg ftslide-img" alt="Alam Syed Mahetab" width="245" height="100%" />
                      <p class="person-name-en">Alam Syed Mahetab</p>
                      <p class="deg-htem-en">Senior Lead - Corporate Solutions Group</p>
                    </div>
                  </div>

                  <div class="first-person-en">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/07_arun_kumar.webp"} class="slideimg ftslide-img" alt="Arun Kumar Gupta" width="245" height="100%" />
                      <p class="person-name-en">Arun Kumar Gupta</p>
                      <p class="deg-htem-en">Head-Claims & Risk Management Services</p>
                    </div>
                  </div>


                  {/* NEW IMAGES SLIDE ENDS HERE */}

                </Slider>
              </div>
            </div>
          </div>
          {/*  MOBILE DISPLAY ENDS HERE */}
        </div>
        {/* FOOTER STARTS HERE  */}
        <FooterEN />
        {/* FOOTER ENDS HERE  */}
      </div>
    </div>
  );
}
export default HomePage;
