import { Link } from "react-router-dom";

import './headeren.scss';

const HeaderEN = () => {

  return (
    <header class="kr-header-en">
      <div class=" hr-container-en">
        <div class="logo-en">
          <Link to="/omanbr"> <img src={process.env.PUBLIC_URL + "/header_images/logo_200x55.webp"} alt="KR Logo" width="185" height="55" /></Link>
        </div>
        <div class="hr-navigation-en">
          <div class="hidden-mobile"
          >
            <a href="/property-insurance" class="hidden-desktop services hidden-md-device ">Business Insurance</a>
            <a href="/motor-insurance" class="hidden-desktop services hidden-md-device ">Personal Insurance</a>
            <a href="/risk-inspection-services" class="hidden-desktop services hidden-md-device ">Value Added Services</a>
            <a href="/report-a-claim" class="hidden-desktop services hidden-md-device ">Customer Services</a>
            <a href="/contactus" class="contactus-btn">Reach Us</a>
          </div>

          <div className="ar-link" onClick={(e) => window.location.href = window.location.href + "-ar"}>
            {/* AR */}
            عربي
          </div>

          <div class="navigation" >
            <input type="checkbox" class="navigation__checkbox_en" id="nav-toggle" />
            <label for="nav-toggle" class="navigation__button_en">
              <span class="navigation__icon_en"></span>
            </label>
            <div class="navigation__background"></div>
            <nav class="navigation__nav" role="navigation">
              <ul class="navigation__list">
                <li class="navigation__item">
                  <a href="/" class="navigation__link_en">Home</a>
                </li>
                <li class="navigation__item">
                  <a href="/aboutus" class="navigation__link_en">About Us</a>
                </li>
                <li class="navigation__item">
                  <a href="/our-insurance-solutions" class="navigation__link_en">Our Insurance Solutions</a>
                </li>
                <li class="navigation__item">
                  <a href="/team" class="navigation__link_en">Our Team</a>
                </li>
                <li class="navigation__item">
                  <a href="/contactus" class="navigation__link_en">Contact Us</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
export default HeaderEN;