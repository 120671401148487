import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arinsuranceaudits.scss';

const ARInsuranceAudits = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div class="insuraudits-tab-imagebannermain-ar">
          <div class="insuraudits-banner-con-ar">
            <div class="insurauditstab-banne-sec-ar">
              <h1 class="insuraudits-banner-heading-ar">خدمات قيمة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="insuraudits-tab-btn-ar">
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="insuraudits-tab-btn-item-active-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="insuraudits-banner-con-ar">
          <div class="insuraudits-bdy-ar">
            <div class="insuraudits-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="insuraudits-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/insurance_audits_ar.png"} alt="تدقيقات التأمين" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="insuraudits-dlex-lft-ar">
                <h2 class="pagetitle-ar">تدقيقات التأمين</h2>

                <p class="insuraudits-pg-txt-con-ar">تتأكد عمليات تدقيق التأمين من أن الأقساط التي تدفعها تتماشى مع مقدار التغطية ويجب ألا تكون هناك أي فجوات في تغطيات التأمين الخاصة بك</p>

                <p class="insuraudits-pg-txt-con-ar">لدينا فريق تقني متخصص في شركة كيمجي رامداس لخدمات التأمين لإجراء عمليات تدقيق التأمين لبرنامج التأمين الحالي لمؤسستك</p>

                <p class="insuraudits-pg-txt-con-ar">سيقوم فريق شركة كيمجي رامداس لخدمات التأمين بتحليل عوامل الخطر التي تنطوي عليها عمليات عملك ومراجعة جميع شروط وأحكام بوليصات التأمين الحالية لمؤسستك. وسوف نقارنه بأفضل ممارسات السوق ونرسل تقريرًا مفصلاً إلى طرفك، مع التوصية بشروط ذات قيمة مضافة وفعالة من حيث التكلفة</p>

                <p class="insuraudits-pg-txt-con-ar">نحن في شركة كيمجي رامداس لخدمات التأمين نتطلع إلى إجراء تدقيق التأمين لوثائق التأمين الحالية لمؤسستك وضمان أفضل التغطيات التأمينية للمخاطر المرتبطة بعملك</p>
                
               
              
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <h3 className="insuraudits-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div className="clearfix"></div>
            
            <div className="insuraudits-pins-list-dksp-ar">
              <div className="insuraudits-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="insuraudits-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="insuraudits-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="insuraudits-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="insuraudits-tab-imagebannermain-ar">
          <div className="insuraudits-con-ar">
            <div className="insurauditstab-banne-sec-ar">
              <h1 className="insuraudits-banner-heading-ar">خدمات قيمة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="insuraudits-tab-btn-ar">
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="insuraudits-tab-btn-item-active-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="insuraudits-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تدقيقات التأمين</h2>
            <div className="tabs-cont-ar">
            <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/insurance_audits_ar.png"} alt="تدقيقات التأمين" />
            
            <p>تتأكد عمليات تدقيق التأمين من أن الأقساط التي تدفعها تتماشى مع مقدار التغطية ويجب ألا تكون هناك أي فجوات في تغطيات التأمين الخاصة بك</p>
            
            <p>لدينا فريق تقني متخصص في شركة كيمجي رامداس لخدمات التأمين لإجراء عمليات تدقيق التأمين لبرنامج التأمين الحالي لمؤسستك</p>
            
            <p>سيقوم فريق شركة كيمجي رامداس لخدمات التأمين بتحليل عوامل الخطر التي تنطوي عليها عمليات عملك ومراجعة جميع شروط وأحكام بوليصات التأمين الحالية لمؤسستك. وسوف نقارنه بأفضل ممارسات السوق ونرسل تقريرًا مفصلاً إلى طرفك، مع التوصية بشروط ذات قيمة مضافة وفعالة من حيث التكلفة</p>
            
            <p>نحن في شركة كيمجي رامداس لخدمات التأمين نتطلع إلى إجراء تدقيق التأمين لوثائق التأمين الحالية لمؤسستك وضمان أفضل التغطيات التأمينية للمخاطر المرتبطة بعملك</p>
            

            {/* <div className="tabkyfet-ar">
              <div className="tabkeyfet-txt-ar">تحليل المجالات والأنشطة المعرضة للمخاطر في عمليات عملك.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">فحص التمديدات الكهربائية، وطفايات الحريق، والتدبير المنزلي، والتكديس، وما إلى ذلك.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">فحص مستويات الأرض وظروف التربة وقوة الهيكل.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">عمليات التفتيش لمسح الطريق للشحنات ذات الأبعاد الزائدة.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">اقتراح تحسين المخاطر/ تدابير التخفيف من الخسارة.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">جمع المعلومات المتعلقة بالخسائر الكبرى السابقة.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">التفتيش من قبل المهندسين ذوي الخبرة</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>              
              </div> */}
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}


      {/* MOBILE PAGE STARTS HERE */}
      <div class="insuraudits-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services-ar"}>خدمات فحص المخاطر </option>
            <option value={"insurance-audits-ar"} selected className="active">تدقيقات التأمين</option>
            <option value={"valuation-services-ar"}>خدمات التقييم</option>
            <option value={"thermography-testing-insurance-ar"}>اختبار التصوير الحراري</option>
            <option value={"employee-wellness-program-ar"}>برنامج صحة الموظفين</option>
          </select>
        </div>
        <div class="insuraudits-mob-main-en">
          <h1 class="insuraudits-banner-heading-ar">تدقيقات التأمين</h1>
          <div class="insuraudits-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/insurance_audits_ar.png"} alt="تدقيقات التأمين" />
          </div>

          <p class="insuraudits-mob-txt-en">تتأكد عمليات تدقيق التأمين من أن الأقساط التي تدفعها تتماشى مع مقدار التغطية ويجب ألا تكون هناك أي فجوات في تغطيات التأمين الخاصة بك</p>

          <p class="insuraudits-mob-txt-en">لدينا فريق تقني متخصص في شركة كيمجي رامداس لخدمات التأمين لإجراء عمليات تدقيق التأمين لبرنامج التأمين الحالي لمؤسستك</p>

          <p class="insuraudits-mob-txt-en">سيقوم فريق شركة كيمجي رامداس لخدمات التأمين بتحليل عوامل الخطر التي تنطوي عليها عمليات عملك ومراجعة جميع شروط وأحكام بوليصات التأمين الحالية لمؤسستك. وسوف نقارنه بأفضل ممارسات السوق ونرسل تقريرًا مفصلاً إلى طرفك، مع التوصية بشروط ذات قيمة مضافة وفعالة من حيث التكلفة</p>

          <p class="insuraudits-mob-txt-en">نحن في شركة كيمجي رامداس لخدمات التأمين نتطلع إلى إجراء تدقيق التأمين لوثائق التأمين الحالية لمؤسستك وضمان أفضل التغطيات التأمينية للمخاطر المرتبطة بعملك</p>
          
          <h3 className="insuraudits-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div className="insuraudits-mob-whykrlist-ar">
            <div className="insuraudits-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="insuraudits-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="insuraudits-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="insuraudits-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARInsuranceAudits;
