import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arstockthroughputinsurance.scss';

const ARStockThroughputInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="stckcovinsu-tab-imagebannermain-ar">
          <div class="stckcovinsu-banner-con-ar">
            <div class="stckcovinsutab-banne-sec-ar">
              <h1 class="stckcovinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="stckcovinsu-tab-btn-ar">
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="stckcovinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="stckcovinsu-banner-con-ar">
          <div class="stckcovinsu-bdy-ar">
            <div class="stckcovinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="stckcovinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/stock_throughput_insurance_ar.png"} alt="تأمين صافي المخزون" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}

              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="stckcovinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">تأمين صافي المخزون</h2>

                <p class="pg-txt-con-ar">تم تصميم هذه البوليصة خصيصًا لتوفير تغطية تأمينية شاملة للمنتجات أثناء التخزين أو أثناء النقل. بمجرد تصنيع البضائع، فإنها إما تصل مباشرة إلى العميل أو يتم تخزينها في المستودعات قبل التوزيع التالي. إذا تم إرسالها إلى التخزين قبل التوزيع التالي، فإن بوليصة إنتاجية المخزون ستكون الحل الأمثل</p>

                <p class="pg-txt-con-ar">الفوائد الهامة للتغطية التأمينة على إنتاجية المخزون هي</p>

                <div class="stckcovinsu-keypoint-ar">
                  <div class="stckcovinsu-keypoint-item-ar">القضاء على الفجوات المحتملة بين البوليصات المتعددة</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar">الجمع بين عدد من البوليصات تحت بوليصة واحدة يُقدم وفورات كبيرة في الأسعار</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar">تغطية متخصصة لقطاعات الصناعة والأعمال المعقدة</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar">تغطية المصالح الأولية والطارئة</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar"> حدود بعيدة وخصومات منخفضة</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar">يمكن تصنيف القسط مقابل حجم المبيعات</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar">يقلل من الحاجة إلى إهدار الوقت في الإقرارات</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-ar">أسعار تنافسية بالنسبة لسعة مستندات الكوراث</div>
                  <div class="stckcovinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="stckcovinsu-txt-con-ar">تعد حلول التأمين على المخزون المُقدمة من شركة كيمجي رامداس مناسبة لتجار التجزئة والمصنعين والتجار، والتي يمكن أن تشمل</p>

            <div class="stckcovinsu-pins-list-dsk-ar">
              <div class="stckcovinsu-pins-list-dsk-item-ar">التنقل الدولي والمحلي بأي وسيلة نقل</div>

              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">المخزون، بما في ذلك المواد الخام، والعمل الجاري وكذلك السلع التامة الصنع</div>

              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">الامتدادات الملحقة مثل العينات المُحملة والمعارض والعروض التجارية</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <p class="stckcovinsu-txt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين منتجات فريدة بقصد وحيد هو حماية مخزونك النهائي، سواء في عملية التصنيع أو التخزين أو النقل</p>

            <p class="stckcovinsu-txt-con-ar">لدى شركة كيمجي رامداس لخدمات التأمين خدمات فريدة لإدارة المخاطر والتي تقدم الاستشارات لحفظ المخزون في المستودعات، واختيار السفن، والتخزين والتعبئة والتغليف. كما نقدم استطلاعات الطريق على أساس كل حالة على حدة. لدينا خطوط مساعدة للطوارئ تعمل على مدار الساعة طوال أيام الأسبوع، مما يضمن أفضل خدمات تسوية المطالبات لعملائنا</p>

            <h3 class="stckcovinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين صافي المخزون</h3>

            <div class="stckcovinsu-pins-list-dsk-ar">
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد الحرائق أو الإنفجارات أو الجنوح، أو الغرق وما إلى ذلك - لا يمكن التنبؤ بهذه المواقف مسبقًا. تعتبر الحرائق أو الإنفجارات أو الغرق أو الجنوح من المشكلات الشائعة التي يتم مواجهتها أثناء رحلات الشحن. تم وضع خطة تأمين بحري للتعامل مع مثل هذه المواقف العصيبة</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد الاصطدام أو الانقلاب أو الانحراف في حالة النقل البري - في بعض الأحيان، بغض النظر عن مدى صعوبة محاولة القبطان، فلا يمكنه السيطرة على المواقف التي تسبب الأضرار غير المرغوب فيها. لذا فإن إن وجود خطة تأمين بحري يحيد مخاطر الأضرار الناجمة عن الاصطدام أو الانقلاب أو الانحراف في حالة النقل البري</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد تفريغ البضائع في ميناء الإنقاذ – عندما يتم تفريغ البضاعة من ميناء في حالة اضطراب أو محنة، وفي حالة تهديد سلامة البضائع. يكون هذا هو السبب الذي يتم من خلاله تضمينها في التغطية التأمينية الأساسية</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد رسوم العامة للتعويض عن الخسائر- توفر خطة التأمين البحري تغطية رسوم العوارية العامة للتعويض عن الخسائر</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد النفقات مثل رسوم المسح، ونفقات إعادة التوجيه، وتكاليف إعادة التهيئة ورسوم الدعوى - هذه النفقات غير متوقعة في بعض الأحيان مما لا يترك لك سوى القليل من الوقت لدفع هذه النفقات. هذا هو السبب من ضرورة وجود خطة تأمين بحري وفائدتها</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد طرح الحمولة الزائدة من السفن أو الغسل في البحر - حالات طرح الحمولة الزائدة من السفن والغسيل من على ظهر السفينة مدرجة في خطة التأمين البحري</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد الزلازل أو ضربات البرق - توفر لك خطة التأمين البحري تغطية من الكوارث الطبيعية مثل الزلازل أو ضربات البرق</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-ar">التأمين ضد الخسارة الكاملة للحزمة في البحر أو سقوطها أثناء التحميل أو التفريغ - بغض النظر عن مدى صعوبة فقدان الحزمة، في كل مرة لا يمكنك إنشاء خطة مضمونة لمنع هذا الموقف من الحدوث عندما يتم نقل الحزمة الخاصة بك في شحنة. هذا هو سبب تضمينها في خطة التأمين البحري الأساسية</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>

            <h3 class="stckcovinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>

            <div class="stckcovinsu-pins-list-dksp-ar">
              <div class="stckcovinsu-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="stckcovinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="stckcovinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="stckcovinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="stckcovinsu-tab-imagebannermain-ar">
          <div class="stckcovinsu-banner-con-ar">
            <div class="stckcovinsutab-banne-sec-ar">
              <h1 class="stckcovinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="stckcovinsu-tab-btn-ar">
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="stckcovinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="stckcovinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="stckcovinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تأمين صافي المخزون</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/stock_throughput_insurance_ar.png"} alt="تأمين صافي المخزون" />

              <p>تم تصميم هذه البوليصة خصيصًا لتوفير تغطية تأمينية شاملة للمنتجات أثناء التخزين أو أثناء النقل. بمجرد تصنيع البضائع، فإنها إما تصل مباشرة إلى العميل أو يتم تخزينها في المستودعات قبل التوزيع التالي. إذا تم إرسالها إلى التخزين قبل التوزيع التالي، فإن بوليصة إنتاجية المخزون ستكون الحل الأمثل</p>

              <p>الفوائد الهامة للتغطية التأمينة على إنتاجية المخزون هي</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">القضاء على الفجوات المحتملة بين البوليصات المتعددة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">الجمع بين عدد من البوليصات تحت بوليصة واحدة يُقدم وفورات كبيرة في الأسعار</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية متخصصة لقطاعات الصناعة والأعمال المعقدة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية المصالح الأولية والطارئة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar"> حدود بعيدة وخصومات منخفضة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">يمكن تصنيف القسط مقابل حجم المبيعات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">يقلل من الحاجة إلى إهدار الوقت في الإقرارات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">أسعار تنافسية بالنسبة لسعة مستندات الكوراث</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <p>تعد حلول التأمين على المخزون المُقدمة من شركة كيمجي رامداس مناسبة لتجار التجزئة والمصنعين والتجار، والتي يمكن أن تشمل</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">التنقل الدولي والمحلي بأي وسيلة نقل</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">المخزون، بما في ذلك المواد الخام، والعمل الجاري وكذلك السلع التامة الصنع</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">الامتدادات الملحقة مثل العينات المُحملة والمعارض والعروض التجارية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <p>تقدم شركة كيمجي رامداس لخدمات التأمين منتجات فريدة بقصد وحيد هو حماية مخزونك النهائي، سواء في عملية التصنيع أو التخزين أو النقل</p>
              
              <p>لدى شركة كيمجي رامداس لخدمات التأمين خدمات فريدة لإدارة المخاطر والتي تقدم الاستشارات لحفظ المخزون في المستودعات، واختيار السفن، والتخزين والتعبئة والتغليف. كما نقدم استطلاعات الطريق على أساس كل حالة على حدة. لدينا خطوط مساعدة للطوارئ تعمل على مدار الساعة طوال أيام الأسبوع، مما يضمن أفضل خدمات تسوية المطالبات لعملائنا</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / تأمين صافي المخزون</h3>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">التأمين ضد الحرائق أو الإنفجارات أو الجنوح، أو الغرق وما إلى ذلك - لا يمكن التنبؤ بهذه المواقف مسبقًا. تعتبر الحرائق أو الإنفجارات أو الغرق أو الجنوح من المشكلات الشائعة التي يتم مواجهتها أثناء رحلات الشحن. تم وضع خطة تأمين بحري للتعامل مع مثل هذه المواقف العصيبة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد الاصطدام أو الانقلاب أو الانحراف في حالة النقل البري - في بعض الأحيان، بغض النظر عن مدى صعوبة محاولة القبطان، فلا يمكنه السيطرة على المواقف التي تسبب الأضرار غير المرغوب فيها. لذا فإن إن وجود خطة تأمين بحري يحيد مخاطر الأضرار الناجمة عن الاصطدام أو الانقلاب أو الانحراف في حالة النقل البري</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد تفريغ البضائع في ميناء الإنقاذ – عندما يتم تفريغ البضاعة من ميناء في حالة اضطراب أو محنة، وفي حالة تهديد سلامة البضائع. يكون هذا هو السبب الذي يتم من خلاله تضمينها في التغطية التأمينية الأساسية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد رسوم العامة للتعويض عن الخسائر- توفر خطة التأمين البحري تغطية رسوم العوارية العامة للتعويض عن الخسائر</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد النفقات مثل رسوم المسح، ونفقات إعادة التوجيه، وتكاليف إعادة التهيئة ورسوم الدعوى - هذه النفقات غير متوقعة في بعض الأحيان مما لا يترك لك سوى القليل من الوقت لدفع هذه النفقات. هذا هو السبب من ضرورة وجود خطة تأمين بحري وفائدتها</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد طرح الحمولة الزائدة من السفن أو الغسل في البحر - حالات طرح الحمولة الزائدة من السفن والغسيل من على ظهر السفينة مدرجة في خطة التأمين البحري</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد الزلازل أو ضربات البرق - توفر لك خطة التأمين البحري تغطية من الكوارث الطبيعية مثل الزلازل أو ضربات البرق</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد الخسارة الكاملة للحزمة في البحر أو سقوطها أثناء التحميل أو التفريغ - بغض النظر عن مدى صعوبة فقدان الحزمة، في كل مرة لا يمكنك إنشاء خطة مضمونة لمنع هذا الموقف من الحدوث عندما يتم نقل الحزمة الخاصة بك في شحنة. هذا هو سبب تضمينها في خطة التأمين البحري الأساسية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="stckcovinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"} selected class="active">تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="stckcovinsu-mob-main-ar">
          <h1 class="stckcovinsu-banner-heading-ar">تأمين صافي المخزون</h1>
          <div class="stckcovinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/stock_throughput_insurance_ar.png"} alt="تأمين صافي المخزون" />
          </div>


          <p class="stckcovinsu-mob-txt-ar">تم تصميم هذه البوليصة خصيصًا لتوفير تغطية تأمينية شاملة للمنتجات أثناء التخزين أو أثناء النقل. بمجرد تصنيع البضائع، فإنها إما تصل مباشرة إلى العميل أو يتم تخزينها في المستودعات قبل التوزيع التالي. إذا تم إرسالها إلى التخزين قبل التوزيع التالي، فإن بوليصة إنتاجية المخزون ستكون الحل الأمثل</p>

          <p class="stckcovinsu-mob-txt-ar">الفوائد الهامة للتغطية التأمينة على إنتاجية المخزون هي</p>

          <div class="stckcovinsu-pins-list-mob-ar">
            <div class="stckcovinsu-pins-list-mob-item-ar">القضاء على الفجوات المحتملة بين البوليصات المتعددة</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">الجمع بين عدد من البوليصات تحت بوليصة واحدة يُقدم وفورات كبيرة في الأسعار</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">تغطية متخصصة لقطاعات الصناعة والأعمال المعقدة</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">تغطية المصالح الأولية والطارئة</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar"> حدود بعيدة وخصومات منخفضة</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">يمكن تصنيف القسط مقابل حجم المبيعات</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">يقلل من الحاجة إلى إهدار الوقت في الإقرارات</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">أسعار تنافسية بالنسبة لسعة مستندات الكوراث</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <p class="stckcovinsu-mob-txt-ar">تعد حلول التأمين على المخزون المُقدمة من شركة كيمجي رامداس مناسبة لتجار التجزئة والمصنعين والتجار، والتي يمكن أن تشمل </p>

          <div class="stckcovinsu-pins-list-mob-ar">
            <div class="stckcovinsu-pins-list-mob-item-ar">التنقل الدولي والمحلي بأي وسيلة نقل</div>

            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">المخزون، بما في ذلك المواد الخام، والعمل الجاري وكذلك السلع التامة الصنع</div>

            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">الامتدادات الملحقة مثل العينات المُحملة والمعارض والعروض التجارية</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>

          <p class="stckcovinsu-mob-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين منتجات فريدة بقصد وحيد هو حماية مخزونك النهائي، سواء في عملية التصنيع أو التخزين أو النقل</p>

          <p class="stckcovinsu-mob-txt-ar">لدى شركة كيمجي رامداس لخدمات التأمين خدمات فريدة لإدارة المخاطر والتي تقدم الاستشارات لحفظ المخزون في المستودعات، واختيار السفن، والتخزين والتعبئة والتغليف. كما نقدم استطلاعات الطريق على أساس كل حالة على حدة. لدينا خطوط مساعدة للطوارئ تعمل على مدار الساعة طوال أيام الأسبوع، مما يضمن أفضل خدمات تسوية المطالبات لعملائنا</p>

          <h3 class="stckcovinsu-subtitle-ar">التغطية الرئيسية / تأمين صافي المخزون</h3>

          <div class="stckcovinsu-pins-list-mob-ar">
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد الحرائق أو الإنفجارات أو الجنوح، أو الغرق وما إلى ذلك - لا يمكن التنبؤ بهذه المواقف مسبقًا. تعتبر الحرائق أو الإنفجارات أو الغرق أو الجنوح من المشكلات الشائعة التي يتم مواجهتها أثناء رحلات الشحن. تم وضع خطة تأمين بحري للتعامل مع مثل هذه المواقف العصيبة</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد الاصطدام أو الانقلاب أو الانحراف في حالة النقل البري - في بعض الأحيان، بغض النظر عن مدى صعوبة محاولة القبطان، فلا يمكنه السيطرة على المواقف التي تسبب الأضرار غير المرغوب فيها. لذا فإن إن وجود خطة تأمين بحري يحيد مخاطر الأضرار الناجمة عن الاصطدام أو الانقلاب أو الانحراف في حالة النقل البري</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد تفريغ البضائع في ميناء الإنقاذ – عندما يتم تفريغ البضاعة من ميناء في حالة اضطراب أو محنة، وفي حالة تهديد سلامة البضائع. يكون هذا هو السبب الذي يتم من خلاله تضمينها في التغطية التأمينية الأساسية</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد رسوم العامة للتعويض عن الخسائر- توفر خطة التأمين البحري تغطية رسوم العوارية العامة للتعويض عن الخسائر</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد النفقات مثل رسوم المسح، ونفقات إعادة التوجيه، وتكاليف إعادة التهيئة ورسوم الدعوى - هذه النفقات غير متوقعة في بعض الأحيان مما لا يترك لك سوى القليل من الوقت لدفع هذه النفقات. هذا هو السبب من ضرورة وجود خطة تأمين بحري وفائدتها</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد طرح الحمولة الزائدة من السفن أو الغسل في البحر - حالات طرح الحمولة الزائدة من السفن والغسيل من على ظهر السفينة مدرجة في خطة التأمين البحري</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد الزلازل أو ضربات البرق - توفر لك خطة التأمين البحري تغطية من الكوارث الطبيعية مثل الزلازل أو ضربات البرق</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-ar">التأمين ضد الخسارة الكاملة للحزمة في البحر أو سقوطها أثناء التحميل أو التفريغ - بغض النظر عن مدى صعوبة فقدان الحزمة، في كل مرة لا يمكنك إنشاء خطة مضمونة لمنع هذا الموقف من الحدوث عندما يتم نقل الحزمة الخاصة بك في شحنة. هذا هو سبب تضمينها في خطة التأمين البحري الأساسية</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>

          <h3 class="stckcovinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="stckcovinsu-mob-whykrlist-ar">
            <div class="stckcovinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="stckcovinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="stckcovinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="stckcovinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARStockThroughputInsurance;