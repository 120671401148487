import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './cybercrimeinsurance.scss';

const CyberCrimeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="cybercrimeinsutab-imagebannermain-en">
          <div class="cybercrimeinsubanner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="cybercrimeinsubanner-heading-en">Business Insurance</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="cybercrimeinsubanner-con-en">
          <div class="cybercrimeinsubdy-en">
            <div class="cybercrimeinsudflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="cybercrimeinsudlex-lft-en">
                <h2 class="pagetitle-en">Cyber Crime Insurance</h2>
                <p class="cybercrimeinsu-txt-con">Most people have heard of cyber insurance. News of cyber breaches grace the headlines almost weekly these days. But are you really covered for all types of thefts committed using the latest technology? The answer is probably not, especially if you aren’t purchasing crime insurance. Let’s take a look at what you’re covered for under a cyber and crime policy.</p>

                <p class="cybercrimeinsu-txt-con">KR Insurance Services offers Cyber-insurance solutions to protect businesses and individual users from Internet-based risks, and more generally from risks relating to information technology infrastructure and activities.</p>

                <h3 class="cybercrimeinsu-page-sbu-title-en">Main Cover / Cyber Crime Insurance</h3>

                <p class="cybercrimeinsutxt-con">Coverage provided by cyber-insurance policies may include -</p>
                <div class="cybercrimeinsupins-lft-dsk-en">
                <div class="cybercrimeinsupins-lft-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-lft-dsk-item-en">Data breach liability - for personal & corporate data</div>

              <div class="cybercrimeinsupins-lft-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-lft-dsk-item-en">Data breach costs - including notification costs & IT forensic costs</div>

              <div class="cybercrimeinsupins-lft-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-lft-dsk-item-en">Network security liability - for hacked or compromised systems including denial of service attacks</div>
                </div>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="cybercrimeinsudlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/cyber_crime_insurance.png"} alt="Cyber Crime Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>


            <div class="cybercrimeinsupins-list-dsk-en">

              <div class="cybercrimeinsupins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-en">Media liability - for digital publications</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-en">Business interruption - caused by a cyber-incident</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-en">Restoration costs for data & programs - resulting from a cyber-business interruption event</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-en">Crisis communication - to mitigate reputational damage</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-en">Hacker theft cover - based upon theft of funds</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-en">E-payment liability - PCI fines and penalties covered</div>
            </div>

            <p class="cybercrimeinsutxt-con">KR Insurance services, jointly with world’s leading insurance broker – Willis Towers watson, provides comprehensive cyber insurance covers to their clients supported by the experienced claim settlement team.</p>

            <p class="cybercrimeinsutxt-con">We look forward to provide best in industry cyber insurance solutions to your esteemed organisation.</p>

            <h3 class="cybercrimeinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="cybercrimeinsupins-list-lftdksp-en">
              <div class="cybercrimeinsult-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="cybercrimeinsult-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="cybercrimeinsult-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="cybercrimeinsult-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="cybercrimeinsutab-imagebannermain-en">
          <div class="cybercrimeinsubanner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="cybercrimeinsubanner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="cybercrimeinsubanner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Cyber Crime Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/cyber_crime_insurance.png"} alt="Cyber Crime Insurance" />

              <p>Most people have heard of cyber insurance. News of cyber breaches grace the headlines almost weekly these days. But are you really covered for all types of thefts committed using the latest technology? The answer is probably not, especially if you aren't purchasing crime insurance. Let's take a look at what you're covered for under a cyber and crime policy.</p>

              <p>KR Insurance Services offers Cyber-insurance solutions to protect businesses and individual users from Internet-based risks, and more generally from risks relating to information technology infrastructure and activities.</p>

              <h3 class="tab-sub-title-en">Main Cover / Cyber Crime Insurance</h3>

              <p>Coverage provided by cyber-insurance policies may include -</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Data breach liability - for personal & corporate data</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Data breach costs - including notification costs & IT forensic costs</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Network security liability - for hacked or compromised systems including denial of service attacks</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Media liability - for digital publications</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Business interruption - caused by a cyber-incident</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Restoration costs for data & programs - resulting from a cyber-business interruption event</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Crisis communication - to mitigate reputational damage</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Hacker theft cover - based upon theft of funds</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">E-payment liability - PCI fines and penalties covered</div>
              </div>
              
              <p>KR Insurance services, jointly with world's leading insurance broker - Willis Towers watson, provides comprehensive cyber insurance covers to their clients supported by the experienced claim settlement team.</p>
              
              <p>We look forward to provide best in industry cyber insurance solutions to your esteemed organisation.</p>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="cybercrimeinsutab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"} selected class="active">Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="cybercrimeinsumob-main-en">
          <h1 class="cybercrimeinsubanner-heading-en">Cyber Crime Insurance</h1>
          <div class="cybercrimeinsumob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/cyber_crime_insurance.png"} alt="Cyber Crime Insurance" />
          </div>

          <p class="cybercrimeinsumob-txt-en">Most people have heard of cyber insurance. News of cyber breaches grace the headlines almost weekly these days. But are you really covered for all types of thefts committed using the latest technology? The answer is probably not, especially if you aren’t purchasing crime insurance. Let’s take a look at what you’re covered for under a cyber and crime policy.</p>

          <p class="cybercrimeinsumob-txt-en">KR Insurance Services offers Cyber-insurance solutions to protect businesses and individual users from Internet-based risks, and more generally from risks relating to information technology infrastructure and activities.</p>

          <h3 class="cybercrimeinsusubtitle-en">Main Cover / Cyber Crime Insurance</h3>

          <p class="cybercrimeinsumob-txt-en">Coverage provided by cyber-insurance policies may include -</p>

          <div class="cybercrimeinsupins-list-mob-en">
            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Data breach liability - for personal & corporate data</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Data breach costs - including notification costs & IT forensic costs</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Network security liability - for hacked or compromised systems including denial of service attacks</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Media liability - for digital publications</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Business interruption - caused by a cyber-incident</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Restoration costs for data & programs - resulting from a cyber-business interruption event</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Crisis communication - to mitigate reputational damage</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">Hacker theft cover - based upon theft of funds</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-en">E-payment liability - PCI fines and penalties covered</div>

          </div>
          <p class="cybercrimeinsumob-txt-en">KR Insurance services, jointly with world’s leading insurance broker – Willis Towers watson, provides comprehensive cyber insurance covers to their clients supported by the experienced claim settlement team.</p>

          <p class="cybercrimeinsumob-txt-en">We look forward to provide best in industry cyber insurance solutions to your esteemed organisation.</p>

          <h3 class="cybercrimeinsuwhykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="cybercrimeinsumob-whykrlist-en">
            <div class="cybercrimeinsumob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="cybercrimeinsumob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="cybercrimeinsumob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="cybercrimeinsumob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
        <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default CyberCrimeInsurance;