import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arspecialityriskeventinsurance.scss';

const ARSpecialityRiskEventInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <HeaderAR />
    {/* DESK TOP AND TABS SECTION STARTS HERE */}
    <div class="dsk-tab-btn-viewmode-ar">
      <div class="splriskevtinsu-tab-imagebannermain-ar">
        <div class="splriskevtinsu-banner-con-ar">
          <div class="splriskevtinsutab-banne-sec-ar">
              <h1 class="splriskevtinsu-banner-heading-ar">تأمين الأعمال </h1>
            {/* TAB BUTTON SECTION STARTS HERE */}
            <div class="splriskevtinsu-tab-btn-ar">
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="splriskevtinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
            {/* TAB BUTTON SECTION ENDS HERE */}
          </div>
        </div>
      </div>
      <div class="splriskevtinsu-banner-con-ar">
        <div class="splriskevtinsu-bdy-ar">
          <div class="splriskevtinsu-dflex-ar">
            {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
            <div class="splriskevtinsu-dlex-rgt-ar">
              <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/specialty_risk_event_insurance_ar.png"} alt="specialty risk event insurance" />
            </div>
            {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
            {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
            <div class="splriskevtinsu-dlex-lft-ar">
              <h2 class="pagetitle-ar">مخاطر التخصص / التأمين أثناء الفعاليات</h2>

              <p class="pg-txt-con-ar">إذا كنت تخطط لفعالية ما، سواء كان مؤتمرًا تجاريًا عالميًا أو مؤتمرًا ملائمًا ليُقام في قرية، فيمكننا مساعدتك في الحماية من ما هو غير متوقع. توفر شركة كيمجي رامداس لخدمات التأمين تأمينًا متخصصًا لجميع أنواع الأحداث والمخاطر التي تصاحبها. سواء كان ذلك إغلاقًا للمكان، أو أضرارًا في الممتلكات، أو نزيلًا مصابًا، فنحن نقدم لك التغطية بغرض إراحة ذهنك، حتى تتمكن من التركيز على التخطيط لمناسبتك</p>

              <p class="pg-txt-con-ar">تم تصميم تأمين الأحداث لحماية حامل الوثيقة من الدعاوى القضائية والمطالبات المتعلقة بالإصابة الجسدية للآخرين والأضرار التي تلحق بالممتلكات في المبنى المستأجر</p>

              <p class="pg-txt-con-ar">قد تكون الأحداث التي يتم تغطيتها تحت غطاء تأمين الحدث - مؤتمرات، معارض، حفلات زفاف، ندوات، بطولات رياضية أو أي حدث شخصي، يتم تنظيمه في مكان خاص أو عام. قد تكون الأحداث لفترات أقصر (ساعات) إلى فترات أطول (أيام)</p>

              <p class="pg-txt-con-ar">تندرج التغطيات الهامة تحت</p>

              <p class="pg-txt-con-ar"><strong>الإلغاء</strong><br />تغطي البوليصة النفقات غير القابلة للاسترداد التي تم انفاقها أو المستحقة بعد إلغاء الفعالية أو توقفها أو تأجيلها لأي سبب لا يخضع لسيطرة حامل البوليصة</p>
              
            </div>
            {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
          </div>
          
          

          {/* <p class="splriskevtinsu-txt-con-ar">تندرج التغطيات الهامة تحت:</p> */}

          {/* <p class="splriskevtinsu-txt-con-ar"><strong>الإلغاء</strong><br />تغطي البوليصة النفقات غير القابلة للاسترداد التي تم انفاقها أو المستحقة بعد إلغاء الفعالية أو توقفها أو تأجيلها لأي سبب لا يخضع لسيطرة حامل البوليصة</p> */}

          <p class="splriskevtinsu-txt-con-ar"><strong>المعدات</strong><br /> يغطي التأمين معدات الصوت أو العرض أو الإضاءة أو التصوير أو التسجيل الصوتي أو البث والاستنساخ، في حالة التلف العرضي أو التدمير أو السطو أو السرقة أو قصر الدائرة أو الجهد الزائد أو تعقب الاستقراءات</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>عدم الظهور</strong><br /> يغطي النفقات المتكبدة بعد تأجيل أو إلغاء أو التخلي عن حدث مؤمن عليه ناتج عن وفاة أو إصابة عرضية أو مرض أو احتجاز ضد إرادته لشخص اسمه خلال فترة التغطية وأقاربهم حتى الدرجة الأولى</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>المسئولية العامة</strong><br /> يغطي الخسائر المالية المتكبدة في حالة الإصابة الجسدية العرضية لأي شخص، الإضرار بالممتلكات أو التسبب في الإعاقة أو التعدي أو الإزعاج أو التدخل في أي حق باية بالهواء أو الضوء أو الماء، أو الاعتقال غير المشروع أو الاحتجاز أو السجن الباطل أو المقاضاة الكيدية التي تحدث داخل الحدود الإقليمية</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>تكاليف إضافية</strong><br /> يغطي التكاليف الإضافية المتكبدة لمواصلة الإنتاج بعد الخسارة أو التلف أو السطو أو السرقة أو عدم تسليم البضائع أو تلف الموقع، إلخ</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>الملكية المؤمن عليها</strong><br /> يغطي المبلغ الذي يمكن أن يتحمل حامل البوليصة المسؤولية القانونية عنه، ويعوضه عن خسارة أو تلف أو سرقة أو تدمير ممتلكات طرف ثالث أثناء فترة تغطية البوليصة</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>مسؤولية تجاه ممتلكات الطرف الثالث</strong><br /> يغطي هذا التأمين الأضرار التي لحقت بممتلكات الأطراف الثالثة المشغولة أثناء الحدث، مثل الأضرار الناجمة عن الحرائق والكهرباء و / أو المياه وكسر الزجاج، باستثناء الأضرار التي تلحق بالمركبات أو المباني والمجموعات الطبيعية</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>سوء الأحوال الجوية</strong><br /> يغطي هذا التأمين صافي الخسارة التي تكبدها المؤمن عليه بسبب الإلغاء أو التأجيل الكامل للحدث الذي يلي أحوال الطقس القاسية في ظل ظروف محددة.</p>

          <p class="splriskevtinsu-txt-con-ar"><strong>التأمين ضد الحوادث الشخصية</strong><br /> تغطي البوليصة الموظفين وطاقم العمل في حالة الوفاة والعجز الدائم والعجز المؤقت والرسوم الطبية</p>

          <h3 class="splriskevtinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          <div class="clearfix"></div>
          
          <div class="splriskevtinsu-pins-list-dksp-ar">
            <div class="splriskevtinsu-lt-two-dksp-ar">خدمات المطالبات الفورية<img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="splriskevtinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="splriskevtinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="splriskevtinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة<img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
    </div>
    {/* DESK TOP AND TABS SECTION ENDS HERE */}

    {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
    <div className="tab-btn-viewmode-ar">
      <div class="splriskevtinsu-tab-imagebannermain-ar">
        <div class="splriskevtinsu-banner-con-ar">
          <div class="splriskevtinsutab-banne-sec-ar">
              <h1 class="splriskevtinsu-banner-heading-ar">تأمين الأعمال </h1>
            {/* TAB BUTTON SECTION STARTS HERE */}
            <div class="splriskevtinsu-tab-btn-ar">
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="splriskevtinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="splriskevtinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
            {/* TAB BUTTON SECTION ENDS HERE */}
          </div>
        </div>
      </div>
      <div class="splriskevtinsu-banner-con-ar">
        <div className="tab-imgtxt-ar">
          <h2>مخاطر التخصص / التأمين أثناء الفعاليات</h2>
          <div className="tabs-cont-ar">
            <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/specialty_risk_event_insurance_ar.png"} alt="specialty risk event insurance" />
            
            <p>إذا كنت تخطط لفعالية ما، سواء كان مؤتمرًا تجاريًا عالميًا أو مؤتمرًا ملائمًا ليُقام في قرية، فيمكننا مساعدتك في الحماية من ما هو غير متوقع. توفر شركة كيمجي رامداس لخدمات التأمين تأمينًا متخصصًا لجميع أنواع الأحداث والمخاطر التي تصاحبها. سواء كان ذلك إغلاقًا للمكان، أو أضرارًا في الممتلكات، أو نزيلًا مصابًا، فنحن نقدم لك التغطية بغرض إراحة ذهنك، حتى تتمكن من التركيز على التخطيط لمناسبتك</p>
            
            <p>تم تصميم تأمين الأحداث لحماية حامل الوثيقة من الدعاوى القضائية والمطالبات المتعلقة بالإصابة الجسدية للآخرين والأضرار التي تلحق بالممتلكات في المبنى المستأجر</p>

              <p>قد تكون الأحداث التي يتم تغطيتها تحت غطاء تأمين الحدث - مؤتمرات، معارض، حفلات زفاف، ندوات، بطولات رياضية أو أي حدث شخصي، يتم تنظيمه في مكان خاص أو عام. قد تكون الأحداث لفترات أقصر (ساعات) إلى فترات أطول (أيام)</p>
              
            <p>تندرج التغطيات الهامة تحت</p>
            
            <p><strong>الإلغاء</strong><br />تغطي البوليصة النفقات غير القابلة للاسترداد التي تم انفاقها أو المستحقة بعد إلغاء الفعالية أو توقفها أو تأجيلها لأي سبب لا يخضع لسيطرة حامل البوليصة</p>
            
            <p><strong>المعدات</strong><br /> يغطي التأمين معدات الصوت أو العرض أو الإضاءة أو التصوير أو التسجيل الصوتي أو البث والاستنساخ، في حالة التلف العرضي أو التدمير أو السطو أو السرقة أو قصر الدائرة أو الجهد الزائد أو تعقب الاستقراءات</p>
            
            <p><strong>عدم الظهور</strong><br /> يغطي النفقات المتكبدة بعد تأجيل أو إلغاء أو التخلي عن حدث مؤمن عليه ناتج عن وفاة أو إصابة عرضية أو مرض أو احتجاز ضد إرادته لشخص اسمه خلال فترة التغطية وأقاربهم حتى الدرجة الأولى</p>
            
            <p><strong>المسئولية العامة</strong><br /> يغطي الخسائر المالية المتكبدة في حالة الإصابة الجسدية العرضية لأي شخص، الإضرار بالممتلكات أو التسبب في الإعاقة أو التعدي أو الإزعاج أو التدخل في أي حق باية بالهواء أو الضوء أو الماء، أو الاعتقال غير المشروع أو الاحتجاز أو السجن الباطل أو المقاضاة الكيدية التي تحدث داخل الحدود الإقليمية</p>
            
            <p><strong>تكاليف إضافية</strong><br /> يغطي التكاليف الإضافية المتكبدة لمواصلة الإنتاج بعد الخسارة أو التلف أو السطو أو السرقة أو عدم تسليم البضائع أو تلف الموقع، إلخ</p>
            
            <p><strong>الملكية المؤمن عليها</strong><br /> يغطي المبلغ الذي يمكن أن يتحمل حامل البوليصة المسؤولية القانونية عنه، ويعوضه عن خسارة أو تلف أو سرقة أو تدمير ممتلكات طرف ثالث أثناء فترة تغطية البوليصة</p>
            
            <p><strong>مسؤولية تجاه ممتلكات الطرف الثالث</strong><br /> يغطي هذا التأمين الأضرار التي لحقت بممتلكات الأطراف الثالثة المشغولة أثناء الحدث، مثل الأضرار الناجمة عن الحرائق والكهرباء و / أو المياه وكسر الزجاج، باستثناء الأضرار التي تلحق بالمركبات أو المباني والمجموعات الطبيعية</p>
            
            <p><strong>سوء الأحوال الجوية</strong><br /> يغطي هذا التأمين صافي الخسارة التي تكبدها المؤمن عليه بسبب الإلغاء أو التأجيل الكامل للحدث الذي يلي أحوال الطقس القاسية في ظل ظروف محددة</p>
            
            <p><strong>التأمين ضد الحوادث الشخصية</strong><br /> تغطي البوليصة الموظفين وطاقم العمل في حالة الوفاة والعجز الدائم والعجز المؤقت والرسوم الطبية</p>

            <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            
            <div class="tab-whykrinsu-pins-list-ar">
              <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

          </div>
        </div>
      </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

    {/* MOBILE PAGE STARTS HERE */}
    <div class="splriskevtinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
      <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"} selected class="active">مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
        </select>
      </div>
      <div class="splriskevtinsu-mob-main-ar">
        <h1 class="splriskevtinsu-banner-heading-ar">مخاطر التخصص / التأمين أثناء الفعاليات</h1>
        <div class="splriskevtinsu-mob-img-ar">
          <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/specialty_risk_event_insurance_ar.png"} alt="specialty risk event insurance" />
        </div>

        <p class="splriskevtinsu-mob-txt-ar">إذا كنت تخطط لفعالية ما، سواء كان مؤتمرًا تجاريًا عالميًا أو مؤتمرًا ملائمًا ليُقام في قرية، فيمكننا مساعدتك في الحماية من ما هو غير متوقع. توفر شركة كيمجي رامداس لخدمات التأمين تأمينًا متخصصًا لجميع أنواع الأحداث والمخاطر التي تصاحبها. سواء كان ذلك إغلاقًا للمكان، أو أضرارًا في الممتلكات، أو نزيلًا مصابًا، فنحن نقدم لك التغطية بغرض إراحة ذهنك، حتى تتمكن من التركيز على التخطيط لمناسبتك</p>
              
              <p class="splriskevtinsu-mob-txt-ar">تم تصميم تأمين الأحداث لحماية حامل الوثيقة من الدعاوى القضائية والمطالبات المتعلقة بالإصابة الجسدية للآخرين والأضرار التي تلحق بالممتلكات في المبنى المستأجر</p>



              <p class="splriskevtinsu-mob-txt-ar">قد تكون الأحداث التي يتم تغطيتها تحت غطاء تأمين الحدث - مؤتمرات، معارض، حفلات زفاف، ندوات، بطولات رياضية أو أي حدث شخصي، يتم تنظيمه في مكان خاص أو عام. قد تكون الأحداث لفترات أقصر (ساعات) إلى فترات أطول (أيام)</p>
              

        <p class="splriskevtinsu-mob-txt-ar">تندرج التغطيات الهامة تحت</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>الإلغاء</strong><br />تغطي البوليصة النفقات غير القابلة للاسترداد التي تم انفاقها أو المستحقة بعد إلغاء الفعالية أو توقفها أو تأجيلها لأي سبب لا يخضع لسيطرة حامل البوليصة</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>المعدات</strong><br /> يغطي التأمين معدات الصوت أو العرض أو الإضاءة أو التصوير أو التسجيل الصوتي أو البث والاستنساخ، في حالة التلف العرضي أو التدمير أو السطو أو السرقة أو قصر الدائرة أو الجهد الزائد أو تعقب الاستقراءات</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>عدم الظهور</strong><br /> يغطي النفقات المتكبدة بعد تأجيل أو إلغاء أو التخلي عن حدث مؤمن عليه ناتج عن وفاة أو إصابة عرضية أو مرض أو احتجاز ضد إرادته لشخص اسمه خلال فترة التغطية وأقاربهم حتى الدرجة الأولى</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>المسئولية العامة</strong><br /> يغطي الخسائر المالية المتكبدة في حالة الإصابة الجسدية العرضية لأي شخص، الإضرار بالممتلكات أو التسبب في الإعاقة أو التعدي أو الإزعاج أو التدخل في أي حق باية بالهواء أو الضوء أو الماء، أو الاعتقال غير المشروع أو الاحتجاز أو السجن الباطل أو المقاضاة الكيدية التي تحدث داخل الحدود الإقليمية</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>تكاليف إضافية</strong><br /> يغطي التكاليف الإضافية المتكبدة لمواصلة الإنتاج بعد الخسارة أو التلف أو السطو أو السرقة أو عدم تسليم البضائع أو تلف الموقع، إلخ</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>الملكية المؤمن عليها</strong><br /> يغطي المبلغ الذي يمكن أن يتحمل حامل البوليصة المسؤولية القانونية عنه، ويعوضه عن خسارة أو تلف أو سرقة أو تدمير ممتلكات طرف ثالث أثناء فترة تغطية البوليصة</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>مسؤولية تجاه ممتلكات الطرف الثالث</strong><br /> يغطي هذا التأمين الأضرار التي لحقت بممتلكات الأطراف الثالثة المشغولة أثناء الحدث، مثل الأضرار الناجمة عن الحرائق والكهرباء و / أو المياه وكسر الزجاج، باستثناء الأضرار التي تلحق بالمركبات أو المباني والمجموعات الطبيعية</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>سوء الأحوال الجوية</strong><br /> يغطي هذا التأمين صافي الخسارة التي تكبدها المؤمن عليه بسبب الإلغاء أو التأجيل الكامل للحدث الذي يلي أحوال الطقس القاسية في ظل ظروف محددة</p>

        <p class="splriskevtinsu-mob-txt-ar"><strong>التأمين ضد الحوادث الشخصية</strong><br /> تغطي البوليصة الموظفين وطاقم العمل في حالة الوفاة والعجز الدائم والعجز المؤقت والرسوم الطبية</p>
        
        <h3 class="splriskevtinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

        <div class="splriskevtinsu-mob-whykrlist-ar">
          <div class="splriskevtinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          <div class="splriskevtinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          <div class="splriskevtinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          <div class="splriskevtinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
        </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
      </div>
    </div>
    {/* MOBILE PAGE ENDS HERE */}

    {/* FOOTER SECTION STARTS HERE */}
    <FooterAR />
    {/* FOOTER SECTION ENDS HERE */}
  </div>
  );
}

export default ARSpecialityRiskEventInsurance;