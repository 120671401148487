import { Link } from "react-router-dom";

const PageNotFound = () => {
    return ( 
        <div className="not-found  text-center">
            <h2>Sorry ...!!</h2>
            <img src={process.env.PUBLIC_URL + '/404notfound.jpg'} alt="404image" />
            <p>Page your looking is not found.</p>
            <div className="back-btn"><Link to="/flow/dripcampaign" className="newblog">Back to Home</Link></div>
        </div>
     );
}
 
export default PageNotFound;