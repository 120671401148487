
import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../headerAR/headerar";
import FooterAR from "../footerAR/footerar";
import './arcontactus.scss';

const ARContactUsPage = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2500);
    return () => clearTimeout(timer);
    
  }, []);

  useEffect(() =>
  {
    // FOR DESKTOP 
    var script = document.createElement("script");
    script.id = "ff-script";
    script.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/classic.js?div=ff-compose";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    // FOR TAB 
    var scriptTab = document.createElement("script");
    scriptTab.id = "ff-script";
    scriptTab.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/classic.js?div=ff-compose-Tab";
    scriptTab.defer = true;
    scriptTab.async = true;
    document.body.appendChild(scriptTab);

    // FOR MOBILE 
    var scriptMob = document.createElement("script");
    scriptMob.id = "ff-script";
    scriptMob.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/classic.js?div=ff-compose-Mob";
    scriptMob.defer = true;
    scriptMob.async = true;
    document.body.appendChild(scriptMob);

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  },[])
  const [loader, setLoader] = useState(true)
  return (
    <div class="main-fluid">
      { loader? 
      <div className="loadermain">
        <div class="loader" id="timer"></div>
      </div>
      :null }
      <HeaderAR />
      <div class="container kr-container mb-3">
        <div class="kr-contact-ar">

          {/* DESKTOP CONTACT SECTION STARTS HERE  */}
          <div className="dsk-view">
            <div className="contactdkp-ar">
              <div className="contactdkp-lft-ar">
                <h2 className="contactdkp-lft-title-ar">
                  We would be delighted to get in touch with you,<br />
                  Please provide us with the below details
                </h2>
                <div className="contactform-ar">
                  {/* <p className="contactfrom-msg-en">AVAILABLE 24 HOURS A DAY</p> */}
                  
                  {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="640" height="1275" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

                   {/* <div id="ff-compose"></div> */}

                   <iframe width="100%" height="1050" frameborder="0" src="https://formfaca.de/sm/YfF-OQ0zG"></iframe>
                   
                   </div>
              </div>
              <div className="contactdkp-rgt-ar">
                <h2 className="contactdkp-rgt-title-ar">WE ARE HERE</h2>
                
                <div className="contact-gmap-en">
                  {/* <p className="contact-gmap-msg-en">MON-FRI 8:30AM - 5PM / PHONE ARE OPEN 24/7</p> */}
                  <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3656.2022821974892!2d58.5398662!3d23.5970773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9e718b31f1b%3A0x8f9b5a9a6f457d55!2sKhimji%20Ramdas%20Insurance%20Services%20LLC!5e0!3m2!1sen!2sin!4v1670304141763!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0" }} allowfullscreen="ture" aria-hidden="false" tabindex="0"></iframe>
                </div>
                {/* MAP DOWN ADDRESS SECTION STARTS HERE  */}
                  <div className="contactadd-ar">
                    <h5 className="office-title-ar">
                      <span className="offitite-ar">عنوان المكتب</span>
                      <span className="offiimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/location_icon.svg"} alt="Location Icon" /></span>
                    </h5>
                    <p className="office-add-ar">
                    كيمجي رامداس لخدمات التأمين ش. م. م  <br />
                    (طابق ميزانين) مبنى كيمجي رامداس ميجا استور القديم <br />
                    طريق الروي الرئيسي (مقابل فندق بيت الحافة) <br />
                    روي - مسقط، سلطنة عمان 
                    </p><br />
                    <h5 className="office-title-ar">
                      <span className="offiimg-ar"></span>
                      <span className="postalitite-ar">العنوان البريدي </span>
                    </h5>
                    <p className="office-add-ar">
                    كيمجي رامداس لخدمات التأمين ش. م. م <br />
                    صندوق البريد - 19؛ الرمز البريدي - 100،<br />
                    مسقط، سلطنة عمان
                    </p>
                  </div>   
                {/* MAP DOWN ADDRESS SECTION ENDS HERE  */}

                {/* EMAIL CONTACT SECTON STARTS HERE  */}
                <div className="contactcall-email-ar">
                  <h5 className="callemail-title-ar">
                  <span className="calltite-ar">للتواصل معنا:</span>
                  <span className="callimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/call_icon.svg"} alt="Call Icon" /></span>
                  </h5>
                  <div className="contactcall-email-sec-ar">
                    <a href="tel:+968 98227845" className="contactcallemail-secitem-ar">+968 98227845</a>
                    <a href="tel:+ 968 91441394" className="contactcallemail-secitem-ar">+ 968 91441394</a>
                </div>
                  <h5 className="email-title-ar">لمراسلتنا عبر البريد الإلكتروني</h5>
                  <a href="mailto:insurance@kr.om" className="emlink-ar">insurance@kr.om</a>

                </div>
                {/* EMAIL CONTACT SECTON ENDS HERE  */}

                {/* HOURS SECTION STARTS HERE  */}
                <div className="contact-whrs-ar">
                  <h5 className="whrs-title-ar">
                  <span className="wkhrstite-ar">ساعات العمل</span>
                  <span className="whrsimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/time_icon.svg"} alt="Time Icon" /></span>
                  </h5>
                  <div className="contactwhrs-time-ar ">
                    <span className="contactwhrs-tmitem-ar">من الأحد إلى الخميس: بدءًا من الساعة 08:00 صباحًا حتى الساعة 05:00 مساءً.</span>
                    <span className="contactwhrs-tmitem-ar">(الغداء: 01:00 مساءً حتى 02:00 مساءً)</span>
                </div>
                </div>

                {/* HOURS SECTION ENDS HERE  */}


              </div>
            </div>
          </div>
          {/* DESKTOP CONTACT SECTION ENDS HERE  */}

          {/* TAB CONTACT SECTION START HERE  */}
          <div className="tab-view">
            <div className="mobcontact-ar">
              <h2 className="mobcontact-title-ar">
                We would be delighted to get in touch with you, Please provide us with the below details
              </h2>
            {/* <p className="mobcontact-msg-en">AVAILABLE 24 HOURS A DAY</p> */}
              <div className="mobcontact-lft-ar">
              <div className="contactform-ar">
                {/* <div id="ff-compose-Tab"></div> */}
                <iframe width="100%" height="1050" frameborder="0" src="https://formfaca.de/sm/YfF-OQ0zG"></iframe>
              </div>
            </div>
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1431" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1275" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

            
              <div className="clearfix"></div>
              
              {/* MOBILE ADDRESS SECTION STARTS HERE  */}
              <div className="mobcontactadd-ar">
                  <h5 className="moboffice-title-ar">
                    <span className="moboffitite-ar">عنوان المكتب</span>
                    <span className="moboffiimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/location_icon.svg"} alt="Location Icon" /></span>
                  </h5>
                  <p className="moboffice-add-ar">
                  كيمجي رامداس لخدمات التأمين ش. م. م  <br />
                  (طابق ميزانين) مبنى كيمجي رامداس ميجا استور القديم <br />
                  طريق الروي الرئيسي (مقابل فندق بيت الحافة) <br />
                  روي - مسقط، سلطنة عمان 
                  </p><br />
                  <h5 className="moboffice-title-ar">
                    <span className="moboffiimg-ar"></span>
                    <span className="mobpostalitite-ar">العنوان البريدي </span>
                  </h5>
                  <p className="moboffice-add-ar">
                  كيمجي رامداس لخدمات التأمين ش. م. م <br />
                  صندوق البريد - 19؛ الرمز البريدي - 100،<br />
                  مسقط، سلطنة عمان
                  </p>
                </div>   
                {/* MOBILE ADDRESS SECTION ENDS HERE  */}
                
                {/* EMAIL CONTACT SECTON STARTS HERE  */}
                <div className="mobcontactcall-email-ar">
                <h5 className="mobcallemail-title-ar">
                <span className="mobcalltite-ar">للتواصل معنا:</span>
                <span className="mobcallimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/call_icon.svg"} alt="Call Icon" /></span>
                </h5>
                <div className="mobcontactcall-email-sec-ar">
                  <a href="tel:+968 98227845" className="mobcontactcallemail-secitem-ar">+968 98227845</a>
                  <a href="tel:+ 968 91441394" className="mobcontactcallemail-secitem-ar">+ 968 91441394</a>
              </div>
                <h5 className="mobemail-title-ar">لمراسلتنا عبر البريد الإلكتروني</h5>
                <a href="mailto:insurance@kr.om" className="mobemlink-ar">insurance@kr.om</a>

              </div>
              {/* EMAIL CONTACT SECTON ENDS HERE  */}

              {/* HOURS SECTION STARTS HERE  */}
              <div className="mobcontact-whrs-ar">
                <h5 className="mobwhrs-title-ar">
                <span className="mobwkhrstite-ar">ساعات العمل</span>
                <span className="mobwhrsimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/time_icon.svg"} alt="Time Icon" /></span>
                </h5>
                <div className="mobcontactwhrs-time-ar ">
                  <span className="mobcontactwhrs-tmitem-ar">من الأحد إلى الخميس: بدءًا من الساعة 08:00 صباحًا حتى الساعة 05:00 مساءً.</span>
                  <span className="mobcontactwhrs-tmitem-ar">(الغداء: 01:00 مساءً حتى 02:00 مساءً)</span>
              </div>
              </div>
              {/* HOURS SECTION ENDS HERE  */}

              <h2 className="mobgmap-title-en">WE ARE HERE</h2>

              {/* <p className="mobgmap-msg-en">MON-FRI 8:30AM - 5PM / PHONE ARE OPEN 24/7</p> */}
              
              <div className="mobcontact-gmap-en">
                <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3656.2022821974892!2d58.5398662!3d23.5970773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9e718b31f1b%3A0x8f9b5a9a6f457d55!2sKhimji%20Ramdas%20Insurance%20Services%20LLC!5e0!3m2!1sen!2sin!4v1670304141763!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0" }} allowfullscreen="ture" aria-hidden="false" tabindex="0"></iframe>
              </div>

            </div>
          </div>
          {/* TAB CONTACT SECTION ENDS HERE  */}

          {/* MOBILE CONTACT SECTION STARTS HERE  */}
          <div className="mobile-view">
            <div className="mobcontact-ar">
              <h2 className="mobcontact-title-ar">
                We would be delighted to get in touch with you, Please provide us with the below details
              </h2>
            {/* <p className="mobcontact-msg-en">AVAILABLE 24 HOURS A DAY</p> */}
              <div className="mobcontact-lft-ar">
              <div className="contactform-ar">
                {/* <div id="ff-compose-Mob"></div> */}
                <iframe width="100%" height="1050" frameborder="0" src="https://formfaca.de/sm/YfF-OQ0zG"></iframe>
              </div>
            </div>
            </div>
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1431" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPHqBPOvswXU6dvPdqPbjKExOwiQ9u7QOkIGgyw_tq6uM4zw/viewform?embedded=true" width="100%" height="1275" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}            

            <div className="mobcontact-ar">
              {/* MOBILE ADDRESS SECTION STARTS HERE  */}
              <div className="mobcontactadd-ar">
                  <h5 className="moboffice-title-ar">
                    <span className="moboffitite-ar">عنوان المكتب</span>
                    <span className="moboffiimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/location_icon.svg"} alt="Location Icon" /></span>
                  </h5>
                  <p className="moboffice-add-ar">
                  كيمجي رامداس لخدمات التأمين ش. م. م  <br />
                  (طابق ميزانين) مبنى كيمجي رامداس ميجا استور القديم <br />
                  طريق الروي الرئيسي (مقابل فندق بيت الحافة) <br />
                  روي - مسقط، سلطنة عمان 
                  </p><br />
                  <h5 className="moboffice-title-ar">
                    <span className="moboffiimg-ar"></span>
                    <span className="mobpostalitite-ar">العنوان البريدي </span>
                  </h5>
                  <p className="moboffice-add-ar">
                  كيمجي رامداس لخدمات التأمين ش. م. م <br />
                  صندوق البريد - 19؛ الرمز البريدي - 100،<br />
                  مسقط، سلطنة عمان
                  </p>
                </div>   
                {/* MOBILE ADDRESS SECTION ENDS HERE  */}
                
                {/* EMAIL CONTACT SECTON STARTS HERE  */}
                <div className="mobcontactcall-email-ar">
                <h5 className="mobcallemail-title-ar">
                <span className="mobcalltite-ar">للتواصل معنا:</span>
                <span className="mobcallimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/call_icon.svg"} alt="Call Icon" /></span>
                </h5>
                <div className="mobcontactcall-email-sec-ar">
                  <a href="tel:+968 98227845" className="mobcontactcallemail-secitem-ar">+968 98227845</a>
                  <a href="tel:+ 968 91441394" className="mobcontactcallemail-secitem-ar">+ 968 91441394</a>
              </div>
                <h5 className="mobemail-title-ar">لمراسلتنا عبر البريد الإلكتروني</h5>
                <a href="mailto:insurance@kr.om" className="mobemlink-ar">insurance@kr.om</a>

              </div>
              {/* EMAIL CONTACT SECTON ENDS HERE  */}

              {/* HOURS SECTION STARTS HERE  */}
              <div className="mobcontact-whrs-ar">
                <h5 className="mobwhrs-title-ar">
                <span className="mobwkhrstite-ar">ساعات العمل</span>
                <span className="mobwhrsimg-ar"><img src={process.env.PUBLIC_URL + "/contacts_images/time_icon.svg"} alt="Time Icon" /></span>
                </h5>
                <div className="mobcontactwhrs-time-ar ">
                  <span className="mobcontactwhrs-tmitem-ar">من الأحد إلى الخميس: بدءًا من الساعة 08:00 صباحًا حتى الساعة 05:00 مساءً.</span>
                  <span className="mobcontactwhrs-tmitem-ar">(الغداء: 01:00 مساءً حتى 02:00 مساءً)</span>
              </div>
              </div>
              {/* HOURS SECTION ENDS HERE  */}

              <h2 className="mobgmap-title-en">WE ARE HERE</h2>

              {/* <p className="mobgmap-msg-en">MON-FRI 8:30AM - 5PM / PHONE ARE OPEN 24/7</p> */}
              
              <div className="mobcontact-gmap-en">
                <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3656.2022821974892!2d58.5398662!3d23.5970773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91f9e718b31f1b%3A0x8f9b5a9a6f457d55!2sKhimji%20Ramdas%20Insurance%20Services%20LLC!5e0!3m2!1sen!2sin!4v1670304141763!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:"0" }} allowfullscreen="ture" aria-hidden="false" tabindex="0"></iframe>
              </div>
            </div>
          </div>
          {/* MOBILE CONTACT SECTION ENDS HERE  */}

        </div>
         </div>
         
         {/* FOOTER SECTION STARTS HERE */}
        <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
        
    </div>
  );
}

export default ARContactUsPage;