import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './footerar.scss';
import { Background } from "react-flow-renderer";

const FooterAR = () => {

  return (
    <div>
      {/* DESKTOP FOOTER STARTS HERE  */}
      <div class="kr-footer-ar d-none d-md-none d-lg-block">
        <div class="ft-wdcon-ar">
          <div class="ft-con-sec-ar">
            <div class="ft-con-itemone-ar">
              <p class="pb-3 desc-ar">
              اولويتنا هي تقديم أفضل خدمات التأمين بلإضافة الى تغطيه تأمينية  شاملة و وضع برامج إدارة المخاطر و جعل عملائنا مستفيدين من فوائد التكلفة
</p>

              <div class="smedia-ar">
                <a href="https://www.linkedin.com/company/khimji-ramdas-insurance-services/?viewAsMember=true" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/in.png"} alt="Linedin Icon" width="23" height="22" /></a>
                {/* <a href="https://www.instagram.com/gettameen/" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/insta.png"} alt="Instagram Icon" width="23" height="23" /></a>
                <a href="https://www.facebook.com/Gettameencom-108908225267493" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/fb.png"} alt="Facebook Icon" width="23" height="23" /></a> */}
              </div>
            </div>
            <div class="ft-con-itemtwo-ar">
              <div class="footer-links-ar">
                <a href="/omanbr-ar">الصفحة الرئيسية</a>
                <a href="/aboutus-ar">معلومات عنا</a>
                <a href="/our-insurance-solutions-ar">حلول التأمين لدينا</a>
                <a href="/team-ar">فريقنا</a>
                <a href="/contactus-ar">اتصل بنا</a>
                <a href="/privacy-policy-ar">سياسة الخصوصية</a>
              </div>
            </div>
            <div class="ft-con-itemthree-ar">
              <p class="address-ar"> كيمجي رامداس لخدمات التأمين ش. م. م<br />
            (طابق ميزانين) مبنى كيمجي رامداس ميجا استور القديم<br />
            طريق الروي الرئيسي (مقابل فندق بيت الحافة) <br />
             روي - مسقط، سلطنة عمان <br />
               <a href="mailto:insurance@kr.om">insurance@kr.om</a>: لمراسلتنا عبر البريد الإلكتروني<br />
              +968 98227845 : للتواصل معنا 
              </p>
              <a class="get-direction-ar" href="https://www.google.com/maps/place/Khimji+Ramdas+Insurance+Services+LLC/@23.5972628,58.5378933,17z/data=!3m1!4b1!4m5!3m4!1s0x3e91f9e718b31f1b:0x8f9b5a9a6f457d55!8m2!3d23.5972629!4d58.540082" target="_blank"><img src={process.env.PUBLIC_URL + "/homepage_images/map.png"} alt="map icon" width="30" height="29" /><br />Get Directions</a>
            </div>
          </div>
        </div>
        <div class="d-block" style={{backgroundColor: "#b30e1f"}}>
          <p class="copywrights-ar">حقوق النشر @ كيمجي رامداس لخدمات التأمين. كل الحقوق محفوظة</p>
        </div>
      </div>
      {/* DESKTOP FOOTER ENDS HERE  */}

      {/* TAB FOOTER STARTS HERE  */}
      <div class="kr-tabfooter-ar d-none d-md-block d-lg-none">
        {/* <img src="https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png" className="tab-ftimg-ar" alt="footerimg" /> */}

        <div class="tab-ft-con-sec-ar">
          <div class="tabft-con-itemone-ar"> 
نم نيديفتسم انئلمع لعجو ،رطاخملا ةرادإ جمارب عضوو ،ةلماش ةينيمأت ةيطغت ىلإ ةفاضلاب نيمأتلا تامدخ لضفأ ميدقت يه انتيولوأ 
	.ةفلكتلا دئاوف

            <div class="tabmediaicon-ar">
              <a href="https://www.linkedin.com/company/khimji-ramdas-insurance-services/?viewAsMember=true" target="_blank">
                <img src={process.env.PUBLIC_URL + "/footer_images/in.png"} alt="Linkedin Icon" width="23" height="22" />
              </a>
              {/* <a href="https://www.instagram.com/gettameen/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/footer_images/insta.png"} alt="Instagram Icon" width="23" height="23" />
              </a>
              <a href="https://www.facebook.com/Gettameencom-108908225267493" target="_blank">
                <img src={process.env.PUBLIC_URL + "/footer_images/fb.png"} alt="Facebook Icon" width="23" height="23" />
              </a> */}
            </div>
          </div>
          <div class="tabftlinks-ar">
            <a href="/omanbr-ar">الصفحة الرئيسية</a>
                <a href="/aboutus-ar">معلومات عنا</a>
                <a href="/our-insurance-solutions-ar">حلول التأمين لدينا</a>
                <a href="/team-ar">فريقنا</a>
                <a href="/contactus-ar">انب لصتا </a>
                <a href="/privacy-policy-ar">سياسة الخصوصية</a>
          </div>
          <div class="ft-con-itemthree-ar">
            <p class="address-ar"> كيمجي رامداس لخدمات التأمين ش. م. م<br />
            (طابق ميزانين) مبنى كيمجي رامداس ميجا استور القديم<br />
            طريق الروي الرئيسي (مقابل فندق بيت الحافة) <br />
             روي - مسقط، سلطنة عمان <br />
              لمراسلتنا عبر البريد الإلكتروني: <a href="mailto:insurance@kr.om">insurance@kr.om</a> 
              +968 98227845 : للتواصل معنا 
            </p>
            <a class="get-direction-ar" href="https://www.google.com/maps/place/Khimji+Ramdas+Insurance+Services+LLC/@23.5972628,58.5378933,17z/data=!3m1!4b1!4m5!3m4!1s0x3e91f9e718b31f1b:0x8f9b5a9a6f457d55!8m2!3d23.5972629!4d58.540082" target="_blank" alt="map icon"><img src={process.env.PUBLIC_URL + "/homepage_images/map.png"} alt="Map Icon" width="30" height="29" /><br />Get Directions</a>
          </div>

          <div className="cleafix"></div>
          <div class="tabcw-ar" style={{backgroundColor: "#b10e1f"}}>حقوق النشر @ كيمجي رامداس لخدمات التأمين. كل الحقوق محفوظة</div>
        </div>
      </div>
      {/* TAB FOOTER ENDS HERE  */}


      {/* MOBILE FOOTER STARTS HERE  */}
      <div class="kr-mbfooter-ar d-block d-sm-none">
        <img src="https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png" className="mob-ftimg-ar" alt="footerimg" />
        <div class="ft-mobwdcon-en">
          <div class="mobft-con-sec-ar">
            <div class="mobft-con-itemone-ar">
نم نيديفتسم انئلمع لعجو ،رطاخملا ةرادإ جمارب عضوو ،ةلماش ةينيمأت ةيطغت ىلإ ةفاضلاب نيمأتلا تامدخ لضفأ ميدقت يه انتيولوأ 
	.ةفلكتلا دئاوف
            </div>
            <div class="mobsmedia-ar">
              <a href="https://www.linkedin.com/company/khimji-ramdas-insurance-services/?viewAsMember=true" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/in.png"} alt="Linkedin Icon" width="23" height="22" /></a>
              {/* <a href="https://www.instagram.com/gettameen/" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/insta.png"} alt="Instagram Icon" width="23" height="23" /></a>
              <a href="https://www.facebook.com/Gettameencom-108908225267493" alt="Facebook Icon" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/fb.png"} alt="Facebook Icon" width="23" height="23" /></a> */}
            </div>
          </div>

          <div className="mobft-lnk-add-ar">
            <div className="mobftlnkadd-item-ar">
              <a href="/omanbr-ar">الصفحة الرئيسية</a>
              <a href="/aboutus-ar">معلومات عنا</a>
              <a href="/our-insurance-solutions-ar">حلول التأمين لدينا</a>
              <a href="/team-ar">فريقنا</a>
              <a href="/contactus-ar">انب لصتا </a>
              <a href="/privacy-policy-ar">سياسة الخصوصية</a>
            </div>
            <div className="mobftlnkadd-additem-ar">
            كيمجي رامداس لخدمات التأمين ش. م. م<br />
            (طابق ميزانين) مبنى كيمجي رامداس ميجا استور القديم<br />
            طريق الروي الرئيسي (مقابل فندق بيت الحافة) <br />
             روي - مسقط، سلطنة عمان <br />
              لمراسلتنا عبر البريد الإلكتروني: <a href="mailto:insurance@kr.om">insurance@kr.om</a> 
              +968 98227845 : للتواصل معنا 

              <a class="mobget-direction-ar" href="https://www.google.com/maps/place/Khimji+Ramdas+Insurance+Services+LLC/@23.5972628,58.5378933,17z/data=!3m1!4b1!4m5!3m4!1s0x3e91f9e718b31f1b:0x8f9b5a9a6f457d55!8m2!3d23.5972629!4d58.540082" target="_blank"><img src={process.env.PUBLIC_URL + "/homepage_images/map.png"} width="30" height="29" alt="Map Icon" /> Get Directions</a>

            </div>
          </div>
          <div class="ft-con-itemthree-ar"><p class="address-ar"></p></div>
        </div>
        <p class="mobcopywrights-ar"  style={{backgroundColor: "#b30e1f"}}>Copyright @ Khimji Ramdas Insurance Services.<br />All Rights  Reserved</p>
      </div>
      {/* MOBILE FOOTER ENDS HERE  */}

    </div>
  );
}
export default FooterAR;