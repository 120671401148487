import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './housemaidinsurance.scss';

const HousemaidInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="homemaidinsu-tab-imagebannermain-en">
          <div class="homemaidinsu-banner-con-en">
            <div class="homemaidinsutab-banne-sec-en">
              <h1 class="homemaidinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="homemaidinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="homemaidinsu-banner-con-en">
          <div class="homemaidinsu-bdy-en">
            <div class="homemaidinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="homemaidinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Housemaid Insurance</h2>

                <p class="pg-txt-con">Insuring domestic workers not only ensures compliance with the legal requirements, but also ensures that the domestic help is given access to treatment, they require, which covers all of their basic healthcare needs due to an accident or critical illness.</p>

                <p class="pg-txt-con">KR Insurance Services offers best insurance covers which provide protection to housemaids who come to Sultanate of Oman for domestic employment.</p>

                <h3 class="homemaidinsu-page-sbu-title-en">Main Cover / Housemaid Insurance</h3>

                <p class="pg-txt-con">The key features of Housemaid / Domestic Help Insurance policy are -</p>

                <div class="homemaidinsu-keyfeatures">
                  <p class="homemaidinsu-lifinsu-fet">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    Basic death cover, in case of death by any cause.</p>
                  <p class="homemaidinsu-lifinsu-fet">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    Expenses on critical illness (optional)</p>
                  <p class="homemaidinsu-lifinsu-fet">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    Reimburses the sponsor for the cost of repatriation. </p>
                  <p class="homemaidinsu-lifinsu-fet">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    Optional Accident Benefit Rider benefits as under :</p>
                  <p class="homemaidinsu-subtxt"> # Accidental Permanent Partial Disability</p>
                  <p class="homemaidinsu-subtxt"> # Accidental Total disability</p>
                  <p class="homemaidinsu-subtxt"> # Accidental Temporary Total Disability</p>
                  <p class="homemaidinsu-subtxt"> # Accidental Medical or Hospital Expenses</p>
                </div>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="homemaidinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/maid_insurance.png"} alt="maid insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <h3 class="homemaidinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>

            <div class="homemaidinsu-pins-list-en">
              <div class="homemaidinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="homemaidinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="homemaidinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="homemaidinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="homemaidinsu-tab-imagebannermain-en">
          <div class="homemaidinsu-banner-con-en">
            <div class="homemaidinsutab-banne-sec-en">
              <h1 class="homemaidinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="homemaidinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="homemaidinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Housemaid Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/maid_insurance.png"} alt="maid insurance" />
              <p>Insuring domestic workers not only ensures compliance with the legal requirements, but also ensures that the domestic help is given access to treatment, they require, which covers all of their basic healthcare needs due to an accident or critical illness.</p>
              <p>KR Insurance Services offers best insurance covers which provide protection to housemaids who come to Sultanate of Oman for domestic employment.</p>

              <p class="tab-subhead-title-en">Main Cover / Housemaid Insurance</p>

              <p>The key features of Housemaid / Domestic Help Insurance policy are -</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Basic death cover, in case of death by any cause.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Expenses on critical illness (optional)</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Reimburses the sponsor for the cost of repatriation.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Optional Accident Benefit Rider benefits as under :<br />
                  <span># Accidental Permanent Partial Disability</span><br />
                  <span># Accidental Total disability</span><br />
                  <span># Accidental Temporary Total Disability</span><br />
                  <span># Accidental Medical or Hospital Expenses</span>
                </div>
              </div>
              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>

        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="homemaidinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"} selected class="active">Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="homemaidinsu-mob-main-en">
          <h1 class="homemaidinsu-banner-heading-en">Housemaid Insurance</h1>
          <div class="homemaidinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/maid_insurance.png"} alt="maid insurance" />
          </div>
          <p class="homemaidinsu-mob-txt-en">Insuring domestic workers not only ensures compliance with the legal requirements, but also ensures that the domestic help is given access to treatment, they require, which covers all of their basic healthcare needs due to an accident or critical illness.</p>

          <p class="homemaidinsu-mob-txt-en">KR Insurance Services offers best insurance covers which provide protection to housemaids who come to Sultanate of Oman for domestic employment.</p>

          <h3 class="homemaidinsu-subtitle-en">Main Cover / Housemaid Insurance</h3>

          <p class="homemaidinsu-mob-txt-en">Stop worrying and consider KR Insurance Services for providing you with the best travel insurance coverage plan. The travel insurance plan from KR Insurance is designed to ensure that you have an enjoyable and relaxing vacation, no matter what uncertainties happen.</p>

          <p class="homemaidinsu-mob-txt-en">The key features of Housemaid / Domestic Help Insurance policy are -</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="homemaidinsu-mob-keyfeatures-en">
            <div class="homemaidinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="homemaidinsu-mob-keyfeatures-q-en">Basic death cover, in case of death by any cause.</div>

            <div class="homemaidinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="homemaidinsu-mob-keyfeatures-q-en">Expenses on critical illness (optional)</div>

            <div class="homemaidinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="homemaidinsu-mob-keyfeatures-q-en">Reimburses the sponsor for the cost of repatriation. </div>

            <div class="homemaidinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="homemaidinsu-mob-keyfeatures-q-en">Optional Accident Benefit Rider benefits as under :</div>

            <div class="homemaidinsu-mob-keyfeatures-ans-en"># Accidental Permanent Partial Disability</div>
            <div class="homemaidinsu-mob-keyfeatures-ans-en"># Accidental Total disability</div>
            <div class="homemaidinsu-mob-keyfeatures-ans-en"># Accidental Temporary Total Disability</div>
            <div class="homemaidinsu-mob-keyfeatures-ans-en"># Accidental Medical or Hospital Expenses</div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}
          <h3 class="homemaidinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="homemaidinsu-mob-whykrlist-en">
            <div class="homemaidinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="homemaidinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="homemaidinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="homemaidinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HousemaidInsurance;