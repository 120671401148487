import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './inhousekrautomotivegarage.scss';

const InhouseKRAutomotiveGarage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="inhouautgar-tab-imagebannermain-en">
          <div class="inhouautgar-banner-con-en">
            <div class="inhouautgartab-banne-sec-en">
              <h1 class="inhouautgar-banner-heading-en">Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
              <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inhouautgar-banner-con-en">
          <div class="inhouautgar-bdy-en">
            {/* <h2 class="pagetitle-en">In house KR Automotive Garage</h2> */}
            <div class="inhouautgar-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="inhouautgar-dlex-lft-en">
                <h2 class="pagetitle-en">In house KR Automotive Garage</h2>
                
                <p class="inhouautgar-pg-txt-con">KR Insurance Services is the only insurance broker in Sultanate of Oman, who has in-house motor workshop facilities to meet urgent requirements of their customers at the time of motor claims. We proudly make the statement that we can successfully handle any large or small motor fleets, from insurance to their claim settlements.</p>

                <p class="inhouautgar-pg-txt-con">KR Automotive Workshop or KR Automotive Division maintains the Group's fleet Vehicles and Other company’s Fleet vehicles with industries best turn around times. It Undertakes all types of mechanical repairs and accident repairs which includes full painting of vehicles for special purposes. KR Automotive Workshop is rated as A-grade by ROP and many other insurance companies for their customer’s vehicles repair in Sultanate of Oman. The Workshop has an exclusive fabrication section which undertakes steel body building and chiller body building (High Quality​​ ​Polyurethane Vehicle insulation) sales and service of freeze​r and chiller units​​ on vans and t​rucks for required temperature is also done here.​ The exclusive body shop utilizes the latest automotive collision repair technology, and is fully equipped to handle major repair work​s for all types of vehicles.</p>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="inhouautgar-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/in_house_kr_automotive_garage.png"} alt="in house kr automotive garage" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            
            <p class="inhouautgar-txt-con">The KR Automotive Workshop is associated with brands such as Hwa Sung Thermo ​​(Chiller and Freezer​Units from SOUTH KOREA), Bosch Authorized Service Centre​ (BAC)​, AMPEX Air Conditioning Solutions and Sanden A/C Compressors.​ ​</p>

            <p class="inhouautgar-txt-con">KR Insurance Services delivering end to end insurance solutions to all your motor insurance requirements.</p>

            <h3 class="inhouautgar-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="inhouautgar-pins-list-dksp-en">
              <div class="inhouautgar-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="inhouautgar-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="inhouautgar-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="inhouautgar-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}


      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="inhouautgar-tab-imagebannermain-en">
          <div class="inhouautgar-banner-con-en">
            <div class="inhouautgartab-banne-sec-en">
              <h1 class="inhouautgar-banner-heading-en">Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
              <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inhouautgar-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>In house KR Automotive Garage</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/customer_service/tab_images/in_house_kr_automotive_garage.png"} alt="in house kr automotive garage" />

              <p>KR Insurance Services is the only insurance broker in Sultanate of Oman, who has in-house motor workshop facilities to meet urgent requirements of their customers at the time of motor claims. We proudly make the statement that we can successfully handle any large or small motor fleets, from insurance to their claim settlements.</p>
              
              <p>KR Automotive Workshop or KR Automotive Division maintains the Group's fleet Vehicles and Other company's Fleet vehicles with industries best turn around times. It Undertakes all types of mechanical repairs and accident repairs which includes full painting of vehicles for special purposes. KR Automotive Workshop is rated as A-grade by ROP and many other insurance companies for their customer's vehicles repair in Sultanate of Oman. The Workshop has an exclusive fabrication section which undertakes steel body building and chiller body building (High Quality​ | ​Polyurethane Vehicle insulation) sales and service of freeze​r and chiller units​​ on vans and t​rucks for required temperature is also done here.​ The exclusive body shop utilizes the latest automotive collision repair technology, and is fully equipped to handle major repair work​s for all types of vehicles.</p>

              <p>The KR Automotive Workshop is associated with brands such as Hwa Sung Thermo ​​(Chiller and Freezer​Units from SOUTH KOREA), Bosch Authorized Service Centre​ (BAC)​, AMPEX Air Conditioning Solutions and Sanden A/C Compressors.​ ​</p>

            <p>KR Insurance Services delivering end to end insurance solutions to all your motor insurance requirements.</p>

            <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inhouautgar-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"claim-consultancy-services"}>Claim Consultancy Services</option>
            <option value={"service-excellence"}>Service Excellence</option>
            <option value={"in-house-kr-automotive-garage"} selected class="active">In house KR Automotive Garage</option>
            <option value={"erp-based-solutions"}>ERP Based Solutions</option>
          </select>
        </div>
        <div class="inhouautgar-mob-main-en">
          <h1 class="inhouautgar-banner-heading-en">In house KR Automotive Garage</h1>
          <div class="inhouautgar-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/in_house_kr_automotive_garage.png"} alt="in house kr automotive garage" />
          </div>
          
          <p class="inhouautgar-mob-txt-en">KR Insurance Services is the only insurance broker in Sultanate of Oman, who has in-house motor workshop facilities to meet urgent requirements of their customers at the time of motor claims. We proudly make the statement that we can successfully handle any large or small motor fleets, from insurance to their claim settlements.</p>

          <p class="inhouautgar-mob-txt-en">KR Automotive Workshop or KR Automotive Division maintains the Group's fleet Vehicles and Other company’s Fleet vehicles with industries best turn around times. It Undertakes all types of mechanical repairs and accident repairs which includes full painting of vehicles for special purposes. KR Automotive Workshop is rated as A-grade by ROP and many other insurance companies for their customer’s vehicles repair in Sultanate of Oman. The Workshop has an exclusive fabrication section which undertakes steel body building and chiller body building (High Quality​​ ​Polyurethane Vehicle insulation) sales and service of freeze​r and chiller units​​ on vans and t​rucks for required temperature is also done here.​ The exclusive body shop utilizes the latest automotive collision repair technology, and is fully equipped to handle major repair work​s for all types of vehicles.</p>

          <p class="inhouautgar-mob-txt-en">The KR Automotive Workshop is associated with brands such as Hwa Sung Thermo ​​(Chiller and Freezer​Units from SOUTH KOREA), Bosch Authorized Service Centre​ (BAC)​, AMPEX Air Conditioning Solutions and Sanden A/C Compressors.​ ​</p>

          <p class="inhouautgar-mob-txt-en">KR Insurance Services delivering end to end insurance solutions to all your motor insurance requirements.</p>
          
          <h3 class="inhouautgar-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="inhouautgar-mob-whykrlist-en">
            <div class="inhouautgar-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="inhouautgar-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="inhouautgar-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="inhouautgar-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default InhouseKRAutomotiveGarage;
