import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arenergyinsurance.scss';

const AREnergyInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="energyinsu-tab-imagebannermain-ar">
          <div class="energyinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="energyinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="energyinsu-tab-btn-ar">
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="energyinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="energyinsu-banner-con-ar">
          <div class="energyinsu-bdy-ar">
            <div class="energyinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="energyinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance_ar.png"} alt="التأمين على الطاقة" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="energyinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين على الطاقة</h2>
                <p class="energyinsu-txt-con-ar">تلتزم شركة كيمجي رامداس لخدمات التأمين بحماية المخاطر التي يواجهها قطاع الطاقة، سواء كنت تعمل في النفط، والغاز، أو الطاقة، والمرافق أو الطاقة البديلة. نحن نخدم كل من صناعات النفط والغاز البرية والبحرية، من خلال نهج مخصص للتحديات العالمية الأكثر تطلبًا في مجال تأمين الطاقة</p>

                <p class="energyinsu-txt-con-ar">يمكننا تقديم قدرة رائدة في السوق لمجموعة واسعة من مخاطر الطاقة، مدعومة بضمانات مالية رائدة في السوق، والحاصلة على تصنيف «AA»(تصنيفات ممتازة) من قبل  ستاندرد آند بورزو، إضافة إلى تصنيفها A+  بواسطة</p>

                <p class="energyinsu-txt-con-ar">تمتلك شركة كيمجي رامداس لخدمات التأمين المطالبات الأكثر خبرة وفرق استشارات المخاطر في خطوط تأمين الطاقة؛ لذا فإننا نفتخر بأنفسنا على اتخاذ قراراتنا السريعة ونهجنا المرن في مواجهة تحديات عملائنا</p>

                <p class="energyinsu-txt-con-ar">التأمين على الطاقة البحرية والبرية</p>
                <h3 class="energyinsu-page-sbu-title-ar">التغطية الرئيسية/ التأمين على الطاقة</h3>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>


            <p class="energyinsu-txt-con-ar">تُصمم خدماتنا لهؤلاء العملاء المتخصصين على نحو فردي دائماً، ووفقًا لاحتياجاتهم، وتشمل ما يلي</p>

            <div class="energyinsu-pins-list-dsk-ar">
              <div class="energyinsu-pins-list-dsk-item-ar">الممتلكات البرية، بما في ذلك تغطية المعدات</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">الممتلكات البحرية، بما في ذلك الأضرار المادية لأجهزة الحفر والبناء البحري</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">تغطية البناء لمشاريع الطاقة البرية من قبل فريقنا الهندسي المتخصص</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">انقطاع العمل/ فقدان إيرادات الإنتاج</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">مراقبة تكاليف النفقات الإضافية للبئر/المشغلين/إعادة الحفر/ التسرب/ التعرض للتلوث</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">التأمين الخاص في حالات الخطر/ المظلة</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">المسؤولية العامة</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">بدن السفينة والآلات/ إجمالي تغطية الخسارة /فائدة الشحن/ فقد الاستئجار</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">خيارات الحمولة، بما في ذلك</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">المغلاة، والآلات</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">الشحنة، والنقل</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">مشروع الشحن</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">معدات المقاول</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">نفقات إضافية</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">الالتزامات البحرية المتنوعة</div>

              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-ar">المسؤولية تجاه الغير</div>
              <div class="energyinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>
            <h3 class="energyinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>
            <div class="energyinsu-pins-list-lftdksp-ar">
              <div class="energyinsu-lt-two-lftdksp-en">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="energyinsu-lt-one-lftdksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="energyinsu-lt-two-lftdksp-en">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="energyinsu-lt-one-lftdksp-ar">مقارنات عروض أسعار واسعة<img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="energyinsu-tab-imagebannermain-ar">
          <div class="energyinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="energyinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              
              <div class="energyinsu-tab-btn-ar">
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="energyinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="energyinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="energyinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على الطاقة</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance_ar.png"} alt="التأمين على الطاقة" />

              <p>تلتزم شركة كيمجي رامداس لخدمات التأمين بحماية المخاطر التي يواجهها قطاع الطاقة، سواء كنت تعمل في النفط، والغاز، أو الطاقة، والمرافق أو الطاقة البديلة. نحن نخدم كل من صناعات النفط والغاز البرية والبحرية، من خلال نهج مخصص للتحديات العالمية الأكثر تطلبًا في مجال تأمين الطاقة</p>
              
              <p>يمكننا تقديم قدرة رائدة في السوق لمجموعة واسعة من مخاطر الطاقة، مدعومة بضمانات مالية رائدة في السوق، والحاصلة على تصنيف «AA»(تصنيفات ممتازة) من قبل  ستاندرد آند بورزو، إضافة إلى تصنيفها A+  بواسطة</p>
              
              <p>تمتلك شركة كيمجي رامداس لخدمات التأمين المطالبات الأكثر خبرة وفرق استشارات المخاطر في خطوط تأمين الطاقة؛ لذا فإننا نفتخر بأنفسنا على اتخاذ قراراتنا السريعة ونهجنا المرن في مواجهة تحديات عملائنا</p>
              
              <p>التأمين على الطاقة البحرية والبرية</p>

              <h3 className="tab-sub-title-ar">التغطية الرئيسية/ التأمين على الطاقة</h3>

              <p>تُصمم خدماتنا لهؤلاء العملاء المتخصصين على نحو فردي دائماً، ووفقًا لاحتياجاتهم، وتشمل ما يلي</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">الممتلكات البرية، بما في ذلك تغطية المعدات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الممتلكات البحرية، بما في ذلك الأضرار المادية لأجهزة الحفر والبناء البحري</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تغطية البناء لمشاريع الطاقة البرية من قبل فريقنا الهندسي المتخصص</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">انقطاع العمل/ فقدان إيرادات الإنتاج</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مراقبة تكاليف النفقات الإضافية للبئر/المشغلين/إعادة الحفر/ التسرب/ التعرض للتلوث</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">التأمين الخاص في حالات الخطر/ المظلة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المسؤولية العامة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">بدن السفينة والآلات/ إجمالي تغطية الخسارة /فائدة الشحن/ فقد الاستئجار</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">خيارات الحمولة، بما في ذلك</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المغلاة، والآلات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الشحنة، والنقل</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مشروع الشحن</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">معدات المقاول</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">نفقات إضافية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الالتزامات البحرية المتنوعة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المسؤولية تجاه الغير</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> خدمات المطالبات الفورية</div>
                <div class="tab-whykrinsu-pinlft-one-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> أقل الأسعار</div>
                <div class="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> أفضل ممارسات صناعة التأمين</div>
                <div class="tab-whykrinsu-pinlft-one-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> مقارنات عروض أسعار واسعة</div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="energyinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"} selected class="active">التأمين على الطاقة</option>
          </select>
        </div>
        <div class="energyinsu-mob-main-ar">
          <h1 class="energyinsu-banner-heading-ar">التأمين على الطاقة</h1>
          <div class="energyinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance_ar.png"} alt="التأمين على الطاقة" />
          </div>

          <p class="energyinsu-mob-txt-ar">تلتزم شركة كيمجي رامداس لخدمات التأمين بحماية المخاطر التي يواجهها قطاع الطاقة، سواء كنت تعمل في النفط، والغاز، أو الطاقة، والمرافق أو الطاقة البديلة. نحن نخدم كل من صناعات النفط والغاز البرية والبحرية، من خلال نهج مخصص للتحديات العالمية الأكثر تطلبًا في مجال تأمين الطاقة</p>

          <p class="energyinsu-mob-txt-ar">يمكننا تقديم قدرة رائدة في السوق لمجموعة واسعة من مخاطر الطاقة، مدعومة بضمانات مالية رائدة في السوق، والحاصلة على تصنيف «AA»(تصنيفات ممتازة) من قبل  ستاندرد آند بورزو، إضافة إلى تصنيفها A+  بواسطة</p>

          <p class="energyinsu-mob-txt-ar">تمتلك شركة كيمجي رامداس لخدمات التأمين المطالبات الأكثر خبرة وفرق استشارات المخاطر في خطوط تأمين الطاقة؛ لذا فإننا نفتخر بأنفسنا على اتخاذ قراراتنا السريعة ونهجنا المرن في مواجهة تحديات عملائنا</p>

          <p class="energyinsu-mob-txt-ar">التأمين على الطاقة البحرية والبرية</p>

          <h3 class="energyinsu-subtitle-ar">التغطية الرئيسية/ التأمين على الطاقة</h3>

          <p class="energyinsu-mob-txt-ar">تُصمم خدماتنا لهؤلاء العملاء المتخصصين على نحو فردي دائماً، ووفقًا لاحتياجاتهم، وتشمل ما يلي</p>

          <div class="energyinsu-pins-list-mob-ar">
            <div class="energyinsu-pins-list-mob-item-ar">الممتلكات البرية، بما في ذلك تغطية المعدات</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">الممتلكات البحرية، بما في ذلك الأضرار المادية لأجهزة الحفر والبناء البحري</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">تغطية البناء لمشاريع الطاقة البرية من قبل فريقنا الهندسي المتخصص</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">انقطاع العمل/ فقدان إيرادات الإنتاج</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">مراقبة تكاليف النفقات الإضافية للبئر/المشغلين/إعادة الحفر/ التسرب/ التعرض للتلوث</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">التأمين الخاص في حالات الخطر/ المظلة</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">المسؤولية العامة</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">بدن السفينة والآلات/ إجمالي تغطية الخسارة /فائدة الشحن/ فقد الاستئجار</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">خيارات الحمولة، بما في ذلك</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">المغلاة، والآلات</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">الشحنة، والنقل</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">مشروع الشحن</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">معدات المقاول</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">نفقات إضافية</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">الالتزامات البحرية المتنوعة</div>

            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-ar">المسؤولية تجاه الغير</div>
            <div class="energyinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          <h3 class="energyinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="energyinsu-mob-whykrlist-ar">
            <div class="energyinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="energyinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="energyinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="energyinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default AREnergyInsurance;