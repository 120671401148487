import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './yachtinsurance.scss';

const YachtInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="yachtinsu-tab-imagebannermain-en">
          <div class="yachtinsu-banner-con-en">
            <div class="yachtinsutab-banne-sec-en">
              <h1 class="yachtinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="yachtinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="yachtinsu-banner-con-en">
          <div class="yachtinsu-bdy-en">
            <div class="yachtinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="yachtinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Yacht Insurance</h2>
                <p class="pg-txt-con">A Comprehensive list of products</p>

                <div class="yachtinsukeyfeatures-en">
                  <div class="yachtinsukeyfeatures-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-en">Peaceful underwriting procedure with a customized cover.</div>
                  <div class="yachtinsukeyfeatures-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-en">Local and regional trading limits.</div>
                  <div class="yachtinsukeyfeatures-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-en">Specialized teams to assist you.</div>
                  <div class="yachtinsukeyfeatures-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-en">With Our Insurance solution, You will sail with Peace of Mind.</div>
                </div>
                <h3 class="yachtinsu-page-sbu-title-en">Main Cover / Yacht Insurance</h3>

                <p class="pg-txt-con">KR Insurance Services offers yacht insurance plan, which is a highly flexible cover to cater the boat owners' requirements; whether an accidental loss or any damage to the ship including any liabilities towards third parties. Your ship is covered whilst in use or moored afloat, or it could be ashore and also during transit.</p>

                <p class="pg-txt-con">Some of the additional incentives are Uninsured Boater coverage, Search & Rescue and bonuses like a no claim rebate.</p>
            <h3 class="yachtinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="yachtinsu-pins-list-en">
                  <div class="yachtinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div class="yachtinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div class="yachtinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div class="yachtinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>
                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="yachtinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance.png"} alt="yatch insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="yachtinsu-tab-imagebannermain-en">
          <div class="yachtinsu-banner-con-en">
            <div class="yachtinsutab-banne-sec-en">
              <h1 class="yachtinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="yachtinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="yachtinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Yacht Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance.png"} alt="yatch insurance" />
              <p>A Comprehensive list of products</p>
              
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />Peaceful underwriting procedure with a customized cover.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />Local and regional trading limits.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />Specialized teams to assist you.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />With Our Insurance solution, You will sail with Peace of Mind.</p>
                  
              <p class="tab-subhead-title-en">Main Cover / Yacht Insurance</p>

                <p>KR Insurance Services offers yacht insurance plan, which is a highly flexible cover to cater the boat owners' requirements; whether an accidental loss or any damage to the ship including any liabilities towards third parties. Your ship is covered whilst in use or moored afloat, or it could be ashore and also during transit.</p>

                <p>Some of the additional incentives are Uninsured Boater coverage, Search & Rescue and bonuses like a no claim rebate.</p>

                <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
                        
                        <div class="tab-whykrinsu-pins-list-en">
                              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
                        </div>
                        {/* ask for quotes button starts here */}
                        <div className="askquto-btn-sec">
                              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                        </div>
                        {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="yachtinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"} selected class="active">Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="yachtinsu-mob-main-en">
        <h1 class="yachtinsu-banner-heading-en">Yacht Insurance</h1>
              <div class="yachtinsu-mob-img-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance.png"} alt="yatch insurance" />
              </div>
              <p class="yachtinsu-mob-txt-en">A Comprehensive list of products</p>

              <div class="yachtinsu-mob-keypoint-en">
                <div class="yachtinsu-mob-keypoint-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="yachtinsu-mob-keypoint-item-en">Peaceful underwriting procedure with a customized cover.</div>

                <div class="yachtinsu-mob-keypoint-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="yachtinsu-mob-keypoint-item-en">Local and regional trading limits.</div>

                <div class="yachtinsu-mob-keypoint-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="yachtinsu-mob-keypoint-item-en">Specialized teams to assist you.</div>

                <div class="yachtinsu-mob-keypoint-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="yachtinsu-mob-keypoint-item-en">With Our Insurance solution, You will sail with Peace of Mind.</div>
              </div>
              
              <p class="yachtinsu-mob-txt-en">These are the key features of KR Insurance Services Yacht Insurance</p>

              <p class="yachtinsu-mob-txt-en">It is the most comprehensive protection plan for your precious boat. It could be brand new power packed motor cruiser or a pleasant sailing dingy.</p>
              
              <h3 class="yachtinsu-subtitle-en">Main Cover / Yacht Insurance</h3>

              <p class="yachtinsu-mob-txt-en">KR Insurance Services offers yacht insurance plan, which is a highly flexible cover to cater the boat owners' requirements; whether an accidental loss or any damage to the ship including any liabilities towards third parties. Your ship is covered whilst in use or moored afloat, or it could be ashore and also during transit.</p>

              <p class="yachtinsu-mob-txt-en">Some of the additional incentives are Uninsured Boater coverage, Search & Rescue and bonuses like a no claim rebate.</p>

              <h3 class="yachtinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

              <div class="yachtinsu-mob-whykrlist-en">
                <div class="yachtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                <div class="yachtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                <div class="yachtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                <div class="yachtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
             </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default YachtInsurance;