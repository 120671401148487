import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './stockthroughputinsurance.scss';

const StockThroughputInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="stckcovinsu-tab-imagebannermain-en">
          <div class="stckcovinsu-banner-con-en">
            <div class="stckcovinsutab-banne-sec-en">
              <h1 class="stckcovinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="stckcovinsu-banner-con-en">
          <div class="stckcovinsu-bdy-en">
            <div class="stckcovinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="stckcovinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Stock Throughput Insurance</h2>

                <p class="pg-txt-con">This policy is specially designed to provide comprehensive insurance coverage to products in storage or in transit. Once the goods are manufactured they either reach directly to the customer or are stored in warehouses before onward distribution. If they are sent to storage before onward distribution, then stock throughput policy is the right solution.</p>

                <p class="pg-txt-con">The important benefits of stock throughput insurance cover are :</p>

                <div class="stckcovinsu-keypoint-en">
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Potential gaps between multiple policies are eliminated.</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Combining number of under one policy provides significant price savings.</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Specialist coverage for industry sectors and complex businesses.</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Cover for primary and contingent interests,</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">High limits and low deductibles.</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Premium can be rated against sales turnover.</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Reduces the need for time consuming declarations.</div>
                  <div class="stckcovinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="stckcovinsu-keypoint-item-en">Competitive rates for CAT Capacity.</div>
                </div>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}

              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="stckcovinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/stock_throughput_insurance.png"} alt="stock throughput insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="stckcovinsu-txt-con">KR Insurance Stock throughput insurance solutions are suited for retailers, manufacturers and traders, which can include:-</p>

            <div class="stckcovinsu-pins-list-dsk-en">
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">International and domestic transits by any mode of transportation.</div>

              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Stock (inventory), including raw materials, work in progress as well as finished goods.</div>

              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Ancillary extensions, such as travellers samples, exhibitions and trade shows.</div>

            </div>

            <p class="stckcovinsu-txt-con">KR Insurance Services offers unique products with the sole intention of safeguarding your end to end stocks, whether in the process of manufacturing, storage or in transit.</p>

            <p class="stckcovinsu-txt-con">KR Insurance Services has a unique risk management services which provides consultancy for stock keeping in warehouses, vessel selection, warehousing and packaging. We are also providing route surveys on case to case basis. We have emergency helplines operating 24x7, assuring best claim settlement services to our clients.</p>

            <h3 class="stckcovinsu-page-sbu-title-en">Main Cover / Stock Throughput Insurance</h3>

            <div class="stckcovinsu-pins-list-dsk-en">
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Fire or explosion, stranding, sinking etc. - can’t predict these situations in advance. Fire or explosion, sinking, stranding are common issues faced during a cargo journey. A marine insurance plan is formulated to take care of such dire situations.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Collision, overturning or derailment of land conveyance - Sometimes, no matter how hard the captain tries, he can’t take control of unfavourable damage causing situations. Having a marine insurance plan neutralizes the risk of damages caused by collision, overturning or derailment of land conveyance.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Discharge of cargo at port of distress - a cargo is a discharged from a port of disturbance or distress, it threatens the safety of the cargo. This is the reason why it is included in the basic insurance coverage.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">General average sacrifice salvage charges - marine insurance plan provides coverage for general average sacrifice salvage charges.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Expenses such as survey fees, forwarding expenses, reconditioning costs and sue charges - These expenses are unexpected at times which leaves you with little time to pay for these expenses. This is why having a marine insurance plan is of great help.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Jettison or washing overboard - Situations of jettison and washing overboard are included in a marine insurance plan.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Earthquake or lightning -&nbsp;marine insurance plan provides you coverage from natural calamities such as earthquake or lightning.</div>
              <div class="stckcovinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="stckcovinsu-pins-list-dsk-item-en">Total loss of package lost overboard or dropped in loading or unloading - No matter how irksome a package loss can be, every time you can’t create a foolproof plan to prevent this situation when your package is being transported in a cargo. This is why it is included in a basic marine insurance plan.</div>

            </div>

            <h3 class="stckcovinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="stckcovinsu-pins-list-dksp-en">
              <div class="stckcovinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="stckcovinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="stckcovinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="stckcovinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="stckcovinsu-tab-imagebannermain-en">
          <div class="stckcovinsu-banner-con-en">
            <div class="stckcovinsutab-banne-sec-en">
              <h1 class="stckcovinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="stckcovinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Stock Throughput Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/stock_throughput_insurance.png"} alt="stock throughput insurance" />

              <p>This policy is specially designed to provide comprehensive insurance coverage to products in storage or in transit. Once the goods are manufactured they either reach directly to the customer or are stored in warehouses before onward distribution. If they are sent to storage before onward distribution, then stock throughput policy is the right solution.</p>

              <p>The important benefits of stock throughput insurance cover are :</p>

              <div className="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Potential gaps between multiple policies are eliminated.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Combining number of under one policy provides significant price savings.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Specialist coverage for industry sectors and complex businesses.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Cover for primary and contingent interests,</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">High limits and low deductibles.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Premium can be rated against sales turnover.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Reduces the need for time consuming declarations.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Competitive rates for CAT Capacity.</div>
              </div>

              <p>KR Insurance Stock throughput insurance solutions are suited for retailers, manufacturers and traders, which can include:-</p>

              <div className="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">International and domestic transits by any mode of transportation.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Stock (inventory), including raw materials, work in progress as well as finished goods.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Ancillary extensions, such as travellers samples, exhibitions and trade shows.</div>
              </div>
              
              <p>KR Insurance Services offers unique products with the sole intention of safeguarding your end to end stocks, whether in the process of manufacturing, storage or in transit.</p>
              
              <p>KR Insurance Services has a unique risk management services which provides consultancy for stock keeping in warehouses, vessel selection, warehousing and packaging. We are also providing route surveys on case to case basis. We have emergency helplines operating 24x7, assuring best claim settlement services to our clients.</p>

              <h3 class="tab-sub-title-en">Main Cover / Stock Throughput Insurance</h3>

              <div className="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Fire or explosion, stranding, sinking etc. - can’t predict these situations in advance. Fire or explosion, sinking, stranding are common issues faced during a cargo journey. A marine insurance plan is formulated to take care of such dire situations.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Collision, overturning or derailment of land conveyance - Sometimes, no matter how hard the captain tries, he can't take control of unfavourable damage causing situations. Having a marine insurance plan neutralizes the risk of damages caused by collision, overturning or derailment of land conveyance.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Discharge of cargo at port of distress - a cargo is a discharged from a port of disturbance or distress, it threatens the safety of the cargo. This is the reason why it is included in the basic insurance coverage.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">General average sacrifice salvage charges - marine insurance plan provides coverage for general average sacrifice salvage charges.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Expenses such as survey fees, forwarding expenses, reconditioning costs and sue charges - These expenses are unexpected at times which leaves you with little time to pay for these expenses. This is why having a marine insurance plan is of great help.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Jettison or washing overboard - Situations of jettison and washing overboard are included in a marine insurance plan.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Earthquake or lightning -&nbsp;marine insurance plan provides you coverage from natural calamities such as earthquake or lightning.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Total loss of package lost overboard or dropped in loading or unloading - No matter how irksome a package loss can be, every time you can’t create a foolproof plan to prevent this situation when your package is being transported in a cargo. This is why it is included in a basic marine insurance plan.</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="stckcovinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"} selected class="active">Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="stckcovinsu-mob-main-en">
          <h1 class="stckcovinsu-banner-heading-en">Stock Throughtput Insurance</h1>
          <div class="stckcovinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/stock_throughput_insurance.png"} />
          </div>


          <p class="stckcovinsu-mob-txt-en">This policy is specially designed to provide comprehensive insurance coverage to products in storage or in transit. Once the goods are manufactured they either reach directly to the customer or are stored in warehouses before onward distribution. If they are sent to storage before onward distribution, then stock throughput policy is the right solution.</p>

          <p class="stckcovinsu-mob-txt-en">The important benefits of stock throughput insurance cover are :</p>

          <div class="stckcovinsu-pins-list-mob-en">
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Potential gaps between multiple policies are eliminated.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Combining number of under one policy provides significant price savings.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Specialist coverage for industry sectors and complex businesses.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Cover for primary and contingent interests,</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">High limits and low deductibles.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Premium can be rated against sales turnover.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Reduces the need for time consuming declarations.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Competitive rates for CAT Capacity.</div>
          </div>

          <p class="stckcovinsu-mob-txt-en">KR Insurance Stock throughput insurance solutions are suited for retailers, manufacturers and traders, which can include:-</p>

          <div class="stckcovinsu-pins-list-mob-en">
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">International and domestic transits by any mode of transportation.</div>

            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Stock (inventory), including raw materials, work in progress as well as finished goods.</div>

            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Ancillary extensions, such as travellers samples, exhibitions and trade shows.</div>

          </div>

          <p class="stckcovinsu-mob-txt-en">KR Insurance Services offers unique products with the sole intention of safeguarding your end to end stocks, whether in the process of manufacturing, storage or in transit.</p>

          <p class="stckcovinsu-mob-txt-en">KR Insurance Services has a unique risk management services which provides consultancy for stock keeping in warehouses, vessel selection, warehousing and packaging. We are also providing route surveys on case to case basis. We have emergency helplines operating 24x7, assuring best claim settlement services to our clients.</p>

          <h3 class="stckcovinsu-subtitle-en">Main Cover / Stock Throughput Insurance</h3>

          <div class="stckcovinsu-pins-list-mob-en">
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Fire or explosion, stranding, sinking etc. - can’t predict these situations in advance. Fire or explosion, sinking, stranding are common issues faced during a cargo journey. A marine insurance plan is formulated to take care of such dire situations.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Collision, overturning or derailment of land conveyance - Sometimes, no matter how hard the captain tries, he can’t take control of unfavourable damage causing situations. Having a marine insurance plan neutralizes the risk of damages caused by collision, overturning or derailment of land conveyance.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Discharge of cargo at port of distress - a cargo is a discharged from a port of disturbance or distress, it threatens the safety of the cargo. This is the reason why it is included in the basic insurance coverage.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">General average sacrifice salvage charges - marine insurance plan provides coverage for general average sacrifice salvage charges.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Expenses such as survey fees, forwarding expenses, reconditioning costs and sue charges - These expenses are unexpected at times which leaves you with little time to pay for these expenses. This is why having a marine insurance plan is of great help.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Jettison or washing overboard - Situations of jettison and washing overboard are included in a marine insurance plan.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Earthquake or lightning -&nbsp;marine insurance plan provides you coverage from natural calamities such as earthquake or lightning.</div>
            <div class="stckcovinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="stckcovinsu-pins-list-mob-item-en">Total loss of package lost overboard or dropped in loading or unloading - No matter how irksome a package loss can be, every time you can’t create a foolproof plan to prevent this situation when your package is being transported in a cargo. This is why it is included in a basic marine insurance plan.</div>

          </div>

          <h3 class="stckcovinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="stckcovinsu-mob-whykrlist-en">
            <div class="stckcovinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="stckcovinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="stckcovinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="stckcovinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default StockThroughputInsurance;