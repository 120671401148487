import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../headerAR/headerar";
import FooterAR from "../footerAR/footerar";
import './arteam.scss';

const AROurteamPage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  return (
    <div>
      <div className="main-fluid">
        <HeaderAR />
      </div>
      {/* <div className="container kr-container"> */}
      <div className="kr-ourteam-ar">
        <h1 className="team-title-ar">الفريق الخاص بنا</h1>
        <p className="team-head-txt-ar">سيسعد فريقنا بالعمل عن كثب على متطلبات التأمين لمؤسستك وتقديم أفضل خدمات التأمين</p>
        {/* OUR TEAM NEW SECTION STARTS HERE */}
        <div className="ourteam-ar">
          {/* 5 TEAM MEMBER Vikram Aditya STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/vikram_aditya.png"} alt="فيكرام أديتيا" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  حاصل على دبلوم الدراسات العليا (ماجستير إدارة الأعمال) في إدارة المستشفيات والصحة مع 17 عامًا من الخبرة في الصناعة
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#VikramAditya">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">فيكرام أديتيا</div>
                <div className="tup-style-1-designation-ar">رئيس خدمات التأمين</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 5 TEAM MEMBER Vikram Aditya ENDS HERE */}

          {/* 2 TEAM MEMBER ABBAS Al SAJWANY STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/abbas_al_sajwany.png"} alt="عباس الساجواني" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  يتمتع بخبرة تزيد عن 20 عامًا في الصناعة وعمل في الصناعات التحويلية والتجارية المرتبطة حاليًا بصناعة التأمين.
                  قراءة المزيد
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#ModalTen">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">عباس الساجواني</div>
                <div className="tup-style-1-designation-ar">مدير أول -  الفريق الاستشاري للشركة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 2 TEAM MEMBER ABBAS Al SAJWANY ENDS HERE */}
          {/* 3 TEAM MEMBER Hamed Al-Hanaai STARTS HERE */}
          {/* <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/hamad_al_hinaai.png"} alt="حمد الهنائي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  يتمتع  بأكثر من 10 سنوات من الخبرة في المجال. لقد تعامل مع كل من خطوط التأمين الشخصية والتجارية
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#ModalEleven">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">حمد الهنائي</div>
                <div className="tup-style-1-designation-ar">مدير مساعد - الفريق الاستشاري للشركة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 3 TEAM MEMBER Hamed Al-Hanaai ENDS HERE */}
          {/* 4 TEAM MEMBER Sawsan Abdul Aziz Al Alawi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/sawsan_al_alawi.png"} alt="سوسن عبد العزيز العلوي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  حصلت على الدبلوم العالي في تكنولوجيا المعلومات و شهادة المحاسب الإداري المهنية المعتمدة في خدمات العملاء والشكاوى
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#ModalSeven">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">سوسن عبد العزيز العلوي</div>
                <div className="tup-style-1-designation-ar">مدير - الحسابات مستحقة القبض</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 4 TEAM MEMBER Sawsan Abdul Aziz Al Alawi ENDS HERE */}


          {/* 17 TEAM MEMBER Alam Syed Mahetab STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/alam_syed_mahetab.png"} alt="علام سيد ماهيتاب" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  لقد حصل على دبلوم في التأمين من معهد تشارترد للتأمين في المملكة المتحدة وهو زميل في التأمين من معهد التأمين في الهند
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#AlamSyedMahetab">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">علام سيد ماهيتاب</div>
                <div className="tup-style-1-designation-ar">كبير القادة - فريق الحلول في الشركة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 17 TEAM MEMBER Alam Syed Mahetab ENDS HERE */}


          {/* 6 TEAM MEMBER Ghalib Mohammed Nasser Al Siyabi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/ghalib_al_siyabi.png"} alt="غالب محمد ناصر السيابي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  حاصل على درجة البكالوريوس في العلاقات العامة وحصل على شهادة في إدارة الأعمال من معهد كيمجي للتدريب

                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#ModalThirteen">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">غالب محمد ناصر السيابي</div>
                <div className="tup-style-1-designation-ar">مدير مساعد - فريق الحلول في الشركة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 6 TEAM MEMBER Ghalib Mohammed Nasser Al Siyabi ENDS HERE */}

          {/* 8 TEAM MEMBER G Jeswanth Alister David STARTS HERE */}
          {/* <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/g_jeswanth_alister_david.png"} alt="جي جيسوانث أليستر ديفيد" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  يتمتع بخبرة تزيد عن 20 عامًا في مجال المبيعات والتسويق والصيانة وإدارة العملاء
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#GJeswanthAlisterDavid">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">جي جيسوانث أليستر ديفيد</div>
                <div className="tup-style-1-designation-ar">قائد  – الفريق الإستشاري في الشركة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 8 TEAM MEMBER G Jeswanth Alister David ENDS HERE */}
          {/* 9 TEAM MEMBER Arun Kumar Gupta STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/arun_kumar_gupta.png"} alt="آرون كومار جوبتا" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  وهو زميل ممارس مساعد معتمد لعمليات التأمين (معهد تشارترد للتأمين- المملكة المتحدة) وهو مساح و مسؤول تسوية مطالبات مرخص ويحمل دبلوم في الهندسة الميكانيكية والكهربائية
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#ArunKumarGupta">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">آرون كومار جوبتا</div>
                <div className="tup-style-1-designation-ar">رئيس - إدارة خدمات المطالبات والمخاطر</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 9 TEAM MEMBER Arun Kumar Gupta ENDS HERE */}
          {/* 10 TEAM MEMBER Najma Abdulla Hamed Al Mahrizy STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/najma_abdulla_hamed_al_mahrizy.png"} alt="نجمة عبد الله حامد المهاريز" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  حصلت على  دبلوم في الحسابات و تدريب متخصص في تكنولوجيا المعلومات مع خبرة في البرمجيات المالية المختلفة وخبرة في إجراءات المنظمة الدولية للتوحيد القياسي
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#NajmaAbdullaHamedAlMahrizy">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">نجمة عبد الله حامد المهاريز</div>
                <div className="tup-style-1-designation-ar">قائد – إدارة المطالبات و المخاطر</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 10 TEAM MEMBER Najma Abdulla Hamed Al Mahrizy ENDS HERE */}
          {/* 11 TEAM MEMBER Qais Ali Thani Al Sulaimi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/qais_ali_thani_al_sulaimi.png"} alt="قيس علي ثاني السليمي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  قيس هو شخص نشيط وطموح ويسعده دائمًا مساعدة العملاء في مطالبات التأمين على السيارات الخاصة بهم. يتمتع  بأكثر من 10 سنوات من الخبرة في صناعة التأمين
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#QaisAliThaniAlSulaimi">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">قيس علي ثاني السليمي</div>
                <div className="tup-style-1-designation-ar">مدير مساعد  - مطالبات السيارات</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 11 TEAM MEMBER Qais Ali Thani Al Sulaimi ENDS HERE */}
          {/* 12 TEAM MEMBER Fatma Ismail Hussain Al Bulushi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/fatma_ismail_hussain_al_bulushi.png"} alt="فاطمة إسماعيل حسين البلوشي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  تتمتع بـ 7 سنوات من الخبرة في العمل وهي مسؤولة عن إدارة بيانات التأمين داخليًا في كيمجي رامداس لخدمات التأمين
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#FatmaIsmailHussainAlBulushi">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">فاطمة إسماعيل حسين البلوشي</div>
                <div className="tup-style-1-designation-ar">مسؤول تنفيذي – إدارة خدمات المطالبات والمخاطر</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 12 TEAM MEMBER Fatma Ismail Hussain Al Bulushi ENDS HERE */}

          {/* 13 TEAM MEMBER Dr Sagar Jagtap STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/dr_sagar_jagtap.png"} alt="الدكتور ساغر جاجتاب" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  طبيب مؤهل، وحصل على ماجستير إدارة الأعمال في إدارة الرعاية الصحية ودبلوم الدراسات العليا في خدمات الطوارئ الطبية
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#DrKiranBhaskarraoKherdekar">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">الدكتور ساغر جاجتاب</div>
                <div className="tup-style-1-designation-ar">رئيس قسم المطالبات الطبية ومطالبات الحياة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 13 TEAM MEMBER Dr Sagar Jagtap ENDS HERE */}

          {/* 14 TEAM MEMBER Saniya Mohammed Aslam AL Balushi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/sania_al_balushi.png"} alt="سانية محمد أسلم البلوشي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  حاصلة على شهادة في إدارة خدمات العملاء. لديها أكثر من 7 سنوات من الخبرة في الصناعة ولديها خبرة في التعامل مع المطالبات الطبية و مطالبات الحياة
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#SaniyaMohammedAslamALBalushi">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">سانية محمد أسلم البلوشي</div>
                <div className="tup-style-1-designation-ar">المدير - قسم المطالبات الطبية و مطالبات الحياة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 14 TEAM MEMBER Saniya Mohammed Aslam AL Balushi ENDS HERE */}

          {/* 15 TEAM MEMBER Amani Moosa Sbeit Al Mukhaini STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/amani_moosa_sbeit_al_mukhaini.png"} alt="Amani Moosa Sbeit Al Mukhaini" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  تتمتع  بخبرة 7 سنوات وهي جزء من فريق المطالبات الطبية
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#AmaniMoosaSbeitAlMukhaini">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">أماني موسى سبيت المخيني</div>
                <div className="tup-style-1-designation-ar">مسؤول تنفيذي - قسم المطالبات الطبية و مطالبات الحياة</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 15 TEAM MEMBER Amani Moosa Sbeit Al Mukhaini ENDS HERE */}

          {/* 16 TEAM MEMBER Samiya Dur Mohammed Albalushi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/samiya_dur_mohammed_albalushi.png"} alt="ساميا دور محمد البلوشي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  ساميا هي شخص نشيط وطموح ويسعدها دائمًا مساعدة العملاء في مطالبات التأمين على السيارات. إنها تتمتع  بأكثر من 14 عامًا
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#SamiyaDurMohammedAlbalushi">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">ساميا دور محمد البلوشي</div>
                <div className="tup-style-1-designation-ar">تنفيذي - مطالبات السيارات</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 16 TEAM MEMBER Samiya Dur Mohammed Albalushi ENDS HERE */}

          {/* 17 TEAM MEMBER Ansari Mohammad Ahmad STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/ansari_mohammad_ahmad.png"} alt="أنصاري محمد أحمد" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  لديه 14 عامًا من الخبرة القوية في وساطة التأمين حيث يتعامل مع عملاء الشركات الصغيرة والمتوسطة والكبيرة في سلطنة عمان. هو خبير في تأمين الممتلكات والحوادث، بالإضافة إلى تأمين السيارات والتأمين البحري. من حيث المؤهلات، هو خريج علوم ويحمل شهادة زميل من معهد التأمين في الهند.                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#AlamSyedMahetab">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">أنصاري محمد أحمد</div>
                <div className="tup-style-1-designation-ar">مدير - مجموعة الحلول المؤسسية</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 17 TEAM MEMBER Ansari Mohammad Ahmad ENDS HERE */}


          {/* 7 TEAM MEMBER Suad Abdullah Humaid Al Kalbani STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/dharminder_kumar.png"} alt="دارميندر كومار" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  محترف ذو خبرة تزيد عن عقد من الزمان في صناعة التأمين. عمل في مؤسسات مرموقة مثل ICICI LOMBARD و HDFC Ergo و Bharti AXA. لديه معرفة متعمقة في مجال التأمين، وتحليل المخاطر، وإدارة محفظات العملاء المتعلقة بالصناعات المختلفة.<span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#SuadAbdullahHumaidAlKalbani">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">دارميندر كومار</div>
                <div className="tup-style-1-designation-ar">مدير - مجموعة الحلول المؤسسية</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 7 TEAM MEMBER Suad Abdullah Humaid Al Kalbani ENDS HERE */}


          {/* 19 TEAM MEMBER Uhood Ali Haji Al Balushi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/uhood_ali_haji_al_balushi.png"} alt="عهود علي حاجي البلوشي" />
              </div>
              {/* <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                Uhood Ali Haji Al Balushi Content here
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#UhoodAliHajiAlBalushi">Read More </a> </span>
                </div>
              </div> */}
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">عهود علي حاجي البلوشي</div>
                <div className="tup-style-1-designation-ar">مدير مساعد – الحسابات</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 19 TEAM MEMBER Uhood Ali Haji Al Balushi ENDS HERE */}

          {/* 20 TEAM MEMBER Nagaraj Binage STARTS HERE */}
          {/* <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/nagaraj_binage.png"} alt="ناجاراج بيناج" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                Nagaraj Binage Content here
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#NagarajBinage">Read More </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">ناجاراج بيناج</div>
                <div className="tup-style-1-designation-ar">مدير - إدارة خدمات المخاطر و المطالبات</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 20 TEAM MEMBER Nagaraj Binage ENDS HERE */}

          {/* 21 TEAM MEMBER Mazoon Al Zadjali  STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/mazoon_al_zadjali.png"} alt="مازون آل زادجالي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  تتمتع  بـ 12 عامًا من الخبرة العملية وهي حاصلة على درجة إدارة الأعمال في التسويق ولديها خبرة غنية في التسويق الرقمي
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#MazoonAlZadjali">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">مازون آل زادجالي</div>
                <div className="tup-style-1-designation-ar">رئيس المبيعات الرقمية والتسويق</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 21 TEAM MEMBER Mazoon Al Zadjali  ENDS HERE */}

          {/* 22 TEAM MEMBER Iman Juma Mohammed Al Ajmi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/iman_juma_mohammed_al_ajmi.png"} alt="إيمان جاما محمد العجمي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  تتمتع بخبرة 11 عامًا ولديها خبرة في خطوط التأمين الفردية
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#ImanJumaMohammedAlAjmi">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">إيمان جاما محمد العجمي</div>
                <div className="tup-style-1-designation-ar">مدير مساعد - المبيعات الرقمية</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 22 TEAM MEMBER Iman Juma Mohammed Al Ajmi ENDS HERE */}

          {/* 23 TEAM MEMBER Rasha Rashid Hamed Al Alawi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-ar">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/rasha_rashid_hamed_al_alawi.png"} alt="رشا رشيد حامد العلوي" />
              </div>
              <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  تتمتع بخبرة عمل 7 سنوات ولديها خبرة في خطوط التأمين الفردية
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#RashaRashidHamedAlAlawi">قراءة المزيد </a> </span>
                </div>
              </div>
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">رشا رشيد حامد العلوي</div>
                <div className="tup-style-1-designation-ar">المسؤول  التنفيذي - المبيعات الرقمية</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 23 TEAM MEMBER Rasha Rashid Hamed Al Alawi ENDS HERE */}

          {/* 24 TEAM MEMBER Tahiya Hamed Salim Alwadhahi STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/tahiya_hamed_salim_alwadhahi.png"} alt="تحية حامد سليم الوضاحي" />
              </div>
              {/* <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                Tahiya Hamed Salim Alwadhahi Content here
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#TahiyaHamedSalimAlwadhahi">Read More </a> </span>
                </div>
              </div> */}
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">تحية حامد سليم الوضاحي</div>
                <div className="tup-style-1-designation-ar">مدير مساعد – الحسابات</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 24 TEAM MEMBER Tahiya Hamed Salim Alwadhahi ENDS HERE */}

          {/* 25 TEAM MEMBER safar_al_balushi.png STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/yousuf_al_nasri.png"} alt="يوسف النصري" />
              </div>
              {/* <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  Yousuf Al Nasri Content here
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#YousufAlNasri">Read More </a> </span>
                </div>
              </div> */}
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">يوسف النصري</div>
                <div className="tup-style-1-designation-ar">المسؤول التنفيذي اللوجستي</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 25 TEAM MEMBER safar_al_balushi.png ENDS HERE */}

          {/* 26 TEAM MEMBER Yousuf Al Nasri STARTS HERE */}
          <div className="ourteam-item-ar">
            <div className="tup-style-1-ar">
              <div className="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/safar_al_balushi.png"} alt="سفار البلوشي" />
              </div>
              {/* <div className="tup-style-1-content-ar">
                <div className="tup-style-1-biography-ar">
                  Yahya Yousuf Ali Alwahaibi Content here
                  <span className="tup-popup-area-1-ar">
                    <a data-bs-toggle="modal" data-bs-target="#SafarAlBalushi">Read More </a> </span>
                </div>
              </div> */}
              <div className="tup-style-1-team-info-ar">
                <div className="tup-style-1-title-ar">سفار البلوشي</div>
                <div className="tup-style-1-designation-ar">المسؤول التنفيذي اللوجستي</div>
                <span className="tup-style-1-mail-ar"><a href="mailto:"></a></span>
                <span className="tup-style-1-telephone-ar"></span>
                <span className="tup-style-1-web-ar"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 26 TEAM MEMBER Yousuf Al Nasri ENDS HERE */}

        </div>
        {/* OUR TEAM NEW SECTION ENDS HERE */}
      </div>
      {/* </div> */}

      <div className="main-fluid">
        <FooterAR />

        {/* TEAM MEMBER MODAL Vikaus Siingh STARTS HERE */}
        <div className="modal fade" id="ModalOne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/vikaus_siingh_new.png"} alt="فيكاوس سيينغ" />
                    <h5 className="team-mempic-nm-ar">فيكاوس سيينغ</h5>
                    <h6 className="team-mempic-dec-ar">رئيس خدمات التأمين</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>لقد أنهى برنامج ما بعد التخرج في إدارة الأعمال ، وتخرج في القانون وحصل على دورات في القيادة والاستراتيجيات من جامعات هارفارد ووارتون. وهو حاصل على ترخيص في التأمين ولديه خبرة صناعية واسعة تزيد عن 25 عامًا</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Vikaus Siingh ENDS HERE */}

        {/* TEAM MEMBER MODAL Vikram Aditya HERE */}
        <div className="modal fade" id="VikramAditya" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/vikram_aditya.png"} alt="فيكرام أديتيا" />
                    <h5 className="team-mempic-nm-ar">فيكرام أديتيا</h5>
                    <h6 className="team-mempic-dec-ar">رئيس خدمات التأمين</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>حاصل على دبلوم الدراسات العليا (ماجستير إدارة الأعمال) في إدارة المستشفيات والصحة مع 17 عامًا من الخبرة في الصناعة</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Vikram Aditya ENDS HERE */}

        {/* TEAM MEMBER MODAL Arun Kumar Gupta HERE */}
        {/* <div className="modal fade" id="ArunKumarGupta" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/arun_kumar_gupta.png"} alt="آرون كومار جوبتا" />
                    <h5 className="team-mempic-nm-ar">آرون كومار جوبتا</h5>
                    <h6 className="team-mempic-dec-ar">رئيس - إدارة خدمات المطالبات والمخاطر</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>وهو زميل ممارس مساعد معتمد لعمليات التأمين (معهد تشارترد للتأمين- المملكة المتحدة) وهو مساح و مسؤول تسوية مطالبات مرخص ويحمل دبلوم في الهندسة الميكانيكية والكهربائية</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> */}
        {/* TEAM MEMBER MODAL Arun Kumar Gupta ENDS HERE */}
        {/* TEAM MEMBER MODAL Najma Abdulla Hamed Al Mahrizy HERE */}
        <div className="modal fade" id="NajmaAbdullaHamedAlMahrizy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/najma_abdulla_hamed_al_mahrizy.png"} alt="Najma Abdulla Hamed Al Mahrizy" />
                    <h5 className="team-mempic-nm-ar">نجمة عبد الله حامد المهاريز</h5>
                    <h6 className="team-mempic-dec-ar">قائد – إدارة المطالبات و المخاطر</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>حصلت على  دبلوم في الحسابات و تدريب متخصص في تكنولوجيا المعلومات مع خبرة في البرمجيات المالية المختلفة وخبرة في إجراءات المنظمة الدولية للتوحيد القياسي</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Najma Abdulla Hamed Al Mahrizy ENDS HERE */}

        {/* TEAM MEMBER MODAL Qais Ali Thani Al Sulaimi HERE */}
        <div className="modal fade" id="QaisAliThaniAlSulaimi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/qais_ali_thani_al_sulaimi.png"} alt="قيس علي ثاني السليمي" />
                    <h5 className="team-mempic-nm-ar">قيس علي ثاني السليمي</h5>
                    <h6 className="team-mempic-dec-ar">مدير مساعد  - مطالبات السيارات</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>قيس هو شخص نشيط وطموح ويسعده دائمًا مساعدة العملاء في مطالبات التأمين على السيارات الخاصة بهم. يتمتع  بأكثر من 10 سنوات من الخبرة في صناعة التأمين</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Qais Ali Thani Al Sulaimi ENDS HERE */}

        {/* TEAM MEMBER MODAL Fatma Ismail Hussain Al Bulushi HERE */}
        <div className="modal fade" id="FatmaIsmailHussainAlBulushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/fatma_ismail_hussain_al_bulushi.png"} alt="فاطمة إسماعيل حسين البلوشي" />
                    <h5 className="team-mempic-nm-ar">فاطمة إسماعيل حسين البلوشي</h5>
                    <h6 className="team-mempic-dec-ar">مسؤول تنفيذي – إدارة خدمات المطالبات والمخاطر</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>تتمتع بـ 7 سنوات من الخبرة في العمل وهي مسؤولة عن إدارة بيانات التأمين داخليًا في كيمجي رامداس لخدمات التأمين</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Fatma Ismail Hussain Al Bulushi ENDS HERE */}

        {/* TEAM MEMBER MODAL Dr Sagar Jagtap HERE */}
        <div className="modal fade" id="DrKiranBhaskarraoKherdekar" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/dr_sagar_jagtap.png"} alt="الدكتور ساغر جاجتاب" />
                    <h5 className="team-mempic-nm-ar">الدكتور ساغر جاجتاب</h5>
                    <h6 className="team-mempic-dec-ar">رئيس قسم المطالبات الطبية ومطالبات الحياة</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>طبيب مؤهل، وحصل على ماجستير إدارة الأعمال في إدارة الرعاية الصحية ودبلوم الدراسات العليا في خدمات الطوارئ الطبية</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Dr Sagar Jagtap ENDS HERE */}

        {/* TEAM MEMBER MODAL Saniya Mohammed Aslam AL Balushi HERE */}
        <div className="modal fade" id="SaniyaMohammedAslamALBalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/sania_al_balushi.png"} alt="سانية محمد أسلم البلوشي" />
                    <h5 className="team-mempic-nm-ar">سانية محمد أسلم البلوشي</h5>
                    <h6 className="team-mempic-dec-ar">المدير - قسم المطالبات الطبية و مطالبات الحياة</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>حاصلة على شهادة في إدارة خدمات العملاء. لديها أكثر من 7 سنوات من الخبرة في الصناعة ولديها خبرة في التعامل مع المطالبات الطبية و مطالبات الحياة</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Saniya Mohammed Aslam AL Balushi ENDS HERE */}

        {/* TEAM MEMBER MODAL Amani Moosa Sbeit Al Mukhaini HERE */}
        <div className="modal fade" id="AmaniMoosaSbeitAlMukhaini" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/amani_moosa_sbeit_al_mukhaini.png"} alt="أماني موسى سبيت المخيني" />
                    <h5 className="team-mempic-nm-ar">أماني موسى سبيت المخيني</h5>
                    <h6 className="team-mempic-dec-ar">مسؤول تنفيذي - قسم المطالبات الطبية و مطالبات الحياة</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>تتمتع  بخبرة 7 سنوات وهي جزء من فريق المطالبات الطبية</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Amani Moosa Sbeit Al Mukhaini ENDS HERE */}

        {/* TEAM MEMBER MODAL Samiya Dur Mohammed Albalushi HERE */}
        <div className="modal fade" id="SamiyaDurMohammedAlbalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/samiya_dur_mohammed_albalushi.png"} alt="ساميا دور محمد البلوشي" />
                    <h5 className="team-mempic-nm-ar">ساميا دور محمد البلوشي</h5>
                    <h6 className="team-mempic-dec-ar">تنفيذي - مطالبات السيارات</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>ساميا هي شخص نشيط وطموح ويسعدها دائمًا مساعدة العملاء في مطالبات التأمين على السيارات. إنها تتمتع  بأكثر من 14 عامًا</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Samiya Dur Mohammed Albalushi ENDS HERE */}

        {/* TEAM MEMBER MODAL SEVEN HERE */}
        <div className="modal fade" id="ModalSeven" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/sawsan_al_alawi.png"} alt="Sawsan Al alawi" />
                    <h5 className="team-mempic-nm-ar">سوسن عبد العزيز العلوي</h5>
                    <h6 className="team-mempic-dec-ar">مدير - الحسابات مستحقة القبض</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>حصلت على الدبلوم العالي في تكنولوجيا المعلومات و شهادة المحاسب الإداري المهنية المعتمدة في خدمات العملاء والشكاوى</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL SEVEN ENDS HERE */}

        {/* TEAM MEMBER MODAL Alam Syed Mahetab HERE */}
        <div className="modal fade" id="AlamSyedMahetab" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/alam_syed_mahetab.png"} alt="علام سيد ماهيتاب" />
                    <h5 className="team-mempic-nm-ar">علام سيد ماهيتاب</h5>
                    <h6 className="team-mempic-dec-ar">كبير القادة - فريق الحلول في الشركة</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>لقد حصل على دبلوم في التأمين من معهد تشارترد للتأمين في المملكة المتحدة وهو زميل في التأمين من معهد التأمين في الهند</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Alam Syed Mahetab ENDS HERE */}

        {/* TEAM MEMBER MODAL TEN HERE */}
        <div className="modal fade" id="ModalTen" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/abbas_al_sajwany.png"} alt="عباس الساجواني" />
                    <h5 className="team-mempic-nm-ar">عباس الساجواني</h5>
                    <h6 className="team-mempic-dec-ar">مدير أول -  الفريق الاستشاري للشركة</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>يتمتع بخبرة تزيد عن 20 عامًا في الصناعة وعمل في الصناعات التحويلية والتجارية المرتبطة حاليًا بصناعة التأمين

                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL TEN ENDS HERE */}
        {/* TEAM MEMBER MODAL ELEVEN HERE */}
        {/* <div className="modal fade" id="ModalEleven" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/hamad_al_hinaai.png"} alt="حمد الهنائي" />
                    <h5 className="team-mempic-nm-ar">حمد الهنائي</h5>
                    <h6 className="team-mempic-dec-ar">مدير مساعد - الفريق الاستشاري للشركة</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>يتمتع  بأكثر من 10 سنوات من الخبرة في المجال. لقد تعامل مع كل من خطوط التأمين الشخصية والتجارية</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> */}
        {/* TEAM MEMBER MODAL ELEVEN ENDS HERE */}

        {/* TEAM MEMBER MODAL Uhood Ali Haji Al Balushi HERE */}
        <div className="modal fade" id="UhoodAliHajiAlBalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/uhood_ali_haji_al_balushi.png"} alt="عهود علي حاجي البلوشي" />
                    <h5 className="team-mempic-nm-ar">عهود علي حاجي البلوشي</h5>
                    <h6 className="team-mempic-dec-ar">مدير مساعد – الحسابات</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>Uhood Ali Haji Al Balushi Content Here</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Uhood Ali Haji Al Balushi ENDS HERE */}

        {/* TEAM MEMBER MODAL Nagaraj Binage HERE */}
        <div className="modal fade" id="NagarajBinage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/nagaraj_binage.png"} alt="ناجاراج بيناج" />
                    <h5 className="team-mempic-nm-ar">ناجاراج بيناج</h5>
                    <h6 className="team-mempic-dec-ar">مدير - إدارة خدمات المخاطر و المطالبات</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>Nagaraj Binage Content Here</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Nagaraj Binage ENDS HERE */}

        {/* TEAM MEMBER MODAL Mazoon Al Zadjali HERE */}
        <div className="modal fade" id="MazoonAlZadjali" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/mazoon_al_zadjali.png"} alt="مازون آل زادجالي" />
                    <h5 className="team-mempic-nm-ar">مازون آل زادجالي</h5>
                    <h6 className="team-mempic-dec-ar">رئيس المبيعات الرقمية والتسويق</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>تتمتع  بـ 12 عامًا من الخبرة العملية وهي حاصلة على درجة إدارة الأعمال في التسويق ولديها خبرة غنية في التسويق الرقمي</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Mazoon Al Zadjali ENDS HERE */}

        {/* TEAM MEMBER MODALIman Juma Mohammed Al Ajmi HERE */}
        <div className="modal fade" id="ImanJumaMohammedAlAjmi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/iman_juma_mohammed_al_ajmi.png"} alt="إيمان جاما محمد العجمي" />
                    <h5 className="team-mempic-nm-ar">إيمان جاما محمد العجمي</h5>
                    <h6 className="team-mempic-dec-ar">مدير مساعد - المبيعات الرقمية</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>تتمتع بخبرة 11 عامًا ولديها خبرة في خطوط التأمين الفردية</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Iman Juma Mohammed Al Ajmi ENDS HERE */}

        {/* TEAM MEMBER MODAL Rasha Rashid Hamed Al Alawi HERE */}
        <div className="modal fade" id="RashaRashidHamedAlAlawi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/rasha_rashid_hamed_al_alawi.png"} alt="رشا رشيد حامد العلوي" />
                    <h5 className="team-mempic-nm-ar">رشا رشيد حامد العلوي</h5>
                    <h6 className="team-mempic-dec-ar">المسؤول  التنفيذي - المبيعات الرقمية</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>تتمتع بخبرة عمل 7 سنوات ولديها خبرة في خطوط التأمين الفردية</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Rasha Rashid Hamed Al Alawi ENDS HERE */}

        {/* TEAM MEMBER MODAL Tahiya Hamed Salim Alwadhahi STARTS HERE */}
        <div className="modal fade" id="TahiyaHamedSalimAlwadhahi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/tahiya_hamed_salim_alwadhahi.png"} alt="تحية حامد سليم الوضاحي" />
                    <h5 className="team-mempic-nm-ar">تحية حامد سليم الوضاحي</h5>
                    <h6 className="team-mempic-dec-ar">مدير مساعد – الحسابات</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>Tahiya Hamed Salim Alwadhahi Content Here</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Tahiya Hamed Salim Alwadhahi ENDS HERE */}

        {/* TEAM MEMBER MODAL Yahya Yousuf Ali Alwahaibi STARTS HERE */}
        <div className="modal fade" id="YahyaYousufAliAlwahaibi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/yahya_yousuf_ali_alwahaibi.png"} alt="Yahya Yousuf Ali Alwahaibi" />
                    <h5 className="team-mempic-nm-ar">Yahya Yousuf Ali Alwahaibi</h5>
                    <h6 className="team-mempic-dec-ar">Executive</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>Yahya Yousuf Ali Alwahaibi Content Here</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Yahya Yousuf Ali Alwahaibi ENDS HERE */}

        {/* TEAM MEMBER MODAL Safar Sabil Khudadat Al Balushi STARTS HERE */}
        <div className="modal fade" id="SafarSabilKhudadatAlBalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/safar_sabil_khudadat_al_balushi.png"} alt="Safar Sabil Khudadat Al Balushi" />
                    <h5 className="team-mempic-nm-ar">Safar Sabil Khudadat Al Balushi</h5>
                    <h6 className="team-mempic-dec-ar">Executive</h6>
                  </div>
                  <div className="team-memcon-ar">
                    <p>Safar Sabil Khudadat Al Balushi Content Here</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Safar Sabil Khudadat Al Balushi ENDS HERE */}

        {/* TEAM MEMBER MODAL THIRTEEN HERE */}
        <div className="modal fade" id="ModalThirteen" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-ar">
                  <div className="team-mempic-lg-ar">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/ghalib_al_siyabi.png"} alt="Ghalib Al Siyabi" />
                    <h5 className="team-mempic-nm-ar">غالب محمد ناصر السيابي</h5>
                    <h6 className="team-mempic-dec-ar">مدير مساعد - فريق الحلول في الشركة</h6>
                  </div>
                  <div className="team-memcon-lg-ar">
                    <p>حاصل على درجة البكالوريوس في العلاقات العامة وحصل على شهادة في إدارة الأعمال من معهد كيمجي للتدريب</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL THIRTEEN ENDS HERE */}


      </div>
    </div>
  );
}

export default AROurteamPage;