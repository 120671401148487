import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arvaluationservices.scss';

const ARValuationServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div class="valuservices-tab-imagebannermain-ar">
          <div class="valuservices-banner-con-ar">
            <div class="valuservicestab-banne-sec-ar">
              <h1 class="valuservices-banner-heading-ar">خدمات قيمة</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="valuservices-tab-btn-ar">
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="valuservices-tab-btn-item-active-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="valuservices-banner-con-ar">
          <div class="valuservices-bdy-ar">
            <div class="valuservices-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="valuservices-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services_ar.png"} alt="خدمات التقييم" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="valuservices-dlex-lft-ar">
                <h2 class="pagetitle-ar">خدمات التقييم</h2>

                <p class="valuservices-pg-txt-con-ar">نحن نقدم خدمات التقييم لدعم جهود مؤسستك لتوفير بيانات مالية دقيقة لأغراض التغطية التأمينية، بحيث تلبي أموال الاسترداد احتياجات الاسترداد بعد الحدث</p>

                <p class="valuservices-pg-txt-con-ar">من خلال خبرتنا الواسعة في الصناعة ومع المهنيين الذين يفهمون أعمال عملائنا، نقدم خدمات تقييم فعالة من حيث التكلفة والوقت لعملائنا</p>

                <p class="valuservices-pg-txt-con-ar">نحن في شركة كيمجي رامداس لخدمات التأمين، نتطلع إلى تزويدك بخدمات التقييم لدينا لتحسين تغطيتك التأمينية</p>
                
                <h3 className="valuservices-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                <div className="clearfix"></div>
                
                <div className="valuservices-pins-list-lftdksp-ar">
                  <div className="valuservices-lt-two-lftdksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="valuservices-lt-one-lftdksp-en">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="valuservices-lt-two-lftdksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="valuservices-lt-one-lftdksp-en">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="insuraudits-tab-imagebannermain-ar">
          <div className="insuraudits-con-ar">
            <div className="insurauditstab-banne-sec-ar">
              <h1 className="insuraudits-banner-heading-ar">خدمات قيمة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="valuservices-tab-btn-ar">
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="valuservices-tab-btn-item-active-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="valuservices-tab-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="insuraudits-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>خدمات التقييم</h2>
            <div className="tabs-cont-ar">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services_ar.png"} alt="خدمات التقييم" />

            <p>نحن نقدم خدمات التقييم لدعم جهود مؤسستك لتوفير بيانات مالية دقيقة لأغراض التغطية التأمينية، بحيث تلبي أموال الاسترداد احتياجات الاسترداد بعد الحدث</p>

            <p>من خلال خبرتنا الواسعة في الصناعة ومع المهنيين الذين يفهمون أعمال عملائنا، نقدم خدمات تقييم فعالة من حيث التكلفة والوقت لعملائنا</p>

            <p>نحن في شركة كيمجي رامداس لخدمات التأمين، نتطلع إلى تزويدك بخدمات التقييم لدينا لتحسين تغطيتك التأمينية.</p>
            
            

            {/* <div className="tabkyfet-ar">
              <div className="tabkeyfet-txt-ar">تحليل المجالات والأنشطة المعرضة للمخاطر في عمليات عملك.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">فحص التمديدات الكهربائية، وطفايات الحريق، والتدبير المنزلي، والتكديس، وما إلى ذلك.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">فحص مستويات الأرض وظروف التربة وقوة الهيكل.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">عمليات التفتيش لمسح الطريق للشحنات ذات الأبعاد الزائدة.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">اقتراح تحسين المخاطر/ تدابير التخفيف من الخسارة.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">جمع المعلومات المتعلقة بالخسائر الكبرى السابقة.</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>

              <div className="tabkeyfet-txt-ar">التفتيش من قبل المهندسين ذوي الخبرة</div>
              <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>              
              </div> */}
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>

            {/* ask for quotes button starts here */}
            {/* <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">Ask for Quotes</a>
            </div> */}
            {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="valuservices-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services-ar"}>خدمات فحص المخاطر </option>
            <option value={"insurance-audits-ar"}>تدقيقات التأمين</option>
            <option value={"valuation-services-ar"} selected className="active">خدمات التقييم</option>
            <option value={"thermography-testing-insurance-ar"}>اختبار التصوير الحراري</option>
            <option value={"employee-wellness-program-ar"}>برنامج صحة الموظفين</option>
          </select>
        </div>
        <div class="valuservices-mob-main-ar">
          <h1 class="valuservices-banner-heading-ar">Valuation Services</h1>
          <div class="valuservices-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services_ar.png"} alt="valuation services" />
          </div>

          <p class="valuservices-mob-txt-ar">نحن نقدم خدمات التقييم لدعم جهود مؤسستك لتوفير بيانات مالية دقيقة لأغراض التغطية التأمينية، بحيث تلبي أموال الاسترداد احتياجات الاسترداد بعد الحدث</p>

          <p class="valuservices-mob-txt-ar">من خلال خبرتنا الواسعة في الصناعة ومع المهنيين الذين يفهمون أعمال عملائنا، نقدم خدمات تقييم فعالة من حيث التكلفة والوقت لعملائنا</p>

          <p class="valuservices-mob-txt-ar">نحن في شركة كيمجي رامداس لخدمات التأمين، نتطلع إلى تزويدك بخدمات التقييم لدينا لتحسين تغطيتك التأمينية</p>
          
          <h3 className="valuservices-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div className="valuservices-mob-whykrlist-ar">
            <div className="valuservices-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="valuservices-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="valuservices-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="valuservices-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>



          </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARValuationServices;
