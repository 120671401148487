import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './travelinsurance.scss';

const TravelInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div class="travinsu-tab-imagebannermain-en">
          <div class="travinsu-banner-con-en">
            <div class="travinsutab-banne-sec-en">
              <h1 class="travinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="travinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="travinsu-banner-con-en">
          <div class="travinsu-bdy-en">
            <div class="travinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="travinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Travel Insurance</h2>
                <p class="pg-txt-con">Are you stressed out with a hectic daily routine and are looking for a relaxing vacation to kick off some steam? However, you are worried about any incidents during the trip.&nbsp; You might have planned for an international trip to Continental Europe, or the west or any other fabulous location within the GCC. However, you are concerned about any untoward incidents that could happen at the start or during the trip. It could be anything; may be a case of baggage getting lost during the outing, any trip cancellations at the last moment or even the worst - a sudden medical emergency before or during the vacation. How do you handle this situation?</p>
                <p class="pg-txt-con">KR insurance services provide various insurance solutions to cover your travel risk, matching to your needs and budget.</p>

                <h3 class="travinsu-page-sbu-title-en">Main Cover / Travel Insurance</h3>

                <p class="pg-txt-con">Stop worrying and consider KR Insurance Services for providing you with the best travel insurance coverage plan.  The travel insurance plan from KR Insurance is designed to ensure that you have an enjoyable and relaxing vacation, no matter what uncertainties happen.</p>

                <h4 class="travinsu-keyfet-en mt-3">The key features of Travel Insurance are - </h4>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="travinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/travel_insurance.png"} alt="travel insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            {/* <p class="travinsu-txt-con">Stop worrying and consider KR Insurance Services for providing you with the best travel insurance coverage plan.  The travel insurance plan from KR Insurance is designed to ensure that you have an enjoyable and relaxing vacation, no matter what uncertainties happen.</p> */}

           
            <div class="clearfix"></div>
            {/* QUESTION SECTION STARTS HERE */}
            <div class="travinsu-keyfeatures-en">
              <p class="travinsu-keyfeatures-q-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Cover You For All the Medical Emergencies</p>
              <p class="travinsu-keyfeatures-ans-en">Unfortunate events can happen anytime and the possibility of it being health related is more. Imagine being stranded with your family in a foreign land in such a situation. Make sure you take a wide coverage which takes care of your in-patient and out-patient medical expenses.</p>

              <p class="travinsu-keyfeatures-q-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Cover against loss of Checked Baggage and Loss of Passport</p>
              <p class="travinsu-keyfeatures-ans-en">Imagine the plight of a person who lands in a completely new place only to find out that his baggage has been lost or that of a person who lost his passport while exploring places. You certainly do not want to be stuck in this situation! Make sure you get a travel insurance which provides you with coverage for these things</p>

              <p class="travinsu-keyfeatures-q-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Cover You Against Personal Accident</p>

              <p class="travinsu-keyfeatures-ans-en">It is important to ensure that your travel insurance covers&nbsp;you against bodily&nbsp;injury&nbsp;or death caused due to accidents.</p>

              <p class="travinsu-keyfeatures-q-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Covers You For Trip Cancellation and Curtailment</p>
              <p class="travinsu-keyfeatures-ans-en">Imagine a situation where a family member suddenly falls ill. While your travel arrangements are made, you certainly cannot travel. Ensure that the travel insurance you opt for covers you for such last minute trip curtailment or&nbsp; cancellations</p>

              <p class="travinsu-keyfeatures-q-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Covers You Against a Burglary While You Are Away</p>
              <p class="travinsu-keyfeatures-ans-en">Home burglary happens mostly when no one is at home. Opting for a plan which covers you for a burglary at your home while you're away is a wise decision.</p>
            </div>
            {/* QUESTION SECTION ENDS HERE */}
            
            <h3 class="travinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            
            <div class="travinsu-pins-list-en">
              <div class="travinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="travinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="travinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="travinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

       {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
       <div className="tab-btn-viewmode-en">
        <div class="travinsu-tab-imagebannermain-en">
          <div class="travinsu-banner-con-en">
            <div class="travinsutab-banne-sec-en">
              <h1 class="travinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="travinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="travinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Travel Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance.png"} alt="motor insurance" />
              <p>Are you stressed out with a hectic daily routine and are looking for a relaxing vacation to kick off some steam? However, you are worried about any incidents during the trip.  You might have planned for an international trip to Continental Europe, or the west or any other fabulous location within the GCC. However, you are concerned about any untoward incidents that could happen at the start or during the trip. It could be anything; may be a case of baggage getting lost during the outing, any trip cancellations at the last moment or even the worst - a sudden medical emergency before or during the vacation. How do you handle this situation?</p>

            <p>KR insurance services provide various insurance solutions to cover your travel risk, matching to your needs and budget.</p>
            
            <h3 class="tab-whykrinsu-title-en">Main Cover / Travel Insurance</h3>

            <p>Stop worrying and consider KR Insurance Services for providing you with the best travel insurance coverage plan. The travel insurance plan from KR Insurance is designed to ensure that you have an enjoyable and relaxing vacation, no matter what uncertainties happen.</p>

            <h3 class="tab-whykrinsu-title-en">The key features of Travel Insurance are -</h3>

            <div class="tabkyfet-en">
              <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-en">Cover You For All the Medical Emergencies <br />
              Unfortunate events can happen anytime and the possibility of it being health related is more. Imagine being stranded with your family in a foreign land in such a situation. Make sure you take a wide coverage which takes care of your in-patient and out-patient medical expenses.</div>
              <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-en">
                Cover against loss of Checked Baggage and Loss of Passport<br/>
                Imagine the plight of a person who lands in a completely new place only to find out that his baggage has been lost or that of a person who lost his passport while exploring places. You certainly do not want to be stuck in this situation! Make sure you get a travel insurance which provides you with coverage for these things
              </div>
              <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-en">
                Cover You Against Personal Accident<br />
                It is important to ensure that your travel insurance covers you against bodily injury or death caused due to accidents.
              </div>
              <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-en">
                Covers You For Trip Cancellation and Curtailment<br/>
                Imagine a situation where a family member suddenly falls ill. While your travel arrangements are made, you certainly cannot travel. Ensure that the travel insurance you opt for covers you for such last minute trip curtailment or  cancellations
              </div>
              <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-en">
                Covers You Against a Burglary While You Are Away<br />
                Home burglary happens mostly when no one is at home. Opting for a plan which covers you for a burglary at your home while you're away is a wise decision.
              </div>
            </div>
            
            <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

            <div class="tab-whykrinsu-pins-list-en">
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="travinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"} selected class="active">Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="travinsu-mob-main-en">
        <h1 class="travinsu-banner-heading-en">Travel Insurance</h1>
              <div class="travinsu-mob-img-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/travel_insurance.png"} alt="travel insurance" />
              </div>
              <p class="travinsu-mob-txt-en">Are you stressed out with a hectic daily routine and are looking for a relaxing vacation to kick off some steam? However, you are worried about any incidents during the trip.&nbsp; You might have planned for an international trip to Continental Europe, or the west or any other fabulous location within the GCC. However, you are concerned about any untoward incidents that could happen at the start or during the trip. It could be anything; may be a case of baggage getting lost during the outing, any trip cancellations at the last moment or even the worst - a sudden medical emergency before or during the vacation. How do you handle this situation?</p>

              <p class="travinsu-mob-txt-en">KR insurance services provide various insurance solutions to cover your travel risk, matching to your needs and budget.</p>

              <h3 class="travinsu-subtitle-en">Main Cover / Travel Insurance</h3>

              <p class="travinsu-mob-txt-en">Stop worrying and consider KR Insurance Services for providing you with the best travel insurance coverage plan. The travel insurance plan from KR Insurance is designed to ensure that you have an enjoyable and relaxing vacation, no matter what uncertainties happen.</p>

              <h4 class="travinsu-subtitle-en">The key features of Travel Insurance are - </h4>

              {/* QUESTION SECTION STARTS HERE */}
              <div class="travinsu-mob-keyfeatures-en">
                <div class="travinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-q-en">Cover You For All the Medical Emergencies</div>
                <div class="travinsu-mob-keyfeatures-ans-en">Unfortunate events can happen anytime and the possibility of it being health related is more. Imagine being stranded with your family in a foreign land in such a situation. Make sure you take a wide coverage which takes care of your in-patient and out-patient medical expenses.</div>

                <div class="travinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-q-en">Cover against loss of Checked Baggage and Loss of Passport</div>
                <div class="travinsu-mob-keyfeatures-ans-en">Imagine the plight of a person who lands in a completely new place only to find out that his baggage has been lost or that of a person who lost his passport while exploring places. You certainly do not want to be stuck in this situation! Make sure you get a travel insurance which provides you with coverage for these things</div>

              <div class="travinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-q-en">Cover You Against Personal Accident Insurance</div>
                <div class="travinsu-mob-keyfeatures-ans-en">It is important to ensure that your travel insurance covers you against bodily injury or death caused due to accidents.</div>

              <div class="travinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-q-en">Covers You For Trip Cancellation and Curtailment</div>
                <div class="travinsu-mob-keyfeatures-ans-en">Imagine a situation where a family member suddenly falls ill. While your travel arrangements are made, you certainly cannot travel. Ensure that the travel insurance you opt for covers you for such last minute trip curtailment or cancellations</div>

              <div class="travinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-q-en">Covers You Against a Burglary While You Are Away</div>
                <div class="travinsu-mob-keyfeatures-ans-en">Home burglary happens mostly when no one is at home. Opting for a plan which covers you for a burglary at your home while you're away is a wise decision.</div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

              <h3 class="travinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

              <div class="travinsu-mob-whykrlist-en">
                <div class="travinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                <div class="travinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                <div class="travinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                <div class="travinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default TravelInsurance;