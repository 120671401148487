import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './burglaryinsurance.scss';

const BurglaryInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="burglaryinsu-tab-imagebannermain-en">
          <div class="burglaryinsu-banner-con-en">
            <div class="burglaryinsutab-banne-sec-en">
              <h1 class="burglaryinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="burglaryinsu-banner-con-en">
          <div class="burglaryinsu-bdy-en">
            <div class="burglaryinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="burglaryinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Burglary Insurance</h2>
                <p class="burglaryinsu-pg-txt-con">This policy provides cover for property contained in business premises, stocks owned by you or held in trust and/or commission. It can be further extended to cover cash, valuables, securities kept in a locked safe or cash box in locked steel cupboard.</p>

                <p class="burglaryinsu-pg-txt-con">KR Insurance Services brings wider burglary insurance terms at right price for their customers.</p>



                <h3 class="burglaryinsu-page-sbu-title-en">Main Cover / Burglary Insurance</h3>

                <p class="burglaryinsu-txt-con">The policy provides cover for the following occurrences:</p>

                <div class="burglaryinsu-keypoint-en">
                  <div class="burglaryinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="burglaryinsu-keypoint-item-en">Loss or damage to insured property due to burglary and/or housebreaking</div>
                  <div class="burglaryinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="burglaryinsu-keypoint-item-en">Damage to premises caused by burglars during burglary or attempts at burglary The policy pays actual loss / damage to the insured property caused by burglary / house breaking subject to the limit of sum insured.</div>

                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="burglaryinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/burglary_insurance.png"} alt="Burglary Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="burglaryinsu-kpnt-en">
              <div class="burglaryinsu-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-en">If the sum insured is not adequate, the policy pays only proportionate loss. There is also a provision in the policy to cover bulk items on "first loss" basis, wherein a percentage of total stock stored can be taken as that exposed to the risk of burglary and housebreaking. The premium is charged on this percentage selected only. A nominal premium is charged on the balance stock.</div>
              <div class="burglaryinsu-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-en">The policy can be extended to cover riot, strike, malicious damage and theft. Further, policies can be issued on declaration basis and on floater basis for stocks.</div>
            </div>

            <p class="burglaryinsu-txt-con"><u>Main exclusions</u></p>

            <p class="burglaryinsu-txt-con">The policy will not pay for loss or damage:</p>


            <div class="burglaryinsu-kpnt-en">
              <div class="burglaryinsu-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-en">For goods held in trust / commission, Cash, jewellery, curios, title deeds, business books (unless specifically insured)</div>
              <div class="burglaryinsu-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-en">Due to shop lifting, acts involving you or your family members / employees</div>
              <div class="burglaryinsu-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-en">Due to war perils, riot & strike (covered by payment of additional premium), natural calamities and nuclear perils</div>
              <div class="burglaryinsu-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-en">For items stolen from a safe using a key or duplicate key, unless it is obtained by violence or threat</div>
            </div>
            <h3 class="burglaryinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="burglaryinsu-pins-list-lftdksp-en">
              <div class="burglaryinsu-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="burglaryinsu-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="burglaryinsu-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="burglaryinsu-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="burglaryinsu-tab-imagebannermain-en">
          <div class="burglaryinsu-banner-con-en">
            <div class="burglaryinsutab-banne-sec-en">
              <h1 class="burglaryinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="burglaryinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Burglary Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/burglary_insurance.png"} alt="Burglary Insurance" />

              <p>This policy provides cover for property contained in business premises, stocks owned by you or held in trust and/or commission. It can be further extended to cover cash, valuables, securities kept in a locked safe or cash box in locked steel cupboard.</p>

              <p>KR Insurance Services brings wider burglary insurance terms at right price for their customers.</p>

              <h3 class="tab-sub-title-en">Main Cover / Burglary Insurance</h3>

              <p>The policy provides cover for the following occurrences:</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Loss or damage to insured property due to burglary and/or housebreaking</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Damage to premises caused by burglars during burglary or attempts at burglary The policy pays actual loss / damage to the insured property caused by burglary / house breaking subject to the limit of sum insured.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">If the sum insured is not adequate, the policy pays only proportionate loss. There is also a provision in the policy to cover bulk items on "first loss" basis, wherein a percentage of total stock stored can be taken as that exposed to the risk of burglary and housebreaking. The premium is charged on this percentage selected only. A nominal premium is charged on the balance stock.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">The policy can be extended to cover riot, strike, malicious damage and theft. Further, policies can be issued on declaration basis and on floater basis for stocks.</div>
              </div>

              <p><u>Main exclusions</u></p>
              
              <p>The policy will not pay for loss or damage:</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">For goods held in trust / commission, Cash, jewellery, curios, title deeds, business books (unless specifically insured)</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Due to shop lifting, acts involving you or your family members / employees</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Due to war perils, riot & strike (covered by payment of additional premium), natural calamities and nuclear perils</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">For items stolen from a safe using a key or duplicate key, unless it is obtained by violence or threat</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="burglaryinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"} selected class="active">Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="burglaryinsu-mob-main-en">
          <h1 class="burglaryinsu-banner-heading-en">Burglary Insurance</h1>
          <div class="burglaryinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/burglary_insurance.png"} alt="Burglary Insurance" />
          </div>

          <p class="burglaryinsu-mob-txt-en">This policy provides cover for property contained in business premises, stocks owned by you or held in trust and/or commission. It can be further extended to cover cash, valuables, securities kept in a locked safe or cash box in locked steel cupboard.</p>

          <p class="burglaryinsu-mob-txt-en">KR Insurance Services brings wider burglary insurance terms at right price for their customers.</p>

          <h3 class="burglaryinsu-subtitle-en">Main Cover / Burglary Insurance</h3>

          <p class="burglaryinsu-mob-txt-en">The policy provides cover for the following occurrences:</p>


          <div class="burglaryinsu-pins-list-mob-en">
            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">Loss or damage to insured property due to burglary and/or housebreaking</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">Damage to premises caused by burglars during burglary or attempts at burglary The policy pays actual loss / damage to the insured property caused by burglary / house breaking subject to the limit of sum insured.</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">If the sum insured is not adequate, the policy pays only proportionate loss. There is also a provision in the policy to cover bulk items on "first loss" basis, wherein a percentage of total stock stored can be taken as that exposed to the risk of burglary and housebreaking. The premium is charged on this percentage selected only. A nominal premium is charged on the balance stock.</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">The policy can be extended to cover riot, strike, malicious damage and theft. Further, policies can be issued on declaration basis and on floater basis for stocks.</div>

          </div>

          <p class="burglaryinsu-mob-txt-en"><u>Main exclusions</u></p>

          <p class="burglaryinsu-mob-txt-en">The policy will not pay for loss or damage:</p>




          <p class="burglaryinsu-mob-txt-en">Property Insurance provides a vast array of covers which includes :-</p>

          <div class="burglaryinsu-pins-list-mob-en">
            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">For goods held in trust / commission, Cash, jewellery, curios, title deeds, business books (unless specifically insured)</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">Due to shop lifting, acts involving you or your family members / employees</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">Due to war perils, riot & strike (covered by payment of additional premium), natural calamities and nuclear perils</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-en">For items stolen from a safe using a key or duplicate key, unless it is obtained by violence or threat</div>
          </div>
          <h3 class="burglaryinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="burglaryinsu-mob-whykrlist-en">
            <div class="burglaryinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="burglaryinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="burglaryinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="burglaryinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default BurglaryInsurance;