import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arhousemaidinsurance.scss';

const ARHousemaidInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="homemaidinsu-tab-imagebannermain-ar">
          <div class="homemaidinsu-banner-con-ar">
            <div class="homemaidinsutab-banne-sec-ar">
              <h1 class="homemaidinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="homemaidinsu-tab-btn-ar">
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="homemaidinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="homemaidinsu-banner-con-ar">
          <div class="homemaidinsu-bdy-ar">
            <div class="homemaidinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="homemaidinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/maid_insurance_ar.png"} alt="تأمين العمالة المنزلية" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="homemaidinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">تأمين العمالة المنزلية</h2>

                <p class="pg-txt-con-ar">لا يضمن التأمين على المنازل الامتثال للمتطلبات القانونية فحسب، بل يضمن أيضًا حصول العاملات المنزليات على العلاج الذي يطلبنه، والذي يغطي جميع احتياجات الرعاية الصحية الأساسية الخاصة إثر حادث أو مرض خطير. تقدم كيمجي رامداس لخدمات التأمين أفضل تغطية تأمينية والتي توفر الحماية للخادمات القادمات إلى سلطنة عمان للعمل في المنازل</p>
                

                <h3 class="homemaidinsu-page-sbu-title-ar">التغطية الرئيسية/ تأمين العمالة المنزلية </h3>

                <p class="pg-txt-con-ar">الملامح الرئيسية لبوليصة تأمين العمالة المنزلية / المساعدة المنزلية هي - </p>

                <div class="homemaidinsu-keyfeatures-ar">
                  <p class="homemaidinsu-lifinsu-fet-ar">تغطية الوفاة، في حالة الوفاة لأي سبب
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    </p>
                  <p class="homemaidinsu-lifinsu-fet-ar">المصروفات على الأمراض الخطيرة (اختياري) 
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    </p>
                  <p class="homemaidinsu-lifinsu-fet-ar">سداد تكلفة العودة إلى الوطن للكفيل 
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                     </p>
                  <p class="homemaidinsu-lifinsu-fet-ar">مزايا الراكب الاختيارية ضد الحوادث على النحو التالي
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    </p>
                  <p class="homemaidinsu-subtxt-ar"> أ. العجز الجزئي الدائم الناتج عن الحوادث.</p>
                  <p class="homemaidinsu-subtxt-ar"> ب. العجز الكلي الناتج عن الحوادث </p>
                  <p class="homemaidinsu-subtxt-ar"> ج. العجز الكلي المؤقت الناتج عن الحوادث </p>
                  <p class="homemaidinsu-subtxt-ar"> د. النفقات الطبية ومصاريف المستشفى إثر الحوادث </p>
                </div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            
            <h3 class="homemaidinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            
            <div class="homemaidinsu-pins-list-ar">
              <div class="homemaidinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="homemaidinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
               <div class="homemaidinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
               <div class="homemaidinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>


            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="homemaidinsu-tab-imagebannermain-ar">
          <div class="homemaidinsu-banner-con-ar">
            <div class="homemaidinsutab-banne-sec-ar">
              <h1 class="homemaidinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="homemaidinsu-tab-btn-ar">
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="homemaidinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="homemaidinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="homemaidinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تأمين العمالة المنزلية</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/maid_insurance_ar.png"} alt="تأمين العمالة المنزلية" />
              <p>لا يضمن التأمين على المنازل الامتثال للمتطلبات القانونية فحسب، بل يضمن أيضًا حصول العاملات المنزليات على العلاج الذي يطلبنه، والذي يغطي جميع احتياجات الرعاية الصحية الأساسية الخاصة إثر حادث أو مرض خطير. تقدم كيمجي رامداس لخدمات التأمين أفضل تغطية تأمينية والتي توفر الحماية للخادمات القادمات إلى سلطنة عمان للعمل في المنازل</p>
              
              <p class="tab-subhead-title-ar">التغطية الرئيسية/ تأمين العمالة المنزلية </p>

              <p>الملامح الرئيسية لبوليصة تأمين العمالة المنزلية / المساعدة المنزلية هي</p>

              <div class="tabkyfet-ar">
              <div class="tabkeyfet-txt-ar">تغطية الوفاة، في حالة الوفاة لأي سبب</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">المصروفات على الأمراض الخطيرة (اختياري)</div>                
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">سداد تكلفة العودة إلى الوطن للكفيل</div>                
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">مزايا الراكب الاختيارية ضد الحوادث على النحو التالي<br />
                  <span>أ. العجز الجزئي الدائم الناتج عن الحوادث.</span><br />
                  <span>ب. العجز الكلي الناتج عن الحوادث </span><br />
                  <span>ج. العجز الكلي المؤقت الناتج عن الحوادث </span><br />
                  <span>د. النفقات الطبية ومصاريف المستشفى إثر الحوادث </span>
                </div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>

        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="homemaidinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"} selected class="active">تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="homemaidinsu-mob-main-ar">
          <h1 class="homemaidinsu-banner-heading-ar">تأمين العمالة المنزلية</h1>
          <div class="homemaidinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/maid_insurance_ar.png"} alt="تأمين العمالة المنزلية" />
          </div>
          <p class="homemaidinsu-mob-txt-ar">لا يضمن التأمين على المنازل الامتثال للمتطلبات القانونية فحسب، بل يضمن أيضًا حصول العاملات المنزليات على العلاج الذي يطلبنه، والذي يغطي جميع احتياجات الرعاية الصحية الأساسية الخاصة إثر حادث أو مرض خطير. تقدم كيمجي رامداس لخدمات التأمين أفضل تغطية تأمينية والتي توفر الحماية للخادمات القادمات إلى سلطنة عمان للعمل في المنازل</p>

          <h3 class="homemaidinsu-subtitle-en">التغطية الرئيسية/ تأمين العمالة المنزلية </h3>

          <p class="homemaidinsu-mob-txt-ar">الملامح الرئيسية لبوليصة تأمين العمالة المنزلية / المساعدة المنزلية هي</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="homemaidinsu-mob-keyfeatures-ar">
            <div class="homemaidinsu-mob-keyfeatures-q-ar">تغطية الوفاة، في حالة الوفاة لأي سبب</div>
            <div class="homemaidinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
            <div class="homemaidinsu-mob-keyfeatures-q-ar">المصروفات على الأمراض الخطيرة (اختياري)</div>
            <div class="homemaidinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
            <div class="homemaidinsu-mob-keyfeatures-q-ar">سداد تكلفة العودة إلى الوطن للكفيل </div>
            <div class="homemaidinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
            <div class="homemaidinsu-mob-keyfeatures-q-ar">مزايا الراكب الاختيارية ضد الحوادث على النحو التالي</div>
            <div class="homemaidinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="homemaidinsu-mob-keyfeatures-ans-ar">أ. العجز الجزئي الدائم الناتج عن الحوادث</div>
            <div class="homemaidinsu-mob-keyfeatures-ans-ar">ب. العجز الكلي الناتج عن الحوادث </div>
            <div class="homemaidinsu-mob-keyfeatures-ans-ar">ج. العجز الكلي المؤقت الناتج عن الحوادث </div>
            <div class="homemaidinsu-mob-keyfeatures-ans-ar">د. النفقات الطبية ومصاريف المستشفى إثر الحوادث </div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}
          
          <h3 class="homemaidinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div class="homemaidinsu-mob-whykrlist-ar">
            <div class="homemaidinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            
            <div class="homemaidinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            
            <div class="homemaidinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            
            <div class="homemaidinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARHousemaidInsurance;