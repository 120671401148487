import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arlifeinsurance.scss';

const ARLifeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="lifeinsu-tab-imagebannermain-ar">
          <div class="lifeinsu-banner-con-ar">
            <div class="lifeinsu-tab-banne-sec-ar">
              <h1 class="lifeinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="lifeinsu-tab-btn-ar">
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="lifeinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="lifeinsu-banner-con-ar">
          <div class="lifeinsu-bdy-ar">
            <div class="lifeinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="lifeinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/life_insurance_ar.png"} alt="التأمين على الحياة " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="lifeinsu-dlex-lft-ar">
              <h2 class="pagetitle-ar">التأمين على الحياة </h2>

                <p class="pg-txt-con-ar">حماية عائلتك والعناية بهم هو الشاغل الأساسي لكل فرد. وفي بعض الأحيان، يمكن أن يصبح هذا الفكر عبئًا ثقيلًا لدرجة أنك تقضي ليالي بلا نوم تتساءل "ماذا تفعل؟" كن مطمئنًا ونم بهدوء. فشركة كيمجي رامداس لخدمات التأمين حاضرة هنا لتهدئة مخاوفك</p>

                <p class="pg-txt-con-ar">قدم برامج التأمين على الحياة بواسطة شركة كيمجي رامداس لخدمات التأمين لك ولعائلتك إحساسًا بالاستقرار والصفاء من خلال إعطائك وسادة مالية عندما تؤدي الأحداث غير المتوقعة إلى فقدان دخل أسرتك. ويعد برنامج التأمين على الحياة الماثل واحد من أكثر الطرق فعالية من حيث التكلفة والتي ستمنح الاستقلال المالي لك ولأحبائك</p>

                <p class="pg-txt-con-ar">تعتبر أقساط التأمين على الحياة اقتصادية فيما يتعلق بمبلغ التغطية الأعلى الذي يقدمه. حيث يعمل التأمين على الحياة على تأمين مستقبل عائلتك. اتخذ أهم قرار للغد، اليوم. واجعل التأمين على الحياة صديقك المفضل وقم بحماية عائلتك</p>

                <h3 class="lifeinsu-page-sbu-title-ar">التغطية التأمينية الرئيسية/ التأمين على الحياة</h3>

                <p class="pg-txt-con-ar">مزايا برنامج التأمين على الحياة </p>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="benefits-lifeinsu-features-ar">
              <p class="benefits-lifeinsu-features-item-ar">احصل على مبلغ إجمالي مقطوع في حالة الوفاة المفاجئة 
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p class="benefits-lifeinsu-features-item-ar">ودّع جميع قروضك والتزاماتك 
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p class="benefits-lifeinsu-features-item-ar">وفّر المال حتى تستمر عائلتك في العيش بكل فخر 
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p class="benefits-lifeinsu-features-item-ar">يتولى التأمين على الحياة أيضًا رعاية الأسرة في حالة إعاقتك أو مرضك الخطير
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p class="benefits-lifeinsu-features-item-ar"> يوفر دخلاً تكميليًا في حالة فقدان الدخل بسبب إعاقة عرضية أو مرض 
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p class="benefits-lifeinsu-features-item-ar">احصل على مبلغ إجمالي مقطوع إذا تم تشخيصك بمرض خطير 
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p class="benefits-lifeinsu-features-item-ar">مبلغ التأمين الإضافي في حالة الوفاة نتيجة حادث 
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
            </div>
            
            <h3 class="whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>
              
              <div class="lifeinsu-pins-list-ar">
                <div class="lifeinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="lifeinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="lifeinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="lifeinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
              </div>
            {/* ask for quotes button starts here */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="lifeinsu-tab-imagebannermain-ar">
          <div class="lifeinsu-banner-con-ar">
            <div class="lifeinsu-tab-banne-sec-ar">
              <h1 class="lifeinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="lifeinsu-tab-btn-ar">
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="lifeinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="lifeinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="lifeinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على الحياة </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/life_insurance_ar.png"} alt="التأمين على الحياة " />

              <p>حماية عائلتك والعناية بهم هو الشاغل الأساسي لكل فرد. وفي بعض الأحيان، يمكن أن يصبح هذا الفكر عبئًا ثقيلًا لدرجة أنك تقضي ليالي بلا نوم تتساءل "ماذا تفعل؟" كن مطمئنًا ونم بهدوء. فشركة كيمجي رامداس لخدمات التأمين حاضرة هنا لتهدئة مخاوفك</p>

              <p>قدم برامج التأمين على الحياة بواسطة شركة كيمجي رامداس لخدمات التأمين لك ولعائلتك إحساسًا بالاستقرار والصفاء من خلال إعطائك وسادة مالية عندما تؤدي الأحداث غير المتوقعة إلى فقدان دخل أسرتك. ويعد برنامج التأمين على الحياة الماثل واحد من أكثر الطرق فعالية من حيث التكلفة والتي ستمنح الاستقلال المالي لك ولأحبائك</p>

              <p>تعتبر أقساط التأمين على الحياة اقتصادية فيما يتعلق بمبلغ التغطية الأعلى الذي يقدمه. حيث يعمل التأمين على الحياة على تأمين مستقبل عائلتك. اتخذ أهم قرار للغد، اليوم. واجعل التأمين على الحياة صديقك المفضل وقم بحماية عائلتك</p>

              <h3 class="tab-sub-title-ar">التغطية التأمينية الرئيسية/ التأمين على الحياة</h3>
              
              <p>مزايا برنامج التأمين على الحياة </p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">احصل على مبلغ إجمالي مقطوع في حالة الوفاة المفاجئة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">ودّع جميع قروضك والتزاماتك</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">وفّر المال حتى تستمر عائلتك في العيش بكل فخر</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">يتولى التأمين على الحياة أيضًا رعاية الأسرة في حالة إعاقتك أو مرضك الخطير</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar"> يوفر دخلاً تكميليًا في حالة فقدان الدخل بسبب إعاقة عرضية أو مرض</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">احصل على مبلغ إجمالي مقطوع إذا تم تشخيصك بمرض خطير</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">مبلغ التأمين الإضافي في حالة الوفاة نتيجة حادث</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="lifeinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"life-insurance-ar"} selected class="active">التأمين على الحياة</option>
          </select>
        </div>
        <div class="lifeinsu-mob-main-ar">
          <h1 class="lifeinsu-mob-main-title-ar">التأمين على الحياة </h1>
          <div class="lifeinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/life_insurance_ar.png"} alt="التأمين على الحياة " />
          </div>
          <p class="lifeinsu-mob-txt-ar">حماية عائلتك والعناية بهم هو الشاغل الأساسي لكل فرد. وفي بعض الأحيان، يمكن أن يصبح هذا الفكر عبئًا ثقيلًا لدرجة أنك تقضي ليالي بلا نوم تتساءل "ماذا تفعل؟" كن مطمئنًا ونم بهدوء. فشركة كيمجي رامداس لخدمات التأمين حاضرة هنا لتهدئة مخاوفك</p>

          <p class="lifeinsu-mob-txt-ar">قدم برامج التأمين على الحياة بواسطة شركة كيمجي رامداس لخدمات التأمين لك ولعائلتك إحساسًا بالاستقرار والصفاء من خلال إعطائك وسادة مالية عندما تؤدي الأحداث غير المتوقعة إلى فقدان دخل أسرتك. ويعد برنامج التأمين على الحياة الماثل واحد من أكثر الطرق فعالية من حيث التكلفة والتي ستمنح الاستقلال المالي لك ولأحبائك</p>

          <p class="lifeinsu-mob-txt-ar">تعتبر أقساط التأمين على الحياة اقتصادية فيما يتعلق بمبلغ التغطية الأعلى الذي يقدمه. حيث يعمل التأمين على الحياة على تأمين مستقبل عائلتك. اتخذ أهم قرار للغد، اليوم. واجعل التأمين على الحياة صديقك المفضل وقم بحماية عائلتك</p>
          
          <h3 class="lifeinsu-subtitle-en">التغطية التأمينية الرئيسية/ التأمين على الحياة</h3>

          <p class="lifeinsu-mob-txt-ar">مزايا برنامج التأمين على الحياة </p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="lifeinsu-mob-keyfeatures-ar">
            <div class="lifeinsu-mob-keyfeatures-q-ar">احصل على مبلغ إجمالي مقطوع في حالة الوفاة المفاجئة</div>

            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-ar">ودّع جميع قروضك والتزاماتك</div>

            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-ar">وفّر المال حتى تستمر عائلتك في العيش بكل فخر</div>

            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-ar">يتولى التأمين على الحياة أيضًا رعاية الأسرة في حالة إعاقتك أو مرضك الخطير.</div>

            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-ar"> يوفر دخلاً تكميليًا في حالة فقدان الدخل بسبب إعاقة عرضية أو مرض</div>

            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-ar">احصل على مبلغ إجمالي مقطوع إذا تم تشخيصك بمرض خطير</div>

            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-ar">مبلغ التأمين الإضافي في حالة الوفاة نتيجة حادث</div>
            <div class="lifeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
          </div>
          {/* QUESTION SECTION ENDS HERE */}
          
          <h3 class="lifeinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div class="lifeinsu-mob-whykrlist-ar">
            <div class="lifeinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="lifeinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="lifeinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="lifeinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARLifeInsurance;