import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './healthinsurance.scss';

const HealthInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div class="healthinsu-tab-imagebannermain-en">
          <div class="healthinsu-banner-con-en">
            <div class="healthinsutab-banne-sec-en">
              <h1 class="healthinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="healthinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="healthinsu-banner-con-en">
          <div class="healthinsu-bdy-en">
            <div class="healthinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="healthinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Health Insurance</h2>
                <p class="healthinsu-pg-txt-con">We all know that health is wealth and spending a nominal amount of money to ensure your health is the best investment you can ever make. You might be concerned about your well-being, or you would naturally want to secure the health of your near and dear ones. The Health Insurance solutions by KR Insurance Services are best suited to your requirements, which you can rely on.</p>
                <p class="healthinsu-pg-txt-con">We offer best health insurance coverage plans for you and your family.</p>

                <h3 class="healthinsu-page-sbu-title-en">Main Cover / Health Insurance</h3>

                <p class="healthinsu-pg-txt-con">The Health Insurance services as offered by KR Insurance includes :-</p>

                <div class="healthinsu-keyfeatures-en">
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Cashless treatment facility during hospitalization.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">In-patient treatment coverage in private hospitals.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Consultation, diagnostics and treatment covering Out-patient facilities.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Coverage for pre/post hospitalization.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Coverage of any declared pre-existing conditions and also pre-existing policy from the first day of the policy.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Coverage of Drugs costs depending on the condition; whether pre-existing or non-pre-existing.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Ambulance charges.</div>
                  <div class="healthinsu-keyfeatures-lft-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="healthinsu-keyfeatures-rgt-en">Worldwide emergency medical assistance.</div>
                </div>      
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="healthinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance.png"} alt="health insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <h3 class="healthinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            {/* <div class="clearfix"></div> */}
            <div class="healthinsu-pins-list-en">
              <div class="healthinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="healthinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="healthinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="healthinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="healthinsu-tab-imagebannermain-en">
          <div class="healthinsu-banner-con-en">
            <div class="healthinsutab-banne-sec-en">
              <h1 class="healthinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="healthinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="healthinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Health Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance.png"} alt="health insurance" />

              <p>We all know that health is wealth and spending a nominal amount of money to ensure your health is the best investment you can ever make. You might be concerned about your well-being, or you would naturally want to secure the health of your near and dear ones. The Health Insurance solutions by KR Insurance Services are best suited to your requirements, which you can rely on.</p>
                <p>We offer best health insurance coverage plans for you and your family.</p>

                <h3 class="motorinsu-page-sbu-title-en">Main Cover / Health Insurance</h3>

                <p>The Health Insurance services as offered by KR Insurance includes :-</p>
                <div class="tabkyfet-en">
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Cashless treatment facility during hospitalization.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">In-patient treatment coverage in private hospitals.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Consultation, diagnostics and treatment covering Out-patient facilities.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Coverage for pre/post hospitalization.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Coverage of any declared pre-existing conditions and also pre-existing policy from the first day of the policy.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Coverage of Drugs costs depending on the condition; whether pre-existing or non-pre-existing.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Ambulance charges.</div>
                  <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="tabkeyfet-txt-en">Worldwide emergency medical assistance.</div>
                </div>
                <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

            <div class="tab-whykrinsu-pins-list-en">
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="healthinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"} selected class="active">Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="healthinsu-mob-main-en">
          <h1 class="healthinsu-banner-heading-en">Health Insurance</h1>
          <div class="healthinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance.png"} alt="health insurance" />
          </div>
          <p class="healthinsu-mob-txt-en">We all know that health is wealth and spending a nominal amount of money to ensure your health is the best investment you can ever make. You might be concerned about your well-being, or you would naturally want to secure the health of your near and dear ones. The Health Insurance solutions by KR Insurance Services are best suited to your requirements, which you can rely on.</p>

          <p class="healthinsu-mob-txt-en">We offer best health insurance coverage plans for you and your family.</p>

          <h3 class="healthinsu-subtitle-en">Main Cover / Health Insurance</h3>

          <p class="healthinsu-mob-txt-en">The Health Insurance services as offered by KR Insurance includes :-</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="healthinsu-mob-keyfeatures-en">
            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Cashless treatment facility during hospitalization.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">In-patient treatment coverage in private hospitals.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Consultation, diagnostics and treatment covering Out-patient facilities.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Coverage for pre/post hospitalization.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Coverage of any declared pre-existing conditions and also pre-existing policy from the first day of the policy.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Coverage of Drugs costs depending on the condition; whether pre-existing or non-pre-existing.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Ambulance charges.</div>

            <div class="healthinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="healthinsu-mob-keyfeatures-q-en">Worldwide emergency medical assistance.</div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 class="healthinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="healthinsu-mob-whykrlist-en">
            <div class="healthinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="healthinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="healthinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="healthinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HealthInsurance;