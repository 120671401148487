import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './specialityriskeventinsurance.scss';

const SpecialityRiskEventInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <HeaderEN />
    {/* DESK TOP AND TABS SECTION STARTS HERE */}
    <div class="dsk-tab-btn-viewmode-en">
      <div class="splriskevtinsu-tab-imagebannermain-en">
        <div class="splriskevtinsu-banner-con-en">
          <div class="splriskevtinsutab-banne-sec-en">
            <h1 class="splriskevtinsu-banner-heading-en">Business Insurance</h1>
            {/* TAB BUTTON SECTION STARTS HERE */}
            <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
              <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
            </div>
            {/* TAB BUTTON SECTION ENDS HERE */}
          </div>
        </div>
      </div>
      <div class="splriskevtinsu-banner-con-en">
        <div class="splriskevtinsu-bdy-en">
          <div class="splriskevtinsu-dflex-en">
            {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
            <div class="splriskevtinsu-dlex-lft-en">
              <h2 class="pagetitle-en">Speciality Risk / Event Insurance</h2>

              <p class="pg-txt-con">If you're planning an event, whether it’s a global trade conference or village fete, we can help you in protecting from the unexpected.</p>

              <p class="pg-txt-con">KR Insurance Services provides specialist insurance for all types of events and the risks that come with them. Whether that's a venue closure, property damages or an injured guest, we offers the cover to put your mind at rest, so you can focus on planning your event.</p>
                            
              <h3 class="splriskevtinsu-page-sbu-title-en">Main Cover / Speciality Risk / Event Insurance</h3>
              <p class="splriskevtinsu-txt-con">Event Insurance is designed to protect the policyholder against lawsuits, claims made related to bodily injury of others, and property damage to the rented premise.</p>

              <p class="splriskevtinsu-txt-con">The events covered under event insurance cover may be - Conferences, exhibitions, weddings, Seminar, sports tournament or any personal event, organized at private or public place. The events may be for shorter durations (Hours) to longer durations (days).</p>
              
            </div>
            {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
            {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
            <div class="splriskevtinsu-dlex-rgt-en">
              <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/specialty_risk_event_insurance.png"} alt="specialty risk event insurance" />
            </div>
            {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
          </div>
          
          

          <p class="splriskevtinsu-txt-con">The important covers are as under -</p>

          <p class="splriskevtinsu-txt-con"><strong>Cancellation</strong><br />Covers the irrecoverable expenses made or due following the cancellation, interruption or postponement of the event for any cause that is not under the control of the Policyholder.</p>

          <p class="splriskevtinsu-txt-con"><strong>Equipment</strong><br /> Covers equipment for sound, projection, lighting, filming, sound recording, broadcasting and reproduction, in case of accidental damage or destruction, burglary, theft, or short circuits, over-voltage, or inductions.</p>

          <p class="splriskevtinsu-txt-con"><strong>Non-Appearance</strong><br /> Covers the expenses incurred following a postponement, cancellation, or abandonment of an insured event resulting from the death, accidental wounding, illness or detention against their will of a person named during the period of cover, and of their relatives up to 1st degree.</p>

          <p class="splriskevtinsu-txt-con"><strong>Public Liability</strong><br /> Covers financial losses incurred in the event of accidental bodily injury to any person; damage to property, obstruction, trespass, nuisance or interference with any right of way by air, light, or water; or wrongful arrest, detention, false imprisonment, or malicious prosecution occurring within territorial limits.</p>

          <p class="splriskevtinsu-txt-con"><strong>Additional Costs</strong><br /> Covers the additional costs incurred to continue the production after a loss, damage, burglary, theft, non-delivery of goods, damage to location, etc.</p>

          <p class="splriskevtinsu-txt-con"><strong>Entrusted Property</strong><br /> Covers the amount that the policyholder could be held legally liable for, for the loss, damage, theft or destruction of the property of a third party during the policy cover.</p>

          <p class="splriskevtinsu-txt-con"><strong>3rd Party Property Liability</strong><br /> Covers damage to the property of third parties that are occupied during the event, such as fire, electrical and/or water damages, and glass breakage, except damage to vehicles, or buildings and natural sets.</p>

          <p class="splriskevtinsu-txt-con"><strong>Inclement Weather</strong><br /> Covers the net loss suffered by the insured because of the cancellation or total postponement of the event following extreme weather under specified circumstances.</p>

          <p class="splriskevtinsu-txt-con"><strong>Personal Accident Insurance</strong><br /> Covers staff and crew in case of death, permanent disablement, temporary disablement, and medical fees.</p>

          <h3 class="splriskevtinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
          <div class="clearfix"></div>
          
          <div class="splriskevtinsu-pins-list-dksp-en">
            <div class="splriskevtinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="splriskevtinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="splriskevtinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="splriskevtinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
    </div>
    {/* DESK TOP AND TABS SECTION ENDS HERE */}

    {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
    <div className="tab-btn-viewmode-en">
      <div class="splriskevtinsu-tab-imagebannermain-en">
        <div class="splriskevtinsu-banner-con-en">
          <div class="splriskevtinsutab-banne-sec-en">
            <h1 class="splriskevtinsu-banner-heading-en">Business Insurance</h1>
            {/* TAB BUTTON SECTION STARTS HERE */}
            <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
              <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
            </div>
            {/* TAB BUTTON SECTION ENDS HERE */}
          </div>
        </div>
      </div>
      <div class="splriskevtinsu-banner-con-en">
        <div className="tab-imgtxt-en">
          <h2>Speciality Risk / Event Insurance</h2>
          <div className="tabs-cont-en">
            <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/specialty_risk_event_insurance.png"} alt="specialty risk event insurance" />
            
            <p>If you're planning an event, whether it's a global trade conference or village fete, we can help you in protecting from the unexpected.</p>
            
            <p>KR Insurance Services provides specialist insurance for all types of events and the risks that come with them. Whether that's a venue closure, property damages or an injured guest, we offers the cover to put your mind at rest, so you can focus on planning your event.</p>
            
            <p className="tab-subhead-title-en">Main Cover / Speciality Risk / Event Insurance</p>
            
            <p>Event Insurance is designed to protect the policyholder against lawsuits, claims made related to bodily injury of others, and property damage to the rented premise.</p>
            
            <p>The events covered under event insurance cover may be - Conferences, exhibitions, weddings, Seminar, sports tournament or any personal event, organized at private or public place. The events may be for shorter durations (Hours) to longer durations (days).</p>
            
            <p>The important covers are as under -</p>
            
            <p><strong>Cancellation</strong><br />Covers the irrecoverable expenses made or due following the cancellation, interruption or postponement of the event for any cause that is not under the control of the Policyholder.</p>
            
            <p><strong>Equipment</strong><br /> Covers equipment for sound, projection, lighting, filming, sound recording, broadcasting and reproduction, in case of accidental damage or destruction, burglary, theft, or short circuits, over-voltage, or inductions.</p>
            
            <p><strong>Non-Appearance</strong><br /> Covers the expenses incurred following a postponement, cancellation, or abandonment of an insured event resulting from the death, accidental wounding, illness or detention against their will of a person named during the period of cover, and of their relatives up to 1st degree.</p>
            
            <p><strong>Public Liability</strong><br /> Covers financial losses incurred in the event of accidental bodily injury to any person; damage to property, obstruction, trespass, nuisance or interference with any right of way by air, light, or water; or wrongful arrest, detention, false imprisonment, or malicious prosecution occurring within territorial limits.</p>
            
            <p><strong>Additional Costs</strong><br /> Covers the additional costs incurred to continue the production after a loss, damage, burglary, theft, non-delivery of goods, damage to location, etc.</p>
            
            <p><strong>Entrusted Property</strong><br /> Covers the amount that the policyholder could be held legally liable for, for the loss, damage, theft or destruction of the property of a third party during the policy cover.</p>
            
            <p><strong>3rd Party Property Liability</strong><br /> Covers damage to the property of third parties that are occupied during the event, such as fire, electrical and/or water damages, and glass breakage, except damage to vehicles, or buildings and natural sets.</p>
            
            <p><strong>Inclement Weather</strong><br /> Covers the net loss suffered by the insured because of the cancellation or total postponement of the event following extreme weather under specified circumstances.</p>
            
            <p><strong>Personal Accident Insurance</strong><br /> Covers staff and crew in case of death, permanent disablement, temporary disablement, and medical fees.</p>

            <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            
            <div class="tab-whykrinsu-pins-list-en">
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}

          </div>
        </div>
      </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

    {/* MOBILE PAGE STARTS HERE */}
    <div class="splriskevtinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
      <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"} selected class="active">Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
        </select>
      </div>
      <div class="splriskevtinsu-mob-main-en">
        <h1 class="splriskevtinsu-banner-heading-en">Speciality Risk / Event Insurance</h1>
        <div class="splriskevtinsu-mob-img-en">
          <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/specialty_risk_event_insurance.png"} alt="specialty risk event insurance" />
        </div>

        <p class="splriskevtinsu-mob-txt-en">If you're planning an event, whether it’s a global trade conference or village fete, we can help you in protecting from the unexpected.</p>
              
              <p class="splriskevtinsu-mob-txt-en">KR Insurance Services provides specialist insurance for all types of events and the risks that come with them. Whether that's a venue closure, property damages or an injured guest, we offers the cover to put your mind at rest, so you can focus on planning your event.</p>
              
               <h3 class="splriskevtinsu-subtitle-en">Main Cover / Speciality Risk / Event Insurance</h3>


        <p class="splriskevtinsu-mob-txt-en">Event Insurance is designed to protect the policyholder against lawsuits, claims made related to bodily injury of others, and property damage to the rented premise.</p>

        <p class="splriskevtinsu-mob-txt-en">The events covered under event insurance cover may be - Conferences, exhibitions, weddings, Seminar, sports tournament or any personal event, organized at private or public place. The events may be for shorter durations (Hours) to longer durations (days).</p>

        <p class="splriskevtinsu-mob-txt-en">The important covers are as under -</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Cancellation</strong><br />Covers the irrecoverable expenses made or due following the cancellation, interruption or postponement of the event for any cause that is not under the control of the Policyholder.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Equipment</strong><br /> Covers equipment for sound, projection, lighting, filming, sound recording, broadcasting and reproduction, in case of accidental damage or destruction, burglary, theft, or short circuits, over-voltage, or inductions.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Non-Appearance</strong><br /> Covers the expenses incurred following a postponement, cancellation, or abandonment of an insured event resulting from the death, accidental wounding, illness or detention against their will of a person named during the period of cover, and of their relatives up to 1st degree.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Public Liability</strong><br /> Covers financial losses incurred in the event of accidental bodily injury to any person; damage to property, obstruction, trespass, nuisance or interference with any right of way by air, light, or water; or wrongful arrest, detention, false imprisonment, or malicious prosecution occurring within territorial limits.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Additional Costs</strong><br /> Covers the additional costs incurred to continue the production after a loss, damage, burglary, theft, non-delivery of goods, damage to location, etc.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Entrusted Property</strong><br /> Covers the amount that the policyholder could be held legally liable for, for the loss, damage, theft or destruction of the property of a third party during the policy cover.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>3rd Party Property Liability</strong><br /> Covers damage to the property of third parties that are occupied during the event, such as fire, electrical and/or water damages, and glass breakage, except damage to vehicles, or buildings and natural sets.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Inclement Weather</strong><br /> Covers the net loss suffered by the insured because of the cancellation or total postponement of the event following extreme weather under specified circumstances.</p>

        <p class="splriskevtinsu-mob-txt-en"><strong>Personal Accident Insurance</strong><br /> Covers staff and crew in case of death, permanent disablement, temporary disablement, and medical fees.</p>
        
        <h3 class="splriskevtinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

        <div class="splriskevtinsu-mob-whykrlist-en">
          <div class="splriskevtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
          <div class="splriskevtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
          <div class="splriskevtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
          <div class="splriskevtinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
        </div>
        {/* ask for quotes button starts here */}
        <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
      </div>
    </div>
    {/* MOBILE PAGE ENDS HERE */}

    {/* FOOTER SECTION STARTS HERE */}
    <FooterEN />
    {/* FOOTER SECTION ENDS HERE */}
  </div>
  );
}

export default SpecialityRiskEventInsurance;