import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arhealthinsurance.scss';

const ARHealthInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="healthinsu-tab-imagebannermain-ar">
          <div className="healthinsu-banner-con-ar">
            <div className="healthinsutab-banne-sec-ar">
              <h1 className="healthinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="healthinsu-tab-btn-ar">
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="healthinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="healthinsu-banner-con-ar">
          <div className="healthinsu-bdy-ar">
            <div className="healthinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="healthinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance_ar.png"} alt="التأمين الصحي" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="healthinsu-dlex-lft-ar">
                <h2 className="pagetitle-ar">التأمين الصحي</h2>
                <p className="healthinsu-pg-txt-con-ar">نعلم جميعًا أن الصحة هي ثروة وإنفاق مبلغ رمزي من المال لضمان صحتك هو أفضل استثمار يمكنك القيام به على الإطلاق. قد تكون قلقًا بشأن صحتك، أو قد ترغب بطبيعة الحال في تأمين صحة أقاربك وأحبائك. فإن برامج التأمين الصحي المقدمة من شركة كيمجي رامداس لخدمات التأمين هي الأنسب لمتطلباتك، والتي يمكنك الاعتماد عليها.</p>
                <p className="healthinsu-pg-txt-con-ar">نحن نقدم أفضل برامج تغطية للتأمين الصحي لك ولعائلتك</p>

                <h3 className="healthinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين الصحي</h3>

                <p className="healthinsu-pg-txt-con-ar">وتشمل خدمات التأمين الصحي التي تقدمها شركة كيمجي رامداس لخدمات التأمين ما يلي</p>

                <div className="healthinsu-keyfeatures-ar">
                  <div className="healthinsu-keyfeatures-rgt-ar">مرفق تلقي العلاج المجاني بالمستشفى</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">تغطية تكاليف علاج المريض في المستشفيات الخاصة</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">الاستشارة والتشخيص والعلاج التي تغطي مرافق العيادات الخارجية</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">تغطية التكاليف قبل/بعد العلاج</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">تغطية أي شروط موجودة مسبقًا ومعلن عنها وكذلك بوليصة التأمين الموجودة مسبقًا من اليوم الأول للبوليصة</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">تغطية تكلفة الأدوية حسب الحالة، سواء كانت متوفر مسبقًا أو غير متوفرة</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">أجرة الإسعاف</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="healthinsu-keyfeatures-rgt-ar">المساعدة الطبية الطارئة في جميع أنحاء العالم</div>
                  <div className="healthinsu-keyfeatures-lft-ar">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>      
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 className="healthinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

              <div className="healthinsu-pins-list-ar">
              <div className="healthinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="healthinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="healthinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="healthinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="healthinsu-tab-imagebannermain-ar">
          <div className="healthinsu-banner-con-ar">
            <div className="healthinsutab-banne-sec-ar">
              <h1 className="healthinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="healthinsu-tab-btn-ar">
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="healthinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="healthinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="healthinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين الصحي</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance_ar.png"} alt="التأمين الصحي" />

              <p>نعلم جميعًا أن الصحة هي ثروة وإنفاق مبلغ رمزي من المال لضمان صحتك هو أفضل استثمار يمكنك القيام به على الإطلاق. قد تكون قلقًا بشأن صحتك، أو قد ترغب بطبيعة الحال في تأمين صحة أقاربك وأحبائك. فإن برامج التأمين الصحي المقدمة من شركة كيمجي رامداس لخدمات التأمين هي الأنسب لمتطلباتك، والتي يمكنك الاعتماد عليها.</p>
                <p>نحن نقدم أفضل برامج تغطية للتأمين الصحي لك ولعائلتك</p>

                <h3 className="motorinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين الصحي</h3>

                <p>وتشمل خدمات التأمين الصحي التي تقدمها شركة كيمجي رامداس لخدمات التأمين ما يلي</p>
                <div className="tabkyfet-ar">
                  <div className="tabkeyfet-txt-ar">مرفق تلقي العلاج المجاني بالمستشفى</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">تغطية تكاليف علاج المريض في المستشفيات الخاصة</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">الاستشارة والتشخيص والعلاج التي تغطي مرافق العيادات الخارجية</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">تغطية التكاليف قبل/بعد العلاج</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">تغطية أي شروط موجودة مسبقًا ومعلن عنها وكذلك بوليصة التأمين الموجودة مسبقًا من اليوم الأول للبوليصة</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">تغطية تكلفة الأدوية حسب الحالة، سواء كانت متوفر مسبقًا أو غير متوفرة</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">أجرة الإسعاف</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-ar">المساعدة الطبية الطارئة في جميع أنحاء العالم</div>
                  <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                </div>

            <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
            <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="healthinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"} selected className="active">التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div className="healthinsu-mob-main-ar">
          <h1 className="healthinsu-banner-heading-ar">التأمين الصحي</h1>
          <div className="healthinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance_ar.png"} alt="التأمين الصحي" />
          </div>
          <p className="healthinsu-mob-txt-ar">نعلم جميعًا أن الصحة هي ثروة وإنفاق مبلغ رمزي من المال لضمان صحتك هو أفضل استثمار يمكنك القيام به على الإطلاق. قد تكون قلقًا بشأن صحتك، أو قد ترغب بطبيعة الحال في تأمين صحة أقاربك وأحبائك. فإن برامج التأمين الصحي المقدمة من شركة كيمجي رامداس لخدمات التأمين هي الأنسب لمتطلباتك، والتي يمكنك الاعتماد عليها</p>

          <p className="healthinsu-mob-txt-ar">نحن نقدم أفضل برامج تغطية للتأمين الصحي لك ولعائلتك</p>

          <h3 className="healthinsu-subtitle-ar">التغطية الرئيسية / التأمين الصحي</h3>

          <p className="healthinsu-mob-txt-ar">وتشمل خدمات التأمين الصحي التي تقدمها شركة كيمجي رامداس لخدمات التأمين ما يلي</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="healthinsu-mob-keyfeatures-ar">
            <div className="healthinsu-mob-keyfeatures-q-ar">مرفق تلقي العلاج المجاني بالمستشفى</div>

            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">تغطية تكاليف علاج المريض في المستشفيات الخاصة</div>

            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">الاستشارة والتشخيص والعلاج التي تغطي مرافق العيادات الخارجية</div>

            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">تغطية التكاليف قبل/بعد العلاج</div>
            تغطية التكاليف قبل/بعد العلاج.
            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">تغطية أي شروط موجودة مسبقًا ومعلن عنها وكذلك بوليصة التأمين الموجودة مسبقًا من اليوم الأول للبوليصة</div>

            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">تغطية تكلفة الأدوية حسب الحالة، سواء كانت متوفر مسبقًا أو غير متوفرة</div>

            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">أجرة الإسعاف</div>

            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="healthinsu-mob-keyfeatures-q-ar">المساعدة الطبية الطارئة في جميع أنحاء العالم</div>
            <div className="healthinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}
          
          <h3 className="healthinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div className="healthinsu-mob-whykrlist-ar">
                <div className="healthinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="healthinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="healthinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="healthinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARHealthInsurance;