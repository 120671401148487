import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './grouppersonalaccidentinsurance.scss';

const GroupPersonalAccidentInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="grpacc-covinsu-tab-imagebannermain-en">
          <div class="grpacc-covinsu-banner-con-en">
            <div class="grpacc-covinsutab-banne-sec-en">
              <h1 class="grpacc-covinsu-banner-heading-en">Business Insurance</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="grpacc-covinsu-banner-con-en">
          <div class="grpacc-covinsu-bdy-en">
            <div class="grpacc-covinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="grpacc-covinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Group Personal Accident Insurance</h2>
                <p class="pg-txt-con">Group Personal Accident insurance covers all accidental bodily injuries, death or any disablement of the people covered by the policy. This cover is ideal for companies to cover their employee’s medical expenses due to accidental injuries.</p>

                <p class="pg-txt-con">Get your employees covered against accidental death and disability with Group Personal accident insurance cover. Purchase best plan at KR Insurance Services and save your medical expenses.</p>

                <p class="pg-txt-con"><strong>KR Insurance Services provides prompt and best value added services to corporates, the key features are</strong></p>

                <div class="grpacc-covinsu-keypoint-en">
                  <div class="grpacc-covinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="grpacc-covinsu-keypoint-item-en">Single window solutions through dedicated relationship manager.</div>

                  <div class="grpacc-covinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="grpacc-covinsu-keypoint-item-en">Arranging wider terms, tailor made policies at best discounted pricing from the insurers.</div>

                  <div class="grpacc-covinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="grpacc-covinsu-keypoint-item-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>

                </div>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="grpacc-covinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_personal_accident_insurance.png"} alt="Group Personal Accident Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="grpacc-covinsu-kpt-en">
              <div class="grpacc-covinsu-kpt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-en">Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>

              <div class="grpacc-covinsu-kpt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-en">Renewal reminder services – auto generated renewal reminders.</div>

              <div class="grpacc-covinsu-kpt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-en">Insurance audit with NIL cost implications of your existing insurance policies.</div>

              <div class="grpacc-covinsu-kpt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-en">Personalized claim assistance through our dedicated and experienced team.</div>

              <div class="grpacc-covinsu-kpt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-en">Analysis of previous claim data to suggest improvement on loss ratios.</div>

              <div class="grpacc-covinsu-kpt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-en">Tailor made policy options matching to the different requirements of the customers.</div>

            </div>


            <h3 class="grpacc-covinsu-page-sbu-title-en">Main Cover / Group Personal Accident Insurance</h3>

            <p class="grpacc-covinsu-txt-con">Enjoy the following advantages under this insurance policy -</p>

            <div class="grpacc-covinsu-dsk-kf-en">
              <div class="grpacc-covinsu-dsk-kf-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-en">Accidental death: The benefits under this plan is payable if demise occurs from a mishap.</div>

              <div class="grpacc-covinsu-dsk-kf-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-en">Accidental disability: It indicates that the policyholder is disabled either partially or wholly.</div>

              <div class="grpacc-covinsu-dsk-kf-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-en">Accidental dismemberment: It indicates that a part of the policyholder's body has been severed or dismembered. It means, if the policyholder loses his hand or leg or eyes in a mishap, then he would be eligible to get a claim under dismemberment.</div>

              <div class="grpacc-covinsu-dsk-kf-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-en">Terrorism act: Injuries afflicted because of terrorist acts are also included.</div>

              <div class="grpacc-covinsu-dsk-kf-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-en">Daily payment during hospitalization: It gives monetary relief to a patient while he undergoes cure at the hospital.</div>
            </div>
            <h3 class="grpacc-covinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="grpacc-covinsu-pins-list-dksp-en">
              <div class="grpacc-covinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="grpacc-covinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="grpacc-covinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="grpacc-covinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="grpacc-covinsu-tab-imagebannermain-en">
          <div class="grpacc-covinsu-banner-con-en">
            <div class="grpacc-covinsutab-banne-sec-en">
              <h1 class="grpacc-covinsu-banner-heading-en">Business Insurance</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="grpacc-covinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Group Personal Accident Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_personal_accident_insurance.png"} alt="Group Personal Accident Insurance" />

              <p>Group Personal Accident insurance covers all accidental bodily injuries, death or any disablement of the people covered by the policy. This cover is ideal for companies to cover their employee’s medical expenses due to accidental injuries.</p>
              
              <p>Get your employees covered against accidental death and disability with Group Personal accident insurance cover. Purchase best plan at KR Insurance Services and save your medical expenses.</p>
              
              <p><strong>KR Insurance Services provides prompt and best value added services to corporates, the key features are</strong></p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Single window solutions through dedicated relationship manager.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Arranging wider terms, tailor made policies at best discounted pricing from the insurers.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Renewal reminder services - auto generated renewal reminders.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Insurance audit with NIL cost implications of your existing insurance policies.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Personalized claim assistance through our dedicated and experienced team.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Analysis of previous claim data to suggest improvement on loss ratios.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Tailor made policy options matching to the different requirements of the customers.</div>
              </div>
              
              <h3 class="tab-sub-title-en">Main Cover / Group Personal Accident Insurance</h3>
              
              <p>Enjoy the following advantages under this insurance policy -</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Accidental death: The benefits under this plan is payable if demise occurs from a mishap.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Accidental disability: It indicates that the policyholder is disabled either partially or wholly.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Accidental dismemberment: It indicates that a part of the policyholder's body has been severed or dismembered. It means, if the policyholder loses his hand or leg or eyes in a mishap, then he would be eligible to get a claim under dismemberment.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Terrorism act: Injuries afflicted because of terrorist acts are also included.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Daily payment during hospitalization: It gives monetary relief to a patient while he undergoes cure at the hospital.</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="grpacc-covinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"} selected class="active">Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="grpacc-covinsu-mob-main-en">
          <h1 class="grpacc-covinsu-banner-heading-en">Group Personal Accident Insurance</h1>
          <div class="grpacc-covinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_personal_accident_insurance.png"} alt="Group Personal Accident Insurance" />
          </div>
          <p class="grpacc-covinsu-mob-txt-en">Group Personal Accident insurance covers all accidental bodily injuries, death or any disablement of the people covered by the policy. This cover is ideal for companies to cover their employee’s medical expenses due to accidental injuries.</p>

          <p class="grpacc-covinsu-mob-txt-en">Get your employees covered against accidental death and disability with Group Personal accident insurance cover. Purchase best plan at KR Insurance Services and save your medical expenses.</p>

          <p class="grpacc-covinsu-mob-txt-en"><strong>KR Insurance Services provides prompt and best value added services to corporates, the key features are</strong></p>

          <h3 class="grpacc-covinsu-subtitle-en">Main Cover / Group Personal Accident Insurance</h3>

          <p class="grpacc-covinsu-mob-txt-en">Enjoy the following advantages under this insurance policy -</p>

          <div class="grpacc-covinsu-mob-keyfeatures-en">
            <div class="grpacc-covinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-en">Accidental death:&nbsp; The benefits under this plan is payable if demise occurs from a mishap.</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-en">Accidental disability: It indicates that the policyholder is disabled either partially or wholly.</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-en">Accidental dismemberment: It indicates that a part of the policyholder's body has been severed or dismembered. It means, if the policyholder loses his hand or leg or eyes in a mishap, then he would be eligible to get a claim under dismemberment.</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-en">Terrorism act: Injuries afflicted because of terrorist acts are also included.</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-en">Daily payment during hospitalization: It gives monetary relief to a patient while he undergoes cure at the hospital.</div>
          </div>
          <h3 class="grpacc-covinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="grpacc-covinsu-mob-whykrlist-en">
            <div class="grpacc-covinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="grpacc-covinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="grpacc-covinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="grpacc-covinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default GroupPersonalAccidentInsurance;