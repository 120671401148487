import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './fidelityguaranteeinsurance.scss';

const FidelityGuaranteeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="fidelityguarinsu-tab-imagebannermain-en">
          <div class="fidelityguarinsu-banner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="fidelityguarinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="fidelityguarinsu-banner-con-en">
          <div class="fidelityguarinsu-bdy-en">
            <div class="fidelityguarinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="fidelityguarinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Fidelity Guarantee Insurance</h2>
                <p class="fidelityguarinsu-txt-con">Companies are exposed to significant financial losses annually, due to crime committed by employees and it is reported that Fraud is on the increase. Combined with the growth in the use of electronic data and asset transfers, the fidelity losses faced by employers are increasing substantially.</p>

                <p class="fidelityguarinsu-txt-con">Fidelity guarantee insurance is most often sought by companies where employees are more likely to cause financial loss because of their constant exposure to cash, stocks or other assets. The potential losses that could arise from fidelity, especially with the increase in computer crime, make it pertinent that those employers at risk use fidelity guarantee insurance as a risk management tool.</p>

                <h3 class="fidelityguarinsu-page-sbu-title-en">Main Cover / Fidelity Guarantee Insurance</h3>

                <p class="fidelityguarinsu-txt-con">Fidelity Guarantee insurance is an insurance policy designed to indemnify the Insured (the employer) for the loss of money or property sustained as a direct result of acts of fraud, theft or dishonesty by an employee in the course of employment.</p>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="fidelityguarinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/fidelity_guarantee_insurance.png"} alt="Fidelity Guarantee Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="fidelityguarinsu-txt-con">The  Policy covers the loss sustained by the employer by reason of any act of forgery and/or fraud and/or dishonesty of monies and/or goods of the employer on the part of the employee Insured committed on or after the date of commencement of the Policy during uninterrupted service with the employer.</p>

            <p class="fidelityguarinsu-txt-con">The loss should be detected during the continuance of the Policy or within 12 calendar months of the expiry of the Policy and in the case of death, dismissal or retirement of the employee within 12 calendar months of such death or dismissal or retirement whichever is earlier.</p>

            <p class="fidelityguarinsu-txt-con">Fidelity guarantee insurance is offered under various types of policies which include individual, collective, floater and blanket. Before any type of claim can be paid, a company must first prove that an act of infidelity was committed by an employee covered under the policy.</p>

            <h3 class="fidelityguarinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="fidelityguarinsu-pins-list-dksp-en">
              <div class="fidelityguarinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="fidelityguarinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="fidelityguarinsu-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="fidelityguarinsu-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="fidelityguarinsu-tab-imagebannermain-en">
          <div class="fidelityguarinsu-banner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="fidelityguarinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="fidelityguarinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Fidelity Guarantee Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/fidelity_guarantee_insurance.png"} alt="Fidelity Guarantee Insurance" />
              <p>Companies are exposed to significant financial losses annually, due to crime committed by employees and it is reported that Fraud is on the increase. Combined with the growth in the use of electronic data and asset transfers, the fidelity losses faced by employers are increasing substantially.</p>

              <p>Fidelity guarantee insurance is most often sought by companies where employees are more likely to cause financial loss because of their constant exposure to cash, stocks or other assets. The potential losses that could arise from fidelity, especially with the increase in computer crime, make it pertinent that those employers at risk use fidelity guarantee insurance as a risk management tool.</p>

              <h3 class="tab-sub-title-en">Main Cover / Fidelity Guarantee Insurance</h3>

              <p>Fidelity Guarantee insurance is an insurance policy designed to indemnify the Insured (the employer) for the loss of money or property sustained as a direct result of acts of fraud, theft or dishonesty by an employee in the course of employment.</p>

              <p>The  Policy covers the loss sustained by the employer by reason of any act of forgery and/or fraud and/or dishonesty of monies and/or goods of the employer on the part of the employee Insured committed on or after the date of commencement of the Policy during uninterrupted service with the employer.</p>

              <p>The loss should be detected during the continuance of the Policy or within 12 calendar months of the expiry of the Policy and in the case of death, dismissal or retirement of the employee within 12 calendar months of such death or dismissal or retirement whichever is earlier.</p>

              <p>Fidelity guarantee insurance is offered under various types of policies which include individual, collective, floater and blanket. Before any type of claim can be paid, a company must first prove that an act of infidelity was committed by an employee covered under the policy.</p>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="fidelityguarinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"} selected class="active">Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="fidelityguarinsu-mob-main-en">
          <h1 class="fidelityguarinsu-banner-heading-en">Fidelity Guarantee Insurance</h1>
          <div class="fidelityguarinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/fidelity_guarantee_insurance.png"} alt="Fidelity Guarantee Insurance" />
          </div>

          <p class="fidelityguarinsu-mob-txt-en">Companies are exposed to significant financial losses annually, due to crime committed by employees and it is reported that Fraud is on the increase. Combined with the growth in the use of electronic data and asset transfers, the fidelity losses faced by employers are increasing substantially.</p>

          <p class="fidelityguarinsu-mob-txt-en">Fidelity guarantee insurance is most often sought by companies where employees are more likely to cause financial loss because of their constant exposure to cash, stocks or other assets. The potential losses that could arise from fidelity, especially with the increase in computer crime, make it pertinent that those employers at risk use fidelity guarantee insurance as a risk management tool.</p>




          <h3 class="fidelityguarinsu-subtitle-en">Main Cover / Fidelity Guarantee Insurance</h3>

          <p class="fidelityguarinsu-mob-txt-en">Fidelity Guarantee insurance is an insurance policy designed to indemnify the Insured (the employer) for the loss of money or property sustained as a direct result of acts of fraud, theft or dishonesty by an employee in the course of employment.</p>

          <p class="fidelityguarinsu-mob-txt-en">The  Policy covers the loss sustained by the employer by reason of any act of forgery and/or fraud and/or dishonesty of monies and/or goods of the employer on the part of the employee Insured committed on or after the date of commencement of the Policy during uninterrupted service with the employer.</p>

          <p class="fidelityguarinsu-mob-txt-en">The loss should be detected during the continuance of the Policy or within 12 calendar months of the expiry of the Policy and in the case of death, dismissal or retirement of the employee within 12 calendar months of such death or dismissal or retirement whichever is earlier.</p>

          <p class="fidelityguarinsu-mob-txt-en">Fidelity guarantee insurance is offered under various types of policies which include individual, collective, floater and blanket. Before any type of claim can be paid, a company must first prove that an act of infidelity was committed by an employee covered under the policy.</p>

          <h3 class="fidelityguarinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="fidelityguarinsu-mob-whykrlist-en">
            <div class="fidelityguarinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="fidelityguarinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="fidelityguarinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="fidelityguarinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default FidelityGuaranteeInsurance;