import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arthermographytestinginsurance.scss';

const ARThermographyTestingInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div class="themogtestinsu-tab-imagebannermain-ar">
          <div class="themogtestinsu-banner-con-ar">
            <div class="themogtestinsutab-banne-sec-ar">
              <h1 class="themogtestinsu-banner-heading-ar">خدمات قيمة</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="themogtestinsu-tab-btn-ar">
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="themogtestinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="themogtestinsu-banner-con-ar">
          <div class="themogtestinsu-bdy-ar">
            <div class="themogtestinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="themogtestinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/thermography_testing_ar.png"} alt="اختبار التصوير الحراري " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="themogtestinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">اختبار التصوير الحراري </h2>

                <p class="themogtestinsu-pg-txt-con-ar">ترتبط غالبية الحرائق التي تحدث في مرافق التصنيع بفشل النظام الكهربائي، مثل فشل العزل الكهربائي والمحطات والمكونات ذات الصلة. نحن نقدم اختبار التصوير الحراري لموظفيهم الموقرين. ويعد التصوير الحراري طريقة اختبار غير مدمرة يمكن استخدامها للكشف عن التوصيلات الكهربائية الضعيفة أو الأحمال غير المتوازنة أو العزل المتدهور أو المشكلات المحتملة الأخرى في المكونات الكهربائية النشطة. وقد تؤدي هذه المشكلات إلى زيادة استخدام الطاقة أو زيادة تكاليف الصيانة أو حدوث عطل كارثي في المعدات يؤدي إلى انقطاعات غير مجدولة للخدمة أو تلف المعدات أو مشكلات أخرى</p>
                
                
                {/* <p class="themogtestinsu-pg-txt-con-ar"><strong>برنامج صحة الناس الخاص بشركة كيمجي رامداس لخدمات التأمين </strong></p>

                <p class="themogtestinsu-pg-txt-con-ar">  نقوم بإنشاء برامج صحية مخصصة ومثيرة لك! </p>

                      <p class="themogtestinsu-pg-txt-con-ar"> في سعينا لتقديم أفضل الخدمات ولإضافة قيمة إلى برنامج التأمين الصحي الخاص بك، نقترح خدمات ذات قيمة مضافة للموظفين المرتبطين بمنظمتك الموقرة. هذه مجرد مبادرة تم اتخاذها من جانبنا لصالح موظفيك مع عدم وجود أي تكاليف. </p>

                      <p class="themogtestinsu-pg-txt-con-ar"> تعاونت شركة كيمجي رامداس لخدمات التأمين مع المستشفيات المشهورة في عُمان لنشر الوعي الصحي وتحفيز الممارسات الصحية الجيدة بين جميع موظفيك. </p>
                      
                      <p class="themogtestinsu-pg-txt-con-ar"> نحن في شركة كيمجي رامداس لخدمات التأمين نتطلع إلى إجراء تدقيق التأمين لوثائق التأمين الحالية لمؤسستك وضمان أفضل التغطيات التأمينية للمخاطر المرتبطة بنشاطك التجاري. </p>
                      
                      <p class="themogtestinsu-pg-txt-con-ar"> يرجى الاتصال بنا للحصول على مزيد من التفاصيل insurance@kr.om </p> */}
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            {/* <p class="themogtestinsu-pg-txt-con-ar"> الجلسات الصحية </p>
                      
                      <p class="themogtestinsu-pg-txt-con-ar"> سيتم إجراء جلسة العافية الصحية من قبل أطباء ممارسين مشهورين وستكون مدة الجلسة ما بين 25 إلى 40 دقيقة. سيتم تسليم الجلسات في مكتبك عبر عروض تقديمية بالصوت والصورة. ويمكنك اختيار أي من الموضوعات التالية لجلسات العافية الصحية المقترحة لدينا -</p>

                      <p class="themogtestinsu-pg-txt-con-ar"> المخيمات الصحية </p>
                      
                      <p class="themogtestinsu-pg-txt-con-ar"> سيتم تنظيم المخيم الصحي داخل مبنى مكتبك من قبل طبيب ممارس وممرضات، مدعومين بأفضل المعدات الطبية في فئتها لإجرائه. وسنحتاج إلى مساحة، أي غرفة اجتماعات أو منطقة غير مأهولة، لإنشاء مخيمنا الصحي والحفاظ على خصوصية الموظف. وسوف يستفيد الموظفون من استشارة فردية مع الطبيب الممارس العام. سيتم تزويد جميع الموظفين الذين يحضرون المخيم الصحي بتقاريرهم الطبية. </p> */}
                      
            <h3 className="themogtestinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                <div className="clearfix"></div>
                
                <div className="themogtestinsu-pins-list-lftdksp-ar">
                  <div className="themogtestinsu-lt-two-lftdksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="themogtestinsu-lt-one-lftdksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="themogtestinsu-lt-two-lftdksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="themogtestinsu-lt-one-lftdksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>
            </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

       {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
       <div className="tab-btn-viewmode-ar">
        <div class="themogtestinsu-tab-imagebannermain-ar">
          <div class="themogtestinsu-tab-banner-con-ar">
            <div class="themogtestinsu-tab-banne-sec-ar">
              <h1 class="artinsu-banner-heading-ar">خدمات قيمة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}  
              <div className="themogtestinsu-tab-btn-ar">
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="themogtestinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="themogtestinsu-tab-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="themogtestinsu-tab-banner-con-ar">
        <div className="tab-imgtxt-ar">
                <h2>اختبار التصوير الحراري </h2>
                  <div className="tabs-cont-ar">
                        <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/thermography_testing_ar.png"} alt="اختبار التصوير الحراري " />

                        <p>ترتبط غالبية الحرائق التي تحدث في مرافق التصنيع بفشل النظام الكهربائي، مثل فشل العزل الكهربائي والمحطات والمكونات ذات الصلة. نحن نقدم اختبار التصوير الحراري لموظفيهم الموقرين. ويعد التصوير الحراري طريقة اختبار غير مدمرة يمكن استخدامها للكشف عن التوصيلات الكهربائية الضعيفة أو الأحمال غير المتوازنة أو العزل المتدهور أو المشكلات المحتملة الأخرى في المكونات الكهربائية النشطة. وقد تؤدي هذه المشكلات إلى زيادة استخدام الطاقة أو زيادة تكاليف الصيانة أو حدوث عطل كارثي في المعدات يؤدي إلى انقطاعات غير مجدولة للخدمة أو تلف المعدات أو مشكلات أخرى. </p>

                        <p class=""><strong>برنامج صحة الناس الخاص بشركة كيمجي رامداس لخدمات التأمين </strong></p>

                      <p>  نقوم بإنشاء برامج صحية مخصصة ومثيرة لك! </p>
                      <p> في سعينا لتقديم أفضل الخدمات ولإضافة قيمة إلى برنامج التأمين الصحي الخاص بك، نقترح خدمات ذات قيمة مضافة للموظفين المرتبطين بمنظمتك الموقرة. هذه مجرد مبادرة تم اتخاذها من جانبنا لصالح موظفيك مع عدم وجود أي تكاليف. </p>
                      <p> تعاونت شركة كيمجي رامداس لخدمات التأمين مع المستشفيات المشهورة في عُمان لنشر الوعي الصحي وتحفيز الممارسات الصحية الجيدة بين جميع موظفيك. </p>
                      
                      <p> نحن في شركة كيمجي رامداس لخدمات التأمين نتطلع إلى إجراء تدقيق التأمين لوثائق التأمين الحالية لمؤسستك وضمان أفضل التغطيات التأمينية للمخاطر المرتبطة بنشاطك التجاري. </p>

                      <p> الجلسات الصحية </p>
                      
                      <p> سيتم إجراء جلسة العافية الصحية من قبل أطباء ممارسين مشهورين وستكون مدة الجلسة ما بين 25 إلى 40 دقيقة. سيتم تسليم الجلسات في مكتبك عبر عروض تقديمية بالصوت والصورة. ويمكنك اختيار أي من الموضوعات التالية لجلسات العافية الصحية المقترحة لدينا -</p>

                      <p> المخيمات الصحية </p>
                      
                      <p> سيتم تنظيم المخيم الصحي داخل مبنى مكتبك من قبل طبيب ممارس وممرضات، مدعومين بأفضل المعدات الطبية في فئتها لإجرائه. وسنحتاج إلى مساحة، أي غرفة اجتماعات أو منطقة غير مأهولة، لإنشاء مخيمنا الصحي والحفاظ على خصوصية الموظف. وسوف يستفيد الموظفون من استشارة فردية مع الطبيب الممارس العام. سيتم تزويد جميع الموظفين الذين يحضرون المخيم الصحي بتقاريرهم الطبية. </p>
                      
                      <p> يرجى الاتصال بنا للحصول على مزيد من التفاصيل insurance@kr.om </p>


                        
                        <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                        
                        <div class="tab-whykrinsu-pins-list-ar">
                          <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                          <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                          <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                          <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                        </div>

                        {/* ask for quotes button starts here */}
                        {/* <div className="askquto-btn-sec-ar">
                              <a href="/omanbr/contactus" className="askquto-btn-ar">Ask for Quotes</a>
                        </div> */}
                        {/* ask for quotes button starts here */}
                  </div>
            </div>
         
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}



      {/* MOBILE PAGE STARTS HERE */}
      <div class="themogtestinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services-ar"}>خدمات فحص المخاطر </option>
            <option value={"insurance-audits-ar"}>تدقيقات التأمين</option>
            <option value={"valuation-services-ar"}>خدمات التقييم</option>
            <option value={"thermography-testing-insurance-ar"} selected className="active">اختبار التصوير الحراري</option>
            <option value={"employee-wellness-program-ar"}>برنامج صحة الموظفين</option>
          </select>
        </div>
        <div class="themogtestinsu-mob-main-ar">
          <h1 class="themogtestinsu-banner-heading-ar">اختبار التصوير الحراري </h1>
          <div class="themogtestinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/thermography_testing_ar.png"} alt="اختبار التصوير الحراري " />
          </div>
          

          <p class="themogtestinsu-mob-txt-ar">ترتبط غالبية الحرائق التي تحدث في مرافق التصنيع بفشل النظام الكهربائي، مثل فشل العزل الكهربائي والمحطات والمكونات ذات الصلة. نحن نقدم اختبار التصوير الحراري لموظفيهم الموقرين. ويعد التصوير الحراري طريقة اختبار غير مدمرة يمكن استخدامها للكشف عن التوصيلات الكهربائية الضعيفة أو الأحمال غير المتوازنة أو العزل المتدهور أو المشكلات المحتملة الأخرى في المكونات الكهربائية النشطة. وقد تؤدي هذه المشكلات إلى زيادة استخدام الطاقة أو زيادة تكاليف الصيانة أو حدوث عطل كارثي في المعدات يؤدي إلى انقطاعات غير مجدولة للخدمة أو تلف المعدات أو مشكلات أخرى. </p>

                        <p class="themogtestinsu-mob-txt-ar"><strong>برنامج صحة الناس الخاص بشركة كيمجي رامداس لخدمات التأمين </strong></p>

                      <p class="themogtestinsu-mob-txt-ar">  نقوم بإنشاء برامج صحية مخصصة ومثيرة لك! </p>
                      <p class="themogtestinsu-mob-txt-ar"> في سعينا لتقديم أفضل الخدمات ولإضافة قيمة إلى برنامج التأمين الصحي الخاص بك، نقترح خدمات ذات قيمة مضافة للموظفين المرتبطين بمنظمتك الموقرة. هذه مجرد مبادرة تم اتخاذها من جانبنا لصالح موظفيك مع عدم وجود أي تكاليف. </p>

                      <p class="themogtestinsu-mob-txt-ar"> تعاونت شركة كيمجي رامداس لخدمات التأمين مع المستشفيات المشهورة في عُمان لنشر الوعي الصحي وتحفيز الممارسات الصحية الجيدة بين جميع موظفيك. </p>
                      
                      <p class="themogtestinsu-mob-txt-ar"> نحن في شركة كيمجي رامداس لخدمات التأمين نتطلع إلى إجراء تدقيق التأمين لوثائق التأمين الحالية لمؤسستك وضمان أفضل التغطيات التأمينية للمخاطر المرتبطة بنشاطك التجاري. </p>

                      <p class="themogtestinsu-mob-txt-ar"> الجلسات الصحية </p>
                      
                      <p class="themogtestinsu-mob-txt-ar"> سيتم إجراء جلسة العافية الصحية من قبل أطباء ممارسين مشهورين وستكون مدة الجلسة ما بين 25 إلى 40 دقيقة. سيتم تسليم الجلسات في مكتبك عبر عروض تقديمية بالصوت والصورة. ويمكنك اختيار أي من الموضوعات التالية لجلسات العافية الصحية المقترحة لدينا -</p>

                      <p class="themogtestinsu-mob-txt-ar"> المخيمات الصحية </p>
                      
                      <p class="themogtestinsu-mob-txt-ar"> سيتم تنظيم المخيم الصحي داخل مبنى مكتبك من قبل طبيب ممارس وممرضات، مدعومين بأفضل المعدات الطبية في فئتها لإجرائه. وسنحتاج إلى مساحة، أي غرفة اجتماعات أو منطقة غير مأهولة، لإنشاء مخيمنا الصحي والحفاظ على خصوصية الموظف. وسوف يستفيد الموظفون من استشارة فردية مع الطبيب الممارس العام. سيتم تزويد جميع الموظفين الذين يحضرون المخيم الصحي بتقاريرهم الطبية. </p>
                      
                      <p class="themogtestinsu-mob-txt-ar"> يرجى الاتصال بنا للحصول على مزيد من التفاصيل insurance@kr.om </p>
          

          <h3 className="themogtestinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div className="themogtestinsu-mob-whykrlist-ar">
            <div className="themogtestinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="themogtestinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="themogtestinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div className="themogtestinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARThermographyTestingInsurance;
