import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './insuranceaudits.scss';

const InsuranceAudits = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="d-none d-md-block">
        <div class="insuraudits-tab-imagebannermain-en">
          <div class="insuraudits-banner-con-en">
            <div class="insurauditstab-banne-sec-en">
              <h1 class="insuraudits-banner-heading-en">Valued Services</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "risk-inspection-services"}>Risk Inspection Services</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "insurance-audits"}>Insurance Audits</div>
                {/* <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "policy-renewalalerts"}>Policy Renewal Alerts</div> */}
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "valuation-services"}>Valuation services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "thermography-testing-insurance"}>Thermography Testing</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "employee-wellness-program"}>Employee Wellness Program</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}

              <div class="best-responsive-tab" id="myExampleBestResponsiveTab1" role="tablist" data-target-content="#myExampleTabsContent">
                {/* {
              tabsList && tabsList.length ? tabsList.map(( each, index ) => <div data-target-index={index} className={index == tab ? "active" : "def"} onClick={() => {
                setTab(index)
                window.location.href = "personalinsurance/" + each.url
              }}>{each.text}</div>) : null

            } */}
                {/* <div data-target-index="0">Travel Insurance</div>
            <div data-target-index="1" onClick={() => window.location.href = "personalinsurance/life-insurance"}>Life Insurance</div>
            <div data-target-index="2">Personal Accident Insurance</div>
            <div data-target-index="3">Relocation Insurance</div>
            <div data-target-index="4">Health Insurance</div>
            <div data-target-index="5">Art insurance</div>
            <div data-target-index="6">Golf Insurance</div>
            <div data-target-index="7">Motor Insurance</div>
            <div data-target-index="8">Housemaid Insurance</div>
            <div data-target-index="9">Home Insurance</div>
            <div data-target-index="10">Yacht Insurance</div> */}


              </div>
            </div>
          </div>
        </div>
        <div class="insuraudits-banner-con-en">
          <div class="insuraudits-bdy-en">
            {/* <h2 class="pagetitle-en">Insurance Audits</h2> */}
            <div class="insuraudits-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="insuraudits-dlex-lft-en">
                <h2 class="pagetitle-en">Insurance Audits</h2>

                <p class="insuraudits-pg-txt-con">The insurance audits make sure that the premiums you're paying are in line with the amount of coverage and there should not be any gaps in your insurance covers.</p>

                <p class="insuraudits-pg-txt-con">We have a dedicated technical team at KR Insurance services to conduct the insurance audits of your Organization’s existing insurance program.</p>

                <p class="insuraudits-pg-txt-con">The KR Insurance team will analyze the risk factors involved in your business operations and review all the terms & conditions of your organization’s existing insurance policies. We will compare it with the best market practices and submit a detailed report to your end, recommending value added & cost effective terms.</p>

                <p class="insuraudits-pg-txt-con">We at KR Insurance Services look forward for conducting an insurance audit for your organization’s existing insurance policies and ensuring best insurance covers for the risk associated with your business.</p>
                
                <h3 class="insuraudits-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              <div class="clearfix"></div>
              
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="insuraudits-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/insurance_audits.png"} alt="insurance audits" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            
            <div class="clearfix"></div>

            <div class="insuraudits-pins-list-dksp-en">
              <div class="insuraudits-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="insuraudits-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="insuraudits-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="insuraudits-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* <h3 class="insuraudits-page-sbu-title-en"></h3>

            <p class="insuraudits-txt-con"></p>
            
            <div class="insuraudits-kpnt-en">
              <div class="insuraudits-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="insuraudits-kpnt-item-en"></div>
              
              <div class="insuraudits-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="insuraudits-kpnt-item-en"></div>
            </div> */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="insuraudits-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services"}>Risk Inspection Services</option>
            <option value={"insurance-audits"} selected class="active">Insurance Audits</option>
            <option value={"valuation-services"}>Valuation Services</option>
            <option value={"thermography-testing-insurance"}>Thermography Testing</option>
            {/* <option value={"policy-renewalalerts"}>Policy Renewal Alerts</option> */}
            <option value={"employee-wellness-program"} selected class="active">Employee Wellness Program</option>
          </select>
        </div>
        <div class="insuraudits-mob-main-en">
          <h1 class="insuraudits-banner-heading-en">Insurance Audits</h1>
          <div class="insuraudits-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/insurance_audits.png"} alt="insurance audits" />
          </div>

          <p class="insuraudits-mob-txt-en">The insurance audits make sure that the premiums you're paying are in line with the amount of coverage and there should not be any gaps in your insurance covers.</p>

          <p class="insuraudits-mob-txt-en">We have a dedicated technical team at KR Insurance services to conduct the insurance audits of your Organization’s existing insurance program.</p>

          <p class="insuraudits-mob-txt-en">The KR Insurance team will analyze the risk factors involved in your business operations and review all the terms & conditions of your organization’s existing insurance policies. We will compare it with the best market practices and submit a detailed report to your end, recommending value added & cost effective terms.</p>

          <p class="insuraudits-mob-txt-en">We at KR Insurance Services look forward for conducting an insurance audit for your organization’s existing insurance policies and ensuring best insurance covers for the risk associated with your business.</p>

          <p class="insuraudits-mob-txt-en">The KR Insurance services, proposes insurance audit services to their clients & prospects for the betterment of their insurance covers at NIL cost.</p>
          
          <h3 class="insuraudits-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="insuraudits-mob-whykrlist-en">
            <div class="insuraudits-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="insuraudits-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="insuraudits-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="insuraudits-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
          </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default InsuranceAudits;
