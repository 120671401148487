import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './workmencompensationinsurance.scss';

const WorkmenCompensationInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="wmencompinsu-tab-imagebannermain-en">
          <div class="wmencompinsu-banner-con-en">
            <div class="wmencompinsutab-banne-sec-en">
              <h1 class="wmencompinsu-banner-heading-en">Business Insurance</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="wmencompinsu-banner-con-en">
          <div class="wmencompinsu-bdy-en">
            <div class="wmencompinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="wmencompinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Workmen Compensation Insurance</h2>
                <p class="pg-txt-con">Workmen's Compensation insurance protects businesses against worker’s demise, injuries and illness arising out of the work environment related exposures as per the laws. Also, the arrangement provides scope for accidental medical benefits in private hospitals, clinics and repatriation expenses.</p>
                
                <h3 class="wmencompinsu-subtitle-en">Main Cover / Workmen Compensation Insurance</h3>

                <p class="wmencompinsu-txt-con">KR Insurance Services policy covers the below mentioned compensation for workmen:-</p>

                <div class="wmencompinsu-keypoint-en">
                  <div class="wmencompinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="wmencompinsu-keypoint-item-en">Employee death.</div>
                  <div class="wmencompinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="wmencompinsu-keypoint-item-en">Bodily injury.</div>
                  <div class="wmencompinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="wmencompinsu-keypoint-item-en">Work environment related illness.</div>
                  <div class="wmencompinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="wmencompinsu-keypoint-item-en">Accidental medical benefits.</div>
                  <div class="wmencompinsu-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="wmencompinsu-keypoint-item-en">Repatriation benefits.</div>
                </div>

                <h3 class="wmencompinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="wmencompinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/workmen_compensation_insurance.png"} alt="workmen compensation insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <div class="wmencompinsu-pins-list-lft-en">
                  <div class="wmencompinsu-lt-one-lft-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div class="wmencompinsu-lt-two-lft-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div class="wmencompinsu-lt-one-lft-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div class="wmencompinsu-lt-two-lft-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>
                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}
      
      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="wmencompinsu-tab-imagebannermain-en">
          <div class="wmencompinsu-banner-con-en">
            <div class="wmencompinsutab-banne-sec-en">
              <h1 class="wmencompinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="wmencompinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Workmen Compensation Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/workmen_compensation_insurance.png"} alt="workmen compensation insurance" />

              <p>Workmen's Compensation insurance protects businesses against worker's demise, injuries and illness arising out of the work environment related exposures as per the laws. Also, the arrangement provides scope for accidental medical benefits in private hospitals, clinics and repatriation expenses.</p>

              <p class="tab-subhead-title-en">Main Cover / Workmen Compensation Insurance</p>
              <p>KR Insurance Services policy covers the below mentioned compensation for workmen:-</p>

              <div className="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Employee death.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Bodily injury.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Work environment related illness.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Accidental medical benefits.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Repatriation benefits.</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec">
                  <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                </div>
                {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="wmencompinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"} selected class="active">Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="wmencompinsu-mob-main-en">
          <h1 class="wmencompinsu-banner-heading-en">Workmen Compensation Insurance</h1>
          <div class="wmencompinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/workmen_compensation_insurance.png"} alt="workmen compensation insurance" />
          </div>

          <p class="wmencompinsu-mob-txt-en">Workmen's Compensation insurance protects businesses against worker’s demise, injuries and illness arising out of the work environment related exposures as per the laws. Also, the arrangement provides scope for accidental medical benefits in private hospitals, clinics and repatriation expenses.</p>

          <h3 class="wmencompinsu-subtitle-mob-en">Main Cover / Workmen Compensation Insurance</h3>
          
          <p class="wmencompinsu-mob-txt-en">KR Insurance Services policy covers the below mentioned compensation for workmen:-</p>
          

          <div class="wmencompinsu-pins-list-mob-en">
            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Employee death.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Bodily injury.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Work environment related illness.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Accidental medical benefits.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Repatriation benefits.</div>
            
          </div>

          

          
          
          <div class="wmencompinsu-pins-list-mob-en">
          <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Fire: The policy offers coverage for any damage caused owing to the fire. However, damage caused by natural heating, own fermentation or spontaneous burning are excluded.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Lightning: Damages caused by lightning is covered. If lightning cause fire, or other damages like cracks in the building or roof area, it will be covered by fire insurance.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Explosion: Fire damage caused by an explosion is covered by this policy.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Aircraft Damage: Fire and any other damage caused by aircraft or other airborne devices, damage due to articles dropped by an aircraft is covered by fire insurance policy.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Riot Strike, Malicious Damages: If the insured property is damaged due to a strike, riot or any terrorist activity, the loss is covered by fire insurance.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Natural Disasters: Apart from the above, this policy also includes damages owing to natural disasters including storm, cyclone, typhoon, tornado, flood. The hazardous situation and consequences of a natural disaster are covered by fire insurance.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Landslide/Rockslide: Your insurer will offer protection under fire insurance for the destruction caused to your property to a notable or even a small part of the site where the property exists by rockslide/landslide.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Bursting of water/overflowing of Water Tank (Inundation): your property is damaged due to the bursting of water or overflowing of the water tank, the policy covers the damage.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Bush Fire: At the time you set fire to overgrown plants and bushes, the possibility is where you end up damaging the property. Fret not! The fire insurance policy that you own covers you for this as well. However, the policy wouldn’t cover the destruction due to the forest fire.</div>

            <div class="wmencompinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="wmencompinsu-pins-list-mob-item-en">Terrorism : Optional cover</div>
            </div>

          <h3 class="wmencompinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="wmencompinsu-mob-whykrlist-en">
            <div class="wmencompinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="wmencompinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="wmencompinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="wmencompinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default WorkmenCompensationInsurance;