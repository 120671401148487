import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arjewellersblockinsurance.scss';

const ARJewellersBlockInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="jewellersblkinsu-tab-imagebannermain-ar">
          <div class="jewellersblkinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="jewellersblkinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="jewellersblkinsu-tab-btn-ar">
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="jewellersblkinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="jewellersblkinsu-banner-con-ar">
          <div class="jewellersblkinsu-bdy-ar">
            <div class="jewellersblkinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="jewellersblkinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/jewellers_block_insurance_ar.png"} alt="التأمين على المجوهرات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="jewellersblkinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين على المجوهرات</h2>
                <p class="pg-txt-con-ar">تغطي بوليصة التأمين على المجوهرات الخسارة أو التلف الذي يلحق بالمجوهرات أو الحلي الذهبية والفضية أو اللآلِئُ والأحجار الكريمة والنقود والأوراق النقدية أثناء وجودها في المبنى المؤمن عليه، والناتح عن الحرائق، والانفجارات، والصواعق، وأعمال السطو، والاقتحام، والسرقة، وسحب الأموال، والنهب وأعمال الشغب والإضراب والأضرار الكيدية وأعمال الإرهاب. كما هو الحال دائمًا، سيسعد فريق التأمين المتخصص لدينا بالإجابة على أي أسئلة لديك بشأن التغطية التأمينية على المجوهرات</p>

                <h3 class="jewellersblkinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين على المجوهرات</h3>
                <p class="pg-txt-con-ar">بوليصة التأمين على المجوهرات هي تغطية تأمينية ضد "جميع المخاطر"، مما يعني أنه يجب على شركة التأمين تحديد ما لا يتم تغطيته. إذا لم يكن الخطر مدرجًا في قائمة الاستثناءات، فسيتم تغطيته. المخاطر النموذجية التي يتم تغطيتها هي عمليات السطو والسرقة والنهب والاستيلاء والهروب والخسارة عن طريق الحيلة والاستبدال والأضرار العرضية، بالإضافة إلى المخاطر المعتادة مثل الحريق</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="jewellersblkinsu-txt-con-ar">تعتمد التغطية على المخزون المتوفر في مقر عملك ولكن يمكن تمديدها لتتبع مخزونك أينما يتم أخذه بشكل تقريبي، بما في ذلك</p>

            <div class="jewellersblkinsu-pins-list-dsk-ar">
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">أصحاب العمل والعمال الخارجيون</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">مذكرة الشحن إلى صائغي المجوهرات الآخرين</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">صناديق الودائع المصرفية أو الآمنة</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">مندوبي المبيعات بالعمولة</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">السفر من قبل المدراء أو الموظفين</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">الشحنات</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">العروض التجارية والمعارض</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">المساكن الشخصية</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-ar">أثناء ارتداءه</div>
              <div class="jewellersblkinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <p class="jewellersblkinsu-txt-con-ar">أهم الاستثناءات هي خيانة الأمانة من قبل الموظفين، وإتلاف السلع التي يتم العمل عليها، والخسارة المبهمة أو الخسارة غير المبررة. يمكن إعادة شراء معظم الاستثناءات الأخرى إذا لزم الأمر أو أنها تقع ضمن الاستثناءات المنطقية</p>

            <h3 class="jewellersblkinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>

            <div class="jewellersblkinsu-lft-pins-list-dksp-ar">
              <div class="jewellersblkinsu-lft-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="jewellersblkinsu-lft-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="jewellersblkinsu-lft-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="jewellersblkinsu-lft-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="jewellersblkinsu-tab-imagebannermain-ar">
          <div class="jewellersblkinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="jewellersblkinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="jewellersblkinsu-tab-btn-ar">
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="jewellersblkinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="jewellersblkinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="jewellersblkinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على المجوهرات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/jewellers_block_insurance_ar.png"} alt="التأمين على المجوهرات" />

              <p>تغطي بوليصة التأمين على المجوهرات الخسارة أو التلف الذي يلحق بالمجوهرات أو الحلي الذهبية والفضية أو اللآلِئُ والأحجار الكريمة والنقود والأوراق النقدية أثناء وجودها في المبنى المؤمن عليه، والناتح عن الحرائق، والانفجارات، والصواعق، وأعمال السطو، والاقتحام، والسرقة، وسحب الأموال، والنهب وأعمال الشغب والإضراب والأضرار الكيدية وأعمال الإرهاب. كما هو الحال دائمًا، سيسعد فريق التأمين المتخصص لدينا بالإجابة على أي أسئلة لديك بشأن التغطية التأمينية على المجوهرات</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / التأمين على المجوهرات</h3>

              <p>بوليصة التأمين على المجوهرات هي تغطية تأمينية ضد "جميع المخاطر"، مما يعني أنه يجب على شركة التأمين تحديد ما لا يتم تغطيته. إذا لم يكن الخطر مدرجًا في قائمة الاستثناءات، فسيتم تغطيته. المخاطر النموذجية التي يتم تغطيتها هي عمليات السطو والسرقة والنهب والاستيلاء والهروب والخسارة عن طريق الحيلة والاستبدال والأضرار العرضية، بالإضافة إلى المخاطر المعتادة مثل الحريق</p>

              <p>تعتمد التغطية على المخزون المتوفر في مقر عملك ولكن يمكن تمديدها لتتبع مخزونك أينما يتم أخذه بشكل تقريبي، بما في ذلك</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">أصحاب العمل والعمال الخارجيون</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مذكرة الشحن إلى صائغي المجوهرات الآخرين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">صناديق الودائع المصرفية أو الآمنة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مندوبي المبيعات بالعمولة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">السفر من قبل المدراء أو الموظفين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الشحنات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">العروض التجارية والمعارض</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المساكن الشخصية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">أثناء ارتداءه</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <p>Tأهم الاستثناءات هي خيانة الأمانة من قبل الموظفين، وإتلاف السلع التي يتم العمل عليها، والخسارة المبهمة أو الخسارة غير المبررة. يمكن إعادة شراء معظم الاستثناءات الأخرى إذا لزم الأمر أو أنها تقع ضمن الاستثناءات المنطقية</p>
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="jewellersblkinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"} selected class="active">التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="jewellersblkinsu-mob-main-ar">
          <h1 class="jewellersblkinsu-banner-heading-ar">التأمين على المجوهرات</h1>
          <div class="jewellersblkinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/jewellers_block_insurance_ar.png"} alt="التأمين على المجوهرات" />
          </div>

          <p class="jewellersblkinsu-mob-txt-ar">تغطي بوليصة التأمين على المجوهرات الخسارة أو التلف الذي يلحق بالمجوهرات أو الحلي الذهبية والفضية أو اللآلِئُ والأحجار الكريمة والنقود والأوراق النقدية أثناء وجودها في المبنى المؤمن عليه، والناتح عن الحرائق، والانفجارات، والصواعق، وأعمال السطو، والاقتحام، والسرقة، وسحب الأموال، والنهب وأعمال الشغب والإضراب والأضرار الكيدية وأعمال الإرهاب. كما هو الحال دائمًا، سيسعد فريق التأمين المتخصص لدينا بالإجابة على أي أسئلة لديك بشأن التغطية التأمينية على المجوهرات</p>

          <h3 class="jewellersblkinsu-subtitle-ar">التغطية الرئيسية / التأمين على المجوهرات</h3>

          <p class="jewellersblkinsu-mob-txt-ar">بوليصة التأمين على المجوهرات هي تغطية تأمينية ضد "جميع المخاطر"، مما يعني أنه يجب على شركة التأمين تحديد ما لا يتم تغطيته. إذا لم يكن الخطر مدرجًا في قائمة الاستثناءات، فسيتم تغطيته. المخاطر النموذجية التي يتم تغطيتها هي عمليات السطو والسرقة والنهب والاستيلاء والهروب والخسارة عن طريق الحيلة والاستبدال والأضرار العرضية، بالإضافة إلى المخاطر المعتادة مثل الحريق</p>

          <p class="jewellersblkinsu-mob-txt-ar">تعتمد التغطية على المخزون المتوفر في مقر عملك ولكن يمكن تمديدها لتتبع مخزونك أينما يتم أخذه بشكل تقريبي، بما في ذلك</p>

          <div class="jewellersblkinsu-pins-list-mob-ar">
            <div class="jewellersblkinsu-pins-list-mob-item-ar">أصحاب العمل والعمال الخارجيون</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">مذكرة الشحن إلى صائغي المجوهرات الآخرين</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">صناديق الودائع المصرفية أو الآمنة</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">مندوبي المبيعات بالعمولة</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">السفر من قبل المدراء أو الموظفين</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">الشحنات</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">العروض التجارية والمعارض</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">المساكن الشخصية</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-ar">أثناء ارتداءه</div>
            <div class="jewellersblkinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <p class="jewellersblkinsu-mob-txt-ar">Tأهم الاستثناءات هي خيانة الأمانة من قبل الموظفين، وإتلاف السلع التي يتم العمل عليها، والخسارة المبهمة أو الخسارة غير المبررة. يمكن إعادة شراء معظم الاستثناءات الأخرى إذا لزم الأمر أو أنها تقع ضمن الاستثناءات المنطقية</p>

          <h3 class="jewellersblkinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="jewellersblkinsu-mob-whykrlist-ar">
            <div class="jewellersblkinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="jewellersblkinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="jewellersblkinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="jewellersblkinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARJewellersBlockInsurance;