import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arartinsurance.scss';

const ARArtInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="artinsu-tab-imagebannermain-ar">
          <div class="artinsu-banner-con-ar">
            <div class="artinsutab-banne-sec-ar">
              <h1 class="artinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="artinsu-tab-btn-ar">
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="artinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="artinsu-banner-con-ar">
          <div class="artinsu-bdy-ar">
            <div class="artinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="artinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/art_insurance_ar.png"} alt="التأمين الفني" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="artinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين الفني</h2>
                <p class="pg-txt-con-ar">الفن شيء يمس قلبك، لكن عقلك يسابق القلق بشأن ما يمكن أن يحدث في حالة تضرر العمل الفني. وتتساءل ماذا تفعل إذا دُمرت محتويات منزلك ومقتنياتك وآلاتك الموسيقية وكيف تتحمل نفقات الترميم؟ </p>

                <h3 class="artinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين الفني</h3>

                <p class="pg-txt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين برنامج تغطية مصمم لتجار هواة الجمع وخبراء الفنون الآخرين. ويوفر هذا البرنامج تغطية شاملة وهي شيء يجب أن يمتلكه كل محب للفن</p>
                
                <h3 class="artinsu-page-sbu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                
                <div class="artinsu-pins-list-ar">
                  <div class="artinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="artinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="artinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="artinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>


               {/* ask for quotes button starts here */}
               <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>                
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="artinsu-tab-imagebannermain-ar">
          <div class="artinsu-banner-con-ar">
            <div class="artinsutab-banne-sec-ar">
              <h1 class="artinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}              
              <div class="artinsu-tab-btn-ar">
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="artinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="artinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="artinsu-banner-con-ar">
        <div className="tab-imgtxt-ar">
                  <h2>التأمين الفني</h2>
                  <div className="tabs-cont-ar">
                        <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/art_insurance_ar.png"} alt="التأمين الفني" />

                        <p>الفن شيء يمس قلبك، لكن عقلك يسابق القلق بشأن ما يمكن أن يحدث في حالة تضرر العمل الفني. وتتساءل ماذا تفعل إذا دُمرت محتويات منزلك ومقتنياتك وآلاتك الموسيقية وكيف تتحمل نفقات الترميم؟ </p>

                        <p class="tab-subhead-title-ar">التغطية الرئيسية / التأمين الفني</p>

                        <p>تقدم شركة كيمجي رامداس لخدمات التأمين برنامج تغطية مصمم لتجار هواة الجمع وخبراء الفنون الآخرين. ويوفر هذا البرنامج تغطية شاملة وهي شيء يجب أن يمتلكه كل محب للفن</p>
                        
                        <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                        
                        <div class="tab-whykrinsu-pins-list-ar">
                          <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                          <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                          <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                          <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                        </div>

                        {/* ask for quotes button starts here */}
                        <div className="askquto-btn-sec-ar">
                              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
                        </div>
                        {/* ask for quotes button starts here */}
                  </div>
            </div>
         
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="artinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"} selected class="active">التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="artinsu-mob-main-ar">
        <h1 class="artinsu-banner-heading-mob-ar">التأمين الفني</h1>
              <div class="artinsu-mob-img-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/art_insurance_ar.png"} alt="التأمين الفني" />
              </div>
              <p class="artinsu-mob-txt-ar">الفن شيء يمس قلبك، لكن عقلك يسابق القلق بشأن ما يمكن أن يحدث في حالة تضرر العمل الفني. وتتساءل ماذا تفعل إذا دُمرت محتويات منزلك ومقتنياتك وآلاتك الموسيقية وكيف تتحمل نفقات الترميم؟ </p>

              <h3 class="artinsu-subtitle-ar">التغطية الرئيسية / التأمين الفني</h3>

              <p class="artinsu-mob-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين برنامج تغطية مصمم لتجار هواة الجمع وخبراء الفنون الآخرين. ويوفر هذا البرنامج تغطية شاملة وهي شيء يجب أن يمتلكه كل محب للفن</p>
              
              <h3 class="artinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="artinsu-mob-whykrlist-ar">
                <div class="artinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="artinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="artinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="artinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>


              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARArtInsurance;