import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arcybercrimeinsurance.scss';

const CyberCrimeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="cybercrimeinsutab-imagebannermain-ar">
          <div class="cybercrimeinsubanner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="cybercrimeinsubanner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="cybercrimeinsu-tab-btn-ar">
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="cybercrimeinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="cybercrimeinsubanner-con-ar">
          <div class="cybercrimeinsubdy-ar">
            <div class="cybercrimeinsudflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="cybercrimeinsudlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/cyber_crime_insurance_ar.png"} alt="التأمين ضد الجرائم الإلكترونية" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="cybercrimeinsudlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين ضد الجرائم الإلكترونية</h2>
                <p class="cybercrimeinsu-txt-con-ar">أغلب الناس قد سمعوا عن التأمين ضد الجرائم الإلكترونية. في هذا الوقت، تتصدر أحداث الخروقات الإلكترونية عناوين الأخبار شبه أسبوعيًا. لكن هل أنت حقًا مؤمن ضد جميع أنواع السرقات التي ترتكب باستخدام أحدث التقنيات؟ ربما تكون الإجابة لا، خاصة إذا كنت لا تشتري تأمينًا ضد الجرائم. دعنا نلقي نظرة على ما تغطيه بوليصة الجرائم الإلكترونية واحتيالات الإنترنت</p>

                <p class="cybercrimeinsu-txt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين حلولاً للتأمين الإلكتروني لحماية الشركات والمستخدمين الفرديين من المخاطر المستندة إلى الإنترنت، وبشكل أعم من المخاطر المتعلقة بالبنية التحتية لتكنولوجيا المعلومات وأنشطتها</p>

                <h3 class="cybercrimeinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين ضد الجرائم الإلكترونية</h3>

                <p class="cybercrimeinsutxt-con-ar">قد تشمل التغطية التي توفرها بوليصات التأمين ضد الجرائم الإلكترونية ما يلي</p>
                <div class="cybercrimeinsupins-lft-dsk-ar">
              <div class="cybercrimeinsupins-lft-dsk-item-ar">المسؤولية عن خرق البيانات - البيانات الشخصية وبيانات الشركة</div>

              <div class="cybercrimeinsupins-lft-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-lft-dsk-item-ar">التأمين تجاه تكاليف خرق البيانات - بما في ذلك تكاليف الإخطار وتكاليف التحاليل الجنائية الحاسوبية</div>

              <div class="cybercrimeinsupins-lft-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-lft-dsk-item-ar">المسؤولية عن أمان الشبكة - للأنظمة المخترقة أو المهكرة بما في ذلك هجمات رفض الخدمة</div>
                <div class="cybercrimeinsupins-lft-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>


            <div class="cybercrimeinsupins-list-dsk-ar">
              <div class="cybercrimeinsupins-list-dsk-item-ar">مسؤولية وسائل الإعلام - بالنسبة للمنشورات الرقمية</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-ar">انقطاع الأعمال - بسبب حادث إلكتروني</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-ar">تكاليف استعادة البيانات والبرامج - الناتجة عن حدث انقطاع الأعمال الإلكترونية</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-ar">الاتصالات المجراه خلال الأزمات - للتخفيف من نتائج الأضرار التي تضر بالسمعة</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-ar">التغطية ضد سرقة المخترقين - على أساس سرقة الأموال</div>

              <div class="cybercrimeinsupins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="cybercrimeinsupins-list-dsk-item-ar">المسؤولية عن الدفع الإلكتروني - تغطية غرامات وعقوبات صناعة بطاقات الدفع</div>
              <div class="cybercrimeinsupins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <p class="cybercrimeinsutxt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين، بالاشتراك مع وسيط التأمين الرائد في العالم - ويليس تاورز واتسون، أغطية تأمين شاملة عبر الإنترنت لعملائها بدعم من فريق تسوية المطالبات المتمرس، ونتطلع إلى تقديم أفضل حلول التأمين ضد الجرائم الإلكترونية في الصناعة لمؤسستك الموقرة</p>

            <p class="cybercrimeinsutxt-con-ar">نتطلع إلى تقديم أفضل حلول التأمين السيبراني لمؤسستك</p>

            <h3 class="cybercrimeinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>

            <div class="cybercrimeinsupins-list-lftdksp-ar">
              <div class="cybercrimeinsult-two-lftdksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="cybercrimeinsult-one-lftdksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="cybercrimeinsult-two-lftdksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="cybercrimeinsult-one-lftdksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="cybercrimeinsutab-imagebannermain-ar">
          <div class="cybercrimeinsubanner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="cybercrimeinsubanner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="cybercrimeinsu-tab-btn-ar">
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="cybercrimeinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="cybercrimeinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="cybercrimeinsubanner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين ضد الجرائم الإلكترونية</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/cyber_crime_insurance_ar.png"} alt="التأمين ضد الجرائم الإلكترونية" />

              <p>أغلب الناس قد سمعوا عن التأمين ضد الجرائم الإلكترونية. في هذا الوقت، تتصدر أحداث الخروقات الإلكترونية عناوين الأخبار شبه أسبوعيًا. لكن هل أنت حقًا مؤمن ضد جميع أنواع السرقات التي ترتكب باستخدام أحدث التقنيات؟ ربما تكون الإجابة لا، خاصة إذا كنت لا تشتري تأمينًا ضد الجرائم. دعنا نلقي نظرة على ما تغطيه بوليصة الجرائم الإلكترونية واحتيالات الإنترنت</p>

              <p>تقدم شركة كيمجي رامداس لخدمات التأمين حلولاً للتأمين الإلكتروني لحماية الشركات والمستخدمين الفرديين من المخاطر المستندة إلى الإنترنت، وبشكل أعم من المخاطر المتعلقة بالبنية التحتية لتكنولوجيا المعلومات وأنشطتها</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / التأمين ضد الجرائم الإلكترونية</h3>

              <p>قد تشمل التغطية التي توفرها بوليصات التأمين ضد الجرائم الإلكترونية ما يلي</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">المسؤولية عن خرق البيانات - البيانات الشخصية وبيانات الشركة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">التأمين تجاه تكاليف خرق البيانات - بما في ذلك تكاليف الإخطار وتكاليف التحاليل الجنائية الحاسوبية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المسؤولية عن أمان الشبكة - للأنظمة المخترقة أو المهكرة بما في ذلك هجمات رفض الخدمة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مسؤولية وسائل الإعلام - بالنسبة للمنشورات الرقمية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">انقطاع الأعمال - بسبب حادث إلكتروني</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تكاليف استعادة البيانات والبرامج - الناتجة عن حدث انقطاع الأعمال الإلكترونية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الاتصالات المجراه خلال الأزمات - للتخفيف من نتائج الأضرار التي تضر بالسمعة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">التغطية ضد سرقة المخترقين - على أساس سرقة الأموال</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المسؤولية عن الدفع الإلكتروني - تغطية غرامات وعقوبات صناعة بطاقات الدفع</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <p>تقدم شركة كيمجي رامداس لخدمات التأمين، بالاشتراك مع وسيط التأمين الرائد في العالم - ويليس تاورز واتسون، أغطية تأمين شاملة عبر الإنترنت لعملائها بدعم من فريق تسوية المطالبات المتمرس، ونتطلع إلى تقديم أفضل حلول التأمين ضد الجرائم الإلكترونية في الصناعة لمؤسستك الموقرة</p>
              

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="cybercrimeinsutab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"} selected class="active">التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="cybercrimeinsumob-main-ar">
          <h1 class="cybercrimeinsubanner-heading-ar">التأمين ضد الجرائم الإلكترونية</h1>
          <div class="cybercrimeinsumob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/cyber_crime_insurance_ar.png"} alt="التأمين ضد الجرائم الإلكترونية" />
          </div>

          <p class="cybercrimeinsumob-txt-ar">أغلب الناس قد سمعوا عن التأمين ضد الجرائم الإلكترونية. في هذا الوقت، تتصدر أحداث الخروقات الإلكترونية عناوين الأخبار شبه أسبوعيًا. لكن هل أنت حقًا مؤمن ضد جميع أنواع السرقات التي ترتكب باستخدام أحدث التقنيات؟ ربما تكون الإجابة لا، خاصة إذا كنت لا تشتري تأمينًا ضد الجرائم. دعنا نلقي نظرة على ما تغطيه بوليصة الجرائم الإلكترونية واحتيالات الإنترنت</p>

          <p class="cybercrimeinsumob-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين حلولاً للتأمين الإلكتروني لحماية الشركات والمستخدمين الفرديين من المخاطر المستندة إلى الإنترنت، وبشكل أعم من المخاطر المتعلقة بالبنية التحتية لتكنولوجيا المعلومات وأنشطتها</p>

          <h3 class="cybercrimeinsusubtitle-ar">التغطية الرئيسية / التأمين ضد الجرائم الإلكترونية</h3>

          <p class="cybercrimeinsumob-txt-ar">قد تشمل التغطية التي توفرها بوليصات التأمين ضد الجرائم الإلكترونية ما يلي</p>

          <div class="cybercrimeinsupins-list-mob-ar">
            <div class="cybercrimeinsupins-list-mob-item-ar">المسؤولية عن خرق البيانات - البيانات الشخصية وبيانات الشركة</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">التأمين تجاه تكاليف خرق البيانات - بما في ذلك تكاليف الإخطار وتكاليف التحاليل الجنائية الحاسوبية</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">المسؤولية عن أمان الشبكة - للأنظمة المخترقة أو المهكرة بما في ذلك هجمات رفض الخدمة</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">مسؤولية وسائل الإعلام - بالنسبة للمنشورات الرقمية</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">انقطاع الأعمال - بسبب حادث إلكتروني</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">تكاليف استعادة البيانات والبرامج - الناتجة عن حدث انقطاع الأعمال الإلكترونية</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">الاتصالات المجراه خلال الأزمات - للتخفيف من نتائج الأضرار التي تضر بالسمعة</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">التغطية ضد سرقة المخترقين - على أساس سرقة الأموال</div>

            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="cybercrimeinsupins-list-mob-item-ar">المسؤولية عن الدفع الإلكتروني - تغطية غرامات وعقوبات صناعة بطاقات الدفع</div>
            <div class="cybercrimeinsupins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>
          <p class="cybercrimeinsumob-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين، بالاشتراك مع وسيط التأمين الرائد في العالم - ويليس تاورز واتسون، أغطية تأمين شاملة عبر الإنترنت لعملائها بدعم من فريق تسوية المطالبات المتمرس، ونتطلع إلى تقديم أفضل حلول التأمين ضد الجرائم الإلكترونية في الصناعة لمؤسستك الموقرة</p>

          <h3 class="cybercrimeinsuwhykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="cybercrimeinsumob-whykrlist-ar">
            <div class="cybercrimeinsumob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="cybercrimeinsumob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="cybercrimeinsumob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="cybercrimeinsumob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
        <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default CyberCrimeInsurance;