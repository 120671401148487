import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arserviceexcellence.scss';

const ARServiceExcellence = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="servicexcell-tab-imagebannermain-ar">
          <div class="servicexcell-banner-con-ar">
            <div class="servicexcelltab-banne-sec-ar">
              <h1 class="servicexcell-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="servicexcell-tab-btn-ar">
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="servicexcell-tab-btn-item-active-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="servicexcell-banner-con-ar">
          <div class="servicexcell-bdy-ar">
            <div class="servicexcell-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="servicexcell-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/service_excellence_ar.png"} alt="التميز في الخدمة" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="servicexcell-dlex-lft-ar">
                <h2 class="pagetitle-ar">التميز في الخدمة</h2>

                <p class="servicexcell-pg-txt-con-ar">حصلت شركة كيمجي رامداس لخدمات التأمين على خبرة وظيفية في الخدمات الاستشارية والاكتتاب وخدمة مطالبات الخبراء وخدمات الاحتفاظ بالعملاء لضمان زيادة الكفاءة في الإنجازات</p>
                
                <div class="servicexcell-keypoint-ar">
              <div class="servicexcell-keypoint-item-ar">تهدف الخدمات الاستشارية إلى تحقيق رضا العملاء من خلال إدارة علاقات العملاء المخصصة، وعمليات فحص المخاطر، ومراجعة السياسات، واستبيانات الرأي، وبرامج الصحة، وتذكيرات تجديد تقارير الائتمان وما إلى ذلك</div>
              <div class="servicexcell-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="servicexcell-keypoint-item-ar">فِرق الاكتتاب المتخصصة لترتيب أفضل الشروط من شركات التأمين وشركات إعادة التأمين، وضمان التغطية الكاملة للمخاطر في متناول اليد</div>

              <div class="servicexcell-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="servicexcell-keypoint-item-ar">تعمل فِرق خدمة مطالبات الخبراء من أجل تسوية المطالبات على نحو سلس عبر خطوط الأعمال لضمان استمرارية الأعمال</div>

              <div class="servicexcell-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="servicexcell-keypoint-item-ar">فريق الاحتفاظ المخصص لتجاوز توقعات خدمة العميل والاحتفاظ بالعملاء معنا على أساس سنوي</div>
              <div class="servicexcell-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            
            </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <h3 class="servicexcell-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
                <div class="clearfix"></div>
                
                <div class="servicexcell-pins-list-lftdksp-ar">
                  <div class="servicexcell-lt-two-lftdksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="servicexcell-lt-one-lftdksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="servicexcell-lt-two-lftdksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="servicexcell-lt-one-lftdksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>
            
            </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="servicexcell-tab-imagebannermain-ar">
          <div class="servicexcell-banner-con-ar">
            <div class="servicexcelltab-banne-sec-ar">
              <h1 class="servicexcell-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="servicexcell-tab-btn-ar">
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="servicexcell-tab-btn-item-active-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="servicexcell-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التميز في الخدمة</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/customer_service/tab_images/service_excellence_ar.png"} alt="التميز في الخدمة" />

                <p>حصلت شركة كيمجي رامداس لخدمات التأمين على خبرة وظيفية في الخدمات الاستشارية والاكتتاب وخدمة مطالبات الخبراء وخدمات الاحتفاظ بالعملاء لضمان زيادة الكفاءة في الإنجازات
                </p>

                  <p className="tabimg-list">
                  تهدف الخدمات الاستشارية إلى تحقيق رضا العملاء من خلال إدارة علاقات العملاء المخصصة، وعمليات فحص المخاطر، ومراجعة السياسات، واستبيانات الرأي، وبرامج الصحة، وتذكيرات تجديد تقارير الائتمان وما إلى ذلك
                  <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> 
                </p>
                <p className="tabimg-list-wdth">
                فِرق الاكتتاب المتخصصة لترتيب أفضل الشروط من شركات التأمين وشركات إعادة التأمين، وضمان التغطية الكاملة للمخاطر في متناول اليد
                  <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> 
                </p>

                <p className="tabimg-list-wdth">
                تعمل فِرق خدمة مطالبات الخبراء من أجل تسوية المطالبات على نحو سلس عبر خطوط الأعمال لضمان استمرارية الأعمال
                  <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> 
                  </p>
                  <p className="tabimg-list-wdth">
                  فريق الاحتفاظ المخصص لتجاوز توقعات خدمة العميل والاحتفاظ بالعملاء معنا على أساس سنوي
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> 
                  </p>
                
                <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
                
                <div class="tab-whykrinsu-pins-list-ar">
                  <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="servicexcell-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"report-a-claim-ar"}>الإبلاغ عن مطالبة </option>
            <option value={"claim-consultancy-services-ar"}>خدمات استشارات المطالبات</option>
            <option value={"service-excellence-ar"} selected class="active">التميز في الخدمة </option>
            <option value={"in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</option>
            <option value={"erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</option>
          </select>
        </div>
        <div class="servicexcell-mob-main-ar">
          <h1 class="servicexcell-banner-heading-ar">التميز في الخدمة</h1>
          <div class="servicexcell-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/service_excellence_ar.png"} alt="التميز في الخدمة" />
          </div>
          
          <p class="servicexcell-mob-txt-ar">حصلت شركة كيمجي رامداس لخدمات التأمين على خبرة وظيفية في الخدمات الاستشارية والاكتتاب وخدمة مطالبات الخبراء وخدمات الاحتفاظ بالعملاء لضمان زيادة الكفاءة في الإنجازات </p>
          
          <div class="servicexcell-pins-list-mob-ar">
            <div class="servicexcell-pins-list-mob-item-ar">تهدف الخدمات الاستشارية إلى تحقيق رضا العملاء من خلال إدارة علاقات العملاء المخصصة، وعمليات فحص المخاطر، ومراجعة السياسات، واستبيانات الرأي، وبرامج الصحة، وتذكيرات تجديد تقارير الائتمان وما إلى ذلك</div>

            <div class="servicexcell-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="servicexcell-pins-list-mob-item-ar">فِرق الاكتتاب المتخصصة لترتيب أفضل الشروط من شركات التأمين وشركات إعادة التأمين، وضمان التغطية الكاملة للمخاطر في متناول اليد</div>

            <div class="servicexcell-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="servicexcell-pins-list-mob-item-ar">تعمل فِرق خدمة مطالبات الخبراء من أجل تسوية المطالبات على نحو سلس عبر خطوط الأعمال لضمان استمرارية الأعمال</div>

            <div class="servicexcell-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="servicexcell-pins-list-mob-item-ar">فريق الاحتفاظ المخصص لتجاوز توقعات خدمة العميل والاحتفاظ بالعملاء معنا على أساس سنوي</div>
            <div class="servicexcell-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          <h3 class="servicexcell-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="servicexcell-mob-whykrlist-ar">
            <div class="servicexcell-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="servicexcell-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="servicexcell-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="servicexcell-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARServiceExcellence;
