import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arclaimconsultancyservices.scss';

const ARClaimConsultancyServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="claimhandinsu-tab-imagebannermain-ar">
          <div class="claimhandinsu-banner-con-ar">
            <div class="claimhandinsutab-banne-sec-ar">
              <h1 class="claimhandinsu-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="reportclaim-tab-btn-ar">
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="reportclaim-tab-btn-item-active-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="claimhandinsu-banner-con-ar">
          <div class="claimhandinsu-bdy-ar">
            <div class="claimhandinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="claimhandinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="خدمات استشارات المطالبات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="claimhandinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">خدمات استشارات المطالبات</h2>

                <div class="claimhandinsu-keypoint-ar">
                  <div class="claimhandinsu-keypoint-item-ar">فريق متخصص ومؤهل وذوي خبرة للتعامل مع خطوط مختلفة من المطالبات</div>

                  <div class="claimhandinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-ar">خبرة في التعامل مع مطالبات المعاملات والمعقدة لعملائنا المرموقين</div>

                  <div class="claimhandinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-ar">علاقات قوية مع جميع شركات التأمين ومقيّمي الخسائر عبر منطقة دول مجلس التعاون الخليجي</div>

                  <div class="claimhandinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-ar">نهج المطالبة الخاصة بالمنتج والعمليات الشائعة</div>

                  <div class="claimhandinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-ar">التقنية تساعد على فهم بنود وشروط البوليصة</div>

                  <div class="claimhandinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="claimhandinsu-keypoint-item-ar">برامج تدريبية تعتمد على العملاء الذين يرغبون في المشاركة الدورية لنظام المعلومات الإدارية</div>
                  <div class="claimhandinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>
                
                <h3 class="claimhandinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
                <div class="clearfix"></div>
                
                <div class="claimhandinsu-pins-list-lftdksp-ar">
                  <div class="claimhandinsu-lt-two-lftdksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="claimhandinsu-lt-one-lftdksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="claimhandinsu-lt-two-lftdksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="claimhandinsu-lt-one-lftdksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="claimhandinsu-tab-imagebannermain-ar">
          <div class="claimhandinsu-banner-con-ar">
            <div class="claimhandinsutab-banne-sec-ar">
              <h1 class="claimhandinsu-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="reportclaim-tab-btn-ar">
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="reportclaim-tab-btn-item-active-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="reportclaim-tab-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="claimhandinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>خدمات استشارات المطالبات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="خدمات استشارات المطالبات" />

              <p className="tabimg-list">
              فريق متخصص ومؤهل وذوي خبرة للتعامل مع خطوط مختلفة من المطالبات
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list">
              خبرة في التعامل مع مطالبات المعاملات والمعقدة لعملائنا المرموقين
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list">
              علاقات قوية مع جميع شركات التأمين ومقيّمي الخسائر عبر منطقة دول مجلس التعاون الخليجي
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list">
              نهج المطالبة الخاصة بالمنتج والعمليات الشائعة
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list-wdth">
              التقنية تساعد على فهم بنود وشروط البوليصة
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list-wdth">
              برامج تدريبية تعتمد على العملاء الذين يرغبون في المشاركة الدورية لنظام المعلومات الإدارية
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>

              {/* <div class="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar"></div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar"></div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar"></div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar"></div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar"></div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar"></div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div> */}
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

            </div>
          </div>          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="claimhandinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"report-a-claim-ar"}>الإبلاغ عن مطالبة </option>
            <option value={"claim-consultancy-services-ar"} selected class="active">خدمات استشارات المطالبات</option>
            <option value={"service-excellence-ar"}>التميز في الخدمة </option>
            <option value={"in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</option>
            <option value={"erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</option>
          </select>
        </div>
        <div class="claimhandinsu-mob-main-ar">
          <h1 class="claimhandinsu-banner-heading-ar">خدمات استشارات المطالبات</h1>
          <div class="claimhandinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="خدمات استشارات المطالبات" />
          </div>

          <div class="claimhandinsu-pins-list-mob-ar">
            <div class="claimhandinsu-pins-list-mob-item-ar">فريق متخصص ومؤهل وذوي خبرة للتعامل مع خطوط مختلفة من المطالبات</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-ar">خبرة في التعامل مع مطالبات المعاملات والمعقدة لعملائنا المرموقين</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-ar">علاقات قوية مع جميع شركات التأمين ومقيّمي الخسائر عبر منطقة دول مجلس التعاون الخليجي</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-ar">نهج المطالبة الخاصة بالمنتج والعمليات الشائعة</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-ar">التقنية تساعد على فهم بنود وشروط البوليصة</div>

            <div class="claimhandinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="claimhandinsu-pins-list-mob-item-ar">برامج تدريبية تعتمد على العملاء الذين يرغبون في المشاركة الدورية لنظام المعلومات الإدارية</div>
            <div class="claimhandinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          <h3 class="claimhandinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="claimhandinsu-mob-whykrlist-ar">
            <div class="claimhandinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="claimhandinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="claimhandinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="claimhandinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARClaimConsultancyServices;
