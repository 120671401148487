import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './jewellersblockinsurance.scss';

const JewellersBlockInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="jewellersblkinsu-tab-imagebannermain-en">
          <div class="jewellersblkinsu-banner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="jewellersblkinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="jewellersblkinsu-banner-con-en">
          <div class="jewellersblkinsu-bdy-en">
            <div class="jewellersblkinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="jewellersblkinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Jewellers Block Insurance</h2>
                <p class="pg-txt-con">Jewellers Block Insurance policy covers loss or damage to jewellery, gold and silver ornaments or plates , pearls, precious stones, cash and currency notes whilst contained in the premises insured, by fire, explosion, lightning, burglary, house breaking, theft, hold up, robbery, riot, strike and malicious damage and terrorism.</p>

                <p class="pg-txt-con">As always, our specialist insurance team would be delighted to answer any questions you have on Jewellers Block Insurance cover.</p>

                <h3 class="jewellersblkinsu-page-sbu-title-en">Main Cover / Jewellers Block Insurance</h3>
                <p class="pg-txt-con">The <strong>jewellers Block</strong> Policy is an "all risk" coverage, which means that the insurer must specify what is not covered. If a risk is not in the list of exclusions, it is covered. Typical risks that are covered are Burglary, Robbery, Shoplifting, Grab and Run, Trick Loss, Substitution and Accidental Damage, in addition to the usual risks such as Fire.</p>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="jewellersblkinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/jewellers_block_insurance.png"} alt="Jewellers Block Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="jewellersblkinsu-txt-con">Coverage is based on stock at your premises but may be extended to follow your stock almost wherever it goes, including:</p>

            <div class="jewellersblkinsu-pins-list-dsk-en">
              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Jobbers And Outworkers</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Memo Of Consignment To Other jewellers</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Bank Or Safety Deposit Boxes</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Commission Sales Representatives</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Travel By Principals Or Employees</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Shipments</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Trade Shows And Exhibitions</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">Personal Residences</div>

              <div class="jewellersblkinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="jewellersblkinsu-pins-list-dsk-item-en">While Being Worn</div>
            </div>

            <p class="jewellersblkinsu-txt-con">The most important exclusions are Employee Dishonesty, Damage to Goods Being Worked Upon and Mysterious Loss or Unexplained Loss. Most other exclusions can be bought back if required or they are "common sense" exclusions.</p>

            <h3 class="jewellersblkinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="jewellersblkinsu-lft-pins-list-dksp-en">
              <div class="jewellersblkinsu-lft-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="jewellersblkinsu-lft-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="jewellersblkinsu-lft-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="jewellersblkinsu-lft-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="jewellersblkinsu-tab-imagebannermain-en">
          <div class="jewellersblkinsu-banner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="jewellersblkinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="jewellersblkinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Jewellers Block Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/jewellers_block_insurance.png"} alt="Jewellers Block Insurance" />

              <p>Jewellers Block Insurance policy covers loss or damage to jewellery, gold and silver ornaments or plates , pearls, precious stones, cash and currency notes whilst contained in the premises insured, by fire, explosion, lightning, burglary, house breaking, theft, hold up, robbery, riot, strike and malicious damage and terrorism.</p>
              
              <p>As always, our specialist insurance team would be delighted to answer any questions you have on Jewellers Block Insurance cover.</p>

              <h3 class="tab-sub-title-en">Main Cover / Jewellers Block Insurance</h3>

              <p>The <strong>jewellers Block</strong> Policy is an "all risk" coverage, which means that the insurer must specify what is not covered. If a risk is not in the list of exclusions, it is covered. Typical risks that are covered are Burglary, Robbery, Shoplifting, Grab and Run, Trick Loss, Substitution and Accidental Damage, in addition to the usual risks such as Fire.</p>

              <p>Coverage is based on stock at your premises but may be extended to follow your stock almost wherever it goes, including:</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Jobbers And Outworkers</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Memo Of Consignment To Other jewellers</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Bank Or Safety Deposit Boxes</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Commission Sales Representatives</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Travel By Principals Or Employees</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Shipments</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Trade Shows And Exhibitions</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Personal Residences</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">While Being Worn</div>
              </div>
              
              <p>The most important exclusions are Employee Dishonesty, Damage to Goods Being Worked Upon and Mysterious Loss or Unexplained Loss. Most other exclusions can be bought back if required or they are "common sense" exclusions.</p>
              
              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="jewellersblkinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"} selected class="active">Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="jewellersblkinsu-mob-main-en">
          <h1 class="jewellersblkinsu-banner-heading-en">Jewellers Block Insurance</h1>
          <div class="jewellersblkinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/jewellers_block_insurance.png"} alt="Jewellers Block Insurance" />
          </div>

          <p class="jewellersblkinsu-mob-txt-en">Jewellers Block Insurance policy covers loss or damage to jewellery, gold and silver ornaments or plates , pearls, precious stones, cash and currency notes whilst contained in the premises insured, by fire, explosion, lightning, burglary, house breaking, theft, hold up, robbery, riot, strike and malicious damage and terrorism.</p>

          <p class="jewellersblkinsu-mob-txt-en">As always, our specialist insurance team would be delighted to answer any questions you have on Jewellers Block Insurance cover.</p>

          <h3 class="jewellersblkinsu-subtitle-en">Main Cover / Jewellers Block Insurance</h3>

          <p class="jewellersblkinsu-mob-txt-en">The <strong>jewellers Block</strong> Policy is an "all risk" coverage, which means that the insurer must specify what is not covered. If a risk is not in the list of exclusions, it is covered. Typical risks that are covered are Burglary, Robbery, Shoplifting, Grab and Run, Trick Loss, Substitution and Accidental Damage, in addition to the usual risks such as Fire.</p>

          <p class="jewellersblkinsu-mob-txt-en">Coverage is based on stock at your premises but may be extended to follow your stock almost wherever it goes, including:</p>

          <div class="jewellersblkinsu-pins-list-mob-en">
            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Liabilities for loss of, or damage to, cargo</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Liabilities arising from errors and omissions including delay and unauthorized delivery</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Jobbers And Outworkers</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Memo Of Consignment To Other jewellers</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Bank Or Safety Deposit Boxes</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Commission Sales Representatives</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Travel By Principals Or Employees</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Shipments</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Trade Shows And Exhibitions</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">Personal Residences</div>

            <div class="jewellersblkinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="jewellersblkinsu-pins-list-mob-item-en">While Being Worn</div>
          </div>

          <p class="jewellersblkinsu-mob-txt-en">The most important exclusions are Employee Dishonesty, Damage to Goods Being Worked Upon and Mysterious Loss or Unexplained Loss. Most other exclusions can be bought back if required or they are "common sense" exclusions.</p>

          <h3 class="jewellersblkinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="jewellersblkinsu-mob-whykrlist-en">
            <div class="jewellersblkinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="jewellersblkinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="jewellersblkinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="jewellersblkinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default JewellersBlockInsurance;