import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './armarineinsurance.scss';

const ARMarineInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="marineinsu-tab-imagebannermain-ar">
          <div class="marineinsu-banner-con-ar">
            <div class="marineinsutab-banne-sec-ar">
              <h1 class="marineinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="marineinsu-tab-btn-ar">
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="marineinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="marineinsu-banner-con-ar">
          <div class="marineinsu-bdy-ar">
            <div class="marineinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="marineinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/marine_insurance_ar.png"} alt="التأمين البحري" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="marineinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين البحري</h2>

                <p class="pg-txt-con-ar">يوفر التأمين البحري تغطية في حال وجود تلف أو فقد في: الشحنة، والسفن، والمركبات، وأي نقل يتم بواسطته اقتناء أي ممتلكات أو نقلها أو الاحتفاظ بها بين موطن المنشأ والمكان المقصود الوصول إليه</p>

                <p class="pg-txt-con-ar">يشمل التأمين البحري: الممتلكات المعرضة إما البرية و/ أو البحرية، إضافة إلى الإصابة البحرية، والمسؤولية البحرية، والأضرار الناجمة في السفينة</p>

                <p class="pg-txt-con-ar">يمكن شراء تأمين الشحن، في حال نقل البضائع باستخدام خدمة البريد السريع</p>

                <p class="pg-txt-con-ar">تقدم  كيمجي رامداس لخدمات التأمين منتجات فريدة من أجل غايتها الوحيدة وهي حماية شحنتك سواء في البحر أو البر أو في الجو</p>

                <p class="pg-txt-con-ar">تتميز كيمجي رامداس لخدمات التأمين بإمكانية الوصول إلى الموارد والخبرة المتعمقة اللازمة للتعامل مع جميع أنواع المسئوليات، مهما كانت معقدة، وتقديم خدمة - بداية من تقييم المخاطر إلى دعم المطالبات - مما يؤكد أنه مهما كان حجم صفقات العميل، يمكن التأكد من الحصول على المستوى العالي من الخدمة الشخصية التي تقتضيها المتطلبات الشخصية</p>

                <p class="pg-txt-con-ar">يمكننا تلبية جميع جوانب التأمين البحري</p>

                <p class="pg-txt-con-ar">تتخطى منتجات شركة كيمجي رامداس لخدمات التأمين المستوى القياسي للتغطية، وتقدمها مجاناً، مع مزيجًا من نوع واحد في شكل أكثر من 30 ملحق تغطية</p>

                <div class="marineinsu-keypoint-ar">
                  <div class="marineinsu-keypoint-item-ar">الشحنة</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">الفنون الجميلة</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">جسم السفينة والآلات</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">فقد الاستئجار</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">الحماية وتقديم الضمان</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">المسؤولية البحرية</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">فدية/ قرصنة</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="marineinsu-keypoint-item-ar">انقسام الرحلات</div>
                  <div class="marineinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="marineinsu-txt-con-ar">تمتلك شركة كيمجي رامداس لخدمات التأمين خدمات فريدة لإدارة المخاطر، حيث توفر حرية الاستشارات لاختيار السفن، وتخزين البضائع،والتغليف. نحن نقدم دراسات للطرق أيضًا  وفقاً لكل حالة على حدة. لدينا الخطوط الهاتفية للمساعدة في حالات الطوارئ والتي تعمل على مدار الساعة طوال أيام الأسبوع، مما يضمن أفضل خدمات تسوية المطالبات لعملائنا</p>
            
            <h3 class="marineinsu-page-sbu-title-ar">التغطية الرئيسية/ التأمين البحري</h3>

            <div class="marineinsu-pins-list-dsk-ar">
              <div class="marineinsu-pins-list-dsk-item-ar">في حال الحريق، أو الانفجار، أو جنوح السفن، أو التعرض للغرق، وما غير ذلك- فإنه لا يمكن التنبؤ بهذه المواقف مسبقًا. فيعد الحريق أو الانفجار، أو الغرق، أو جنوح السفن هي مشكلات شائعة يمكن التعرض لها أثناء رحلة الشحن. وصيغت خطة للتأمين البحري لمعالجة هذه الأوضاع الصعبة</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">الاصطدام، أو الانقلاب، أو الانحراف عن مسار النقل البري - في بعض الأحيان، بغض النظر عن مدى صعوبة محاولات القبطان، فإنه لا يمكنه السيطرة على الضرر غير المواتي المسبب لهذه المواقف. إن وجود خطة تأمين بحرية يبطل مخاطر الأضرار الناجمة عن الاصطدام، أو انقلاب، أو انحراف وسائل النقل البري عن مسارها</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">تفريغ الشحنة في ميناء الإغاثة - الشحنة المنطلقة من ميناء الاضطراب أو الإغاثة، فإن ذلك يهدد سلامة الشحنة. وهذا هو سبب إدراجه في التغطية التأمينية الأساسية</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">المتوسط العام لرسوم إنقاذ التضحية - توفر خطة التأمين البحري تغطية للمتوسط العام لرسوم إنقاذ التضحية</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">التكاليف مثل رسوم الاستطلاعات، وتكاليف الشحن، وتكاليف التجديد، ورسوم المقاضاة - تعد قيمة هذه التكاليف، غير متوقعة في بعض الأحيان مما يتيح لك القليل من الوقت لدفع هذه التكاليف. هذا هو السبب في أن وجود خطة تأمين بحرية، يعد أمر مفيد للغاية</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">تخفيف حمولة السفينة، أوطغيان الأمواج فوق سطح السفينة - فإنه تتضمن خطة التأمين البحري حالات تخفيف حمولة السفينة، وطغيان الأمواج فوق سطح السقينة جميعها</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">الزلزال أو البرق - توفر لك خطة التأمين البحري تغطية من الكوارث الطبيعية مثل الزلزال أو البرق</div>

              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="marineinsu-pins-list-dsk-item-ar">فقدان كامل للحمولة، بسقوطها من على متن السفينة، لمفقودة في البحر أو سقوطها عند التحميل أو التفريغ - بغض النظر عن مدى إزعاج فقدان الحمولة، فإنه لا يمكنك في كل مرة إنشاء خطة مضمونة لمنع هذا الموقف عند نقل الحمولة الخاصة بك في الشحنة. لهذا السبب فإنه تتضمن خطة التأمين البحري الأساسية ذلك الأمر</div>
              <div class="marineinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            <h3 class="marineinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>

            <div class="marineinsu-pins-list-lft-ar">
              <div class="marineinsu-lt-two-lft-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="marineinsu-lt-one-lft-ar">Lأقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="marineinsu-lt-two-lft-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="marineinsu-lt-one-lft-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="marineinsu-tab-imagebannermain-ar">
          <div class="marineinsu-banner-con-ar">
            <div class="marineinsutab-banne-sec-ar">
              <h1 class="marineinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="marineinsu-tab-btn-ar">
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="marineinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="marineinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="marineinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين البحري</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/marine_insurance_ar.png"} alt="التأمين البحري" />
              
              <p>يوفر التأمين البحري تغطية في حال وجود تلف أو فقد في: الشحنة، والسفن، والمركبات، وأي نقل يتم بواسطته اقتناء أي ممتلكات أو نقلها أو الاحتفاظ بها بين موطن المنشأ والمكان المقصود الوصول إليه</p>
              
              <p>يشمل التأمين البحري: الممتلكات المعرضة إما البرية و/ أو البحرية، إضافة إلى الإصابة البحرية، والمسؤولية البحرية، والأضرار الناجمة في السفينة</p>
              
              <p>يمكن شراء تأمين الشحن، في حال نقل البضائع باستخدام خدمة البريد السريع</p>
              
              <p>تقدم  كيمجي رامداس لخدمات التأمين منتجات فريدة من أجل غايتها الوحيدة وهي حماية شحنتك سواء في البحر أو البر أو في الجو.</p>
              
              <p>تتميز كيمجي رامداس لخدمات التأمين بإمكانية الوصول إلى الموارد والخبرة المتعمقة اللازمة للتعامل مع جميع أنواع المسئوليات، مهما كانت معقدة، وتقديم خدمة - بداية من تقييم المخاطر إلى دعم المطالبات - مما يؤكد أنه مهما كان حجم صفقات العميل، يمكن التأكد من الحصول على المستوى العالي من الخدمة الشخصية التي تقتضيها المتطلبات الشخصية</p>
              
              <p>يمكننا تلبية جميع جوانب التأمين البحري</p>

              <p>تتخطى منتجات شركة كيمجي رامداس لخدمات التأمين المستوى القياسي للتغطية، وتقدمها مجاناً، مع مزيجًا من نوع واحد في شكل أكثر من 30 ملحق تغطية</p>
              
              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">الشحنة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الفنون الجميلة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">جسم السفينة والآلات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">فقد الاستئجار</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الحماية وتقديم الضمان</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المسؤولية البحرية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">فدية/ قرصنة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">انقسام الرحلات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <h3 class="tab-sub-title-ar">التغطية الرئيسية/ التأمين البحري</h3>
              
              <p>تمتلك شركة كيمجي رامداس لخدمات التأمين خدمات فريدة لإدارة المخاطر، حيث توفر حرية الاستشارات لاختيار السفن، وتخزين البضائع،والتغليف. نحن نقدم دراسات للطرق أيضًا  وفقاً لكل حالة على حدة. لدينا الخطوط الهاتفية للمساعدة في حالات الطوارئ والتي تعمل على مدار الساعة طوال أيام الأسبوع، مما يضمن أفضل خدمات تسوية المطالبات لعملائنا</p>
              
              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">في حال الحريق، أو الانفجار، أو جنوح السفن، أو التعرض للغرق، وما غير ذلك- فإنه لا يمكن التنبؤ بهذه المواقف مسبقًا. فيعد الحريق أو الانفجار، أو الغرق، أو جنوح السفن هي مشكلات شائعة يمكن التعرض لها أثناء رحلة الشحن. وصيغت خطة للتأمين البحري لمعالجة هذه الأوضاع الصعبة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الاصطدام، أو الانقلاب، أو الانحراف عن مسار النقل البري - في بعض الأحيان، بغض النظر عن مدى صعوبة محاولات القبطان، فإنه لا يمكنه السيطرة على الضرر غير المواتي المسبب لهذه المواقف. إن وجود خطة تأمين بحرية يبطل مخاطر الأضرار الناجمة عن الاصطدام، أو انقلاب، أو انحراف وسائل النقل البري عن مسارها</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تفريغ الشحنة في ميناء الإغاثة - الشحنة المنطلقة من ميناء الاضطراب أو الإغاثة، فإن ذلك يهدد سلامة الشحنة. وهذا هو سبب إدراجه في التغطية التأمينية الأساسية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المتوسط العام لرسوم إنقاذ التضحية - توفر خطة التأمين البحري تغطية للمتوسط العام لرسوم إنقاذ التضحية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">التكاليف مثل رسوم الاستطلاعات، وتكاليف الشحن، وتكاليف التجديد، ورسوم المقاضاة - تعد قيمة هذه التكاليف، غير متوقعة في بعض الأحيان مما يتيح لك القليل من الوقت لدفع هذه التكاليف. هذا هو السبب في أن وجود خطة تأمين بحرية، يعد أمر مفيد للغاية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تخفيف حمولة السفينة، أوطغيان الأمواج فوق سطح السفينة - فإنه تتضمن خطة التأمين البحري حالات تخفيف حمولة السفينة، وطغيان الأمواج فوق سطح السقينة جميعها</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">الزلزال أو البرق - توفر لك خطة التأمين البحري تغطية من الكوارث الطبيعية مثل الزلزال أو البرق</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">فقدان كامل للحمولة، بسقوطها من على متن السفينة، لمفقودة في البحر أو سقوطها عند التحميل أو التفريغ - بغض النظر عن مدى إزعاج فقدان الحمولة، فإنه لا يمكنك في كل مرة إنشاء خطة مضمونة لمنع هذا الموقف عند نقل الحمولة الخاصة بك في الشحنة. لهذا السبب فإنه تتضمن خطة التأمين البحري الأساسية ذلك الأمر</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">Lأقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="marineinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"} selected class="active">التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="marineinsu-mob-main-ar">
          <h1 class="marineinsu-banner-heading-ar">التأمين البحري</h1>
          <div class="marineinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/marine_insurance_ar.png"} alt="التأمين البحري" />
          </div>

          <p class="marineinsu-mob-txt-ar">يوفر التأمين البحري تغطية في حال وجود تلف أو فقد في: الشحنة، والسفن، والمركبات، وأي نقل يتم بواسطته اقتناء أي ممتلكات أو نقلها أو الاحتفاظ بها بين موطن المنشأ والمكان المقصود الوصول إليه.</p>

          <p class="marineinsu-mob-txt-ar">يشمل التأمين البحري: الممتلكات المعرضة إما البرية و/ أو البحرية، إضافة إلى الإصابة البحرية، والمسؤولية البحرية، والأضرار الناجمة في السفينة</p>

          <p class="marineinsu-mob-txt-ar">يمكن شراء تأمين الشحن، في حال نقل البضائع باستخدام خدمة البريد السريع</p>

          <p class="marineinsu-mob-txt-ar">تقدم  كيمجي رامداس لخدمات التأمين منتجات فريدة من أجل غايتها الوحيدة وهي حماية شحنتك سواء في البحر أو البر أو في الجو</p>

          <p class="marineinsu-mob-txt-ar">تتميز كيمجي رامداس لخدمات التأمين بإمكانية الوصول إلى الموارد والخبرة المتعمقة اللازمة للتعامل مع جميع أنواع المسئوليات، مهما كانت معقدة، وتقديم خدمة - بداية من تقييم المخاطر إلى دعم المطالبات - مما يؤكد أنه مهما كان حجم صفقات العميل، يمكن التأكد من الحصول على المستوى العالي من الخدمة الشخصية التي تقتضيها المتطلبات الشخصية</p>

          <p class="marineinsu-mob-txt-ar">يمكننا تلبية جميع جوانب التأمين البحري</p>

          <div class="marineinsu-pins-list-mob-ar">
            <div class="marineinsu-pins-list-mob-item-ar">الشحنة</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">الفنون الجميلة</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">جسم السفينة والآلات</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">فقد الاستئجار</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">الحماية وتقديم الضمان</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">المسؤولية البحرية</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">فدية/ قرصنة</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">انقسام الرحلات</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>


          <p class="marineinsu-mob-txt-ar">تمتلك شركة كيمجي رامداس لخدمات التأمين خدمات فريدة لإدارة المخاطر، حيث توفر حرية الاستشارات لاختيار السفن، وتخزين البضائع،والتغليف. نحن نقدم دراسات للطرق أيضًا  وفقاً لكل حالة على حدة. لدينا الخطوط الهاتفية للمساعدة في حالات الطوارئ والتي تعمل على مدار الساعة طوال أيام الأسبوع، مما يضمن أفضل خدمات تسوية المطالبات لعملائنا</p>

          <h3 class="marineinsu-subtitle-ar">التغطية الرئيسية/ التأمين البحري</h3>
          
          <p class="marineinsu-mob-txt-ar">تتخطى منتجات شركة كيمجي رامداس لخدمات التأمين المستوى القياسي للتغطية، وتقدمها مجاناً، مع مزيجًا من نوع واحد في شكل أكثر من 30 ملحق تغطية</p>

          <div class="marineinsu-pins-list-mob-ar">
            <div class="marineinsu-pins-list-mob-item-ar">في حال الحريق، أو الانفجار، أو جنوح السفن، أو التعرض للغرق، وما غير ذلك- فإنه لا يمكن التنبؤ بهذه المواقف مسبقًا. فيعد الحريق أو الانفجار، أو الغرق، أو جنوح السفن هي مشكلات شائعة يمكن التعرض لها أثناء رحلة الشحن. وصيغت خطة للتأمين البحري لمعالجة هذه الأوضاع الصعبة</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">الاصطدام، أو الانقلاب، أو الانحراف عن مسار النقل البري - في بعض الأحيان، بغض النظر عن مدى صعوبة محاولات القبطان، فإنه لا يمكنه السيطرة على الضرر غير المواتي المسبب لهذه المواقف. إن وجود خطة تأمين بحرية يبطل مخاطر الأضرار الناجمة عن الاصطدام، أو انقلاب، أو انحراف وسائل النقل البري عن مسارها</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">تفريغ الشحنة في ميناء الإغاثة - الشحنة المنطلقة من ميناء الاضطراب أو الإغاثة، فإن ذلك يهدد سلامة الشحنة. وهذا هو سبب إدراجه في التغطية التأمينية الأساسية</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">المتوسط العام لرسوم إنقاذ التضحية - توفر خطة التأمين البحري تغطية للمتوسط العام لرسوم إنقاذ التضحية</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">التكاليف مثل رسوم الاستطلاعات، وتكاليف الشحن، وتكاليف التجديد، ورسوم المقاضاة - تعد قيمة هذه التكاليف، غير متوقعة في بعض الأحيان مما يتيح لك القليل من الوقت لدفع هذه التكاليف. هذا هو السبب في أن وجود خطة تأمين بحرية، يعد أمر مفيد للغاية</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">تخفيف حمولة السفينة، أوطغيان الأمواج فوق سطح السفينة - فإنه تتضمن خطة التأمين البحري حالات تخفيف حمولة السفينة، وطغيان الأمواج فوق سطح السقينة جميعها</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">الزلزال أو البرق - توفر لك خطة التأمين البحري تغطية من الكوارث الطبيعية مثل الزلزال أو البرق</div>

            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="marineinsu-pins-list-mob-item-ar">فقدان كامل للحمولة، بسقوطها من على متن السفينة، لمفقودة في البحر أو سقوطها عند التحميل أو التفريغ - بغض النظر عن مدى إزعاج فقدان الحمولة، فإنه لا يمكنك في كل مرة إنشاء خطة مضمونة لمنع هذا الموقف عند نقل الحمولة الخاصة بك في الشحنة. لهذا السبب فإنه تتضمن خطة التأمين البحري الأساسية ذلك الأمر</div>
            <div class="marineinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 class="marineinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="marineinsu-mob-whykrlist-ar">
            <div class="marineinsu-mob-whykrlist-item-ar">Lأقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="marineinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="marineinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="marineinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARMarineInsurance;