import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arrelocationinsurance.scss';

const ARRelocationInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
     <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="relocationinsu-tab-imagebannermain-ar">
          <div class="relocationinsu-banner-con-ar">
            <div class="relocationinsutab-banne-sec-ar">
              <h1 class="relocationinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="relocationinsu-tab-btn-ar">
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="relocationinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="relocationinsu-banner-con-ar">
          <div class="relocationinsu-bdy-ar">
            <div class="relocationinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="relocationinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance_ar.png"} alt="relocation insurance" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="relocationinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين علي الانتقال </h2>
                <p class="pg-txt-con-ar">هل حان الوقت للمغادرة؟ هل ستنتقل إلى مكان جديد؟ هل هذا المكان في مدينة جديدة أم أنك تخطط للانتقال إلى بلد آخر أو مجرد الانتقال إلى منزل جديد في آخر الشارع؟ لابد أنك قلق بشأن ممتلكاتك الثمينة كيف تضمن ألا يحدث لها شيء غير مرغوب فيه؟ <br /><br /></p> 

                <p class="pg-txt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين برامج تأمين مصممة خصيصاً لهذا الغرض، أي "تأمين الانتقال"، ويحمي برنامج تغطية التأمين الخاص بالنقل أصولك وتحميها من اللحظة التي تبدأ فيها بحزم حقائبك إلى الوقت الذي تصل فيه إلى منزلك الجديد وتفرغها <br /><br /></p>
                <h3 class="relocationinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين الانتقال </h3>

                <p class="pg-txt-con-ar">برنامج تغطية النقل هو برنامج فريد من نوعه يوفر فوائد تختلف عن البرامج المتوفرة في السوق، حيث تتوفر خيارات مختلفة للاختيار من بينها حسب العبوة المطلوبة</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            {/* QUESTION SECTION STARTS HERE */}
            <div class="relocationinsu-keyfeatures-ar">
              <p class="relocationinsu-keyfeatures-q-ar">الشروط الكاملة – الأمتعة المعبأة باحترافية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>

              <p class="relocationinsu-keyfeatures-q-ar">الشروط المقيدة – الأمتعة المعبأة من قِبل المالك <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>

              <p class="relocationinsu-keyfeatures-q-ar">تغطية للمركبات الآلية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>

              <p class="relocationinsu-keyfeatures-q-ar">ملحقات خاصة - للأمتعة الشخصية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>

              <p class="relocationinsu-keyfeatures-q-ar">امتدادات التخزين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
            </div>
            {/* QUESTION SECTION ENDS HERE */}
            
            <h3 class="relocationinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              <div class="clearfix"></div>

              <div class="relocationinsu-pins-list-ar">
              <div class="relocationinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="relocationinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="relocationinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="relocationinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>

            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div class="tab-btn-viewmode-ar">
        <div class="relocationinsu-tab-imagebannermain-ar">
          <div class="relocationinsu-banner-con-ar">
            <div class="relocationinsutab-banne-sec-ar">
              <h1 class="relocationinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="relocationinsu-tab-btn-ar">
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="relocationinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="relocationinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="relocationinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين علي الانتقال </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance_ar.png"} alt="relocation insurance" />
              <p>هل حان الوقت للمغادرة؟ هل ستنتقل إلى مكان جديد؟ هل هذا المكان في مدينة جديدة أم أنك تخطط للانتقال إلى بلد آخر أو مجرد الانتقال إلى منزل جديد في آخر الشارع؟ لابد أنك قلق بشأن ممتلكاتك الثمينة كيف تضمن ألا يحدث لها شيء غير مرغوب فيه؟ </p>
              
              <p>تقدم شركة كيمجي رامداس لخدمات التأمين برامج تأمين مصممة خصيصاً لهذا الغرض، أي "تأمين الانتقال"، ويحمي برنامج تغطية التأمين الخاص بالنقل أصولك وتحميها من اللحظة التي تبدأ فيها بحزم حقائبك إلى الوقت الذي تصل فيه إلى منزلك الجديد وتفرغها.</p>

              <h3 class="motorinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين الانتقال </h3>
              
              <p>برنامج تغطية النقل هو برنامج فريد من نوعه يوفر فوائد تختلف عن البرامج المتوفرة في السوق، حيث تتوفر خيارات مختلفة للاختيار من بينها حسب العبوة المطلوبة</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">الشروط الكاملة – الأمتعة المعبأة باحترافية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">الشروط المقيدة – الأمتعة المعبأة من قِبل المالك</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية للمركبات الآلية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">ملحقات خاصة - للأمتعة الشخصية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">امتدادات التخزين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

            <h3 class="motorinsu-page-sbu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
            <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="relocationinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"} selected class="active">التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="relocationinsu-mob-main-ar">
          <h1 class="relocationinsu-banner-heading-ar">التأمين علي الانتقال </h1>
          <div class="relocationinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance_ar.png"} alt="relocation insurance" />
          </div>
          <p class="relocationinsu-mob-txt-ar">هل حان الوقت للمغادرة؟ هل ستنتقل إلى مكان جديد؟ هل هذا المكان في مدينة جديدة أم أنك تخطط للانتقال إلى بلد آخر أو مجرد الانتقال إلى منزل جديد في آخر الشارع؟ لابد أنك قلق بشأن ممتلكاتك الثمينة كيف تضمن ألا يحدث لها شيء غير مرغوب فيه؟ </p>

          <p class="relocationinsu-mob-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين برامج تأمين مصممة خصيصاً لهذا الغرض، أي "تأمين الانتقال"، ويحمي برنامج تغطية التأمين الخاص بالنقل أصولك وتحميها من اللحظة التي تبدأ فيها بحزم حقائبك إلى الوقت الذي تصل فيه إلى منزلك الجديد وتفرغها</p>

          <h3 class="relocationinsu-subtitle-ar">التغطية الرئيسية / تأمين الانتقال </h3>

          <p class="relocationinsu-mob-txt-ar">برنامج تغطية النقل هو برنامج فريد من نوعه يوفر فوائد تختلف عن البرامج المتوفرة في السوق، حيث تتوفر خيارات مختلفة للاختيار من بينها حسب العبوة المطلوبة</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="relocationinsu-mob-keyfeatures-ar">
            <div class="relocationinsu-mob-keyfeatures-q-ar">الشروط الكاملة – الأمتعة المعبأة باحترافية</div>

            <div class="relocationinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="relocationinsu-mob-keyfeatures-q-ar">الشروط المقيدة – الأمتعة المعبأة من قِبل المالك</div>

            <div class="relocationinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="relocationinsu-mob-keyfeatures-q-ar">تغطية للمركبات الآلية</div>

            <div class="relocationinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="relocationinsu-mob-keyfeatures-q-ar">ملحقات خاصة - للأمتعة الشخصية</div>

            <div class="relocationinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="relocationinsu-mob-keyfeatures-q-ar">امتدادات التخزين</div>
            <div class="relocationinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 class="relocationinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div class="relocationinsu-mob-whykrlist-ar">
                <div class="relocationinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="relocationinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="relocationinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="relocationinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARRelocationInsurance;