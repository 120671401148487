import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './personalaccident.scss';

const PersonalAccident = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
     <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="personal-accinsu-tab-imagebannermain-en">
          <div class="personal-accinsu-banner-con-en">
            <div class="personal-accinsutab-banne-sec-en">
              <h1 class="personal-accinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="personal-accinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="personal-accinsu-banner-con-en">
          <div class="personal-accinsu-bdy-en">
            <div class="personal-accinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="personal-accinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Personal Accident Insurance</h2>

                <p class="pg-txt-con">Life is full of uncertainties, and any unfortunate circumstances can have a severe life-altering impact. You can secure the welfare of your family with the help of KR Insurance Services and prepare a safety net for your loved ones by ensuring their financial well-being.</p>

                <p class="pg-txt-con">An accident tends to trigger many expenses which might have never crossed your mind. However, incurring these costs becomes a necessity at that point in time. Do you believe that you are well equipped to handle them?</p>

                <p class="pg-txt-con">Get covered against accidental death and disability with personal accident insurance. Purchase the best plan at KR insurance services and save your medical expenses.</p>

                <div class="peraccinsu-tab-lifinsu-en">
                  <p class="peraccinsu-tab-lifinsu-item-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                      Compare Accidental Plans from Top Insurers.</p>
                  <p class="peraccinsu-tab-lifinsu-item-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    Lowest Premium on Personal Accident Cover.</p>
                  <p class="peraccinsu-tab-lifinsu-item-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    Instant Personal Accident Policy.</p>
                </div>
                    <h3 class="personal-accinsu-page-sbu-title-en">Main Cover / Personal Accident Insurance</h3>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="personal-accinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/personal_insurance.png"} alt="personal insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
              <p class="personal-accinsu-txt-con"><strong>Enjoy the following advantages under this insurance policy -</strong></p>
                <div class="clearfix"></div>
              {/* QUESTION SECTION STARTS HERE */}
              <div class="personal-accinsu-dsk-keyfeatures-en">
                <div class="personal-accinsu-dsk-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-en"><strong>Accidental death:</strong> The benefits under this plan is payable if demise occurs from a mishap.</div>

                <div class="personal-accinsu-dsk-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-en"><strong>Accidental disability:</strong> It indicates that the policyholder is disabled either partially or wholly.</div>
                

              <div class="personal-accinsu-dsk-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-en"><strong>Accidental dismemberment:</strong> It indicates that a part of the policyholder's body has been severed or dismembered. It means, if the policyholder loses his hand or leg or eyes in a mishap, then he would be eligible to get a claim under dismemberment.</div>
                

              <div class="personal-accinsu-dsk-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-en"><strong>Terrorism act:</strong> Injuries afflicted because of terrorist acts are also included.</div>
                

              <div class="personal-accinsu-dsk-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-en"><strong>Daily payment during hospitalization:</strong> It gives monetary relief to a patient while he undergoes cure at the hospital.</div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 class="personal-accinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>
            <div class="personal-accinsu-pins-list-en">
              <div class="personal-accinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="personal-accinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="personal-accinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="personal-accinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
      <div class="personal-accinsu-tab-imagebannermain-en">
          <div class="personal-accinsu-banner-con-en">
            <div class="personal-accinsutab-banne-sec-en">
              <h1 class="personal-accinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="personal-accinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="personal-accinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Personal Accident Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/personal_insurance.png"} alt="personal insurance" />

              <p>Life is full of uncertainties, and any unfortunate circumstances can have a severe life-altering impact. You can secure the welfare of your family with the help of KR Insurance Services and prepare a safety net for your loved ones by ensuring their financial well-being.</p>
              <p>An accident tends to trigger many expenses which might have never crossed your mind. However, incurring these costs becomes a necessity at that point in time. Do you believe that you are well equipped to handle them?</p>
              <p>Get covered against accidental death and disability with personal accident insurance. Purchase the best plan at KR insurance services and save your medical expenses.</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Compare Accidental Plans from Top Insurers.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Lowest Premium on Personal Accident Cover.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Instant Personal Accident Policy.</div>
              </div>
              
              <h3 class="motorinsu-page-sbu-title-en">Main Cover / Personal Accident Insurance</h3>

              <p><strong>Enjoy the following advantages under this insurance policy -</strong></p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en"><strong>Accidental death:</strong> The benefits under this plan is payable if demise occurs from a mishap.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en"><strong>Accidental disability:</strong> It indicates that the policyholder is disabled either partially or wholly.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en"><strong>Accidental dismemberment:</strong> It indicates that a part of the policyholder's body has been severed or dismembered. It means, if the policyholder loses his hand or leg or eyes in a mishap, then he would be eligible to get a claim under dismemberment.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en"><strong>Terrorism act:</strong> Injuries afflicted because of terrorist acts are also included.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en"><strong>Daily payment during hospitalization:</strong> It gives monetary relief to a patient while he undergoes cure at the hospital.</div>
              </div>

              <h3 class="motorinsu-page-sbu-title-en">Why KR Insurance Services ?</h3>

              <div class="tab-whykrinsu-pins-list-en">
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
              <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
              <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="personal-accinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"} selected class="active">Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="personal-accinsu-mob-main-en">
        <h1 class="personal-accinsu-banner-heading-en">Personal Accident Insurance</h1>
              <div class="personal-accinsu-mob-img-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/personal_insurance.png"} alt="personal insurance" />
              </div>
              <p class="personal-accinsu-mob-txt-en">Life is full of uncertainties, and any unfortunate circumstances can have a severe life-altering impact. You can secure the welfare of your family with the help of KR Insurance Services and prepare a safety net for your loved ones by ensuring their financial well-being.</p>

              <p class="personal-accinsu-mob-txt-en">An accident tends to trigger many expenses which might have never crossed your mind. However, incurring these costs becomes a necessity at that point in time. Do you believe that you are well equipped to handle them?</p>

              <p class="personal-accinsu-mob-txt-en">Get covered against accidental death and disability with personal accident insurance. Purchase the best plan at KR insurance services and save your medical expenses.</p>
              
              <div class="personal-accinsu-tbpoints-en">
                <div class="personal-accinsu-tbpoints-imgitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-tbpoints-item-en">Compare Accidental Plans from Top Insurers.</div><div class="personal-accinsu-tbpoints-imgitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-tbpoints-item-en">Lowest Premium on Personal Accident Cover.</div>
                <div class="personal-accinsu-tbpoints-imgitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-tbpoints-item-en">Instant Personal Accident Policy.</div>
                </div>
                
                <h3 class="personal-accinsu-subtitle-en">Main Cover / Personal Accident Insurance</h3>

              <p class="personal-accinsu-mob-txt-en"><strong>Enjoy the following advantages under this insurance policy -</strong></p>

              {/* QUESTION SECTION STARTS HERE */}
              <div class="personal-accinsu-mob-keyfeatures-en">
                <div class="personal-accinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-en"><strong>Accidental death:</strong> The benefits under this plan is payable if demise occurs from a mishap.</div>

                <div class="personal-accinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-en"><strong>Accidental disability:</strong> It indicates that the policyholder is disabled either partially or wholly.</div>
                

              <div class="personal-accinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-en"><strong>Accidental dismemberment:</strong> It indicates that a part of the policyholder's body has been severed or dismembered. It means, if the policyholder loses his hand or leg or eyes in a mishap, then he would be eligible to get a claim under dismemberment.</div>
                

              <div class="personal-accinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-en"><strong>Terrorism act:</strong> Injuries afflicted because of terrorist acts are also included.</div>
                

              <div class="personal-accinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-en"><strong>Daily payment during hospitalization:</strong> It gives monetary relief to a patient while he undergoes cure at the hospital.</div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}
            <h3 class="personal-accinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

              <div class="personal-accinsu-mob-whykrlist-en">
                <div class="personal-accinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                <div class="personal-accinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                <div class="personal-accinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                <div class="personal-accinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
              </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default PersonalAccident;