import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arburglaryinsurance.scss';

const ARBurglaryInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="burglaryinsu-tab-imagebannermain-ar">
          <div class="burglaryinsu-banner-con-ar">
            <div class="burglaryinsutab-banne-sec-ar">
              <h1 class="burglaryinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="burglaryinsu-tab-btn-ar">
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="burglaryinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="burglaryinsu-banner-con-ar">
          <div class="burglaryinsu-bdy-ar">
            <div class="burglaryinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="burglaryinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/burglary_insurance_ar.png"} alt="التأمين ضد السرقة" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="burglaryinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين ضد السرقة</h2>
                <p class="burglaryinsu-pg-txt-con-ar">توفر هذه البوليصة تأمين للممتلكات الموجودة في المباني التجارية، والأسهم المملوكة لك أو الموجود في حوذتك و/ أو العمولة. يمكن تمديده ليشمل النقد والأشياء الثمينة والأوراق المالية المحفوظة في خزنة مقفلة أو صندوق نقدي في خزانة معدنية مقفلة. تقدم شركة كيمجي رامداس لخدمات التأمين شروط تأمين أوسع ضد عمليات السطو بالسعر المناسب لعملائها <br /><br /></p>

                <p class="burglaryinsu-txt-con-ar">توفر البوليصة تغطية للحالات التالية<br /></p>

                <div class="burglaryinsu-keypoint-ar">
                  <div class="burglaryinsu-keypoint-item-ar">فقدان أو تلف الممتلكات المؤمن عليها بسبب السطو و/ أو اقتحام المنزل</div>
                  <div class="burglaryinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  
                  <div class="burglaryinsu-keypoint-item-ar">الأضرار التي لحقت بالمباني بسبب اللصوص أثناء السطو أو محاولات السطو، تدفع البوليصة تعويضًا عن الخسارة / الضرر الفعلي للممتلكات المؤمن عليها بسبب السطو/ اقتحام المنزل مع مراعاة حد المبلغ المؤمن عليه</div>
                  <div class="burglaryinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="burglaryinsu-kpnt-ar">
              <div class="burglaryinsu-kpnt-item-ar">إذا كان مبلغ التأمين غير كافٍ، فإن البوليصة تدفع فقط الخسارة المتناسبة معه. يوجد أيضًا بند في البوليصة خاص بتغطية المواد السائبة على أساس "الخسارة الأولى"، حيث يمكن اعتبار نسبة من إجمالي المخزون المخزن على أنها معرضة لخطر السطو واقتحام المنازل. يتم احتساب القسط على هذه النسبة المحددة فقط. يتم تحميل قسط رمزي على رصيد المخزون</div>
              <div class="burglaryinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-ar">يمكن أن تمتد البوليصة لتشمل أعمال الشغب والإضراب والأضرار الكيدية والسرقة علاوة على ذلك، يمكن إصدار البوليصات على أساس الإعلان وعلى أساس الممتلكات الشخصية غير المجدولة</div>
              <div class="burglaryinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <p class="burglaryinsu-txt-con-ar">الاستثناءات الرئيسية، لن تدفع البوليصة مقابل الخسارة أو الضرر فيما يتعلق بما يلي</p>


            <div class="burglaryinsu-kpnt-ar">
              <div class="burglaryinsu-kpnt-item-ar">البضائع المحتفظ بها في الأمانات/ العمولات، والمبالغ النقدية، والمجوهرات، والتحف، وسندات الملكية، ودفاتر الأعمال (ما لم تكن مؤمنة على وجه التحديد)</div>
              <div class="burglaryinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-ar">أعمال السرقة من المتاجر التي تشترك فيها أنت أو أفراد عائلتك أو موظفوك</div>
              <div class="burglaryinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-ar">مخاطر الحرب وأعمال الشغب والإضراب (التي يغطيها دفع قسط إضافي) والكوارث الطبيعية والأخطار النووية</div>
              <div class="burglaryinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="burglaryinsu-kpnt-item-ar">الأشياء المسروقة من الخزنة باستخدام مفتاح أو مفتاح منسوخ، ما لم يتم الحصول عليه عن طريق العنف أو التهديد</div>
              <div class="burglaryinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            <h3 class="burglaryinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>

            <div class="burglaryinsu-pins-list-lftdksp-ar">
              <div class="burglaryinsu-lt-two-lftdksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="burglaryinsu-lt-one-lftdksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="burglaryinsu-lt-two-lftdksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="burglaryinsu-lt-one-lftdksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="burglaryinsu-tab-imagebannermain-ar">
          <div class="burglaryinsu-banner-con-ar">
            <div class="burglaryinsutab-banne-sec-ar">
              <h1 class="burglaryinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="burglaryinsu-tab-btn-ar">
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="burglaryinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="burglaryinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="burglaryinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين ضد السرقة</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/burglary_insurance_ar.png"} alt="التأمين ضد السرقة" />

              <p>توفر هذه البوليصة تأمين للممتلكات الموجودة في المباني التجارية، والأسهم المملوكة لك أو الموجود في حوذتك و/ أو العمولة. يمكن تمديده ليشمل النقد والأشياء الثمينة والأوراق المالية المحفوظة في خزنة مقفلة أو صندوق نقدي في خزانة معدنية مقفلة. تقدم شركة كيمجي رامداس لخدمات التأمين شروط تأمين أوسع ضد عمليات السطو بالسعر المناسب لعملائها</p>

              <p>توفر البوليصة تغطية للحالات التالية</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">فقدان أو تلف الممتلكات المؤمن عليها بسبب السطو و/ أو اقتحام المنزل</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">الأضرار التي لحقت بالمباني بسبب اللصوص أثناء السطو أو محاولات السطو، تدفع البوليصة تعويضًا عن الخسارة / الضرر الفعلي للممتلكات المؤمن عليها بسبب السطو/ اقتحام المنزل مع مراعاة حد المبلغ المؤمن عليه</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">إذا كان مبلغ التأمين غير كافٍ، فإن البوليصة تدفع فقط الخسارة المتناسبة معه. يوجد أيضًا بند في البوليصة خاص بتغطية المواد السائبة على أساس "الخسارة الأولى"، حيث يمكن اعتبار نسبة من إجمالي المخزون المخزن على أنها معرضة لخطر السطو واقتحام المنازل. يتم احتساب القسط على هذه النسبة المحددة فقط. يتم تحميل قسط رمزي على رصيد المخزون</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">يمكن أن تمتد البوليصة لتشمل أعمال الشغب والإضراب والأضرار الكيدية والسرقة. علاوة على ذلك، يمكن إصدار البوليصات على أساس الإعلان وعلى أساس الممتلكات الشخصية غير المجدولة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <p>الاستثناءات الرئيسية، لن تدفع البوليصة مقابل الخسارة أو الضرر فيما يتعلق بما يلي</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">البضائع المحتفظ بها في الأمانات/ العمولات، والمبالغ النقدية، والمجوهرات، والتحف، وسندات الملكية، ودفاتر الأعمال (ما لم تكن مؤمنة على وجه التحديد)</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.سرقة المتاجر، أو الأفعال التي تتكبد فيها الخسارة بنفسك أو أفراد عائلتك / موظفيكsvg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">سرقة المتاجر، أو الأفعال التي تتكبد فيها الخسارة بنفسك أو أفراد عائلتك / موظفيك</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">مخاطر الحرب وأعمال الشغب والإضراب (التي يغطيها دفع قسط إضافي) والكوارث الطبيعية والأخطار النووية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">الأشياء المسروقة من الخزنة باستخدام مفتاح أو مفتاح منسوخ، ما لم يتم الحصول عليه عن طريق العنف أو التهديد</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="burglaryinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"} selected class="active">التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="burglaryinsu-mob-main-ar">
          <h1 class="burglaryinsu-banner-heading-ar">التأمين ضد السرقة</h1>
          <div class="burglaryinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/burglary_insurance_ar.png"} alt="التأمين ضد السرقة" />
          </div>

          <p class="burglaryinsu-mob-txt-ar">توفر هذه البوليصة تأمين للممتلكات الموجودة في المباني التجارية، والأسهم المملوكة لك أو الموجود في حوذتك و/ أو العمولة. يمكن تمديده ليشمل النقد والأشياء الثمينة والأوراق المالية المحفوظة في خزنة مقفلة أو صندوق نقدي في خزانة معدنية مقفلة. تقدم شركة كيمجي رامداس لخدمات التأمين شروط تأمين أوسع ضد عمليات السطو بالسعر المناسب لعملائها</p>

          <p class="burglaryinsu-mob-txt-ar">توفر البوليصة تغطية للحالات التالية</p>


          <div class="burglaryinsu-pins-list-mob-ar">
            <div class="burglaryinsu-pins-list-mob-item-ar">فقدان أو تلف الممتلكات المؤمن عليها بسبب السطو و/ أو اقتحام المنزل</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-ar">الأضرار التي لحقت بالمباني بسبب اللصوص أثناء السطو أو محاولات السطو، تدفع البوليصة تعويضًا عن الخسارة / الضرر الفعلي للممتلكات المؤمن عليها بسبب السطو/ اقتحام المنزل مع مراعاة حد المبلغ المؤمن عليه</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-ar">إذا كان مبلغ التأمين غير كافٍ، فإن البوليصة تدفع فقط الخسارة المتناسبة معه. يوجد أيضًا بند في البوليصة خاص بتغطية المواد السائبة على أساس "الخسارة الأولى"، حيث يمكن اعتبار نسبة من إجمالي المخزون المخزن على أنها معرضة لخطر السطو واقتحام المنازل. يتم احتساب القسط على هذه النسبة المحددة فقط. يتم تحميل قسط رمزي على رصيد المخزون</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-ar">يمكن أن تمتد البوليصة لتشمل أعمال الشغب والإضراب والأضرار الكيدية والسرقة. علاوة على ذلك، يمكن إصدار البوليصات على أساس الإعلان وعلى أساس الممتلكات الشخصية غير المجدولة</div>
            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <p class="burglaryinsu-mob-txt-ar">الاستثناءات الرئيسية، لن تدفع البوليصة مقابل الخسارة أو الضرر فيما يتعلق بما يلي</p>

          <div class="burglaryinsu-pins-list-mob-ar">
            <div class="burglaryinsu-pins-list-mob-item-ar">البضائع المحتفظ بها في الأمانات/ العمولات، والمبالغ النقدية، والمجوهرات، والتحف، وسندات الملكية، ودفاتر الأعمال (ما لم تكن مؤمنة على وجه التحديد)</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-ar">سرقة المتاجر، أو الأفعال التي تتكبد فيها الخسارة بنفسك أو أفراد عائلتك / موظفيك</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-ar">مخاطر الحرب وأعمال الشغب والإضراب (التي يغطيها دفع قسط إضافي) والكوارث الطبيعية والأخطار النووية</div>

            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="burglaryinsu-pins-list-mob-item-ar">الأشياء المسروقة من الخزنة باستخدام مفتاح أو مفتاح منسوخ، ما لم يتم الحصول عليه عن طريق العنف أو التهديد</div>
            <div class="burglaryinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          <h3 class="burglaryinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="burglaryinsu-mob-whykrlist-ar">
            <div class="burglaryinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="burglaryinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="burglaryinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="burglaryinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARBurglaryInsurance;