import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './valuationservices.scss';

const ValuationServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="d-none d-md-block">
        <div class="valuservices-tab-imagebannermain-en">
          <div class="valuservices-banner-con-en">
            <div class="valuservicestab-banne-sec-en">
              <h1 class="valuservices-banner-heading-en">Valued Services</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "risk-inspection-services"}>Risk Inspection Services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "insurance-audits"}>Insurance Audits</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "valuation-services"}>Valuation services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "thermography-testing-insurance"}>Thermography Testing</div>
                {/* <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "policy-renewalalerts"}>Policy Renewal Alerts</div> */}
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "employee-wellness-program"}>Employee Wellness Program</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}

              <div class="best-responsive-tab" id="myExampleBestResponsiveTab1" role="tablist" data-target-content="#myExampleTabsContent">
                {/* {
                tabsList && tabsList.length ? tabsList.map(( each, index ) => <div data-target-index={index} className={index == tab ? "active" : "def"} onClick={() => {
                  setTab(index)
                  window.location.href = "personalinsurance/" + each.url
                }}>{each.text}</div>) : null

              } */}
                {/* <div data-target-index="0">Travel Insurance</div>
              <div data-target-index="1" onClick={() => window.location.href = "personalinsurance/life-insurance"}>Life Insurance</div>
              <div data-target-index="2">Personal Accident Insurance</div>
              <div data-target-index="3">Relocation Insurance</div>
              <div data-target-index="4">Health Insurance</div>
              <div data-target-index="5">Art insurance</div>
              <div data-target-index="6">Golf Insurance</div>
              <div data-target-index="7">Motor Insurance</div>
              <div data-target-index="8">Housemaid Insurance</div>
              <div data-target-index="9">Home Insurance</div>
              <div data-target-index="10">Yacht Insurance</div> */}


              </div>
            </div>
          </div>
        </div>
        <div class="valuservices-banner-con-en">
          <div class="valuservices-bdy-en">
            {/* <h2 class="pagetitle-en">Valuation services</h2> */}
            <div class="valuservices-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="valuservices-dlex-lft-en">
                <h2 class="pagetitle-en">Valuation services</h2>

                <p class="valuservices-pg-txt-con">We offer valuation services to support your organizations efforts to provide accurate financial data for insurance coverage purposes, so that the recovery funds meet with recovery needs post-event.</p>

                <p class="valuservices-pg-txt-con">With our broad industry experience and with professionals who understand our client’s business, we offer time and cost effective valuation services to our clients.</p>

                <p class="valuservices-pg-txt-con">We at KR Insurance Services, look forward to provide you with our valuation services to optimize your insurance covers.</p>

                <h3 class="valuservices-whykrinsu-title-en">Why KR Insurance Services ?</h3>
                <div class="clearfix"></div>

                <div class="valuservices-pins-list-lftdksp-en">
                  <div class="valuservices-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div class="valuservices-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div class="valuservices-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div class="valuservices-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="valuservices-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services.png"} alt="valuation services" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="valuservices-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services"}>Risk Inspection Services</option>
            <option value={"insurance-audits"}>Insurance Audits</option>
            <option value={"valuation-services"} selected class="active">Valuation Services</option>
            <option value={"thermography-testing-insurance"}>Thermography Testing</option>
            {/* <option value={"policy-renewalalerts"}>Policy Renewal Alerts</option> */}
            <option value={"employee-wellness-program"}>Employee Wellness Program</option>
          </select>
        </div>
        <div class="valuservices-mob-main-en">
          <h1 class="valuservices-banner-heading-en">Valuation Services</h1>
          <div class="valuservices-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services.png"} alt="valuation services" />
          </div>

          <p class="valuservices-mob-txt-en">We offer valuation services to support your organizations efforts to provide accurate financial data for insurance coverage purposes, so that the recovery funds meet with recovery needs post-event.</p>

          <p class="valuservices-mob-txt-en">With our broad industry experience and with professionals who understand our client’s business, we offer time and cost effective valuation services to our clients.</p>

          <p class="valuservices-mob-txt-en">We at KR Insurance Services, look forward to provide you with our valuation services to optimize your insurance covers.</p>

          <h3 class="valuservices-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="valuservices-mob-whykrlist-en">
            <div class="valuservices-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="valuservices-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="valuservices-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="valuservices-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ValuationServices;
