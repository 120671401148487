import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './argrouphealthinsurance.scss';

const ARGroupHealthInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="grphlthinsu-tab-imagebannermain-ar">
          <div class="grphlthinsu-banner-con-ar">
            <div class="grphlthinsutab-banne-sec-ar">
              <h1 class="grphlthinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grphlthinsu-tab-btn-ar">
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="grphlthinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="grphlthinsu-banner-con-ar">
          <div class="grphlthinsu-bdy-ar">
            <div class="grphlthinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="grphlthinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_health_insurance_ar.png"} alt="التأمين الصحي الجماعي" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="grphlthinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين الصحي الجماعي</h2>

                <p class="grphlthinsu-txt-con-ar">يحتاج النشاط التجاري إلى ضمان سلامة موارده الأساسية، موظفيه. تقدم بوليصة المطالبة الطبية الجماعية / الرعاية الصحية في المجموعة التي تقدمها شركة كيمجي رامداس لخدمات التأمين خدماتها لجميع موظفي الشركة، بغض النظر عن حجم الكيان. نحن نقدم أغطية تأمينية للمؤسسات الكبيرة والمتوسطة والصغيرة الحجم، تتراوح قدرة الموظف من 5 موظفين فيما أكثر</p>

                <p class="grphlthinsu-txt-con-ar">تقدم خدمة التأمين في كيمجي رامداس مجموعة من خطط التأمين الطبي وحلول الرعاية الصحية التي تعد مزيجًا ممتازًا لتناسب متطلبات شركتك بالإضافة إلى ميزانيتك</p>

                <p class="grphlthinsu-txt-con-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين خدمات سريعة وأفضل قيمة مضافة للشركات، والسمات الرئيسية هي كما يلي</strong></p>

                <div class="grphlthinsu-keypoint-ar">
                  <div class="grphlthinsu-keypoint-item-ar">حلول النافذة الواحدة من خلال مدير علاقات مخصص</div>
                  <div class="grphlthinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                  <div class="grphlthinsu-keypoint-item-ar">ترتيب شروط أوسع، سياسات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>
                  <div class="grphlthinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                  <div class="grphlthinsu-keypoint-item-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصات، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>
                  <div class="grphlthinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="grphlthinsu-kpnt-ar">
              <div class="grphlthinsu-keypoint-item-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق التوفير لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">خدمات تذكير بموعد التجديد – تذكيرات بموعد التجديد التي يتم إنشاؤها تلقائيًا</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">تدقيق التأمين مع الآثار المترتبة على عدم ترتب تكلفة خاصة ببوليصات التأمين الحالية الخاصة بك</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">مساعدة شخصية في المطالبة من خلال فريقنا المتفاني وذوي الخبرة</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">تحليل بيانات المطالبات السابقة لاقتراح تحسين على نسب الخسارة</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">أولوية العلاج في المستشفى على أساس كل حالة على حدة</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">من خلال شركائنا في الشبكة في الهند، نقوم بترتيب عروض الأسعار لدخول المستشفى المقصود في الهند</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">فريق طبي داخلي للحصول على رأي ثان في المطالبات (إذا لزم الأمر)</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">خيارات البوليصة المصممة خصيصًا والتي تتناسب مع متطلبات العملاء المختلفة</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">برامج الصحة والعافية - جلسات التوعية الصحية، فحوصات الصحة الأساسية، فحوصات العين / الأسنان، جلسة فن العيش لمجموعة من الموظفين، جلسة مع أخصائي التغذية، إلخ</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-keypoint-item-ar">برنامج توعية بالبوليصة للموظفين من خلال - العروض التقديمية، ومكتب المساعدة، والمراسلات والرسائل المصممة خصيصًا</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">إرسال النصائح الصحية الأسبوعية لموظفي مؤسستك (اختياري)</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="grphlthinsu-kpnt-item-ar">وضع علامات على العملاء / الموظفين المهمين لتقديم مزيد من الاهتمام أثناء معالجة مطالباتهم وتقديم الخدمات لهم - خدمات السجادة الحمراء</div>
              <div class="grphlthinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            
            <h3 class="grphlthinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين الصحي الجماعي</h3>

            <p class="grphlthinsu-txt-con-ar">تقدم حلول الرعاية الصحية وخطط التأمين الطبي المصممة خصيصًا، الميزات التالية </p>

            <p class="grphlthinsu-txt-con-ar">العلاج غير النقدي في المستشفيات</p>

            <p class="grphlthinsu-txt-con-ar">مزايا الاستشفاء لكل مؤمن عليه لكل سنة حتى بلوغ قيمة مبلغ التأمين</p>

            <p class="grphlthinsu-txt-con-ar"><strong>معالجة المريض المقيم</strong></p>

            <p class="grphlthinsu-txt-con-ar">غرفة يومية / إقامة بالمستشفى</p>

            <p class="grphlthinsu-txt-con-ar">فئة الاستشفاء</p>

            <p class="grphlthinsu-txt-con-ar">وحدة العناية المركزة</p>

            <p class="grphlthinsu-txt-con-ar">أتعاب الاستشاريين والجراحين وطبيب التخدير</p>

            <p class="grphlthinsu-txt-con-ar">الفحوصات التشخيصية</p>

            <p class="grphlthinsu-txt-con-ar">الحصول على الأشعة المقطعية والتصوير بالرنين المغناطيسي والتصوير المقطعي بالإصدار البوزيتروني كمريض مقيم</p>

            <p class="grphlthinsu-txt-con-ar">العلاجات المختلفة بما في ذلك العلاج الطبيعي والعلاج الكيميائي والعلاج الإشعاعي وما إلى ذلك</p>

            <p class="grphlthinsu-txt-con-ar">غرفة مرافق ومكان للإقامة</p>

            <p class="grphlthinsu-txt-con-ar">الاستحقاق النقدي داخل المستشفى (للعلاج الداخلي يتم تلقيه مجانًا بدون أي تكاليف)</p>

            <p class="grphlthinsu-txt-con-ar">استخدام المعدات الطبية في المستشفيات (مثل أنظمة دعم القلب والرئة، وما إلى ذلك)</p>

            <p class="grphlthinsu-txt-con-ar">خدمة زراعة الأعضاء المتلقية، باستثناء أي رسوم تتعلق بالمتبرع</p>

            <p class="grphlthinsu-txt-con-ar">تكاليف الإجلاء الطبي والإعادة إلى الوطن لنقل الجثمان إلى بلد المنشأ</p>

            <p class="grphlthinsu-txt-con-ar"><strong>العلاج في العيادات الخارجية</strong></p>

            <p class="grphlthinsu-txt-con-ar">رسوم الممارس الطبي مقابل الاستشارات / أتعاب الأخصائي 

جراحة العيادات الخارجية</p>

            <p class="grphlthinsu-txt-con-ar">علاج الرعاية النهارية (العلاج الذي يتم في المستشفى ولكن لا يتطلب المبيت)</p>

            <p class="grphlthinsu-txt-con-ar">وصفات الأدوية</p>

            <p class="grphlthinsu-txt-con-ar">التشخيص (الأشعة السينية، التصوير بالرنين المغناطيسي، الأشعة المقطعية، الموجات فوق الصوتية، إلخ) والاختبارات المعملية</p>

            <p class="grphlthinsu-txt-con-ar">العلاج الطبيعي [يُطلب إذن مُسبق]</p>

            <p class="grphlthinsu-txt-con-ar">التأمين المشترك للعلاج لدى مزودي الخدمات غير المعينين ضمن الحدود الإقليمية، باستثناء المستشفيات الحكومية والمراكز الصحية (بعد التأمين المشترك القابل للخصم والمزايا الأخرى)</p>

            <p class="grphlthinsu-txt-con-ar"><strong>فوائد أخرى</strong></p>

            <p class="grphlthinsu-txt-con-ar">الأضرار العرضية للأسنان الطبيعية بعد وقوع حادث</p>

            <p class="grphlthinsu-txt-con-ar">الطب البديل (مثل الأيورفيدا والعلاج بالمواد الطبيعية والوخز بالإبر والعلاجات العشبية)</p>

            <p class="grphlthinsu-txt-con-ar">الشروط الموجودة مسبقًا</p>

            <h3 class="grphlthinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>

            <div class="grphlthinsu-pins-list-dksp-ar">
              <div class="grphlthinsu-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grphlthinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grphlthinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="grphlthinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="grphlthinsu-tab-imagebannermain-ar">
          <div class="grphlthinsu-banner-con-ar">
            <div class="grphlthinsutab-banne-sec-ar">
              <h1 class="grphlthinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grphlthinsu-tab-btn-ar">
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="grphlthinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="grphlthinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="grphlthinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين الصحي الجماعي</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_health_insurance_ar.png"} alt="التأمين الصحي الجماعي" />

              <p>يحتاج النشاط التجاري إلى ضمان سلامة موارده الأساسية، موظفيه. تقدم بوليصة المطالبة الطبية الجماعية / الرعاية الصحية في المجموعة التي تقدمها شركة كيمجي رامداس لخدمات التأمين خدماتها لجميع موظفي الشركة، بغض النظر عن حجم الكيان. نحن نقدم أغطية تأمينية للمؤسسات الكبيرة والمتوسطة والصغيرة الحجم، تتراوح قدرة الموظف من 5 موظفين فيما أكثر.</p>
              
              <p>تقدم خدمة التأمين في كيمجي رامداس مجموعة من خطط التأمين الطبي وحلول الرعاية الصحية التي تعد مزيجًا ممتازًا لتناسب متطلبات شركتك بالإضافة إلى ميزانيتك</p>
              
              <p><strong>تقدم شركة كيمجي رامداس لخدمات التأمين خدمات سريعة وأفضل قيمة مضافة للشركات، والسمات الرئيسية هي كما يلي</strong></p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">حلول النافذة الواحدة من خلال مدير علاقات مخصص</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">ترتيب شروط أوسع، سياسات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصات، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق التوفير لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">خدمات تذكير بموعد التجديد – تذكيرات بموعد التجديد التي يتم إنشاؤها تلقائيًا</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تدقيق التأمين مع الآثار المترتبة على عدم ترتب تكلفة خاصة ببوليصات التأمين الحالية الخاصة بك</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">مساعدة شخصية في المطالبة من خلال فريقنا المتفاني وذوي الخبرة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تحليل بيانات المطالبات السابقة لاقتراح تحسين على نسب الخسارة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">أولوية العلاج في المستشفى على أساس كل حالة على حدة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">من خلال شركائنا في الشبكة في الهند، نقوم بترتيب عروض الأسعار لدخول المستشفى المقصود في الهند</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">فريق طبي داخلي للحصول على رأي ثان في المطالبات (إذا لزم الأمر)</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">خيارات البوليصة المصممة خصيصًا والتي تتناسب مع متطلبات العملاء المختلفة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">برامج الصحة والعافية - جلسات التوعية الصحية، فحوصات الصحة الأساسية، فحوصات العين / الأسنان، جلسة فن العيش لمجموعة من الموظفين، جلسة مع أخصائي التغذية، إلخ</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">برنامج توعية بالبوليصة للموظفين من خلال - العروض التقديمية، ومكتب المساعدة، والمراسلات والرسائل المصممة خصيصًا</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">إرسال النصائح الصحية الأسبوعية لموظفي مؤسستك (اختياري)</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">وضع علامات على العملاء / الموظفين المهمين لتقديم مزيد من الاهتمام أثناء معالجة مطالباتهم وتقديم الخدمات لهم - خدمات السجادة الحمراء</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / التأمين الصحي الجماعي</h3>

              <p>تقدم حلول الرعاية الصحية وخطط التأمين الطبي المصممة خصيصًا، الميزات التالية </p>
              <p>العلاج غير النقدي في المستشفيات</p>
              <p>مزايا الاستشفاء لكل مؤمن عليه لكل سنة حتى بلوغ قيمة مبلغ التأمين</p>
              <p><strong>معالجة المريض المقيم</strong></p>
              <p>غرفة يومية / إقامة بالمستشفى</p>
              <p>فئة الاستشفاء</p>
              <p>وحدة العناية المركزة</p>
              <p>أتعاب الاستشاريين والجراحين وطبيب التخدير</p>
              <p>الفحوصات التشخيصية</p>
              <p>الحصول على الأشعة المقطعية والتصوير بالرنين المغناطيسي والتصوير المقطعي بالإصدار البوزيتروني كمريض مقيم</p>
              <p>العلاجات المختلفة بما في ذلك العلاج الطبيعي والعلاج الكيميائي والعلاج الإشعاعي وما إلى ذلك</p>
              <p>غرفة مرافق ومكان للإقامة</p>
              <p>الاستحقاق النقدي داخل المستشفى (للعلاج الداخلي يتم تلقيه مجانًا بدون أي تكاليف)</p>
              <p>استخدام المعدات الطبية في المستشفيات (مثل أنظمة دعم القلب والرئة، وما إلى ذلك)</p>
              <p>خدمة زراعة الأعضاء المتلقية، باستثناء أي رسوم تتعلق بالمتبرع</p>
              <p>تكاليف الإجلاء الطبي والإعادة إلى الوطن لنقل الجثمان إلى بلد المنشأ</p>
              <p><strong>العلاج في العيادات الخارجية</strong></p>
              <p>رسوم الممارس الطبي مقابل الاستشارات / أتعاب الأخصائي 

جراحة العيادات الخارجية</p>
              
              <p>علاج الرعاية النهارية (العلاج الذي يتم في المستشفى ولكن لا يتطلب المبيت)</p>
              <p>وصفات الأدوية</p>
              <p>التشخيص (الأشعة السينية، التصوير بالرنين المغناطيسي، الأشعة المقطعية، الموجات فوق الصوتية، إلخ) والاختبارات المعملية</p>
              <p>العلاج الطبيعي [يُطلب إذن مُسبق]</p>
              <p>التأمين المشترك للعلاج لدى مزودي الخدمات غير المعينين ضمن الحدود الإقليمية، باستثناء المستشفيات الحكومية والمراكز الصحية (بعد التأمين المشترك القابل للخصم والمزايا الأخرى)</p>
              <p><strong>فوائد أخرى</strong></p>
              <p>الأضرار العرضية للأسنان الطبيعية بعد وقوع حادث</p>
              <p>الطب البديل (مثل الأيورفيدا والعلاج بالمواد الطبيعية والوخز بالإبر والعلاجات العشبية)</p>
              <p>الشروط الموجودة مسبقًا</p>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            
              </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="grphlthinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"}>تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"} selected class="active">التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="grphlthinsu-mob-main-ar">
          <h1 class="grphlthinsu-banner-heading-ar">التأمين الصحي الجماعي</h1>
          <div class="grphlthinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_health_insurance_ar.png"} alt="التأمين الصحي الجماعي" />
          </div>

          <p class="grphlthinsu-mob-txt-ar">يحتاج النشاط التجاري إلى ضمان سلامة موارده الأساسية، موظفيه. تقدم بوليصة المطالبة الطبية الجماعية / الرعاية الصحية في المجموعة التي تقدمها شركة كيمجي رامداس لخدمات التأمين خدماتها لجميع موظفي الشركة، بغض النظر عن حجم الكيان. نحن نقدم أغطية تأمينية للمؤسسات الكبيرة والمتوسطة والصغيرة الحجم، تتراوح قدرة الموظف من 5 موظفين فيما أكثر</p>

          <p class="grphlthinsu-mob-txt-ar">تقدم خدمة التأمين في كيمجي رامداس مجموعة من خطط التأمين الطبي وحلول الرعاية الصحية التي تعد مزيجًا ممتازًا لتناسب متطلبات شركتك بالإضافة إلى ميزانيتك</p>

          <p class="grphlthinsu-mob-txt-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين خدمات سريعة وأفضل قيمة مضافة للشركات، والسمات الرئيسية هي كما يلي</strong></p>


          <div class="grphlthinsu-pins-list-mob-ar">
            <div class="grphlthinsu-pins-list-mob-item-ar">حلول النافذة الواحدة من خلال مدير علاقات مخصص</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">ترتيب شروط أوسع، سياسات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصات، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق التوفير لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">خدمات تذكير بموعد التجديد – تذكيرات بموعد التجديد التي يتم إنشاؤها تلقائيًا</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">تدقيق التأمين مع الآثار المترتبة على عدم ترتب تكلفة خاصة ببوليصات التأمين الحالية الخاصة بك</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">مساعدة شخصية في المطالبة من خلال فريقنا المتفاني وذوي الخبرة</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">تحليل بيانات المطالبات السابقة لاقتراح تحسين على نسب الخسارة</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">أولوية العلاج في المستشفى على أساس كل حالة على حدة</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">من خلال شركائنا في الشبكة في الهند، نقوم بترتيب عروض الأسعار لدخول المستشفى المقصود في الهند</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">فريق طبي داخلي للحصول على رأي ثان في المطالبات (إذا لزم الأمر)</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">خيارات البوليصة المصممة خصيصًا والتي تتناسب مع متطلبات العملاء المختلفة</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">برامج الصحة والعافية - جلسات التوعية الصحية، فحوصات الصحة الأساسية، فحوصات العين / الأسنان، جلسة فن العيش لمجموعة من الموظفين، جلسة مع أخصائي التغذية، إلخ</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">برنامج توعية بالبوليصة للموظفين من خلال - العروض التقديمية، ومكتب المساعدة، والمراسلات والرسائل المصممة خصيصًا</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">إرسال النصائح الصحية الأسبوعية لموظفي مؤسستك (اختياري)</div>

            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-pins-list-mob-item-ar">وضع علامات على العملاء / الموظفين المهمين لتقديم مزيد من الاهتمام أثناء معالجة مطالباتهم وتقديم الخدمات لهم - خدمات السجادة الحمراء</div>
            <div class="grphlthinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>

          <h3 class="grphlthinsu-whykrinsu-mob-title-ar">التغطية الرئيسية / التأمين الصحي الجماعي</h3>

          <p class="grphlthinsu-mob-txt-ar">تقدم حلول الرعاية الصحية وخطط التأمين الطبي المصممة خصيصًا، الميزات التالية</p>

          <p class="grphlthinsu-mob-numtxt-ar">العلاج غير النقدي في المستشفيات</p>

          <p class="grphlthinsu-mob-numtxt-ar">مزايا الاستشفاء لكل مؤمن عليه لكل سنة حتى بلوغ قيمة مبلغ التأمين</p>

          <p class="grphlthinsu-mob-numtxt-ar"><strong>معالجة المريض المقيم</strong></p>

          <p class="grphlthinsu-mob-numtxt-ar">غرفة يومية / إقامة بالمستشفى</p>

          <p class="grphlthinsu-mob-numtxt-ar">فئة الاستشفاء</p>

          <p class="grphlthinsu-mob-numtxt-ar">وحدة العناية المركزة</p>

          <p class="grphlthinsu-mob-numtxt-ar">أتعاب الاستشاريين والجراحين وطبيب التخدير</p>

          <p class="grphlthinsu-mob-numtxt-ar">الفحوصات التشخيصية</p>

          <p class="grphlthinsu-mob-numtxt-ar">الحصول على الأشعة المقطعية والتصوير بالرنين المغناطيسي والتصوير المقطعي بالإصدار البوزيتروني كمريض مقيم</p>

          <p class="grphlthinsu-mob-numtxt-ar">العلاجات المختلفة بما في ذلك العلاج الطبيعي والعلاج الكيميائي والعلاج الإشعاعي وما إلى ذلك</p>

          <p class="grphlthinsu-mob-numtxt-ar">غرفة مرافق ومكان للإقامة</p>

          <p class="grphlthinsu-mob-numtxt-ar">الاستحقاق النقدي داخل المستشفى (للعلاج الداخلي يتم تلقيه مجانًا بدون أي تكاليف)</p>

          <p class="grphlthinsu-mob-numtxt-ar">استخدام المعدات الطبية في المستشفيات (مثل أنظمة دعم القلب والرئة، وما إلى ذلك)</p>

          <p class="grphlthinsu-mob-numtxt-ar">خدمة زراعة الأعضاء المتلقية، باستثناء أي رسوم تتعلق بالمتبرع</p>

          <p class="grphlthinsu-mob-numtxt-ar">تكاليف الإجلاء الطبي والإعادة إلى الوطن لنقل الجثمان إلى بلد المنشأ</p>

          <p class="grphlthinsu-mob-numtxt-ar"><strong>العلاج في العيادات الخارجية</strong></p>

          <p class="grphlthinsu-mob-numtxt-ar">رسوم الممارس الطبي مقابل الاستشارات / أتعاب الأخصائي 
جراحة العيادات الخارجية</p>


          <p class="grphlthinsu-mob-numtxt-ar">علاج الرعاية النهارية (العلاج الذي يتم في المستشفى ولكن لا يتطلب المبيت)</p>

          <p class="grphlthinsu-mob-numtxt-ar">وصفات الأدوية</p>

          <p class="grphlthinsu-mob-numtxt-ar">التشخيص (الأشعة السينية، التصوير بالرنين المغناطيسي، الأشعة المقطعية، الموجات فوق الصوتية، إلخ) والاختبارات المعملية</p>

          <p class="grphlthinsu-mob-numtxt-ar">العلاج الطبيعي [يُطلب إذن مُسبق]</p>

          <p class="grphlthinsu-mob-numtxt-ar">التأمين المشترك للعلاج لدى مزودي الخدمات غير المعينين ضمن الحدود الإقليمية، باستثناء المستشفيات الحكومية والمراكز الصحية (بعد التأمين المشترك القابل للخصم والمزايا الأخرى)</p>
          
          <p class="grphlthinsu-mob-numtxt-ar"><strong>فوائد أخرى</strong></p>
          
          <p class="grphlthinsu-mob-numtxt-ar">الأضرار العرضية للأسنان الطبيعية بعد وقوع حادث</p>
          
          <p class="grphlthinsu-mob-numtxt-ar">الطب البديل (مثل الأيورفيدا والعلاج بالمواد الطبيعية والوخز بالإبر والعلاجات العشبية)</p>
          
          <p class="grphlthinsu-mob-numtxt-ar">الشروط الموجودة مسبقًا</p>
          

          <h3 class="grphlthinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="grphlthinsu-mob-whykrlist-ar">
            <div class="grphlthinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grphlthinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="grphlthinsu-mob-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARGroupHealthInsurance;