import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './argrouppersonalaccidentinsurance.scss';

const ARGroupPersonalAccidentInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="grpacc-covinsu-tab-imagebannermain-ar">
          <div class="grpacc-covinsu-banner-con-ar">
            <div class="grpacc-covinsutab-banne-sec-ar">
              <h1 class="grpacc-covinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-tab-btn-ar">
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="grpacc-tab-btn-item-active-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="grpacc-covinsu-banner-con-ar">
          <div class="grpacc-covinsu-bdy-ar">
            <div class="grpacc-covinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="grpacc-covinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_personal_accident_insurance_ar.png"} alt="التأمين الجماعي ضد الحوادث الشخصية " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="grpacc-covinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين الجماعي ضد الحوادث الشخصية </h2>
                <p class="pg-txt-con-ar">يغطي التأمين الجماعي ضد الحوادث الشخصية جميع الإصابات الجسدية العرضية أو أحداث الوفاة أو أي عجز للأشخاص الذين تشملهم البوليصة. هذه التغطية مثالية للشركات حيث تقدم تغطية النفقات الطبية لموظفيهم بسبب الإصابات العرضية. لذا يلزم التأمين على موظفيك ضد الوفاة والعجز الناتج عن حادث من خلال تغطية التأمين الجماعي ضد الحوادث الشخصية. اشترِ أفضل خطة في شركة كيمجي رامداس لخدمات التأمين ووفر نفقاتك الطبية</p>

                <p class="pg-txt-con-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين خدمات سريعة وأفضل قيمة مضافة للشركات، والسمات الرئيسية هي كالآتي</strong></p>

                <div class="grpacc-covinsu-keypoint-ar">
                  <div class="grpacc-covinsu-keypoint-item-ar">حلول النافذة الواحدة من خلال مدير علاقات مخصص</div>

                  <div class="grpacc-covinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="grpacc-covinsu-keypoint-item-ar">ترتيب شروط اكثر شمولًا، وبوليصات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>

                  <div class="grpacc-covinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="grpacc-covinsu-keypoint-item-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصة، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>
                  <div class="grpacc-covinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                </div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="grpacc-covinsu-kpt-ar">
              <div class="grpacc-covinsu-kpt-item-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق وفورات لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>

              <div class="grpacc-covinsu-kpt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-ar">خدمات التذكير بموعد التجديد – تذكيرات بمواعيد التجديد التي يتم إنشاؤها تلقائيًا</div>

              <div class="grpacc-covinsu-kpt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-ar">تدقيق التأمين مع الآثار المترتبة علىعد ترتب تكلفة في بوليصات التأمين الحالية الخاصة بك</div>

              <div class="grpacc-covinsu-kpt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-ar">تقديم المساعدة الشخصية في المطالبة من خلال فريقنا المتفاني وذوي الخبرة</div>

              <div class="grpacc-covinsu-kpt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-ar">تحليل بيانات المطالبات السابقة لاقتراح تحسين على نسب الخسارة</div>

              <div class="grpacc-covinsu-kpt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-kpt-item-ar">خيارات البوليصة المصممة خصيصًا والتي تتوافق مع المتطلبات المختلفة للعملاء</div>
              <div class="grpacc-covinsu-kpt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>


            <h3 class="grpacc-covinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين الجماعي ضد الحوادث الشخصية</h3>

            <p class="grpacc-covinsu-txt-con-ar">تمتع بالمزايا التالية بموجب بوليصة التأمين هذه</p>

            <div class="grpacc-covinsu-dsk-kf-ar">
              <div class="grpacc-covinsu-dsk-kf-ar">التغطية التأمينة ضد الوفاة الناتجة عن حادث: تكون التعويضات بموجب هذا البرنامج مستحقة الدفع في حالة حدوث الوفاة نتيجة حادث مؤسف</div>

              <div class="grpacc-covinsu-dsk-kf-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-ar">التغطية التأمينة ضد الإعاقة العرضية: يشير إلى أن حامل الوثيقة معاق جزئيًا أو كليًا</div>

              <div class="grpacc-covinsu-dsk-kf-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-ar">التغطية التأمينة ضد قطع أوتار ناتج عن حادث: يشير إلى أن جزءًا من جسم حامل البوليصة قد تم قطعه أو قطع أوتاره. هذا يعني أنه إذا فقد حامل البوليصة يده أو ساقه أو عينيه في حادث مؤسف، فسيكون مؤهلاً للحصول على مطالبة بموجب تقطيع أوصاله</div>

              <div class="grpacc-covinsu-dsk-kf-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-ar">التغطية التأمينة ضد الأعمال الإرهابية: تشمل أيضا الإصابات التي لحقت به بسبب الأعمال الإرهابية</div>

              <div class="grpacc-covinsu-dsk-kf-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="grpacc-covinsu-dsk-kf-ar">تكاليف الإقامة بالمستشفى لتلقي العلاج: تتحمل بوليصة التأمين كافة تكاليف العلاج الخاصة بالمريض خلال خضوعه للعلاج بالمستشفى</div>
              <div class="grpacc-covinsu-dsk-kf-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            <h3 class="grpacc-covinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>

            <div class="grpacc-covinsu-pins-list-dksp-ar">
              <div class="grpacc-covinsu-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="grpacc-covinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="grpacc-covinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="grpacc-covinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="grpacc-covinsu-tab-imagebannermain-ar">
          <div class="grpacc-covinsu-banner-con-ar">
            <div class="grpacc-covinsutab-banne-sec-ar">
              <h1 class="grpacc-covinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-tab-btn-ar">
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="grpacc-tab-btn-item-active-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="grpacc-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="grpacc-covinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين الجماعي ضد الحوادث الشخصية </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_personal_accident_insurance_ar.png"} alt="التأمين الجماعي ضد الحوادث الشخصية " />

              <p>يغطي التأمين الجماعي ضد الحوادث الشخصية جميع الإصابات الجسدية العرضية أو أحداث الوفاة أو أي عجز للأشخاص الذين تشملهم البوليصة. هذه التغطية مثالية للشركات حيث تقدم تغطية النفقات الطبية لموظفيهم بسبب الإصابات العرضية. لذا يلزم التأمين على موظفيك ضد الوفاة والعجز الناتج عن حادث من خلال تغطية التأمين الجماعي ضد الحوادث الشخصية. اشترِ أفضل خطة في شركة كيمجي رامداس لخدمات التأمين ووفر نفقاتك الطبية</p>
              
              <p><strong>تقدم شركة كيمجي رامداس لخدمات التأمين خدمات سريعة وأفضل قيمة مضافة للشركات، والسمات الرئيسية هي كالآتي</strong></p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">حلول النافذة الواحدة من خلال مدير علاقات مخصص</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">ترتيب شروط اكثر شمولًا، وبوليصات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصة، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق وفورات لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">خدمات التذكير بموعد التجديد – تذكيرات بمواعيد التجديد التي يتم إنشاؤها تلقائيًا</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تدقيق التأمين مع الآثار المترتبة علىعد ترتب تكلفة في بوليصات التأمين الحالية الخاصة بك</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تقديم المساعدة الشخصية في المطالبة من خلال فريقنا المتفاني وذوي الخبرة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تحليل بيانات المطالبات السابقة لاقتراح تحسين على نسب الخسارة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">خيارات البوليصة المصممة خصيصًا والتي تتوافق مع المتطلبات المختلفة للعملاء</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <h3 class="tab-sub-title-ar">التغطية الرئيسية / التأمين الجماعي ضد الحوادث الشخصية</h3>
              
              <p>تمتع بالمزايا التالية بموجب بوليصة التأمين هذه</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد الوفاة الناتجة عن حادث: تكون التعويضات بموجب هذا البرنامج مستحقة الدفع في حالة حدوث الوفاة نتيجة حادث مؤسف</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد الإعاقة العرضية: يشير إلى أن حامل الوثيقة معاق جزئيًا أو كليًا</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد قطع أوتار ناتج عن حادث: يشير إلى أن جزءًا من جسم حامل البوليصة قد تم قطعه أو قطع أوتاره. هذا يعني أنه إذا فقد حامل البوليصة يده أو ساقه أو عينيه في حادث مؤسف، فسيكون مؤهلاً للحصول على مطالبة بموجب تقطيع أوصاله</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد الأعمال الإرهابية: تشمل أيضا الإصابات التي لحقت به بسبب الأعمال الإرهابية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تكاليف الإقامة بالمستشفى لتلقي العلاج: تتحمل بوليصة التأمين كافة تكاليف العلاج الخاصة بالمريض خلال خضوعه للعلاج بالمستشفى</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> أفضل ممارسات صناعة التأمين</div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة<img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="grpacc-covinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"} selected class="active">التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="grpacc-covinsu-mob-main-ar">
          <h1 class="grpacc-covinsu-banner-heading-ar">التأمين الجماعي ضد الحوادث الشخصية </h1>
          <div class="grpacc-covinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_personal_accident_insurance_ar.png"} alt="التأمين الجماعي ضد الحوادث الشخصية " />
          </div>
          <p class="grpacc-covinsu-mob-txt-ar">يغطي التأمين الجماعي ضد الحوادث الشخصية جميع الإصابات الجسدية العرضية أو أحداث الوفاة أو أي عجز للأشخاص الذين تشملهم البوليصة. هذه التغطية مثالية للشركات حيث تقدم تغطية النفقات الطبية لموظفيهم بسبب الإصابات العرضية. لذا يلزم التأمين على موظفيك ضد الوفاة والعجز الناتج عن حادث من خلال تغطية التأمين الجماعي ضد الحوادث الشخصية. اشترِ أفضل خطة في شركة كيمجي رامداس لخدمات التأمين ووفر نفقاتك الطبية</p>

          <p class="grpacc-covinsu-mob-txt-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين خدمات سريعة وأفضل قيمة مضافة للشركات، والسمات الرئيسية هي كالآتي</strong></p>

          <div class="grpacc-covinsu-mob-keyfeatures-ar">
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">حلول النافذة الواحدة من خلال مدير علاقات مخصص</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">ترتيب شروط اكثر شمولًا، وبوليصات مصممة خصيصًا بأفضل الأسعار المخفضة من شركات التأمين</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">استفادة العملاء من خلال معرفتنا المتعمقة بشروط التأمين، ووضع البوليصة، وخدمات تسوية المطالبات وممارسات التأمين الأخرى</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">تؤدي الخدمات المجانية والسريعة إلى تحقيق وفورات لعملائنا من حيث ساعات العمل والتكاليف الإدارية</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">خدمات التذكير بموعد التجديد – تذكيرات بمواعيد التجديد التي يتم إنشاؤها تلقائيًا</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">تدقيق التأمين مع الآثار المترتبة علىعد ترتب تكلفة في بوليصات التأمين الحالية الخاصة بك</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">تقديم المساعدة الشخصية في المطالبة من خلال فريقنا المتفاني وذوي الخبرة</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">تحليل بيانات المطالبات السابقة لاقتراح تحسين على نسب الخسارة</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">خيارات البوليصة المصممة خصيصًا والتي تتوافق مع المتطلبات المختلفة للعملاء</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
          </div>

          <h3 class="grpacc-covinsu-subtitle-ar">التغطية الرئيسية / التأمين الجماعي ضد الحوادث الشخصية</h3>

          <p class="grpacc-covinsu-mob-txt-ar">تمتع بالمزايا التالية بموجب بوليصة التأمين هذه</p>

          <div class="grpacc-covinsu-mob-keyfeatures-ar">
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد الوفاة الناتجة عن حادث: تكون التعويضات بموجب هذا البرنامج مستحقة الدفع في حالة حدوث الوفاة نتيجة حادث مؤسف</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد الإعاقة العرضية: يشير إلى أن حامل الوثيقة معاق جزئيًا أو كليًا.</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد قطع أوتار ناتج عن حادث: يشير إلى أن جزءًا من جسم حامل البوليصة قد تم قطعه أو قطع أوتاره. هذا يعني أنه إذا فقد حامل البوليصة يده أو ساقه أو عينيه في حادث مؤسف، فسيكون مؤهلاً للحصول على مطالبة بموجب تقطيع أوصاله</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد الأعمال الإرهابية: تشمل أيضا الإصابات التي لحقت به بسبب الأعمال الإرهابية</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="grpacc-covinsu-mob-keyfeatures-q-ar">تكاليف الإقامة بالمستشفى لتلقي العلاج: تتحمل بوليصة التأمين كافة تكاليف العلاج الخاصة بالمريض خلال خضوعه للعلاج بالمستشفى</div>
            <div class="grpacc-covinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          <h3 class="grpacc-covinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="grpacc-covinsu-mob-whykrlist-ar">
            <div class="grpacc-covinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="grpacc-covinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="grpacc-covinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="grpacc-covinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARGroupPersonalAccidentInsurance;