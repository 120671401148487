import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../headerEN/headeren";
import FooterEN from "../footerEN/footer";
import './team.scss';

const OurteamPage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  return (
    <div>
      <div class="main-fluid">
        <HeaderEN />
      </div>
      {/* <div class="container kr-container"> */}
      <div class="kr-ourteam-en">
        <h1 class="team-title-en">Our Team</h1>
        <p class="team-head-txt-en">Our team would be delighted to work closely on your organization's insurance requirements and deliver best insurance services</p>
        {/* OUR TEAM NEW SECTION STARTS HERE */}
        <div class="ourteam-en">
          {/* 1 TEAM MEMBER VIKAUS SIINGH STARTS HERE */}
          {/* <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/vikaus_siingh_new.png"} alt="Vikaus Siingh" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He has done post-graduation Program in Business Management, a law graduate and has done courses in leadership and strategies from Harvard & Wharton universities. He is a licentiate in insurance and carries vast industry experience of over 25 years.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ModalOne">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Vikaus Siingh</div>
                <div class="tup-style-1-designation-en">Head of Insurance Services</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div> */}

          {/* 5 TEAM MEMBER Vikram Aditya STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/vikram_aditya.png"} alt="Vikram Aditya" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He is a Postgraduate Diploma (MBA) holder in Hospital & Health Management with 17 years of industry experience.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#VikramAditya">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Vikram Aditya</div>
                <div class="tup-style-1-designation-en">Head of Insurance Services</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 5 TEAM MEMBER Vikram Aditya ENDS HERE */}
          {/* 1 TEAM MEMBER VIKAUS SIINGH ENDS HERE */}

          {/* 2 TEAM MEMBER ABBAS Al SAJWANY STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/abbas_al_sajwany.png"} alt="Abbas Al Sajwany" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He comes with more than 20 years of industry experience and has worked in manufacturing & trading industries currently associated with insurance industry.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ModalTen">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Abbas Al Sajwany</div>
                <div class="tup-style-1-designation-en">Senior Manager - Corporate Consultancy Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 2 TEAM MEMBER ABBAS Al SAJWANY ENDS HERE */}
          {/* 3 TEAM MEMBER Hamed Al-Hanaai STARTS HERE */}
          {/* <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/hamad_al_hinaai.png"} alt="Hamed Al-Hanaai" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He carries more than 10 years of industry experience. He has handled both personal and commercial lines of insurance
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ModalEleven">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Hamed Al-Hanaai</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Corporate Consultancy Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 3 TEAM MEMBER Hamed Al-Hanaai ENDS HERE */}
          {/* 4 TEAM MEMBER Sawsan Abdul Aziz Al Alawi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/sawsan_al_alawi.png"} alt="Sawsan Abdul Aziz Al Alawi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has done her higher diploma in information technology and a CMA certified professional in customer services and Grievances.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ModalSeven">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Sawsan Abdul Aziz Al Alawi</div>
                <div class="tup-style-1-designation-en">Manager - Services & Receivables</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 4 TEAM MEMBER Sawsan Abdul Aziz Al Alawi ENDS HERE */}


          {/* 17 TEAM MEMBER Alam Syed Mahetab STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/alam_syed_mahetab.png"} alt="Alam Syed Mahetab" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He has done diploma in insurance from Charted Insurance Institute (CII) UK and is a fellow in insurance from insurance institute of India.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#AlamSyedMahetab">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Alam Syed Mahetab</div>
                <div class="tup-style-1-designation-en">Senior Lead - Corporate Solutions Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 17 TEAM MEMBER Alam Syed Mahetab ENDS HERE */}

          {/* 6 TEAM MEMBER Ghalib Mohammed Nasser Al Siyabi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/ghalib_al_siyabi.png"} alt="Ghalib Mohammed Nasser Al Siyabi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He has bachelor's degree in public relations and got certified in business management from Khimji training institute.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ModalThirteen">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Ghalib Mohammed Nasser Al Siyabi</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Corporate Solutions Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 6 TEAM MEMBER Ghalib Mohammed Nasser Al Siyabi ENDS HERE */}
          {/* 7 TEAM MEMBER Suad Abdullah Humaid Al Kalbani STARTS HERE */}
          {/* <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/suad_abdullah_humaid_al_kalbani.png"} alt="Suad Abdullah Humaid Al Kalbani" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  Suad has over 11 years of insurance and hospital industry experience. Presently, she is responsible in managing customer relations...
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#SuadAbdullahHumaidAlKalbani">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Suad Abdullah Humaid Al Kalbani</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Corporate Solutions Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div> */}


          {/* 9 TEAM MEMBER Arun Kumar Gupta STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/arun_kumar_gupta.png"} alt="Arun Kumar Gupta" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He is an associate of chartered insurance practitioner (CII - UK). He is a licensed surveyor & loss adjuster and holds diploma in mechanical and electrical engineering.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ArunKumarGupta">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Arun Kumar Gupta</div>
                <div class="tup-style-1-designation-en">Head-Claims & Risk Management Services</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 9 TEAM MEMBER Arun Kumar Gupta ENDS HERE */}


          {/* 7 TEAM MEMBER Suad Abdullah Humaid Al Kalbani ENDS HERE */}
          {/* 8 TEAM MEMBER G Jeswanth Alister David STARTS HERE */}
          {/* <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/g_jeswanth_alister_david.png"} alt="G Jeswanth Alister David" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He carries more than 20 years of industry experience of Sales & Marketing, Retention & Client Management
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#GJeswanthAlisterDavid">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">G Jeswanth Alister David</div>
                <div class="tup-style-1-designation-en">Lead - Corporate Consultancy Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 8 TEAM MEMBER G Jeswanth Alister David ENDS HERE */}

          {/* 10 TEAM MEMBER Najma Abdulla Hamed Al Mahrizy STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/najma_abdulla_hamed_al_mahrizy.png"} alt="Najma Abdulla Hamed Al Mahrizy" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has diploma in accounts, IT Trained professional with hand on experience of different financial software and hands on experience of ISO procedures.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#NajmaAbdullaHamedAlMahrizy">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Najma Abdulla Hamed Al Mahrizy</div>
                <div class="tup-style-1-designation-en">Lead - Claims & Risk Management </div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 10 TEAM MEMBER Najma Abdulla Hamed Al Mahrizy ENDS HERE */}
          {/* 11 TEAM MEMBER Qais Ali Thani Al Sulaimi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/qais_ali_thani_al_sulaimi.png"} alt="Qais Ali Thani Al Sulaimi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  Qais is a go-getter and always delighted to help customers with their motor insurance claims. He carries more than 10 years of insurance industry experience.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#QaisAliThaniAlSulaimi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Qais Ali Thani Al Sulaimi</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Motor Claims</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 11 TEAM MEMBER Qais Ali Thani Al Sulaimi ENDS HERE */}
          {/* 12 TEAM MEMBER Fatma Ismail Hussain Al Bulushi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/fatma_ismail_hussain_al_bulushi.png"} alt="Fatma Ismail Hussain Al Bulushi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has 7 years' of work experience and is responsible for managing insurance data internally in KR insurance.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#FatmaIsmailHussainAlBulushi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Fatma Ismail Hussain Al Bulushi</div>
                <div class="tup-style-1-designation-en">Executive-Claims & Risk Management Services</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 12 TEAM MEMBER Fatma Ismail Hussain Al Bulushi ENDS HERE */}

          {/* 13 TEAM MEMBER Dr Sagar Jagtap STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/dr_sagar_jagtap.png"} alt="Dr Sagar Jagtap" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He is a qualified doctor, done his MBA in health care administration and post graduate diploma in emergency medical services.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#DrKiranBhaskarraoKherdekar">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Dr Sagar Jagtap</div>
                <div class="tup-style-1-designation-en">Head of Medical & Life Claims</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 13 TEAM MEMBER Dr Sagar Jagtap ENDS HERE */}

          {/* 14 TEAM MEMBER Saniya Mohammed Aslam AL Balushi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/sania_al_balushi.png"} alt="Saniya Mohammed Aslam AL Balushi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She is certified in managing customer services. She has got more than 7 years of industry experience and has got expertise of handling medical & life claims.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#SaniyaMohammedAslamALBalushi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Saniya Mohammed Aslam AL Balushi</div>
                <div class="tup-style-1-designation-en">Manager - Medical & Life Claims</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 14 TEAM MEMBER Saniya Mohammed Aslam AL Balushi ENDS HERE */}

          {/* 15 TEAM MEMBER Amani Moosa Sbeit Al Mukhaini STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/amani_moosa_sbeit_al_mukhaini.png"} alt="Amani Moosa Sbeit Al Mukhaini" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has 7 years’ experience and is part of medical claims team
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#AmaniMoosaSbeitAlMukhaini">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Amani Moosa Sbeit Al Mukhaini</div>
                <div class="tup-style-1-designation-en">Executive-Medical & Life Claims</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 15 TEAM MEMBER Amani Moosa Sbeit Al Mukhaini ENDS HERE */}

          {/* 16 TEAM MEMBER Samiya Dur Mohammed Albalushi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/samiya_dur_mohammed_albalushi.png"} alt="Samiya Dur Mohammed Albalushi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  Samiya is a go-getter and is always delighted to help customers with their motor insurance claims. She carries more than 14 years...
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#SamiyaDurMohammedAlbalushi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Samiya Dur Mohammed Albalushi</div>
                <div class="tup-style-1-designation-en">Executive - Motor Claims</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 16 TEAM MEMBER Samiya Dur Mohammed Albalushi ENDS HERE */}







          {/* 17 TEAM MEMBER Ansari Mohammad Ahmad STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/ansari_mohammad_ahmad.png"} alt="Ansari Mohammad Ahmad" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  He has 14 years of strong Insurance Broking experience handling SME and Large size clients in the Sultanate of Oman.
                  He is an expert in the Property & Casualty Insurance along with the Motor and Marine Insurance.
                  By Qualification he is a Science Graduate and has an Associate certificate from Insurance Institute of India.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#AlamSyedMahetab">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Ansari Mohammad Ahmad</div>
                <div class="tup-style-1-designation-en">Manager - Corporate Solutions Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 17 TEAM MEMBER Ansari Mohammad Ahmad ENDS HERE */}

          {/* 27 TEAM MEMBER safar_al_balushi.png STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/dharminder_kumar.png"} alt="Dharminder Kumar" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  Experienced professional carrying more than a decade experience of in the insurance industry. He has worked in reputed organizations like ICICI LOMBARD, HDFC Ergo, and Bharti AXA. He has in-depth domain knowledge of insurance, risk analysis, and portfolio management of customers pertaining to varied industries.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#DharminderKumar">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Dharminder Kumar</div>
                <div class="tup-style-1-designation-en">Manager - Corporate Solutions Group</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 27 TEAM MEMBER safar_al_balushi.png ENDS HERE */}


          {/* 19 TEAM MEMBER Uhood Ali Haji Al Balushi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/uhood_ali_haji_al_balushi.png"} alt="Uhood Ali Haji Al Balushi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has as a total experience of 9 years and has a qualification is accounts and finance and attended CMA training in insurance accounts.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#UhoodAliHajiAlBalushi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Uhood Ali Haji Al Balushi</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Accounts</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>

          {/* 19 TEAM MEMBER Uhood Ali Haji Al Balushi ENDS HERE */}

          {/* 20 TEAM MEMBER Nagaraj Binage STARTS HERE */}
          {/* <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/nagaraj_binage.png"} alt="Nagaraj Binage" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                Nagaraj Binage Content here
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#NagarajBinage">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Nagaraj Binage</div>
                <div class="tup-style-1-designation-en">Manager - Claims & Risk Management Services</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 20 TEAM MEMBER Nagaraj Binage ENDS HERE */}

          {/* 21 TEAM MEMBER Mazoon Al Zadjali  STARTS HERE */}
          {/* <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/mazoon_al_zadjali.png"} alt="Mazoon Al Zadjali" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                She has 12 years’ work experience she is holding business administration degree in marketing and carries rich experience in digital marketing.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#MazoonAlZadjali">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Mazoon Al Zadjali</div>
                <div class="tup-style-1-designation-en">Head of Digital Sales & Marketing</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div> */}
          {/* 21 TEAM MEMBER Mazoon Al Zadjali  ENDS HERE */}

          {/* 22 TEAM MEMBER Iman Juma Mohammed Al Ajmi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/iman_juma_mohammed_al_ajmi.png"} alt="Iman Juma Mohammed Al Ajmi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has 11 years’ experience and has expertise of individual lines of insurance.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#ImanJumaMohammedAlAjmi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Iman Juma Mohammed Al Ajmi</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Digital Sales</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 22 TEAM MEMBER Iman Juma Mohammed Al Ajmi ENDS HERE */}

          {/* 23 TEAM MEMBER Rasha Rashid Hamed Al Alawi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/rasha_rashid_hamed_al_alawi.png"} alt="Rasha Rashid Hamed Al Alawi" />
              </div>
              <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  She has a total of 7 years’ work experience and has the expertise of individual lines of insurance.
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#RashaRashidHamedAlAlawi">Read More </a> </span>
                </div>
              </div>
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Rasha Rashid Hamed Al Alawi</div>
                <div class="tup-style-1-designation-en">Executive - Digital Sales</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 23 TEAM MEMBER Rasha Rashid Hamed Al Alawi ENDS HERE */}

          {/* 24 TEAM MEMBER Tahiya Hamed Salim Alwadhahi STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/tahiya_hamed_salim_alwadhahi.png"} alt="Tahiya Hamed Salim Alwadhahi" />
              </div>
              {/* <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                Tahiya Hamed Salim Alwadhahi Content here
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#TahiyaHamedSalimAlwadhahi">Read More </a> </span>
                </div>
              </div> */}
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Tahiya Hamed Salim Alwadhahi</div>
                <div class="tup-style-1-designation-en">Assistant Manager - Accounts</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 24 TEAM MEMBER Tahiya Hamed Salim Alwadhahi ENDS HERE */}

          {/* 25 TEAM MEMBER safar_al_balushi.png STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/yousuf_al_nasri.png"} alt="Yousuf Al Nasri" />
              </div>
              {/* <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  Yousuf Al Nasri Content here
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#YousufAlNasri">Read More </a> </span>
                </div>
              </div> */}
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Yousuf Al Nasri</div>
                <div class="tup-style-1-designation-en">Logistic Executive</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 25 TEAM MEMBER safar_al_balushi.png ENDS HERE */}

          {/* 26 TEAM MEMBER Yousuf Al Nasri STARTS HERE */}
          <div class="ourteam-item-en">
            <div class="tup-style-1-en">
              <div class="tup-style-1-pic-no-en">
                <img src={process.env.PUBLIC_URL + "/ourteam_images/safar_al_balushi.png"} alt="Safar Al Balushi" />
              </div>
              {/* <div class="tup-style-1-content-en">
                <div class="tup-style-1-biography-en">
                  Yahya Yousuf Ali Alwahaibi Content here
                  <span class="tup-popup-area-1-en">
                    <a data-bs-toggle="modal" data-bs-target="#SafarAlBalushi">Read More </a> </span>
                </div>
              </div> */}
              <div class="tup-style-1-team-info-en">
                <div class="tup-style-1-title-en">Safar Al Balushi</div>
                <div class="tup-style-1-designation-en">Logistic Executive</div>
                <span class="tup-style-1-mail"><a href="mailto:"></a></span>
                <span class="tup-style-1-telephone"></span>
                <span class="tup-style-1-web"><a href=""></a></span>
              </div>
            </div>
          </div>
          {/* 26 TEAM MEMBER Yousuf Al Nasri ENDS HERE */}


        </div>
        {/* OUR TEAM NEW SECTION ENDS HERE */}
      </div>
      {/* </div> */}

      <div class="main-fluid">
        <FooterEN />

        {/* TEAM MEMBER MODAL Vikaus Siingh STARTS HERE */}
        {/* <div class="modal fade" id="ModalOne" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/vikaus_siingh_new.png"} alt="Vikaus Siingh" />
                    <h5 class="team-mempic-nm-en">Vikaus Siingh</h5>
                    <h6 class="team-mempic-dec-en">Head of Insurance Services</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He has done post-graduation Program in Business Management, a law graduate and has done courses in leadership and strategies from Harvard & Wharton universities. He is a licentiate in insurance and carries vast industry experience of over 25 years. He has successfully consulted clients on their Insurance portfolio, considering under insurance and over insurance of assets, handled large property, project and marine claims, managed underwriting, servicing and claims of large portfolios. He has an exposure of arranging re-insurance support for different sectors - Automobiles, Pharma, Project, Infra, Textiles, Telecom, IT and manufacturing. He carries in-depth knowledge of credit and liability</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* TEAM MEMBER MODAL Vikram Aditya HERE */}
        <div class="modal fade" id="VikramAditya" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/vikram_aditya.png"} alt="Vikram Aditya" />
                    <h5 class="team-mempic-nm-en">Vikram Aditya</h5>
                    <h6 class="team-mempic-dec-en">Head of Insurance Services</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He is a Postgraduate Diploma (MBA) holder in Hospital & Health Management with 17 years of industry experience. He has worked with international insurance and broking companies and carries a sound track record of managing Insurance portfolio of large global clients having multi - industry & multi geography presence, few reputed names are PWC, DXC Technologies, Ericsson, ST Micro, Adobe, Huawei, DLF Group, Galfar Engineering and Contracting, Towell Engineering, Mazoon Dairy, ONIEC & many more.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Vikram Aditya ENDS HERE */}

        {/* TEAM MEMBER MODAL Alam Syed Mahetab HERE */}
        <div class="modal fade" id="AlamSyedMahetab" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/alam_syed_mahetab.png"} alt="Alam Syed Mahetab" />
                    <h5 class="team-mempic-nm-en">Alam Syed Mahetab</h5>
                    <h6 class="team-mempic-dec-en">Senior Lead - Corporate Solutions Group</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He has done diploma in insurance from Charted Insurance Institute (CII) UK and is a fellow in insurance from insurance institute of India. He carries bachelor’s degree in science and a post graduate diploma in Banking Operations from Institute of Finance, Banking & Insurance. He has been instrumental in defining the insurance programs of large & mid-size corporates. He has specialization in commercial lines and employee benefit programs. He carries experience in managing specialty lines of Insurance such as Cyber risk, Terrorism, Oil & gas related packages, Liability etc. He has exposure of RI markets and placement of risks with them.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Alam Syed Mahetab ENDS HERE */}


        {/* TEAM MEMBER MODAL Vikaus Siingh ENDS HERE */}

        {/* TEAM MEMBER MODAL Vikram Aditya HERE */}
        <div class="modal fade" id="VikramAditya" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/vikram_aditya.png"} alt="Vikram Aditya" />
                    <h5 class="team-mempic-nm-en">Vikram Aditya</h5>
                    <h6 class="team-mempic-dec-en">Head of Insurance Services</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He is a Postgraduate Diploma (MBA) holder in Hospital & Health Management with 17 years of industry experience. He has worked with international insurance and broking companies and carries a sound track record of managing Insurance portfolio of large global clients having multi - industry & multi geography presence, few reputed names are PWC, DXC Technologies, Ericsson, ST Micro, Adobe, Huawei, DLF Group, Galfar Engineering and Contracting, Towell Engineering, Mazoon Dairy, ONIEC & many more.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Vikram Aditya ENDS HERE */}

        {/* TEAM MEMBER MODAL Arun Kumar Gupta HERE */}
        {/* <div class="modal fade" id="ArunKumarGupta" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/arun_kumar_gupta.png"} alt="Arun Kumar Gupta" />
                    <h5 class="team-mempic-nm-en">Arun Kumar Gupta</h5>
                    <h6 class="team-mempic-dec-en">Head-Claims & Risk Management Services</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He is an associate of chartered insurance practitioner (CII - UK). He is a licensed surveyor & loss adjuster and holds diploma in mechanical and electrical engineering. He has done course on Solar Photovoltaic Systems from IIT Madras in collaboration with Seimens (USA). He has more than 20 years of industry experience and Managed and settled largest Property / Fire damaged claim of Oman. In-depth exposure of handling engineering claims and marine claims of national and multinational clients in Oman. Participated in Zurich Insurance International Program for Management of Claims & Underwriting and Policy and Risk Engineering organised by the FM Global Reinsurer. Contributed with Capital Market Authority, Ministry of Agriculture and Fisheries and Oman Chamber of Commerce and Industry for agriculture development program in Oman. In India, he has exposure of handling claims of Reliance Coal Bed Methane Plant, Orient Paper Mills, Amarkantak Thermal Power Plant, Universal Cables Ltd., Prism Cements Ltd., Star Automobiles Ltd., LIC, Central Bank of India, IndusInd Bank, Shriram Group of Companies, and others</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> */}
        {/* TEAM MEMBER MODAL Arun Kumar Gupta ENDS HERE */}
        {/* TEAM MEMBER MODAL Najma Abdulla Hamed Al Mahrizy HERE */}
        <div class="modal fade" id="NajmaAbdullaHamedAlMahrizy" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/najma_abdulla_hamed_al_mahrizy.png"} alt="Najma Abdulla Hamed Al Mahrizy" />
                    <h5 class="team-mempic-nm-en">Najma Abdulla Hamed Al Mahrizy</h5>
                    <h6 class="team-mempic-dec-en">Lead - Claims & Risk Management</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has diploma in accounts, IT Trained professional with hand on experience of different financial software and hands on experience of ISO procedures. She has proficiency in handling claims - Property, Marine, Projects etc. She maintains good coordination with all loss adjusters in Oman and assist clients on claim intimation, documentations and policy terms / conditions. She has got good report with all insurer's claims team and brings best insurance practices in claim settlements.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Najma Abdulla Hamed Al Mahrizy ENDS HERE */}

        {/* TEAM MEMBER MODAL Qais Ali Thani Al Sulaimi HERE */}
        <div class="modal fade" id="QaisAliThaniAlSulaimi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/qais_ali_thani_al_sulaimi.png"} alt="Qais Ali Thani Al Sulaimi" />
                    <h5 class="team-mempic-nm-en">Qais Ali Thani Al Sulaimi</h5>
                    <h6 class="team-mempic-dec-en">Assistant Manager - Motor Claims</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Qais is a go-getter and always delighted to help customers with their motor insurance claims. He carries more than 10 years of insurance industry experience. He takes care of motor insurance and assists in Mulkiya coordination. He is certified in customer services, accounting, computers and risk management professional. He has also attended CMA training program in money laundering. </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Qais Ali Thani Al Sulaimi ENDS HERE */}

        {/* TEAM MEMBER MODAL Fatma Ismail Hussain Al Bulushi HERE */}
        <div class="modal fade" id="FatmaIsmailHussainAlBulushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/fatma_ismail_hussain_al_bulushi.png"} alt="Fatma Ismail Hussain Al Bulushi" />
                    <h5 class="team-mempic-nm-en">Fatma Ismail Hussain Al Bulushi</h5>
                    <h6 class="team-mempic-dec-en">Assistant Manager - Motor Claims</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>FShe has 7 years’ of work experience and is responsible for managing insurance data internally in KR insurance. </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Fatma Ismail Hussain Al Bulushi ENDS HERE */}

        {/* TEAM MEMBER MODAL Dr Sagar Jagtap HERE */}
        <div class="modal fade" id="DrKiranBhaskarraoKherdekar" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/dr_sagar_jagtap.png"} alt="Dr Sagar Jagtap" />
                    <h5 class="team-mempic-nm-en">Dr Sagar Jagtap</h5>
                    <h6 class="team-mempic-dec-en">Head of Medical & Life Claims</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He is a qualified doctor, done his MBA in health care administration and post graduate diploma in emergency medical services. He carries more than 10 years of industry experience and has expertise in handling medical claims adjudication, medical claims audit, employee wellness programs.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Dr Sagar Jagtap ENDS HERE */}

        {/* TEAM MEMBER MODAL Saniya Mohammed Aslam AL Balushi HERE */}
        <div class="modal fade" id="SaniyaMohammedAslamALBalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/sania_al_balushi.png"} alt="Saniya Mohammed Aslam AL Balushi" />
                    <h5 class="team-mempic-nm-en">Saniya Mohammed Aslam AL Balushi</h5>
                    <h6 class="team-mempic-dec-en">Manager - Medical & Life Claims</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She is certified in managing customer services. She has got more than 7 years of industry experience and has got expertise of handling medical & life claims. She is efficient in processing medical claims and coordinating with insurers for their settlement, well versed with many medical terminologies as used in claims. She maintains good relations with all the insurer’s claim departments and important hospitals. In-depth exposure of using medical claims software for managing claims and generating reports.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Saniya Mohammed Aslam AL Balushi ENDS HERE */}

        {/* TEAM MEMBER MODAL Amani Moosa Sbeit Al Mukhaini HERE */}
        <div class="modal fade" id="AmaniMoosaSbeitAlMukhaini" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/amani_moosa_sbeit_al_mukhaini.png"} alt="Amani Moosa Sbeit Al Mukhaini" />
                    <h5 class="team-mempic-nm-en">Amani Moosa Sbeit Al Mukhaini</h5>
                    <h6 class="team-mempic-dec-en">Executive-Medical & Life Claims</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has 7 years’ experience and is part of medical claims team</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Amani Moosa Sbeit Al Mukhaini ENDS HERE */}

        {/* TEAM MEMBER MODAL Samiya Dur Mohammed Albalushi HERE */}
        <div class="modal fade" id="SamiyaDurMohammedAlbalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/samiya_dur_mohammed_albalushi.png"} alt="Samiya Dur Mohammed Albalushi" />
                    <h5 class="team-mempic-nm-en">Samiya Dur Mohammed Albalushi</h5>
                    <h6 class="team-mempic-dec-en">Executive - Motor Claims</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Samiya is a go-getter and is always delighted to help customers with their motor insurance claims. She carries more than 14 years of diverse industry work experience with hands on exposure of Sales, Consultant. Administration and basic accounting.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Samiya Dur Mohammed Albalushi ENDS HERE */}

        {/* TEAM MEMBER MODAL SEVEN HERE */}
        <div class="modal fade" id="ModalSeven" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/sawsan_al_alawi.png"} alt="Sawsan Al alawi" />
                    <h5 class="team-mempic-nm-en">Sawsan Al Alawi</h5>
                    <h6 class="team-mempic-dec-en">Receivable Management</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has done her higher diploma in information technology and a CMA certified professional in customer services and Grievances. She carries more than 10 years of industry experience adept in managing receivables, client servicing, operations & processes and insurance regulations and compliances.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL SEVEN ENDS HERE */}

        {/* TEAM MEMBER MODAL TEN HERE */}
        <div class="modal fade" id="ModalTen" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/abbas_al_sajwany.png"} alt="Abbas Abdul Hussain" />
                    <h5 class="team-mempic-nm-en">Abbas Abdul Hussain</h5>
                    <h6 class="team-mempic-dec-en">Manager - Corporate Consultancy Group</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He comes with more than 20 years of industry experience and has worked in manufacturing & trading industries currently associated with insurance industry. He has done diploma in electronics and has a certificate in marketing skills from National Institute of sales (NIS). He is proficient in understanding client requirements and suggesting them with the best insurance programs. He carries a very strong relation with all the insurers and the clients assigned to him. He is well versed in commercial and medical lines of insurance.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL TEN ENDS HERE */}
        {/* TEAM MEMBER MODAL ELEVEN HERE */}
        <div class="modal fade" id="ModalEleven" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/hamad_al_hinaai.png"} alt="Hamed Al Hinaai" />
                    <h5 class="team-mempic-nm-en">Hamed Al Hinaai</h5>
                    <h6 class="team-mempic-dec-en">Asst Manager - Corporate Consultancy Group</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He carries more than 10 years of industry experience. He has handled both personal and commercial lines of insurance and shows a great interest in delivering insurance solutions to the clients. He strongly believes in building client relations and providing them comprehensive insurance consultancy on their risk management programs. He has specialization in employee benefit programs, property and project insurance.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL ELEVEN ENDS HERE */}


        {/* TEAM MEMBER MODAL Uhood Ali Haji Al Balushi HERE */}
        <div class="modal fade" id="UhoodAliHajiAlBalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/uhood_ali_haji_al_balushi.png"} alt="Uhood Ali Haji Al Balushi" />
                    <h5 class="team-mempic-nm-en">Uhood Ali Haji Al Balushi</h5>
                    <h6 class="team-mempic-dec-en">Assistant Manager - Accounts</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has as a total experience of 9 years and has a qualification is accounts and finance and attended CMA training in insurance accounts.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Uhood Ali Haji Al Balushi ENDS HERE */}

        {/* TEAM MEMBER MODAL Nagaraj Binage HERE */}
        <div class="modal fade" id="NagarajBinage" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/nagaraj_binage.png"} alt="Nagaraj Binage" />
                    <h5 class="team-mempic-nm-en">Nagaraj Binage</h5>
                    <h6 class="team-mempic-dec-en">Manager - Claims & Risk Management Services</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Nagaraj Binage Content Here</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Nagaraj Binage ENDS HERE */}

        {/* TEAM MEMBER MODAL Mazoon Al Zadjali HERE */}
        <div class="modal fade" id="MazoonAlZadjali" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/mazoon_al_zadjali.png"} alt="Mazoon Al Zadjali" />
                    <h5 class="team-mempic-nm-en">Mazoon Al Zadjali</h5>
                    <h6 class="team-mempic-dec-en">Head of Digital Sales & Marketing</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has 12 years’ work experience she is holding business administration degree in marketing and carries rich experience in digital marketing. Social media promotions, event management and branding. She has delivered client satisfaction across different countries i.e. Oman, UAE, US, Thailand, and across various industries. In her current role she is responsible for digital sales and marketing of our e-initiatives</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Mazoon Al Zadjali ENDS HERE */}

        {/* TEAM MEMBER MODALIman Juma Mohammed Al Ajmi HERE */}
        <div class="modal fade" id="ImanJumaMohammedAlAjmi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/iman_juma_mohammed_al_ajmi.png"} alt="Iman Juma Mohammed Al Ajmi" />
                    <h5 class="team-mempic-nm-en">Iman Juma Mohammed Al Ajmi</h5>
                    <h6 class="team-mempic-dec-en">Assistant Manager - Digital Sales</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has 11 years’ experience and has expertise of individual lines of insurance.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Iman Juma Mohammed Al Ajmi ENDS HERE */}

        {/* TEAM MEMBER MODAL Rasha Rashid Hamed Al Alawi HERE */}
        <div class="modal fade" id="RashaRashidHamedAlAlawi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/rasha_rashid_hamed_al_alawi.png"} alt="Rasha Rashid Hamed Al Alawi" />
                    <h5 class="team-mempic-nm-en">Rasha Rashid Hamed Al Alawi</h5>
                    <h6 class="team-mempic-dec-en">Executive - Digital Sales</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>She has a total of 7 years’ work experience and has the expertise of individual lines of insurance.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Rasha Rashid Hamed Al Alawi ENDS HERE */}

        {/* TEAM MEMBER MODAL Tahiya Hamed Salim Alwadhahi STARTS HERE */}
        <div class="modal fade" id="TahiyaHamedSalimAlwadhahi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/tahiya_hamed_salim_alwadhahi.png"} alt="Tahiya Hamed Salim Alwadhahi" />
                    <h5 class="team-mempic-nm-en">Tahiya Hamed Salim Alwadhahi</h5>
                    <h6 class="team-mempic-dec-en">Assistant Manager - Accounts</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Tahiya Hamed Salim Alwadhahi Content Here</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Tahiya Hamed Salim Alwadhahi ENDS HERE */}

        {/* TEAM MEMBER MODAL Yahya Yousuf Ali Alwahaibi STARTS HERE */}
        <div class="modal fade" id="YahyaYousufAliAlwahaibi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/yahya_yousuf_ali_alwahaibi.png"} alt="Yahya Yousuf Ali Alwahaibi" />
                    <h5 class="team-mempic-nm-en">Yahya Yousuf Ali Alwahaibi</h5>
                    <h6 class="team-mempic-dec-en">Executive</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Yahya Yousuf Ali Alwahaibi Content Here</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Yahya Yousuf Ali Alwahaibi ENDS HERE */}

        {/* TEAM MEMBER MODAL Safar Sabil Khudadat Al Balushi STARTS HERE */}
        <div class="modal fade" id="SafarSabilKhudadatAlBalushi" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/safar_sabil_khudadat_al_balushi.png"} alt="Safar Sabil Khudadat Al Balushi" />
                    <h5 class="team-mempic-nm-en">Safar Sabil Khudadat Al Balushi</h5>
                    <h6 class="team-mempic-dec-en">Executive</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Safar Sabil Khudadat Al Balushi Content Here</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Safar Sabil Khudadat Al Balushi ENDS HERE */}

        {/* TEAM MEMBER MODAL THIRTEEN HERE */}
        <div class="modal fade" id="ModalThirteen" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/ghalib_al_siyabi.png"} alt="Ghalib Al Siyabi" />
                    <h5 class="team-mempic-nm-en">Ghalib Al Siyabi</h5>
                    <h6 class="team-mempic-dec-en">Customer Solutions Group</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He has bachelor’s degree in public relations and got certified in business management from Khimji training institute.  He carries more than 5 years of industry experience and has in depth knowledge of client servicing and managing relation with insured & insurers. His has experience of handling all lines of insurance programs, due diligence of policy and claims documents, handling client’s regular insurance requirements. </p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL THIRTEEN ENDS HERE */}

        {/* TEAM MEMBER MODAL Suad Abdullah Humaid Al Kalbani HERE */}
        <div class="modal fade" id="SuadAbdullahHumaidAlKalbani" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/suad_abdullah_humaid_al_kalbani.png"} alt="Suad Abdullah Humaid Al Kalbani" />
                    <h5 class="team-mempic-nm-en">Suad Abdullah Humaid Al Kalbani</h5>
                    <h6 class="team-mempic-dec-en">Assistant Manager - Corporate Solutions Group</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>Suad has over 11 years of insurance and hospital industry experience. Presently, she is responsible in managing customer relations and providing them best & prompt insurance services at all times. To improve her skill set, she has successfully completed many courses related to the industry.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Suad Abdullah Humaid Al Kalbani ENDS HERE */}

        {/* TEAM MEMBER MODAL G G Jeswanth Alister David HERE */}
        {/* <div class="modal fade" id="GJeswanthAlisterDavid" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header border-bottom-0 p-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="team-mem-en">
                  <div class="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/g_jeswanth_alister_david.png"} alt="G Jeswanth Alister David" />
                    <h5 class="team-mempic-nm-en">G Jeswanth Alister David</h5>
                    <h6 class="team-mempic-dec-en">Lead - Corporate Consultancy Group</h6>
                  </div>
                  <div class="team-memcon-en">
                    <p>He carries more than 20 years of industry experience of Sales & Marketing, Retention & Client Management. He has done Advanced Reinsurance Training Program and licentiate program in insurance. He has handled major clients insurance program related to Glass Manufacturing, Automobile, Textiles, Power Plants, Sugar Plants, Oil/Gas & Telecom sectors etc. He has in depth knowledge of medical, property and project lines of insurance. He also carries vast experience of managing global clients in Oman, suggesting and arranging risk management programs for them. Renewal Retention Management-Focusing on all the requirements i.e Renewals/Claims/Policy issuance/Day to day requirements of the clients is his forte.</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div> */}
        {/* TEAM MEMBER MODAL G Jeswanth Alister David ENDS HERE */}

        {/* TEAM MEMBER MODAL Dharminder Kumar HERE */}
        <div className="modal fade" id="DharminderKumar" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 p-0">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="team-mem-en">
                  <div className="team-mempic-en">
                    <img src={process.env.PUBLIC_URL + "/ourteam_images/dharminder_kumar.png"} alt="Dharminder Kumar" />
                    <h5 className="team-mempic-nm-ar">Dharminder Kumar</h5>
                    <h6 className="team-mempic-dec-ar">Lead - Corporate Consultancy Group</h6>
                  </div>
                  <div className="team-memcon-en">
                    <p>Experienced professional carrying more than a decade experience of in the insurance industry. He has worked in reputed organizations like ICICI LOMBARD, HDFC Ergo, and Bharti AXA. He has in-depth domain knowledge of insurance, risk analysis, and portfolio management of customers pertaining to varied industries.</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        {/* TEAM MEMBER MODAL Dharminder Kumar ENDS HERE */}
      </div>
    </div>
  );
}

export default OurteamPage;