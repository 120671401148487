import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arhomeinsurance.scss';

const ARHomeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="homeinsu-tab-imagebannermain-ar">
          <div class="homeinsu-banner-con-ar">
            <div class="homeinsutab-banne-sec-ar">
              <h1 class="homeinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="homeinsu-tab-btn-ar">
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="homeinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="homeinsu-banner-con-ar">
          <div class="homeinsu-bdy-ar">
            <div class="homeinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="homeinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance_ar.png"} alt="التأمين على المنازل " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="homeinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين على المنازل </h2>

                <p class="pg-txt-con-ar">هل سمعت من قبل عن التأمين على المنزل؟ أتتساءل عما يكون؟ </p>
                
                <p class="pg-txt-con-ar">في عالم التأمين، غالبًا ما تكون بوالص التأمين على المنزل واحدة من أكثر البوالص شمولًا. عادةً ما توفر بوليصة التأمين على المنزل حماية شاملة تغطيك وتغطي منزلك وأفراد أسرتك وأيضًا أي مسؤولية قد تنشأ. يوفر التأمين على المنزل تغطية تمتد إلى ما هو أبعد من مجرد هيكل المنزل. يمكن تصنيف أهم ميزات بوليصة التأمين على المنزل إلى جزأين، بناءً على نوع التغطية التي توفرها.وهم</p>

                <div class="homeinsu-kft-ar">
                  <p class="homeinsu-kft-item-ar">تغطية المتلفات <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
                  <p class="homeinsu-kft-item-ar">تغطية الالتزامات <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p></div>
                  
                  <h3 class="homeinsu-page-sbu-title-ar">التغطية الرئيسية/ التأمين على المنزل </h3>
                  
                  <p class="homeinsu-txt-con-ar">تغطي بوليصة التأمين على المنزل مجموعة متنوعة من الجوانب وتوفر تغطية أكثر أهمية لحماية منزلك. الملامح الرئيسية كالآتي:</p>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            
            <div class="homeinsukeyfeatures-ar">
                <div class="homeinsukeyfeatures-item-ar">التغطية ضد الأحداث غير المتوقعة والكوارث الطبيعية</div>
              <div class="homeinsukeyfeatures-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsukeyfeatures-item-ar">تضمن كيمجي رامداس لخدمات التأمين لك منزلًا مؤمن ضد الكوارث مثل الحرائق والزلازل والبرق والعواصف والفيضانات والغارات وآثار أعمال الشغب وتسرب المياه والسرقة وما إلى ذلك </div>
              <div class="homeinsukeyfeatures-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsukeyfeatures-item-ar">استبدال القديم بالجدي </div>
              <div class="homeinsukeyfeatures-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsukeyfeatures-item-ar">يتم تغطية جميع محتويات المنزل تقريبًا بموجب خطة التأمين على المنزل. ومع ذلك، فهي لا تشمل الملابس والمفروشات المنزلي </div>
              <div class="homeinsukeyfeatures-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsukeyfeatures-item-ar">سكن بديل </div>
              <div class="homeinsukeyfeatures-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsukeyfeatures-item-ar">يحميك من أي مسائلة قانونية في حالة حدوث أي ضرر أو أذى جسدي يحدث لأي شخص وذلك عند حدوث أي ضرر / إصابة جسدية أو ضرر لشخص ثالث أو أغراض، في المبنى الخاص بك أو من خلالك </div>
              <div class="homeinsukeyfeatures-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <h3 class="homeinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="homeinsu-pins-list-ar">
                <div class="homeinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="homeinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="homeinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="homeinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="homeinsu-tab-imagebannermain-ar">
          <div class="homeinsu-banner-con-ar">
            <div class="homeinsutab-banne-sec-ar">
              <h1 class="homeinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="homeinsu-tab-btn-ar">
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="homeinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="homeinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="homeinsu-banner-con-ar">
        <div className="tab-imgtxt-ar">
          <h2>التأمين على المنازل </h2>
          <div className="tabs-cont-ar">
            <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance_ar.png"} alt="التأمين على المنازل " />
            <p>هل سمعت من قبل عن التأمين على المنزل؟ أتتساءل عما يكون؟ </p>

            <p>في عالم التأمين، غالبًا ما تكون بوالص التأمين على المنزل واحدة من أكثر البوالص شمولًا. عادةً ما توفر بوليصة التأمين على المنزل حماية شاملة تغطيك وتغطي منزلك وأفراد أسرتك وأيضًا أي مسؤولية قد تنشأ. يوفر التأمين على المنزل تغطية تمتد إلى ما هو أبعد من مجرد هيكل المنزل. يمكن تصنيف أهم ميزات بوليصة التأمين على المنزل إلى جزأين، بناءً على نوع التغطية التي توفرها.وهم</p>

            <p>تغطية المتلفات. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>

            <p>تغطية الالتزامات. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>

            <h3 class="tab-sub-title-ar">التغطية الرئيسية/ التأمين على المنزل </h3>

            <p>تغطي بوليصة التأمين على المنزل مجموعة متنوعة من الجوانب وتوفر تغطية أكثر أهمية لحماية منزلك. الملامح الرئيسية كالآتي</p>

            <div class="tabkyfet-ar">
              <div class="tabkeyfet-txt-ar">التغطية ضد الأحداث غير المتوقعة والكوارث الطبيعية </div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">تضمن كيمجي رامداس لخدمات التأمين لك منزلًا مؤمن ضد الكوارث مثل الحرائق والزلازل والبرق والعواصف والفيضانات والغارات وآثار أعمال الشغب وتسرب المياه والسرقة وما إلى ذلك</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">استبدال القديم بالجديد</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">يتم تغطية جميع محتويات المنزل تقريبًا بموجب خطة التأمين على المنزل. ومع ذلك، فهي لا تشمل الملابس والمفروشات المنزلية</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">سكن بديل</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">يحميك من أي مسائلة قانونية في حالة حدوث أي ضرر أو أذى جسدي يحدث لأي شخص وذلك عند حدوث أي ضرر / إصابة جسدية أو ضرر لشخص ثالث أو أغراض، في المبنى الخاص بك أو من خلالك</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}

          </div>
        </div>
        </div>
        
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="homeinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"home-insurance-ar"} selected class="active">التأمين على المنازل</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="homeinsu-mob-main-ar">
        <h1 class="homeinsu-banner-heading-ar">التأمين على المنازل </h1>
              <div class="homeinsu-mob-img-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance_ar.png"} alt="التأمين على المنازل " />
              </div>
              <p class="homeinsu-mob-txt-ar">هل سمعت من قبل عن التأمين على المنزل؟ أتتساءل عما يكون؟ ?</p>

              <p class="homeinsu-mob-txt-ar">في عالم التأمين، غالبًا ما تكون بوالص التأمين على المنزل واحدة من أكثر البوالص شمولًا. عادةً ما توفر بوليصة التأمين على المنزل حماية شاملة تغطيك وتغطي منزلك وأفراد أسرتك وأيضًا أي مسؤولية قد تنشأ. يوفر التأمين على المنزل تغطية تمتد إلى ما هو أبعد من مجرد هيكل المنزل. يمكن تصنيف أهم ميزات بوليصة التأمين على المنزل إلى جزأين، بناءً على نوع التغطية التي توفرها.وهم</p>

              <div class="homeinsu-mob-keypoint-ar">
                <p class="homeinsu-mob-keypoint-item-ar">تغطية المتلفات. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
                <p class="homeinsu-mob-keypoint-item-ar">تغطية الالتزامات. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
              </div>
              
              <h3 class="homeinsu-subtitle-ar">التغطية الرئيسية/ التأمين على المنزل </h3>

              <p class="homeinsu-mob-txt-ar">تغطي بوليصة التأمين على المنزل مجموعة متنوعة من الجوانب وتوفر تغطية أكثر أهمية لحماية منزلك. الملامح الرئيسية كالآتي</p>

              {/* QUESTION SECTION STARTS HERE */}
              <div class="homeinsu-mob-keyfeatures-ar">
                <div class="homeinsu-mob-keyfeatures-q-ar">التغطية ضد الأحداث غير المتوقعة والكوارث الطبيعية. </div>
                <div class="homeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsu-mob-keyfeatures-q-ar">تضمن كيمجي رامداس لخدمات التأمين لك منزلًا مؤمن ضد الكوارث مثل الحرائق والزلازل والبرق والعواصف والفيضانات والغارات وآثار أعمال الشغب وتسرب المياه والسرقة وما إلى ذلك</div>

              <div class="homeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsu-mob-keyfeatures-q-ar">استبدال القديم بالجديد</div>
                
              <div class="homeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsu-mob-keyfeatures-q-ar">يتم تغطية جميع محتويات المنزل تقريبًا بموجب خطة التأمين على المنزل. ومع ذلك، فهي لا تشمل الملابس والمفروشات المنزلية</div>

              <div class="homeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsu-mob-keyfeatures-q-ar">سكن بديل</div>

              <div class="homeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="homeinsu-mob-keyfeatures-q-ar">يحميك من أي مسائلة قانونية في حالة حدوث أي ضرر أو أذى جسدي يحدث لأي شخص وذلك عند حدوث أي ضرر / إصابة جسدية أو ضرر لشخص ثالث أو أغراض، في المبنى الخاص بك أو من خلالك</div>
                <div class="homeinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                
            </div>
            {/* QUESTION SECTION ENDS HERE */}
            
            <h3 class="homeinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            
            <div class="homeinsu-mob-whykrlist-ar">
              <div class="homeinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="homeinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="homeinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="homeinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARHomeInsurance;