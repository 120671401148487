import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './employeewellnessprogram.scss';

const EmployeeWellnessProgram = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="d-none d-md-block">
        <div class="empwellpro-tab-imagebannermain-en">
          <div class="empwellpro-banner-con-en">
            <div class="empwellprotab-banne-sec-en">
              <h1 class="empwellpro-banner-heading-en">Valued Services</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "risk-inspection-services"}>Risk Inspection Services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "insurance-audits"}>Insurance Audits</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "valuation-services"}>Valuation services</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "thermography-testing-insurance"}>Thermography Testing</div>
                {/* <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "policy-renewalalerts"}>Policy Renewal Alerts</div> */}
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "employee-wellness-program"}>Employee Wellness Program</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}

              <div class="best-responsive-tab" id="myExampleBestResponsiveTab1" role="tablist" data-target-content="#myExampleTabsContent">
                {/* {
              tabsList && tabsList.length ? tabsList.map(( each, index ) => <div data-target-index={index} className={index == tab ? "active" : "def"} onClick={() => {
                setTab(index)
                window.location.href = "personalinsurance/" + each.url
              }}>{each.text}</div>) : null

            } */}
                {/* <div data-target-index="0">Travel Insurance</div>
            <div data-target-index="1" onClick={() => window.location.href = "personalinsurance/life-insurance"}>Life Insurance</div>
            <div data-target-index="2">Personal Accident Insurance</div>
            <div data-target-index="3">Relocation Insurance</div>
            <div data-target-index="4">Health Insurance</div>
            <div data-target-index="5">Art insurance</div>
            <div data-target-index="6">Golf Insurance</div>
            <div data-target-index="7">Motor Insurance</div>
            <div data-target-index="8">Housemaid Insurance</div>
            <div data-target-index="9">Home Insurance</div>
            <div data-target-index="10">Yacht Insurance</div> */}


              </div>
            </div>
          </div>
        </div>
        <div class="empwellpro-banner-con-en">
          <div class="empwellpro-bdy-en">
            {/* <h2 class="pagetitle-en">Insurance Audits</h2> */}
            <div class="empwellpro-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="empwellpro-dlex-lft-en">
                <h2 class="pagetitle-en">KR Insurance's People Wellness Program</h2>

                <p class="empwellpro-pg-txt-con"><strong>We create customized and exciting wellness programs for you!</strong></p>

                <p class="empwellpro-pg-txt-con">In our endeavor to deliver the best services and to add value to your health insurance program, we propose value-added services to the employees associated with your esteemed organization. This is purely an initiative taken at our end for the benefit of your employees with nil cost implications.</p>

                <p class="empwellpro-pg-txt-con">KR Insurance Services have tied up with renowned hospitals in Oman for bringing health awareness and stimulating good health practices among all your employees.</p>

                <p class="empwellpro-pg-txt-con">We at KR Insurance Services look forward for conducting an insurance audit for your organization’s existing insurance policies and ensuring best insurance covers for the risk associated with your business.</p>

                <h3 class="empwellpro-whykrinsu-title-en">Health Sessions</h3>
                
                <div class="clearfix"></div>
                </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="empwellpro-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program.png"} alt="employee wellness program" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="empwellpro-txt-con">The health wellness session will be conducted by renowned medical practitioners and the duration of the session shall be between 25 to 40 minutes. The sessions will be delivered in your office via audio-video presentations. You can choose any one of the following topics for our proposed health wellness sessions - </p>

            <h3 class="empwellpro-page-sbu-title-en">Health Camps </h3>

            <p class="empwellpro-txt-con">The health camp will be organized inside your office building by a medical practitioner and nurses, supported by best-in-class medical equipment for conducting it. We will be requiring space i.e. a meeting room or some unoccupied area, to set up our health camp and to maintain employee privacy. Employees will be benefitted from one-to-one consultation with the General Practitioner. All the employees, attending the health camp will be provided with their medical reports.</p>

            <p class="empwellpro-txt-con">Please contact us for more details <a href="mailto:insurance@kr.om">insurance@kr.om</a></p>



            <h3 class="empwellpro-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>

            <div class="empwellpro-pins-list-dksp-en">
              <div class="empwellpro-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Lowest Quotations</div>
              <div class="empwellpro-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Prompt Claim Services</div>
              <div class="empwellpro-lt-one-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Wide Quote Comparisons</div>
              <div class="empwellpro-lt-two-dksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Best Insurance Industry Practices</div>
            </div>

            {/* <h3 class="empwellpro-page-sbu-title-en"></h3>

            <p class="empwellpro-txt-con"></p>
            
            <div class="empwellpro-kpnt-en">
              <div class="empwellpro-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /></div>
              <div class="empwellpro-kpnt-item-en"></div>
              
              <div class="empwellpro-kpnt-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /></div>
              <div class="empwellpro-kpnt-item-en"></div>
            </div> */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="empwellpro-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services"}>Risk Inspection Services</option>
            <option value={"insurance-audits"}>Insurance Audits</option>
            <option value={"valuation-services"}>Valuation Services</option>
            <option value={"thermography-testing-insurance"}>Thermography Testing</option>
            {/* <option value={"policy-renewalalerts"}>Policy Renewal Alerts</option> */}
            <option value={"employee-wellness-program"} selected class="active">Employee Wellness Program</option>
          </select>
        </div>
        <div class="empwellpro-mob-main-en">
          <h1 class="empwellpro-banner-heading-en">KR Insurance's People Wellness Program</h1>
          <div class="empwellpro-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program.png"} alt="employee wellness program" />
          </div>

          <p class="empwellpro-mob-txt-en"><strong>We create customized and exciting wellness programs for you!</strong></p>

          <p class="empwellpro-mob-txt-en">In our endeavor to deliver the best services and to add value to your health insurance program, we propose value-added services to the employees associated with your esteemed organization. This is purely an initiative taken at our end for the benefit of your employees with nil cost implications.</p>

          <p class="empwellpro-mob-txt-en">KR Insurance Services have tied up with renowned hospitals in Oman for bringing health awareness and stimulating good health practices among all your employees.</p>

          <p class="empwellpro-mob-txt-en">We at KR Insurance Services look forward for conducting an insurance audit for your organization’s existing insurance policies and ensuring best insurance covers for the risk associated with your business.</p>
          
          <h3 class="empwellpro-whykrinsu-mob-title-en">Health Sessions</h3>

          <p class="empwellpro-mob-txt-en">The health wellness session will be conducted by renowned medical practitioners and the duration of the session shall be between 25 to 40 minutes. The sessions will be delivered in your office via audio-video presentations. You can choose any one of the following topics for our proposed health wellness sessions -</p>

          <h3 class="empwellpro-whykrinsu-mob-title-en">Health Camps</h3>

          <p class="empwellpro-mob-txt-en">The health camp will be organized inside your office building by a medical practitioner and nurses, supported by best-in-class medical equipment for conducting it. We will be requiring space i.e. a meeting room or some unoccupied area, to set up our health camp and to maintain employee privacy. Employees will be benefitted from one-to-one consultation with the General Practitioner. All the employees, attending the health camp will be provided with their medical reports.</p>

          <p class="empwellpro-mob-txt-en">Please contact us for more details <a href="mailto:insurance@kr.om">insurance@kr.om</a></p>
          

          <h3 class="empwellpro-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="empwellpro-mob-whykrlist-en">
            <div class="empwellpro-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Lowest Quotations</div>
            <div class="empwellpro-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Prompt Claim Services</div>
            <div class="empwellpro-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Wide Quote Comparisons</div>
            <div class="empwellpro-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheld" /> Best Insurance Industry Practices</div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default EmployeeWellnessProgram;
