import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './energyinsurance.scss';

const EnergyInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="energyinsu-tab-imagebannermain-en">
          <div class="energyinsu-banner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="energyinsu-banner-heading-en">Business Insurance</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="energyinsu-banner-con-en">
          <div class="energyinsu-bdy-en">
            <div class="energyinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="energyinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Energy Insurance</h2>
                <p class="energyinsu-txt-con">KR Insurance Services is committed to protect the risks faced by the energy sector, whether you work in oil and gas, power and utility, or alternative energy. We are serving both the onshore and the offshore oil and gas industries, through customized approach for the most demanding global energy insurance challenges.</p>

                <p class="energyinsu-txt-con">We can offer market-leading capacity for a wide range of energy risks, underpinned by market-leading financial securities, rated 'AA' by Standard & Poor's and A+ by A.M.Best.</p>

                <p class="energyinsu-txt-con">KR Insurance Services has got most experienced claims &amp; risk consulting teams in the <strong>energy insurance</strong> lines; we pride ourselves on our fast decision making and flexible approach to our clients' challenges.</p>

                <p class="energyinsu-txt-con">Offshore and Onshore Energy Insurance</p>
                <h3 class="energyinsu-page-sbu-title-en">Main Cover / Energy Insurance</h3>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="energyinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance.png"} alt="Energy Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>


            <p class="energyinsu-txt-con"><strong>Our services to these specialist clients are always individually designed to their needs, and include:</strong></p>

            <div class="energyinsu-pins-list-dsk-en">
              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Onshore property, including equipment coverage</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Offshore property, including rig physical damage and offshore construction</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Construction coverage for onshore energy projects from our specialist&nbsp;engineering team</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Business interruption/loss of production income</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Control of well/operators extra expense costs/re-drill/seepage/pollution</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Excess liability/umbrella</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">General liability</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Hull and machinery/total loss cover/freight interest/loss of hire</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Package options, including:</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Boiler and machinery</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Cargo, transit</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Project Cargo</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Contractor's equipment</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Extra expense</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Miscellaneous marine liability</div>

              <div class="energyinsu-pins-list-dsk-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="energyinsu-pins-list-dsk-item-en">Third party liability</div>

            </div>
            <h3 class="energyinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>
            <div class="energyinsu-pins-list-lftdksp-en">
              <div class="energyinsu-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="energyinsu-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="energyinsu-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="energyinsu-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="energyinsu-tab-imagebannermain-en">
          <div class="energyinsu-banner-con-en">
            <div class="propertyinsutab-banne-sec-en">
              <h1 class="energyinsu-banner-heading-en">Business Insurance</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="grpacc-covinsu-tab-btn-en">
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="grpacc-insu-tab-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="grpacc-insu-tab-btn-item-active-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="energyinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Energy Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance.png"} alt="Energy Insurance" />

              <p>KR Insurance Services is committed to protect the risks faced by the energy sector, whether you work in oil and gas, power and utility, or alternative energy. We are serving both the onshore and the offshore oil and gas industries, through customized approach for the most demanding global energy insurance challenges.</p>
              
              <p>We can offer market-leading capacity for a wide range of energy risks, underpinned by market-leading financial securities, rated 'AA' by Standard & Poor's and A+ by A.M.Best.</p>
              
              <p>KR Insurance Services has got most experienced claims &amp; risk consulting teams in the <strong>energy insurance</strong> lines; we pride ourselves on our fast decision making and flexible approach to our clients' challenges.</p>
              
              <p>Offshore and Onshore Energy Insurance</p>

              <h3 className="tab-sub-title-en">Main Cover / Energy Insurance</h3>

              <p><strong>Our services to these specialist clients are always individually designed to their needs, and include:</strong></p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Onshore property, including equipment coverage</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Offshore property, including rig physical damage and offshore construction</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Construction coverage for onshore energy projects from our specialist&nbsp;engineering team</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Business interruption/loss of production income</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Control of well/operators extra expense costs/re-drill/seepage/pollution</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Excess liability/umbrella</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">General liability</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Hull and machinery/total loss cover/freight interest/loss of hire</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Package options, including:</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Boiler and machinery</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Cargo, transit</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Project Cargo</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Contractor's equipment</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Extra expense</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Miscellaneous marine liability</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Third party liability</div>
              </div>
              
              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="energyinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"} selected class="active">Energy Insurance</option>
          </select>
        </div>
        <div class="energyinsu-mob-main-en">
          <h1 class="energyinsu-banner-heading-en">Energy Insurance</h1>
          <div class="energyinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance.png"} alt="Energy Insurance" />
          </div>

          <p class="energyinsu-mob-txt-en">KR Insurance Services is committed to protect the risks faced by the energy sector, whether you work in oil and gas, power and utility, or alternative energy. We are serving both the onshore and the offshore oil and gas industries, through customized approach for the most demanding global energy insurance challenges.</p>

          <p class="energyinsu-mob-txt-en">We can offer market-leading capacity for a wide range of energy risks, underpinned by market-leading financial securities, rated 'AA' by Standard & Poor's and A+ by A.M.Best.</p>

          <p class="energyinsu-mob-txt-en">KR Insurance Services has got most experienced claims &amp; risk consulting teams in the <strong>energy insurance</strong> lines; we pride ourselves on our fast decision making and flexible approach to our clients' challenges.</p>

          <p class="energyinsu-mob-txt-en">Offshore and Onshore Energy Insurance</p>

          <h3 class="energyinsu-subtitle-en">Main Cover / Energy Insurance</h3>

          <p class="energyinsu-mob-txt-en"><strong>Our services to these specialist clients are always individually designed to their needs, and include: </strong></p>

          <div class="energyinsu-pins-list-mob-en">
            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Onshore property, including equipment coverage</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Offshore property, including rig physical damage and offshore construction</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Construction coverage for onshore energy projects from our specialist&nbsp;<a href="http://www.agcs.allianz.com/services/engineering/" target="_blank">engineering</a> team</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Business interruption/loss of production income</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Control of well/operators extra expense costs/re-drill/seepage/pollution</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Excess liability/umbrella</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">General liability</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en"><a href="http://www.agcs.allianz.com/services/marine/hull/" target="_blank">Hull and machinery</a>/total loss cover/freight interest/loss of hire</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Package options, including:</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Boiler and machinery</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Cargo, transit</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Project Cargo</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Contractor's equipment</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Extra expense</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Miscellaneous marine liability</div>

            <div class="energyinsu-pins-list-mob-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="energyinsu-pins-list-mob-item-en">Third party liability</div>
          </div>
          <h3 class="energyinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="energyinsu-mob-whykrlist-en">
            <div class="energyinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="energyinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="energyinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="energyinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default EnergyInsurance;