import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './lifeinsurance.scss';

const LifeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="lifeinsu-tab-imagebannermain-en">
          <div class="lifeinsu-banner-con-en">
            <div class="lifeinsu-tab-banne-sec-en">
              <h1 class="lifeinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="lifeinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="lifeinsu-banner-con-en">
          <div class="lifeinsu-bdy-en">
            <div class="lifeinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="lifeinsu-dlex-lft-en">
              <h2 class="pagetitle-en">Life Insurance</h2>
                <p class="pg-txt-con">Safeguarding your family and taking care of them is the primary concern for every individual. At times this thought can become a burden so heavy that you spend sleepless nights wondering ‘What to do?’  Rest assured and sleep peacefully. KR Insurance Services is here to put your worries to rest.</p>
                <p class="pg-txt-con">The Life Plans by KR Insurance Services offers you and your family a sense of stability and serenity by giving you a financial cushion when unforeseen events lead to a loss of your household income. This Life Plan is one of the most cost-effective ways which will give financial independence to you and your loved ones.</p>
                <p class="pg-txt-con">The Life insurance premiums are economical with respect to the higher coverage amount it offers. Life insurance secures your family's future. Take the most important decision of tomorrow, TODAY. Make Life insurance your best friend and protect your family.</p>

                <h3 class="lifeinsu-page-sbu-title-en">Main Cover / Life Insurance</h3>

                <p class="pg-txt-con">Benefits of Life Insurance Plan - </p>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="lifeinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/life_insurance.png"} alt="life insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="benefits-lifeinsu-features-en">
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                Get lump sum amount in the event sudden death
              </p>
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                See off all your loans and liabilities
              </p>
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                Provide money so that your family continues to live with pride
              </p>
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                Life Insurance also takes care of family in case of your disability or critical illness.
              </p>
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                Provides supplementary income in case of loss of income due to accidental disability or illness
              </p>
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                Get lump sum amount if diagnosed with critical illness
              </p>
              <p class="benefits-lifeinsu-features-item-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                Additional sum insured in case of accidental death
              </p>
            </div>

            <h3 class="whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>
            <div class="lifeinsu-pins-list-en">
              <div class="lifeinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="lifeinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="lifeinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="lifeinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="lifeinsu-tab-imagebannermain-en">
          <div class="lifeinsu-banner-con-en">
            <div class="lifeinsu-tab-banne-sec-en">
              <h1 class="lifeinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="lifeinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="lifeinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Life Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/life_insurance.png"} alt="life insurance" />

              <p>Safeguarding your family and taking care of them is the primary concern for every individual. At times this thought can become a burden so heavy that you spend sleepless nights wondering ‘What to do?’  Rest assured and sleep peacefully. KR Insurance Services is here to put your worries to rest.</p>

              <p>The Life Plans by KR Insurance Services offers you and your family a sense of stability and serenity by giving you a financial cushion when unforeseen events lead to a loss of your household income. This Life Plan is one of the most cost-effective ways which will give financial independence to you and your loved ones.</p>

              <p>The Life insurance premiums are economical with respect to the higher coverage amount it offers. Life insurance secures your family's future. Take the most important decision of tomorrow, TODAY. Make Life insurance your best friend and protect your family.</p>

              <h3 class="tab-sub-title-en">Main Cover / Life Insurance</h3>
              
              <p>Benefits of Life Insurance Plan - </p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Get lump sum amount in the event sudden death</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">See off all your loans and liabilities</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Provide money so that your family continues to live with pride</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Life Insurance also takes care of family in case of your disability or critical illness.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Provides supplementary income in case of loss of income due to accidental disability or illness</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Get lump sum amount if diagnosed with critical illness</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Additional sum insured in case of accidental death</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="lifeinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"} selected class="active">Life Insurance</option>
          </select>
        </div>
        <div class="lifeinsu-mob-main-en">
          <h1 class="lifeinsu-mob-main-title-en">Life Insurance</h1>
          <div class="lifeinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/life_insurance.png"} alt="life insurance" />
          </div>
          <p class="lifeinsu-mob-txt-en">Safeguarding your family and taking care of them is the primary concern for every individual. At times this thought can become a burden so heavy that you spend sleepless nights wondering 'What to do?' Rest assured and sleep peacefully. KR Insurance Services is here to put your worries to rest.</p>

          <p class="lifeinsu-mob-txt-en">The Life Plans by KR Insurance Services offers you and your family a sense of stability and serenity by giving you a financial cushion when unforeseen events lead to a loss of your household income. This Life Plan is one of the most cost-effective ways which will give financial independence to you and your loved ones.</p>

          <p class="lifeinsu-mob-txt-en">The Life insurance premiums are economical with respect to the higher coverage amount it offers. Life insurance secures your family's future. Take the most important decision of tomorrow, TODAY. Make Life insurance your best friend and protect your family.</p>
          
          <h3 class="lifeinsu-subtitle-en">Main Cover / Life Insurance</h3>

          <p class="lifeinsu-mob-txt-en">Benefits of Life Insurance Plan -</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="lifeinsu-mob-keyfeatures-en">
            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">Get lump sum amount in the event sudden death</div>

            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">See off all your loans and liabilities</div>

            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">Provide money so that your family continues to live with pride</div>

            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">Life Insurance also takes care of family in case of your disability or critical illness.</div>

            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">Provides supplementary income in case of loss of income due to accidental disability or illness</div>

            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">Get lump sum amount if diagnosed with critical illness</div>

            <div class="lifeinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="lifeinsu-mob-keyfeatures-q-en">Additional sum insured in case of accidental death</div>
            
          </div>
          {/* QUESTION SECTION ENDS HERE */}
          <h3 class="lifeinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="lifeinsu-mob-whykrlist-en">
            <div class="lifeinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="lifeinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="lifeinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="lifeinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default LifeInsurance;