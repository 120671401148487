import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './armotorfleetinsurance.scss';

const ARMotorFleetInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="motorfleetinsu-tab-imagebannermain-ar">
          <div class="motorfleetinsu-banner-con-ar">
            <div class="motorfleetinsutab-banne-sec-ar">
              <h1 class="motorfleetinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="motorfleetinsu-tab-btn-ar">
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="motorfleetinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="motorfleetinsu-banner-con-ar">
          <div class="motorfleetinsu-bdy-ar">
            <div class="motorfleetinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="motorfleetinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/motor_fleet_insurance_ar.png"} alt="تأمين أسطول المركبات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="motorfleetinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">تأمين أسطول المركبات</h2>

                <p class="pg-txt-con-ar">تساعدك شركة كيمجي رامداس لخدمات التأمين في تأمين المركبات بموجب بوليصة واحدة (تأمين أسطول المركبات) لتخفيف عبء التأمين عليها بشكل فردي. علاوة على ذلك، لا يوجد سوى تاريخ تجديد واحد وخطة دفع واحدة ونقطة اتصال واحدة وهي ميزة كبيرة وتوفر الوقت عندما يتعين عليكم التعامل مع المطالبات أو عند إضافة أو إزالة المركبات من بوليصتك الحالية</p>

                <p class="pg-txt-con-ar">إذا كان لديكم العديد من المركبات التي تحتاج إلى التأمين، فقم بالبحث عن شركة كيمجي رامداس لتأمين أسطول المركبات. سواء كنت تمتلك العديد من المركبات عالية الجودة أو أسطولًا من السيارات الخاصة، يمكن تصميم بوليصة التأمين الخاصة بنا وفقًا لاحتياجاتك لضمان سير أعمالك باستمرار وتقديم نتائج حقيقية، مهما حدث! توفر شركة كيمجي رامداس لخدمات التأمين تغطية مصممة خصيصًا لتلائم احتياجاتك وستزودك بالتأمين الصحيح على سيارتك بالقيمة الصحيحة</p>

                <p class="pg-txt-con-ar"><strong>خدمات القيمة المضافة التي تقدمها شركة كيمجي رامداس لخدمات التأمين هي كما يلي</strong></p>


                <div class="motorfleetinsu-keypoint-ar">
                  <div class="motorfleetinsu-keypoint-item-ar">مدير خدمة مخصص للمساعدة في توثيق المطالبات وتسجيل المطالبات وحتى تسوية المطالبات</div>
                  <div class="motorfleetinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                  <div class="motorfleetinsu-keypoint-item-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
                  <div class="motorfleetinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                  <div class="motorfleetinsu-keypoint-item-ar">سهولة في تحميل البوليصة في موقع شرطة عمان السلطانية وتحديث الحالة</div>
                  <div class="motorfleetinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                  <div class="motorfleetinsu-keypoint-item-ar">خدمات السجادة الحمراء في مرائب الإصلاح المفضلة لدينا</div>
                  <div class="motorfleetinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="motorfleetinsu-kpnt-ar">
              <div class="motorfleetinsu-kpnt-item-ar">كوبونات خصم لغسيل وصيانة المركبات من خلال شبكتنا (اختياري)</div>
              <div class="motorfleetinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
              
              <div class="motorfleetinsu-kpnt-item-ar">توافر مركبات بديلة للأغراض العاجلة على أساس الدفع</div>
              <div class="motorfleetinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

              <div class="motorfleetinsu-kpnt-item-ar">نحن وسيط التأمين الوحيد الذي حصل على مرائب سيارات داخلية مرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك. (مرآب مسعر، معتمد من شرطة عمان السلطانية، معتمدة من قبل POD؛ مركبات MOD)</div>
              <div class="motorfleetinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            </div>

            <p class="motorfleetinsu-txt-con-ar">نحن متخصصون أيضًا في تصميم المنتجات المصممة خصيصًا للمركبات عالية القيمة، على سبيل المثال فيراري، أستون مارتن، بنتلي ورويس، إلخ. شاحنات، صهاريج، حافلات، سيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

            <h3 class="motorfleetinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين أسطول المركبات</h3>

            <p class="motorfleetinsu-txt-con-ar">تتضمن الخيارات النموذجية، من بين أمور أخرى، التعطل والمساعدة في الإصلاح ومزايا التأمين ضد الحوادث الخاصة</p>

            <div class="motorfleetinsu-pins-list-dsk-ar">
              <div class="motorfleetinsu-pins-list-dsk-item-ar">يوفر منتج أسطول السيارات الخاص بنا تغطية تأمينية شاملة أو تأمينية لطرف ثالث</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

              <div class="motorfleetinsu-pins-list-dsk-item-ar">تغطية لأضرار الطرف الثالث - أضرار الممتلكات والإصابات الجسدية</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

              <div class="motorfleetinsu-pins-list-dsk-item-ar">تأمينالأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر محددة مثل حوادث الطرق، الاصطدام، الانقلاب، السرقة، الأذى المتعمد، الموقد والانفجار، والكوارث الطبيعية</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>              

              <div class="motorfleetinsu-pins-list-dsk-item-ar">تغطية ضد الحوادث الشخصية</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>              

              <div class="motorfleetinsu-pins-list-dsk-item-ar">المساعدة على الطريق</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>              

              <div class="motorfleetinsu-pins-list-dsk-item-ar">خيارات الإصلاح في الوكالة</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>              

              <div class="motorfleetinsu-pins-list-dsk-item-ar">تغطية ضد تلف قطع الغيار</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>              

              <div class="motorfleetinsu-pins-list-dsk-item-ar">تغطية ضد حوادث الطرق الوعرة</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>              

              <div class="motorfleetinsu-pins-list-dsk-item-ar">تغطية التكاليف الطبية</div>
              <div class="motorfleetinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            </div>
            <h3 class="motorfleetinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            <div class="clearfix"></div>

            <div class="motorfleetinsu-pins-list-dksp-ar">
              <div class="motorfleetinsu-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /> </div>
              <div class="motorfleetinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /> </div>
              <div class="motorfleetinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /> </div>
              <div class="motorfleetinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /> </div>
            </div>
            
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}


          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="motorfleetinsu-tab-imagebannermain-ar">
          <div class="motorfleetinsu-banner-con-ar">
            <div class="motorfleetinsutab-banne-sec-ar">
              <h1 class="motorfleetinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="motorfleetinsu-tab-btn-ar">
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="motorfleetinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="motorfleetinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="motorfleetinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تأمين أسطول المركبات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/motor_fleet_insurance_ar.png"} alt="تأمين أسطول المركبات" />

              <p>تساعدك شركة كيمجي رامداس لخدمات التأمين في تأمين المركبات بموجب بوليصة واحدة (تأمين أسطول المركبات) لتخفيف عبء التأمين عليها بشكل فردي. علاوة على ذلك، لا يوجد سوى تاريخ تجديد واحد وخطة دفع واحدة ونقطة اتصال واحدة وهي ميزة كبيرة وتوفر الوقت عندما يتعين عليكم التعامل مع المطالبات أو عند إضافة أو إزالة المركبات من بوليصتك الحالية</p>
              
              <p>إذا كان لديكم العديد من المركبات التي تحتاج إلى التأمين، فقم بالبحث عن شركة كيمجي رامداس لتأمين أسطول المركبات. سواء كنت تمتلك العديد من المركبات عالية الجودة أو أسطولًا من السيارات الخاصة، يمكن تصميم بوليصة التأمين الخاصة بنا وفقًا لاحتياجاتك لضمان سير أعمالك باستمرار وتقديم نتائج حقيقية، مهما حدث! توفر شركة كيمجي رامداس لخدمات التأمين تغطية مصممة خصيصًا لتلائم احتياجاتك وستزودك بالتأمين الصحيح على سيارتك بالقيمة الصحيحة</p>
              
              <p><strong>خدمات القيمة المضافة التي تقدمها شركة كيمجي رامداس لخدمات التأمين هي كما يلي </strong></p>
              
              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">مدير خدمة مخصص للمساعدة في توثيق المطالبات وتسجيل المطالبات وحتى تسوية المطالبات</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="tabkeyfet-txt-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="tabkeyfet-txt-ar">سهولة في تحميل البوليصة في موقع شرطة عمان السلطانية وتحديث الحالة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="tabkeyfet-txt-ar">خدمات السجادة الحمراء في مرائب الإصلاح المفضلة لدينا</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="tabkeyfet-txt-ar">كوبونات خصم لغسيل وصيانة المركبات من خلال شبكتنا (اختياري)</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="tabkeyfet-txt-ar">توافر مركبات بديلة للأغراض العاجلة على أساس الدفع</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="tabkeyfet-txt-ar">نحن وسيط التأمين الوحيد الذي حصل على مرائب سيارات داخلية مرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك. (مرآب مسعر، معتمد من شرطة عمان السلطانية، معتمدة من قبل POD؛ مركبات MOD)</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
              </div>

              <p>نحن متخصصون أيضًا في تصميم المنتجات المصممة خصيصًا للمركبات عالية القيمة، على سبيل المثال فيراري، أستون مارتن، بنتلي ورويس، إلخ. شاحنات، صهاريج، حافلات، سيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / تأمين أسطول المركبات</h3>

              <p>تتضمن الخيارات النموذجية، من بين أمور أخرى، التعطل والمساعدة في الإصلاح ومزايا التأمين ضد الحوادث الخاصة</p>
              
              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">يوفر منتج أسطول السيارات الخاص بنا تغطية تأمينية شاملة أو تأمينية لطرف ثالث.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية لأضرار الطرف الثالث - أضرار الممتلكات والإصابات الجسدية.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تأمينالأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر محددة مثل حوادث الطرق، الاصطدام، الانقلاب، السرقة، الأذى المتعمد، الموقد والانفجار، والكوارث الطبيعية.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد الحوادث الشخصية.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">المساعدة على الطريق</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">خيارات الإصلاح في الوكالة.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد تلف قطع الغيار.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد حوادث الطرق الوعرة.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية التكاليف الطبية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
            
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}

            
              
              </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="motorfleetinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"}  selected class="active">تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="motorfleetinsu-mob-main-ar">
          <h1 class="motorfleetinsu-banner-heading-ar">تأمين أسطول المركبات</h1>
          <div class="motorfleetinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/motor_fleet_insurance_ar.png"} alt="تأمين أسطول المركبات" />
          </div>

          <p class="motorfleetinsu-mob-txt-ar">تساعدك شركة كيمجي رامداس لخدمات التأمين في تأمين المركبات بموجب بوليصة واحدة (تأمين أسطول المركبات) لتخفيف عبء التأمين عليها بشكل فردي. علاوة على ذلك، لا يوجد سوى تاريخ تجديد واحد وخطة دفع واحدة ونقطة اتصال واحدة وهي ميزة كبيرة وتوفر الوقت عندما يتعين عليكم التعامل مع المطالبات أو عند إضافة أو إزالة المركبات من بوليصتك الحالية</p>

          <p class="motorfleetinsu-mob-txt-ar">إذا كان لديكم العديد من المركبات التي تحتاج إلى التأمين، فقم بالبحث عن شركة كيمجي رامداس لتأمين أسطول المركبات. سواء كنت تمتلك العديد من المركبات عالية الجودة أو أسطولًا من السيارات الخاصة، يمكن تصميم بوليصة التأمين الخاصة بنا وفقًا لاحتياجاتك لضمان سير أعمالك باستمرار وتقديم نتائج حقيقية، مهما حدث! توفر شركة كيمجي رامداس لخدمات التأمين تغطية مصممة خصيصًا لتلائم احتياجاتك وستزودك بالتأمين الصحيح على سيارتك بالقيمة الصحيحة</p>

          <p class="motorfleetinsu-mob-txt-ar"><strong>خدمات القيمة المضافة التي تقدمها شركة كيمجي رامداس لخدمات التأمين هي كما يلي</strong></p>

          <div class="motorfleetinsu-pins-list-mob-ar">
            <div class="motorfleetinsu-pins-list-mob-item-ar">مدير خدمة مخصص للمساعدة في توثيق المطالبات وتسجيل المطالبات وحتى تسوية المطالبات</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="motorfleetinsu-pins-list-mob-item-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="motorfleetinsu-pins-list-mob-item-ar">سهولة في تحميل البوليصة في موقع شرطة عمان السلطانية وتحديث الحالة</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="motorfleetinsu-pins-list-mob-item-ar">خدمات السجادة الحمراء في مرائب الإصلاح المفضلة لدينا</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="motorfleetinsu-pins-list-mob-item-ar">كوبونات خصم لغسيل وصيانة المركبات من خلال شبكتنا (اختياري)</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="motorfleetinsu-pins-list-mob-item-ar">توافر مركبات بديلة للأغراض العاجلة على أساس الدفع</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
                
                <div class="motorfleetinsu-pins-list-mob-item-ar">نحن وسيط التأمين الوحيد الذي حصل على مرائب سيارات داخلية مرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك (مرآب مسعر، معتمد من شرطة عمان السلطانية، معتمدة من قبل POD؛ مركبات MOD)</div>
                <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>
          </div>

          <p class="motorfleetinsu-mob-txt-ar">نحن متخصصون أيضًا في تصميم المنتجات المصممة خصيصًا للمركبات عالية القيمة، على سبيل المثال فيراري، أستون مارتن، بنتلي ورويس، إلخ. شاحنات، صهاريج، حافلات، سيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات </p>

          <h3 class="motorfleetinsu-subtitle-ar">التغطية الرئيسية / تأمين أسطول المركبات</h3>

          <p class="motorfleetinsu-mob-txt-ar">تتضمن الخيارات النموذجية، من بين أمور أخرى، التعطل والمساعدة في الإصلاح ومزايا التأمين ضد الحوادث الخاصة</p>

          <div class="motorfleetinsu-pins-list-mob-ar">
            <div class="motorfleetinsu-pins-list-mob-item-ar">يوفر منتج أسطول السيارات الخاص بنا تغطية تأمينية شاملة أو تأمينية لطرف ثالث.</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">تغطية لأضرار الطرف الثالث - أضرار الممتلكات والإصابات الجسدية</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">تأمينالأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر محددة مثل حوادث الطرق، الاصطدام، الانقلاب، السرقة، الأذى المتعمد، الموقد والانفجار، والكوارث الطبيعية</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">تغطية ضد الحوادث الشخصية</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">المساعدة على الطريق</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">خيارات الإصلاح في الوكالة</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">تغطية ضد تلف قطع الغيار</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">تغطية ضد حوادث الطرق الوعرة</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

            <div class="motorfleetinsu-pins-list-mob-item-ar">تغطية التكاليف الطبية</div>
            <div class="motorfleetinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield"  /></div>

          </div>
          <h3 class="motorfleetinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="motorfleetinsu-mob-whykrlist-ar">
            <div class="motorfleetinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="motorfleetinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="motorfleetinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="motorfleetinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div> 
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARMotorFleetInsurance;