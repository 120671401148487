import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './argolfinsurance.scss';

const ARGolfInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="golfinsu-tab-imagebannermain-ar">
          <div class="golfinsu-banner-con-ar">
            <div class="golfinsutab-banne-sec-ar">
              <h1 class="golfinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="golfinsu-tab-btn-ar">
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="golfinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="golfinsu-banner-con-ar">
          <div class="golfinsu-bdy-ar">
            <div class="golfinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="golfinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/golf_insurance_ar.png"} alt="التأمين على الجولف" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="golfinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين على الجولف</h2>
                <p class="pg-txt-con-ar">هل تحب لعب الجولف؟ إنها رياضة مشوقة، ومثل أي رياضة أخرى، هناك بعض المخاطر الكامنة المرتبطة باللعبة. الجولف رياضة تنفيذية ويمكن أن تكون باهظة الثمن. نوادي الجولف وغيرها من المعدات "باهظة الثمن"، ولا يمكن تحمل التكلفة في حالة التعرض للتلف أو الضياع أو حتى السرقة.أيضًا، في بعض الأحيان قد تؤدي تسديدة قوية في الملعب إلى انطلاق الكرة وإصابة أحد الأشخاص بالملعب. يبدو الأمر مضحكًا دائمًا، ولكن قد يكون هذا سببًا للقلق، وقد ينتهي بك إلى دفع مبلغ كبير من  لتدارك الأضرار</p>

                <h3 class="golfinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين الجولف</h3>

                <p class="pg-txt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين خطة تأمين تضع في اعتبارها كل هذه الاحتمالات وتضمن لك الاستمتاع بلعبتك دون أي قلق. تعتبر شركة كيمجي رامداس لخدمات التأمين أن تأمين الجولف هو بالتأكيد أحد الملحقات "التي يجب وضعها في الاعتبار أثناء اقتنائك معدات الجولف.</p>

                <p class="golfinsu-txt-con-ar">تغطية المفاتيح </p>

                <div class="golfinsu-keyfeatures-ar">
                  <div class="golfinsu-keyfeatures-items-ar"> السطو / سرقة المعدات</div>
                  <div class="golfinsu-keyfeatures-itemsimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                  <div class="golfinsu-keyfeatures-items-ar">خسائر الطرف الثالث - الإصابات الجسدية / متلفات الممتلكات</div>
                  <div class="golfinsu-keyfeatures-itemsimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            
            <h3 class="golfinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                
                <div class="golfinsu-pins-list-ar">
                  <div class="golfinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="golfinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="golfinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="golfinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>


            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
            </div>
            {/* ask for quotes button starts here */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="golfinsu-tab-imagebannermain-ar">
          <div class="golfinsu-banner-con-ar">
            <div class="golfinsutab-banne-sec-ar">
              <h1 class="golfinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="golfinsu-tab-btn-ar">
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="golfinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="golfinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="golfinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على الجولف</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/golf_insurance_ar.png"} alt="التأمين على الجولف" />
              <p>هل تحب لعب الجولف؟ إنها رياضة مشوقة، ومثل أي رياضة أخرى، هناك بعض المخاطر الكامنة المرتبطة باللعبة. الجولف رياضة تنفيذية ويمكن أن تكون باهظة الثمن. نوادي الجولف وغيرها من المعدات "باهظة الثمن"، ولا يمكن تحمل التكلفة في حالة التعرض للتلف أو الضياع أو حتى السرقة.أيضًا، في بعض الأحيان قد تؤدي تسديدة قوية في الملعب إلى انطلاق الكرة وإصابة أحد الأشخاص بالملعب. يبدو الأمر مضحكًا دائمًا، ولكن قد يكون هذا سببًا للقلق، وقد ينتهي بك إلى دفع مبلغ كبير من  لتدارك الأضرار</p>
              <p class="tab-subhead-title-ar">التغطية الرئيسية / تأمين الجولف</p>

              <p>تقدم شركة كيمجي رامداس لخدمات التأمين خطة تأمين تضع في اعتبارها كل هذه الاحتمالات وتضمن لك الاستمتاع بلعبتك دون أي قلق. تعتبر شركة كيمجي رامداس لخدمات التأمين أن تأمين الجولف هو بالتأكيد أحد الملحقات "التي يجب وضعها في الاعتبار أثناء اقتنائك معدات الجولف</p>
              
              <p class="tab-subhead-title-ar">تغطية المفاتيح </p>

              <div class="golfinsu-keyfeatures-ar">
                  <div class="golfinsu-keyfeatures-items-ar"> السطو / سرقة المعدات</div>
                  <div class="golfinsu-keyfeatures-itemsimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                  <div class="golfinsu-keyfeatures-items-ar">خسائر الطرف الثالث - الإصابات الجسدية / متلفات الممتلكات</div>
                  <div class="golfinsu-keyfeatures-itemsimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}
      
      {/* MOBILE PAGE STARTS HERE */}
      <div class="golfinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"} selected class="active">التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="golfinsu-mob-main-ar">
          <h1 class="golfinsu-mob-main-title-ar">التأمين على الجولف</h1>
          <div class="golfinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/golf_insurance_ar.png"} alt="golf insurance" />
          </div>

          <p class="golfinsu-mob-txt-ar">هل تحب لعب الجولف؟ إنها رياضة مشوقة، ومثل أي رياضة أخرى، هناك بعض المخاطر الكامنة المرتبطة باللعبة. الجولف رياضة تنفيذية ويمكن أن تكون باهظة الثمن. نوادي الجولف وغيرها من المعدات "باهظة الثمن"، ولا يمكن تحمل التكلفة في حالة التعرض للتلف أو الضياع أو حتى السرقة.أيضًا، في بعض الأحيان قد تؤدي تسديدة قوية في الملعب إلى انطلاق الكرة وإصابة أحد الأشخاص بالملعب. يبدو الأمر مضحكًا دائمًا، ولكن قد يكون هذا سببًا للقلق، وقد ينتهي بك إلى دفع مبلغ كبير من  لتدارك الأضرار</p>

          <h3 class="golfinsu-subtitle-ar">التغطية الرئيسية / تأمين الجولف</h3>

          <p class="golfinsu-mob-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين خطة تأمين تضع في اعتبارها كل هذه الاحتمالات وتضمن لك الاستمتاع بلعبتك دون أي قلق. تعتبر شركة كيمجي رامداس لخدمات التأمين أن تأمين الجولف هو بالتأكيد أحد الملحقات "التي يجب وضعها في الاعتبار أثناء اقتنائك معدات الجولف</p>

          <p class="golfinsu-mob-txt-ar">تغطية المفاتيح </p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="golfinsu-mob-keyfeatures-ar">
            <div class="golfinsu-mob-keyfeatures-q-ar"> السطو / سرقة المعدات</div>
            <div class="golfinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="golfinsu-mob-keyfeatures-q-ar">خسائر الطرف الثالث - الإصابات الجسدية / متلفات الممتلكات</div>
            <div class="golfinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}
          
          <h3 class="golfinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
          
          <div class="golfinsu-mob-whykrlist-ar">
            <div class="golfinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            
            <div class="golfinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            
            <div class="golfinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            
            <div class="golfinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
              </div>
              {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARGolfInsurance;