import { Link } from "react-router-dom";

import Slider from "react-slick";

import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";
import HeaderAR from "../headerAR/headerar";
import FooterAR from "../footerAR/footerar";
import './home.scss';

const HomePage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const homeslider = {
    dots: true,
    fade: true,
    infinite: true,
    //speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <div class="main-fluid">
        <HeaderAR />
      </div>
      {/* FIRST SECTION STARTS HERE */}
      <div class="dsk-visable">
        <div class="hm-ft-con-ar">
          <div class="hm-ft-con-rgt-main-ar">
            <div class="hm-banner-slider-ar">
              <Slider {...homeslider}>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_01.webp"} alt="Property Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_02.webp"} alt="Project Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_03.webp"} alt="Group Health Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_04.webp"} alt="Motor Fleet Insurance" width="780" height="780" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_05.webp"} alt="Cyber Crime Insurance" width="780" height="780" />
                </div>
              </Slider>
            </div>
          </div>

          <div class="hm-ft-con-lft-main-ar">
            <h1 class="hm-ft-heading-ar">مقدم خدمات التأمين <span class="omn-title-en"> الرائد والمُعتمَد في سلطنة عمان </span></h1>

            <p class="banner-txt-ar">نحن شركة وساطة تأمين مرخصة من الهيئة العامة لسوق المال في سلطنة عمان. أولويتنا هي تقديم أفضل خدمات التأمين بالإضافة إلى تغطية تأمينة شاملة، ووضع برامج إدارة المخاطر، وجعل عملائنا مستفيدين من فوائد التكلفة</p>

            <p class="sub-title-ar">تتمثل رؤيتنا في دفع سلطنة عمان إلى الصدارة من خلال تقديم أفضل ممارسات التأمين</p>

          </div>


        </div>
      </div>
      {/* FIRST SECTION ENDS HERE */}

      {/* TAB FIRST SECTION STARTS HERE */}
      <div class="tab-visable">
        <div class="hm-ft-con-tab-ar">
          <div class="hm-ft-con-lft-main-tab-ar">
            <h1 class="hm-ft-heading-tab-ar">مقدم خدمات التأمين <span class="omn-title-en"> الرائد والمُعتمَد في سلطنة عمان </span></h1>

          </div>
          <div class="hm-ft-con-rgt-main-tab-ar">
            <div class="hm-banner-slider-ar">
              <Slider {...homeslider}>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_01.webp"} alt="Property Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_02.webp"} alt="Project Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_03.webp"} alt="Group Health Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_04.webp"} alt="Motor Fleet Insurance" width="100%" height="100%" />
                </div>
                <div class="slick-slide-en">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_img_05.webp"} alt="Cyber Crime Insurance" width="100%" height="100%" />
                </div>
              </Slider>
            </div>
          </div>

          <div class="hm-ft-con-lft-two-main-tab-ar">
            <p class="banner-txt-tab-ar">نحن شركة وساطة تأمين مرخصة من الهيئة العامة لسوق المال في سلطنة عمان. أولويتنا هي تقديم أفضل خدمات التأمين بالإضافة إلى تغطية تأمينة شاملة، ووضع برامج إدارة المخاطر، وجعل عملائنا مستفيدين من فوائد التكلفة </p>

            <p class="sub-title-tab-ar">تتمثل رؤيتنا في دفع سلطنة عمان إلى الصدارة من خلال تقديم أفضل ممارسات التأمين</p>
          </div>
        </div>
      </div>
      {/* TAB FIRST SECTION ENDS HERE */}

      {/* FOR MOBILE FIRST SECTION STARTS HERE */}
      <div class="hm-ft-con-mob-en d-block d-sm-none">
        <div class="hm-ft-con-lft-main-mob-ar">
          <h1 class="hm-ft-heading-mob-ar">مقدم خدمات التأمين <span class="omn-title-en"> الرائد والمُعتمَد في سلطنة عمان </span></h1>
        </div>
        <div class="hm-ft-con-rgt-main-mob-ar">
          <div class="hm-banner-slider-ar">
            <Slider {...homeslider}>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_mob_img_01.webp"} class="hm-img" alt="Property Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_mob_img_02.webp"} class="hm-img" alt="Project Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_mob_img_03.webp"} class="hm-img" alt="Group Health Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_mob_img_04.webp"} class="hm-img" alt="Motor Fleet Insurance" width="350" height="350" />
              </div>
              <div class="slick-slide-en">
                <img src={process.env.PUBLIC_URL + "/homepage_images/slide_images/ar_mob_img_05.webp"} class="hm-img" alt="Cyber Crime Insurance" width="350" height="350" />
              </div>
            </Slider>
          </div>
        </div>

        <div class="hm-ft-con-lft-two-main-mob-ar">
          <p class="banner-txt-mob-ar">نحن شركة وساطة تأمين مرخصة من الهيئة العامة لسوق المال في سلطنة عمان. أولويتنا هي تقديم أفضل خدمات التأمين بالإضافة إلى تغطية تأمينة شاملة، ووضع برامج إدارة المخاطر، وجعل عملائنا مستفيدين من فوائد التكلفة </p>

          <p class="sub-title-mob-ar">تتمثل رؤيتنا في دفع سلطنة عمان إلى الصدارة من خلال تقديم أفضل ممارسات التأمين </p>

        </div>
      </div>
      {/* FOR MOBILE FIRST SECTION ENDS HERE */}

      <div class="clearfix"></div>
      {/* SECOND DESKTOP SECTION STARTS HERE */}
      <div class="dsk-visable">
        <div class="hm-sc-con-ar">
          <h2 class="we-do-title-ar">حلول وخبرات التأمين التي نمتلكها </h2>
          <div class="hm-sc-ar">
            <div class="hm-sc-lft-ar">
              <div class="expertises-sec-ar">
                <a href="/property-insurance" class="expertises-card-ar">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="تأمين الأعمال" /></div>
                  <div class="expe-insnm-en">تأمين الأعمال</div>
                </a>
                <a href="/motor-insurance" class="expertises-card-ar">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="التأمين الشخصي" /></div>
                  <div class="expe-insnm-en">التأمين الشخصي</div>
                </a>

                <a href="/risk-inspection-services" class="expertises-card-ar">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="خدمات قيمة" /></div>
                  <div class="expe-insnm-en">خدمات قيمة</div>
                </a>
                <a href="/report-a-claim" class="expertises-card-ar">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="خدمات العملاء" /></div>
                  <div class="expe-insnm-en">خدمات العملاء</div>
                </a>
              </div>
            </div>
            <div class="hm-sc-rgt-ar">
              <div class="hm-sc-we-do-ar">
                <div class="hm-sc-list-ar">
                  <div class="hm-sc-list-txt-item-ar">
                    <p>نحن فريق تأمين متخصص يعمل بشكل وثيق مع العملاء لفهم متطلبات التأمين الخاصة بهم وكيفية إدارة المخاطر</p>
                  </div>
                  <div class="hm-sc-list-img-item-one-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="specialized" /></div>
                </div>
                <div class="hm-sc-list-ar">
                  <div class="hm-sc-list-txt-item-ar">
                    <p>لدينا تعاملات مع جميع شركات التأمين في سلطنة عمان. وبالنيابة عن عملائنا، يقوم فريقنا بوضع شروط تأمين شاملة وتنافسية</p>
                  </div>
                  <div class="hm-sc-list-img-item-two-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="terms icon" /></div>
                </div>
                <div class="hm-sc-list-ar">
                  <div class="hm-sc-list-txt-item-ar">
                    <p>نقدم خدمات التأمين ونساعد عملائنا في تسوية مطالبات التأمين (إذا لزم الأمر) بغرض تقديم إنجازات تتجاوز توقعات عملائنا</p>
                  </div>
                  <div class="hm-sc-list-img-item-two-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="insurance services" /></div>
                </div>

              </div>
            </div>
            {/* <div class="d-block d-sm-none">
            <h1>Mobile section </h1>
          </div> */}
          </div>
        </div>
      </div>
      {/* SECOND SECTION ENDS HERE */}

      {/* SECOND TAB SECTION STARTS HERE */}
      <div class="tab-visable">
        <div class="hm-sc-tabcon-ar">
          <h2 class="we-do-title-ar">حلول وخبرات التأمين التي نمتلكها </h2>

          <div className="hm-sc-tabinc-ar">
            <a href="/property-insurance" class="hm-sc-tabinc-item-ar">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="Business Insurance" /></div>
              <div class="expe-insnm-en">تأمين الأعمال</div>
            </a>
            <a href="/motor-insurance" class="hm-sc-tabinc-item-ar">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="التأمين الشخصي" /></div>
              <div class="expe-insnm-en">التأمين الشخصي</div>
            </a>
            <a href="/risk-inspection-services" class="hm-sc-tabinc-item-ar">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="خدمات قيمة" /></div>
              <div class="expe-insnm-en">خدمات قيمة</div>
            </a>

            <a href="/report-a-claim" class="hm-sc-tabinc-item-ar">
              <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="خدمات العملاء" /></div>
              <div class="expe-insnm-en">خدمات العملاء</div>
            </a>
          </div>

          <div class="hm-sc-wedotabcon-ar">
            <div class="hm-sc-wedotabconlist-ar">
              <div class="hm-sc-listtxt-item-ar">نحن فريق تأمين متخصص يعمل بشكل وثيق مع العملاء لفهم متطلبات التأمين الخاصة بهم وكيفية إدارة المخاطر
              </div>
              <div class="hm-sc-listimg-item-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="specialized insurance" /></div>
            </div>
            <div class="hm-sc-wedotabconlist-ar">
              <div class="hm-sc-listtxt-item-ar">
                لدينا تعاملات مع جميع شركات التأمين في سلطنة عمان. وبالنيابة عن عملائنا، يقوم فريقنا بوضع شروط تأمين شاملة وتنافسية.
              </div>
              <div class="hm-sc-listimg-item-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="our customers" /></div>
            </div>
            <div class="hm-sc-wedotabconlist-ar">
              <div class="hm-sc-listtxt-item-ar">
                نقدم خدمات التأمين ونساعد عملائنا في تسوية مطالبات التأمين (إذا لزم الأمر) بغرض تقديم إنجازات تتجاوز توقعات عملائنا.
              </div>
              <div class="hm-sc-listimg-item-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="settlement insurance" /></div>
            </div>
          </div>
        </div>
      </div>
      {/* SECOND TAB SECTION ENDS HERE */}

      {/* SECOND MOBILE SECTION STARTS HERE */}
      <div class="mobile-view">
        <div class="hm-sc-con-ar">
          <h2 class="we-do-title-ar">حلول وخبرات التأمين التي نمتلكها </h2>
          <div class="hm-sc-ar">
            <div class="hm-sc-rgt-ar">
              <div class="hm-sc-we-do-ar">
                <div class="hm-sc-list-ar">
                  <div class="hm-sc-list-img-item-one-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="specialized insurance" /></div>
                  <div class="hm-sc-list-txt-item-ar">
                    <p>نحن فريق تأمين متخصص يعمل بشكل وثيق مع العملاء لفهم متطلبات التأمين الخاصة بهم وكيفية إدارة المخاطر</p>
                  </div>
                </div>
                <div class="hm-sc-list-ar">
                  <div class="hm-sc-list-img-item-two-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="insurance terms" /></div>
                  <div class="hm-sc-list-txt-item-ar">
                    <p>لدينا تعاملات مع جميع شركات التأمين في سلطنة عمان. وبالنيابة عن عملائنا، يقوم فريقنا بوضع شروط تأمين شاملة وتنافسية. </p>
                  </div>
                </div>
                <div class="hm-sc-list-ar">
                  <div class="hm-sc-list-img-item-two-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="insurance services" /></div>
                  <div class="hm-sc-list-txt-item-ar">
                    <p>نقدم خدمات التأمين ونساعد عملائنا في تسوية مطالبات التأمين (إذا لزم الأمر) بغرض تقديم إنجازات تتجاوز توقعات عملائنا. </p>
                  </div>
                </div>

              </div>
            </div>
            <div class="hm-sc-lft-ar">
              <div class="expertises-sec-mob-ar">
                <a href="/property-insurance" class="expertises-card-mob-ar">
                  <div class="expe-insimg-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="تأمين الأعمال" /></div>
                  <div class="expe-insnm-mob-ar">تأمين الأعمال </div>
                </a>
                <a href="/motor-insurance" class="expertises-card-mob-ar">
                  <div class="expe-insimg-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="التأمين الشخصي" /></div>
                  <div class="expe-insnm-mob-ar">التأمين الشخصي</div>
                </a>

                <a href="/risk-inspection-services" class="expertises-card-mob-ar">
                  <div class="expe-insimg-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="خدمات قيمة" /></div>
                  <div class="expe-insnm-mob-ar">خدمات قيمة</div>
                </a>

                <a href="/report-a-claim" class="expertises-card-mob-ar">
                  <div class="expe-insimg-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="خدمات العملاء" /></div>
                  <div class="expe-insnm-mob-ar">خدمات العملاء</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SECOND SECTION ENDS HERE */}

      <div class="main-fluid">
        <div class="kr-container-bdy-ar">
          {/* TAB AND DEKSTOP DISPLAY SECTION STARTS HERE */}
          <div class="d-none d-lg-block">
            <div class="addvalue-ar">
              <div class="addvalue-item-head-ar">
                <h3 class="addvalu-title-ar">إضافة قيمة إلى برامج التأمين التي نُقدمها إلى عملائنا </h3>
              </div>
              <div class="addvalue-cards-ar">
                <div class="addvalue-item-ar">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img01.webp"} alt="claims settlement" />
                  <div class="bxone-en">نوفر سهولة اتخذا القرار للإدارة العليا في شأن وضع برامج التأمين الخاص بهم وتسهيل تسوية المطالبات </div>
                </div>
                <div class="addvalue-item-ar">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img02.webp"} alt="value cost" />
                  <div class="bxtwo-en">قدمنا شروطًا شاملة ووفرنا تكاليف تصل إلى 30٪ لعملائنا المقترنيين </div>
                </div>
              </div>
            </div>
          </div>
          {/* TAB AND DEKSTOP DISPLAY SECTION ENDS HERE */}
          <div class="tab-visable">
            <div class="addvalue-tab-ar">
              <div class="addvalue-item-head-tab-ar">
                <h3 class="addvalu-title-tab-ar">إضافة قيمة إلى برامج التأمين التي نُقدمها إلى عملائنا </h3>
              </div>
              <div class="addvalue-cards-tab-ar">
                <div class="addvalue-item-tab-ar">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img01.webp"} alt="claims settlement" />
                  <div class="bxone-tab-ar">نوفر سهولة اتخذا القرار للإدارة العليا في شأن وضع برامج التأمين الخاص بهم وتسهيل تسوية المطالبات </div>
                </div>
                <div class="addvalue-item-tab-ar">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img02.webp"} alt="value cost" />
                  <div class="bxtwo-tab-ar">قدمنا شروطًا شاملة ووفرنا تكاليف تصل إلى 30٪ لعملائنا المقترنيين </div>
                </div>
              </div>
            </div>
          </div>
          {/* MOBILE DISPLAY SECTION STARTS HERE */}
          <div class="d-block d-sm-none">
            <div class="addvalue-mob-ar">
              <h3 class="addvalu-title-ar">إضافة قيمة إلى برامج التأمين التي نُقدمها إلى عملائنا </h3>
              <div class="clearfix"></div>
              <div class="addvalue-cards-mob-ar">
                <div class="addvalue-item-mob-ar">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img01.webp"} class="img-fluid" alt="claims settlement" />
                  <div class="mb-bxone-en">نوفر سهولة اتخذا القرار للإدارة العليا في شأن وضع برامج التأمين الخاص بهم وتسهيل تسوية المطالبات </div>
                </div>
                <div class="addvalue-item-mob-ar">
                  <img src={process.env.PUBLIC_URL + "/homepage_images/addvalue_img02.webp"} class="img-fluid" alt="value cost" />
                  <div class="mb-bxtwo-en">قدمنا شروطًا شاملة ووفرنا تكاليف تصل إلى 30٪ لعملائنا المقترنيين </div>
                </div>
              </div>
            </div>
          </div>
          {/* MOBILE DISPLAY SECTION ENDS HERE */}
          {/* DESKTOP DISPLAY STARTS HERE */}
          <div className="dsk-visable">
            <div class="hm-contactus-ar">
              <h2 class="hm-contact-title-ar">اتصل بنا</h2>
              <div class="hm-link-txt-ar"><a href="mailto:insurance@kr.om">insurance@kr.om</a> <a href="tel:968 93879145">-
                +968 98227845 : الهاتف
              </a></div>
              <p class="hm-contact-txt-ar">سيُسر فريقنا بالعمل بشكل وثيق لتقديم متطلبات التأمين الخاصة بمؤسساتكم وتقديم أفضل خدمات التأمين إليكم</p>
              <div class="contact-persons-ar">
                <Slider {...settings}>
                  {/* NEW IMAGES SLIDE STARTS HERE */}
                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/03_vikram.webp"} width="245" class="slideimg" alt="فيكرام أديتيا" />
                      <p class="person-name-ar">فيكرام أديتيا</p>
                      <p class="deg-htem-ar">رئيس خدمات التأمين</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/01_abbas.webp"} width="245" class="slideimg" alt="عباس الساجواني" />
                      <p class="person-name-ar">عباس الساجواني</p>
                      <p class="deg-htem-ar">مدير أول -  الفريق الاستشاري للشركة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/09_qais_ali_thani_ai_sulaimi.webp"} width="245" class="slideimg" alt="قيس علي ثاني السليمي" />
                      <p class="person-name-ar">قيس علي ثاني السليمي</p>
                      <p class="deg-htem-ar">مدير مساعد  - مطالبات السيارات</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/08_dr_sagar_jagtap.png"} width="245" class="slideimg" alt="الدكتور ساغار جاجتاب" />
                      <p class="person-name-ar">الدكتور ساغار جاجتاب</p>
                      <p class="deg-htem-ar">رئيس الدكتور ساغار جاجتاب</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/04_ghalib.webp"} width="245" class="slideimg" alt="غالب محمد ناصر السيابي" />
                      <p class="person-name-ar">غالب محمد ناصر السيابي</p>
                      <p class="deg-htem-ar">مدير مساعد - فريق الحلول في الشركة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/05_sawsan.webp"} width="245" class="slideimg" alt="سوسن عبد العزيز العلوي" />
                      <p class="person-name-ar">سوسن عبد العزيز العلوي</p>
                      <p class="deg-htem-ar">مدير - الخدمات والحسابات مستحقة القبض</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/06_mehtab.webp"} width="245" class="slideimg" alt="علام سيد ماهيتاب" />
                      <p class="person-name-ar">علام سيد ماهيتاب</p>
                      <p class="deg-htem-ar">كبير القادة - فريق الحلول في الشركة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/07_arun_kumar.webp"} width="245" class="slideimg" alt="آرون كومار جوبتا" />
                      <p class="person-name-ar">آرون كومار جوبتا</p>
                      <p class="deg-htem-ar">رئيس - إدارة خدمات المطالبات والمخاطر</p>
                    </div>
                  </div>


                  {/* NEW IMAGES SLIDE ENDS HERE */}

                </Slider>
              </div>
            </div>
          </div>
          {/* DESKTOP DISPLAY ENDS HERE */}
          {/* MOBILE DISPLAY STARTS HERE */}
          <div className="mobile-view">
            <div class="hm-contactus-ar">
              <h2 class="hm-contact-title-ar">اتصل بنا</h2>
              <div class="hm-link-txt-ar"><a href="mailto:insurance@kr.om">insurance@kr.om</a> <a href="tel:968 93879145">-
                +968 98227845 : الهاتف
              </a></div>
              <p class="hm-contact-txt-ar">سيُسر فريقنا بالعمل بشكل وثيق لتقديم متطلبات التأمين الخاصة بمؤسساتكم وتقديم أفضل خدمات التأمين إليكم</p>
              <div class="contact-persons-ar">
                <Slider {...settings}>
                  {/* NEW IMAGES SLIDE STARTS HERE */}
                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/03_vikram.webp"} width="245" class="slideimg ftslide-img" alt="فيكرام أديتيا" />
                      <p class="person-name-ar">فيكرام أديتيا</p>
                      <p class="deg-htem-ar">رئيس خدمات التأمين</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/01_abbas.webp"} width="245" class="slideimg ftslide-img" alt="عباس الساجواني" />
                      <p class="person-name-ar">عباس الساجواني</p>
                      <p class="deg-htem-ar">مدير أول -  الفريق الاستشاري للشركة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/09_qais_ali_thani_ai_sulaimi.webp"} width="245" class="slideimg ftslide-img" alt="قيس علي ثاني السليمي" />
                      <p class="person-name-ar">قيس علي ثاني السليمي</p>
                      <p class="deg-htem-ar">مدير مساعد  - مطالبات السيارات</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/08_dr_sagar_jagtap.png"} width="245" class="slideimg ftslide-img" alt="الدكتور ساغار جاجتاب" />
                      <p class="person-name-ar">الدكتور ساغار جاجتاب</p>
                      <p class="deg-htem-ar">رئيس قسم المطالبات الطبية والتأمين على الحياة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/04_ghalib.webp"} width="245" class="slideimg ftslide-img" alt="غالب محمد ناصر السيابي" />
                      <p class="person-name-ar">غالب محمد ناصر السيابي</p>
                      <p class="deg-htem-ar">مدير مساعد - فريق الحلول في الشركة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/05_sawsan.webp"} width="245" class="slideimg ftslide-img" alt="سوسن عبد العزيز العلوي" />
                      <p class="person-name-ar">سوسن عبد العزيز العلوي</p>
                      <p class="deg-htem-ar">مدير - الخدمات والحسابات مستحقة القبض</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/06_mehtab.webp"} width="245" class="slideimg ftslide-img" alt="علام سيد ماهيتاب" />
                      <p class="person-name-ar">علام سيد ماهيتاب</p>
                      <p class="deg-htem-ar">كبير القادة - فريق الحلول في الشركة</p>
                    </div>
                  </div>

                  <div class="first-person-ar">
                    <div class="person-details">
                      <img src={process.env.PUBLIC_URL + "/homepage_images/team_img/07_arun_kumar.webp"} width="245" class="slideimg ftslide-img" alt="آرون كومار جوبتا" />
                      <p class="person-name-ar">آرون كومار جوبتا</p>
                      <p class="deg-htem-ar">رئيس - إدارة خدمات المطالبات والمخاطر</p>
                    </div>
                  </div>

                  {/* NEW IMAGES SLIDE ENDS HERE */}

                </Slider>
              </div>
            </div>
          </div>
          {/* MOBILE DISPLAY ENDS HERE */}
        </div>
        {/* FOOTER STARTS HERE  */}
        <FooterAR />
        {/* FOOTER ENDS HERE  */}
      </div>
    </div>
  );
}
export default HomePage;
