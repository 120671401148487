import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './golfinsurance.scss';

const GolfInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="golfinsu-tab-imagebannermain-en">
          <div class="golfinsu-banner-con-en">
            <div class="golfinsutab-banne-sec-en">
              <h1 class="golfinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="golfinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="golfinsu-banner-con-en">
          <div class="golfinsu-bdy-en">
            <div class="golfinsu-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="golfinsu-dlex-lft-en">
                <h2 class="pagetitle-en">Golf Insurance</h2>
                <p class="pg-txt-con">Do you love to play Golf? It is a passion sport, and like every other sport, there are some inherent risks associated with the game. Golf is an executive sport and can be pretty expensive. The Golf Clubs and other equipments are too 'pricey', and it is unbearable if they end up getting damaged, lost or even stolen. There are also times when a good strong swing of the club might cause the ball to fly off and cause an injury to someone on the Golf Course. It always sounds funny but this can be a cause of worry, and you might have to end up paying a substantial amount of damages.</p>

                <h3 class="golfinsu-page-sbu-title-en">Main Cover / Golf Insurance</h3>
                <p class="pg-txt-con">KR Insurance Services offers an insurance plan which takes care of all of these eventualities will ensure that you can enjoy your game without any worries. KR Insurance Services suggest that Golf insurance is definitely one of the 'must-have' accessories in your Golf Kit.</p>

                <p class="golfinsu-txt-con">Key covers - </p>

                <div class="golfinsu-keyfeatures-en">
                  <div class="golfinsu-keyfeatures-itemsimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="golfinsu-keyfeatures-items-en">Burglary / Theft of equipment’s.</div>
                  <div class="golfinsu-keyfeatures-itemsimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="golfinsu-keyfeatures-items-en">Third party losses – bodily injuries / property damages.</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="golfinsu-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/golf_insurance.png"} alt="golf insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 class="golfinsu-whykrinsu-title-en">Why KR Insurance Services ?</h3>
            <div class="golfinsu-pins-list-en">
              <div class="golfinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="golfinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="golfinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="golfinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="golfinsu-tab-imagebannermain-en">
          <div class="golfinsu-banner-con-en">
            <div class="golfinsutab-banne-sec-en">
              <h1 class="golfinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="golfinsu-tab-btn-en">
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div class="artinsu-tab-btn-item-active-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div class="artinsu-tab-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="golfinsu-banner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Golf Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/golf_insurance.png"} alt="golf insurance" />
              <p>Do you love to play Golf? It is a passion sport, and like every other sport, there are some inherent risks associated with the game. Golf is an executive sport and can be pretty expensive. The Golf Clubs and other equipments are too 'pricey', and it is unbearable if they end up getting damaged, lost or even stolen. There are also times when a good strong swing of the club might cause the ball to fly off and cause an injury to someone on the Golf Course. It always sounds funny but this can be a cause of worry, and you might have to end up paying a substantial amount of damages.</p>
              <p class="tab-subhead-title-en">Main Cover / Golf Insurance</p>

              <p>KR Insurance Services offers an insurance plan which takes care of all of these eventualities will ensure that you can enjoy your game without any worries. KR Insurance Services suggest that Golf insurance is definitely one of the 'must-have' accessories in your Golf Kit.</p>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}



      {/* MOBILE PAGE STARTS HERE */}
      <div class="golfinsu-tab-imagebannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"} selected class="active">Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="golfinsu-mob-main-en">
          <h1 class="golfinsu-banner-heading-en">Golf Insurance</h1>
          <div class="golfinsu-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/golf_insurance.png"} alt="golf insurance" />
          </div>

          <p class="golfinsu-mob-txt-en">Do you love to play Golf? It is a passion sport, and like every other sport, there are some inherent risks associated with the game. Golf is an executive sport and can be pretty expensive. The Golf Clubs and other equipments are too ‘pricey’, and it is unbearable if they end up getting damaged, lost or even stolen. There are also times when a good strong swing of the club might cause the ball to fly off and cause an injury to someone on the Golf Course. It always sounds funny but this can be a cause of worry, and you might have to end up paying a substantial amount of damages.</p>

          <h3 class="golfinsu-subtitle-en">Main Cover / Golf Insurance</h3>

          <p class="golfinsu-mob-txt-en">KR Insurance Services offers an insurance plan which takes care of all of these eventualities will ensure that you can enjoy your game without any worries. KR Insurance Services suggest that Golf insurance is definitely one of the ‘must-have’ accessories in your Golf Kit.</p>

          <p class="golfinsu-mob-txt-en">Key covers –</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="golfinsu-mob-keyfeatures-en">
            <div class="golfinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="golfinsu-mob-keyfeatures-q-en">Burglary / Theft of equipment’s.</div>

            <div class="golfinsu-mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="golfinsu-mob-keyfeatures-q-en">Third party losses – bodily injuries / property damages.</div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 class="golfinsu-whykrinsu-mob-title-en">Why KR Insurance Services ?</h3>

          <div class="golfinsu-mob-whykrlist-en">
            <div class="golfinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="golfinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="golfinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="golfinsu-mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/omanbr/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default GolfInsurance;