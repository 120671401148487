import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './aryachtinsurance.scss';

const ARYachtInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="yachtinsu-tab-imagebannermain-ar">
          <div class="yachtinsu-banner-con-ar">
            <div class="yachtinsutab-banne-sec-ar">
              <h1 class="yachtinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="yachtinsu-tab-btn-ar">
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="yachtinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="yachtinsu-banner-con-ar">
          <div class="yachtinsu-bdy-ar">
            <div class="yachtinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="yachtinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance_ar.png"} alt="التأمين على اليخوت" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="yachtinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين على اليخوت</h2>

                <div class="yachtinsukeyfeatures-ar">
                  <div class="yachtinsukeyfeatures-item-ar"> قائمة شاملة بالمنتجات</div>
                  <div class="yachtinsukeyfeatures-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-ar">إجراء اكتتاب سلمي مع تغطية خاصة</div>
                  <div class="yachtinsukeyfeatures-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-ar">حدود التجارة المحلية والإقليمية</div>
                  <div class="yachtinsukeyfeatures-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="yachtinsukeyfeatures-item-ar">فرق متخصصة لمساعدتك</div>
                  <div class="yachtinsukeyfeatures-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

                <p class="pg-txt-con-ar">مع حل التأمين الخاص بنا، ستبحر وأنت مرتاح البال</p>

                <h3 class="yachtinsu-page-sbu-title-ar">التغطية الرئيسية/التأمين على اليخت</h3>


                <p class="pg-txt-con-ar">تقدم كيمجي رامداس لخدمات التأمين خطة تأمينة لليخوت، وهي تغطية مرنة للغاية لتلبية متطلبات مالكي القوارب؛ في حالة الخسائر الناجمة عن الحوادث أو أي تلف في القارب بما في ذلك أي التزامات تجاه أطراف ثالثة قاربك مغطىً أثناء استخدام أو رسيانه على سطح الماء، قد يكون على الشاطئ كذلك أو حال عبوره</p>

                <p class="pg-txt-con-ar">بعض الحوافز الإضافية وهي تغطية القوارب غير المؤمن عليها والبحث والإنقاذ والمكافآت مثل خصم عدم وجود مطالبات</p>
                
                <h3 class="yachtinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                
                <div class="yachtinsu-pins-list-ar">
                  <div class="yachtinsu-lt-two-en">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="yachtinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="yachtinsu-lt-two-en">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="yachtinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  
                </div>
                
                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
                  {/* ask for quotes button starts here */}
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="yachtinsu-tab-imagebannermain-ar">
          <div class="yachtinsu-banner-con-ar">
            <div class="yachtinsutab-banne-sec-ar">
              <h1 class="yachtinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="yachtinsu-tab-btn-ar">
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="yachtinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="yachtinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="yachtinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على اليخوت</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance_ar.png"} alt="التأمين على اليخوت" />
              
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> قائمة شاملة بالمنتجات</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />إجراء اكتتاب سلمي مع تغطية خاصة</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />حدود التجارة المحلية والإقليمية</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />فرق متخصصة لمساعدتك.</p>

              <p>مع حل التأمين الخاص بنا، ستبحر وأنت مرتاح البال</p>
                  
              <p class="tab-subhead-title-ar">التغطية الرئيسية/التأمين على اليخت</p>

                <p>تقدم كيمجي رامداس لخدمات التأمين خطة تأمينة لليخوت، وهي تغطية مرنة للغاية لتلبية متطلبات مالكي القوارب؛ في حالة الخسائر الناجمة عن الحوادث أو أي تلف في القارب بما في ذلك أي التزامات تجاه أطراف ثالثة.قاربك مغطىً أثناء استخدام أو رسيانه على سطح الماء، قد يكون على الشاطئ كذلك أو حال عبوره</p>

                <p>بعض الحوافز الإضافية وهي تغطية القوارب غير المؤمن عليها والبحث والإنقاذ والمكافآت مثل خصم عدم وجود مطالبات </p>
                
                <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
                
                <div class="tab-whykrinsu-pins-list-ar">
                  <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                </div>
                
                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                </div>
                {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="yachtinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"} selected class="active">Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div class="yachtinsu-mob-main-ar">
        <h1 class="yachtinsu-banner-heading-ar">التأمين على اليخوت</h1>
              <div class="yachtinsu-mob-img-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance_ar.png"} alt="التأمين على اليخوت" />
              </div>
              
              <div class="yachtinsu-mob-keypoint-ar">
                <div class="yachtinsu-mob-keypoint-item-ar"> قائمة شاملة بالمنتجات</div>
                <div class="yachtinsu-mob-keypoint-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div class="yachtinsu-mob-keypoint-item-ar">إجراء اكتتاب سلمي مع تغطية خاصة</div>
                <div class="yachtinsu-mob-keypoint-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div class="yachtinsu-mob-keypoint-item-ar">حدود التجارة المحلية والإقليمية</div>
                <div class="yachtinsu-mob-keypoint-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div class="yachtinsu-mob-keypoint-item-ar">فرق متخصصة لمساعدتك</div>
                <div class="yachtinsu-mob-keypoint-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              
              <p class="yachtinsu-mob-txt-ar">مع حل التأمين الخاص بنا، ستبحر وأنت مرتاح البا </p>
              
              <h3 class="yachtinsu-subtitle-ar">التغطية الرئيسية/التأمين على اليخت</h3>
              
              <p class="yachtinsu-mob-txt-ar">تقدم كيمجي رامداس لخدمات التأمين خطة تأمينة لليخوت، وهي تغطية مرنة للغاية لتلبية متطلبات مالكي القوارب؛ في حالة الخسائر الناجمة عن الحوادث أو أي تلف في القارب بما في ذلك أي التزامات تجاه أطراف ثالثة.قاربك مغطىً أثناء استخدام أو رسيانه على سطح الماء، قد يكون على الشاطئ كذلك أو حال عبوره</p>

              <p class="yachtinsu-mob-txt-ar">بعض الحوافز الإضافية وهي تغطية القوارب غير المؤمن عليها والبحث والإنقاذ والمكافآت مثل خصم عدم وجود مطالبات</p>
              
              <h3 class="yachtinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="yachtinsu-mob-whykrlist-ar">
                <div class="yachtinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="yachtinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="yachtinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="yachtinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                  <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
             </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARYachtInsurance;