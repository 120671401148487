import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arinhousekrautomotivegarage.scss';

const ARInhouseKRAutomotiveGarage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="inhouautgar-tab-imagebannermain-ar">
          <div class="inhouautgar-banner-con-ar">
            <div class="inhouautgartab-banne-sec-ar">
              <h1 class="inhouautgar-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="servicexcell-tab-btn-ar">
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="servicexcell-tab-btn-item-active-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inhouautgar-banner-con-ar">
          <div class="inhouautgar-bdy-ar">
            <div class="inhouautgar-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="inhouautgar-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/in_house_kr_automotive_garage_ar.png"} alt="مرأب سيارات شركة كيمجي رامداس الداخلية" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="inhouautgar-dlex-lft-ar">
                <h2 class="pagetitle-ar">مرأب سيارات شركة كيمجي رامداس الداخلية</h2>

                <p class="inhouautgar-pg-txt-con-ar">نعتبر شركة كيمجي رامداس لخدمات التأمين سمسار التأمين الوحيد في سلطنة عمان، ولديها مرافق ورش سيارات داخلية لتلبية المتطلبات العاجلة لعملائها في وقت تقديم مطالبات السيارات. وبكل فخر، ندلي ببيان مفاده أنه يمكننا التعامل بنجاح مع أي أساطيل سيارات كبيرة كانت أو صغيرة، بدءًا من التأمين وحتى تسويات مطالباتهم. ويحتفظ قسم السيارات الخاص بشركة كيمجي رامداس أو ورش السيارات الخاصة بها بأسطول سيارات المجموعة وأسطول سيارات الشركة الأخرى ذات الصناعات التي تتحول بشكل أفضل مع الأوقات. وتقوم الشركة بجميع أنواع الإصلاحات الميكانيكية وإصلاحات الحوادث التي تشمل الطلاء الكامل للسيارات لأغراض خاصة</p>
                
                <p class="inhouautgar-pg-txt-con-ar">نعتبر شركة كيمجي رامداس لخدمات التأمين سمسار التأمين الوحيد في سلطنة عمان، ولديها مرافق ورش سيارات داخلية لتلبية المتطلبات العاجلة لعملائها في وقت تقديم مطالبات السيارات. وبكل فخر، ندلي ببيان مفاده أنه يمكننا التعامل بنجاح مع أي أساطيل سيارات كبيرة كانت أو صغيرة، بدءًا من التأمين وحتى تسويات مطالباتهم. ويحتفظ قسم السيارات الخاص بشركة كيمجي رامداس أو ورش السيارات الخاصة بها بأسطول سيارات المجموعة وأسطول سيارات الشركة الأخرى ذات الصناعات التي تتحول بشكل أفضل مع الأوقات. وتقوم الشركة بجميع أنواع الإصلاحات الميكانيكية وإصلاحات الحوادث التي تشمل الطلاء الكامل للسيارات لأغراض خاصة</p>

                <p class="inhouautgar-pg-txt-con-ar">تم تصنيف ورشة كيمجي رامداس للسيارات على أنها شركة من الدرجة الأولى من قبل شرطة عمان السلطانية والعديد من شركات التأمين الأخرى، في إصلاح سيارات عملائها في سلطنة عمان. وتحتوي الورشة على قسم حصري للتصنيع يتولى عمليات بناء الهياكل الفولاذية وبناء هيكل المبرد (عزل عالي الجودة للسيارات باستخدام مادة البولي يوريثان) وتقوم أيضًا بعمليات البيع وتوفير خدمة وحدات التجميد والتبريد في الشاحنات الصغيرة وسيارات النقل لتطابق درجة الحرارة المطلوبة وتستخدم ورشة التصليح الحصرية أحدث التقنيات لإصلاح اصطدام السيارات، وهي مجهزة بالكامل للتعامل مع أعمال الإصلاح الرئيسية لجميع أنواع السيارات</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            
            <p class="inhouautgar-txt-con-ar">ترتبط ورشة شركة كيمجي رامداس للسيارات بعلامات تجارية مثل هواسونج ثيرمو (وحدات التبريد والتجميد من كوريا الجنوبية) ومركز صيانة بوش المعتمد وشركة امبكس لحلول مكيفات الهواء وضواغط مكيفات هواء السيارات من ساندن وتقدم شركة كيمجي رامداس لخدمات التأمين حلول التأمين النهائية لجميع متطلبات التأمين على السيارات​</p>
            
            
            <h3 class="inhouautgar-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>
            
            <div class="inhouautgar-pins-list-dksp-ar">
              <div class="inhouautgar-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="inhouautgar-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="inhouautgar-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="inhouautgar-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}


      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="inhouautgar-tab-imagebannermain-ar">
          <div class="inhouautgar-banner-con-ar">
            <div class="inhouautgartab-banne-sec-ar">
              <h1 class="inhouautgar-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="servicexcell-tab-btn-ar">
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="servicexcell-tab-btn-item-active-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="servicexcell-tab-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inhouautgar-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>مرأب سيارات شركة كيمجي رامداس الداخلية</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/customer_service/tab_images/in_house_kr_automotive_garage_ar.png"} alt="مرأب سيارات شركة كيمجي رامداس الداخلية" />

              <p>نعتبر شركة كيمجي رامداس لخدمات التأمين سمسار التأمين الوحيد في سلطنة عمان، ولديها مرافق ورش سيارات داخلية لتلبية المتطلبات العاجلة لعملائها في وقت تقديم مطالبات السيارات. وبكل فخر، ندلي ببيان مفاده أنه يمكننا التعامل بنجاح مع أي أساطيل سيارات كبيرة كانت أو صغيرة، بدءًا من التأمين وحتى تسويات مطالباتهم. ويحتفظ قسم السيارات الخاص بشركة كيمجي رامداس أو ورش السيارات الخاصة بها بأسطول سيارات المجموعة وأسطول سيارات الشركة الأخرى ذات الصناعات التي تتحول بشكل أفضل مع الأوقات. وتقوم الشركة بجميع أنواع الإصلاحات الميكانيكية وإصلاحات الحوادث التي تشمل الطلاء الكامل للسيارات لأغراض خاصة</p>
              
              <p>تم تصنيف ورشة كيمجي رامداس للسيارات على أنها شركة من الدرجة الأولى من قبل شرطة عمان السلطانية والعديد من شركات التأمين الأخرى، في إصلاح سيارات عملائها في سلطنة عمان. وتحتوي الورشة على قسم حصري للتصنيع يتولى عمليات بناء الهياكل الفولاذية وبناء هيكل المبرد (عزل عالي الجودة للسيارات باستخدام مادة البولي يوريثان) وتقوم أيضًا بعمليات البيع وتوفير خدمة وحدات التجميد والتبريد في الشاحنات الصغيرة وسيارات النقل لتطابق درجة الحرارة المطلوبة. وتستخدم ورشة التصليح الحصرية أحدث التقنيات لإصلاح اصطدام السيارات، وهي مجهزة بالكامل للتعامل مع أعمال الإصلاح الرئيسية لجميع أنواع السيارات</p>

              <p>ترتبط ورشة شركة كيمجي رامداس للسيارات بعلامات تجارية مثل هواسونج ثيرمو (وحدات التبريد والتجميد من كوريا الجنوبية) ومركز صيانة بوش المعتمد وشركة امبكس لحلول مكيفات الهواء وضواغط مكيفات هواء السيارات من ساندن. وتقدم شركة كيمجي رامداس لخدمات التأمين حلول التأمين النهائية لجميع متطلبات التأمين على السيارات​</p>
            
            <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            
            <div class="tab-whykrinsu-pins-list-ar">
              <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inhouautgar-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"report-a-claim-ar"}>الإبلاغ عن مطالبة </option>
            <option value={"claim-consultancy-services-ar"}>خدمات استشارات المطالبات</option>
            <option value={"service-excellence-ar"}>التميز في الخدمة </option>
            <option value={"in-house-kr-automotive-garage-ar"} selected class="active">مرأب سيارات شركة كيمجي رامداس الداخلية</option>
            <option value={"erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</option>
          </select>
        </div>
        <div class="inhouautgar-mob-main-ar">
          <h1 class="inhouautgar-banner-heading-ar">مرأب سيارات شركة كيمجي رامداس الداخلية</h1>
          <div class="inhouautgar-mob-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/in_house_kr_automotive_garage_ar.png"} alt="مرأب سيارات شركة كيمجي رامداس الداخلية" />
          </div>
          
          <p class="inhouautgar-mob-txt-ar">نعتبر شركة كيمجي رامداس لخدمات التأمين سمسار التأمين الوحيد في سلطنة عمان، ولديها مرافق ورش سيارات داخلية لتلبية المتطلبات العاجلة لعملائها في وقت تقديم مطالبات السيارات. وبكل فخر، ندلي ببيان مفاده أنه يمكننا التعامل بنجاح مع أي أساطيل سيارات كبيرة كانت أو صغيرة، بدءًا من التأمين وحتى تسويات مطالباتهم. ويحتفظ قسم السيارات الخاص بشركة كيمجي رامداس أو ورش السيارات الخاصة بها بأسطول سيارات المجموعة وأسطول سيارات الشركة الأخرى ذات الصناعات التي تتحول بشكل أفضل مع الأوقات. وتقوم الشركة بجميع أنواع الإصلاحات الميكانيكية وإصلاحات الحوادث التي تشمل الطلاء الكامل للسيارات لأغراض خاصة</p>

          <p class="inhouautgar-mob-txt-ar">تم تصنيف ورشة كيمجي رامداس للسيارات على أنها شركة من الدرجة الأولى من قبل شرطة عمان السلطانية والعديد من شركات التأمين الأخرى، في إصلاح سيارات عملائها في سلطنة عمان. وتحتوي الورشة على قسم حصري للتصنيع يتولى عمليات بناء الهياكل الفولاذية وبناء هيكل المبرد (عزل عالي الجودة للسيارات باستخدام مادة البولي يوريثان) وتقوم أيضًا بعمليات البيع وتوفير خدمة وحدات التجميد والتبريد في الشاحنات الصغيرة وسيارات النقل لتطابق درجة الحرارة المطلوبة. وتستخدم ورشة التصليح الحصرية أحدث التقنيات لإصلاح اصطدام السيارات، وهي مجهزة بالكامل للتعامل مع أعمال الإصلاح الرئيسية لجميع أنواع السيارات</p>

          <p class="inhouautgar-mob-txt-ar">ترتبط ورشة شركة كيمجي رامداس للسيارات بعلامات تجارية مثل هواسونج ثيرمو (وحدات التبريد والتجميد من كوريا الجنوبية) ومركز صيانة بوش المعتمد وشركة امبكس لحلول مكيفات الهواء وضواغط مكيفات هواء السيارات من ساندن. وتقدم شركة كيمجي رامداس لخدمات التأمين حلول التأمين النهائية لجميع متطلبات التأمين على السيارات.​ ​</p>
          
          <h3 class="inhouautgar-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="inhouautgar-mob-whykrlist-ar">
            <div class="inhouautgar-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="inhouautgar-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="inhouautgar-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            <div class="inhouautgar-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
          </div>


        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARInhouseKRAutomotiveGarage;
