import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './artravelinsurance.scss';

const ARTravelInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div class="travinsu-tab-imagebannermain-ar">
          <div class="travinsu-banner-con-ar">
            <div class="travinsutab-banne-sec-ar">
              <h1 class="travinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="travinsu-tab-btn-ar">
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="travinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="travinsu-banner-con-ar">
          <div class="travinsu-bdy-ar">
            <div class="travinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="travinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/travel_insurance_ar.png"} alt="التأمين أثناء السفر" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="travinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">التأمين أثناء السفر</h2>
                <p class="pg-txt-con-ar">هل أُرهَقت من الروتين اليومي المحموم، وتبحث عن عُطلة مريحة للتخلص من بعض الضغوطات؟ ولكن ينتابك القلق بشأن وقوع أي حوادث أثناء الرحلة. ربما تكون قد خططت لرحلة دولية إلى قارة أوروبا، أو الذهاب غربًا أو أي موقع رائع آخر داخل دول مجلس التعاون الخليجي. ومن ناحية أخرى يساورك القلق بشأن إمكانية وقوع أي حوادث غير محمودة في بداية الرحلة أو أثناءها. يمكن لأي شيء أن يحدُث؛ كضياع الأمتعة أثناء التنزه، أو أي إلغاء للرحلة في اللحظة الأخيرة أو حتى ما هو أسوأ – أو أي ظروف طبية طارئة ومفاجئة قبل أو أثناء الإجازة. كيف تتعامل مع هذا الموقف؟</p>

                <p class="pg-txt-con-ar">توفّر كيمجي رامداس لخدمات التأمين حلول تأمينية متعددة لتغطية أي مخاطر قد تتعرض لها أثناء رحلتك، بما يُلائم احتياجاتك وميزانيتك</p>

                <h3 class="travinsu-page-sbu-title-ar">التغطية الرئيسية/ التأمين أثناء السفر</h3>

                <p class="pg-txt-con-ar">توقف عن القلق رجاءً وفكر في تأمين شركة كيمجي رامداس لخدمات التأمين لتزويدك بأفضل برنامج تغطية تأمينية أثناء السفر. تم وضع برنامج تأمين السفر المُقدم من قِبل شركة كيمجي رامداس لخدمات التأمين لضمان تقضيتك لإجازة مليئة بالمُتعة والراحة، بصرف النظر عن أي شكوك قد تساورك</p>

                <h4 class="travinsu-keyfet-ar mt-3">الميزات الرئيسية لتأمين السفر هي</h4>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
           
            <div class="clearfix"></div>
            {/* QUESTION SECTION STARTS HERE */}
            <div class="travinsu-keyfeatures-ar">
              <p class="travinsu-keyfeatures-q-ar">تغطية جميع حالات الطوارئ الطبية. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
              <p class="travinsu-keyfeatures-ans-ar">يمكن للحوادث المؤسفة بإمكانها أن تحدث في أي وقت، كما يُحتمَل بنسبة كبيرة أن تهدد صحتك. لتتخيل أنك عالق مع عائلتك في أرض غريبة في موقف صعب. لذا عليك التأكد من حصولك على تغطية واسعة تعني بمصاريفك الطبية في العيادات الداخلية والخارجية</p>

              <p class="travinsu-keyfeatures-q-ar">تغطية ضد فقدان الأمتعة المسجلة وفقدان جواز السفر. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
              <p class="travinsu-keyfeatures-ans-ar">عليك تخيل وقوع شخص في محنة بوجوده في مكان جديد تمامًا فقط ليكتشف أن أمتعته قد ضاعت أو أن شخصًا فقد جواز سفره أثناء استكشاف الأماكن. إنك بالتأكيد لا تريد أن تتعثر في هذا الموقف! عليك أن تتأكد من حصولك على تأمين سفر يوفر لك تغطية لهذه الأشياء</p>

              <p class="travinsu-keyfeatures-q-ar">التغطية ضد الحوادث الشخصية الخاصة بك <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>

              <p class="travinsu-keyfeatures-ans-ar">تأكد من أن تأمين السفر الخاص بك يوفر لك التغطية ضد الإصابة الجسدية أو الوفاة بفعل الحوادث</p>

              <p class="travinsu-keyfeatures-q-ar">تغطية خاصة بك في حالة إلغاء الرحلة واختصارها. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
              <p class="travinsu-keyfeatures-ans-ar">للتخيل أن يُصاب أحد أفراد أسرتك بأي مكروه فجأة. وذلك أثناء إجراء ترتيبات السفر الخاصة بك، حيث بالتأكيد لا يمكنك السفر. تأكد من أن تأمين السفر الذي تختاره يغطي تكلفة تقييدك للسفر أو إلغاء الرحلة في اللحظة الأخيرة</p>

              <p class="travinsu-keyfeatures-q-ar">التغطية ضد عملية سطو أثناء غيابك. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </p>
              <p class="travinsu-keyfeatures-ans-ar">عادةً ما يحدث السطو على المنازل في الغالب عندما لا يكون هناك أحد في المنزل يعد اختيار برنامج تغطية خاص بك في حالة السطو على منزلك أثناء تواجدك بعيدًا قرارًا حكيمًا</p>
            </div>
            {/* QUESTION SECTION ENDS HERE */}
            
            <h3 class="travinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

            <div class="travinsu-pins-list-ar">
              <div class="travinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="travinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="travinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="travinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

       {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
       <div className="tab-btn-viewmode-ar">
        <div class="travinsu-tab-imagebannermain-ar">
          <div class="travinsu-banner-con-ar">
            <div class="travinsutab-banne-sec-ar">
              <h1 class="travinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="travinsu-tab-btn-ar">
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="travinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="travinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="travinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>التأمين أثناء السفر</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance.png"} alt="التأمين أثناء السفر" />

              <p>هل أُرهَقت من الروتين اليومي المحموم، وتبحث عن عُطلة مريحة للتخلص من بعض الضغوطات؟ ولكن ينتابك القلق بشأن وقوع أي حوادث أثناء الرحلة. ربما تكون قد خططت لرحلة دولية إلى قارة أوروبا، أو الذهاب غربًا أو أي موقع رائع آخر داخل دول مجلس التعاون الخليجي. ومن ناحية أخرى يساورك القلق بشأن إمكانية وقوع أي حوادث غير محمودة في بداية الرحلة أو أثناءها. يمكن لأي شيء أن يحدُث؛ كضياع الأمتعة أثناء التنزه، أو أي إلغاء للرحلة في اللحظة الأخيرة أو حتى ما هو أسوأ – أو أي ظروف طبية طارئة ومفاجئة قبل أو أثناء الإجازة. كيف تتعامل مع هذا الموقف؟</p>

            <p>توفّر كيمجي رامداس لخدمات التأمين حلول تأمينية متعددة لتغطية أي مخاطر قد تتعرض لها أثناء رحلتك، بما يُلائم احتياجاتك وميزانيتك</p>
            
            <h3 class="tab-whykrinsu-title-ar">التغطية الرئيسية/ التأمين أثناء السفر</h3>

            <p>توقف عن القلق رجاءً وفكر في تأمين شركة كيمجي رامداس لخدمات التأمين لتزويدك بأفضل برنامج تغطية تأمينية أثناء السفر. تم وضع برنامج تأمين السفر المُقدم من قِبل شركة كيمجي رامداس لخدمات التأمين لضمان تقضيتك لإجازة مليئة بالمُتعة والراحة، بصرف النظر عن أي شكوك قد تساورك</p>

            <h3 class="tab-whykrinsu-title-ar">الميزات الرئيسية لتأمين السفر هي</h3>

            <div class="tabkyfet-ar">
              <div class="tabkeyfet-txt-ar">تغطية جميع حالات الطوارئ الطبية. <br />
              يمكن للحوادث المؤسفة بإمكانها أن تحدث في أي وقت، كما يُحتمَل بنسبة كبيرة أن تهدد صحتك. لتتخيل أنك عالق مع عائلتك في أرض غريبة في موقف صعب. لذا عليك التأكد من حصولك على تغطية واسعة تعني بمصاريفك الطبية في العيادات الداخلية والخارجية</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">
              تغطية ضد فقدان الأمتعة المسجلة وفقدان جواز السفر.<br/>
              عليك تخيل وقوع شخص في محنة بوجوده في مكان جديد تمامًا فقط ليكتشف أن أمتعته قد ضاعت أو أن شخصًا فقد جواز سفره أثناء استكشاف الأماكن. إنك بالتأكيد لا تريد أن تتعثر في هذا الموقف! عليك أن تتأكد من حصولك على تأمين سفر يوفر لك تغطية لهذه الأشياء
              </div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">
              التغطية ضد الحوادث الشخصية الخاصة بك.<br />
              تأكد من أن تأمين السفر الخاص بك يوفر لك التغطية ضد الإصابة الجسدية أو الوفاة بفعل الحوادث
              </div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">
              تغطية خاصة بك في حالة إلغاء الرحلة واختصارها.<br/>
              للتخيل أن يُصاب أحد أفراد أسرتك بأي مكروه فجأة. وذلك أثناء إجراء ترتيبات السفر الخاصة بك، حيث بالتأكيد لا يمكنك السفر. تأكد من أن تأمين السفر الذي تختاره يغطي تكلفة تقييدك للسفر أو إلغاء الرحلة في اللحظة الأخيرة
              </div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">
              التغطية ضد عملية سطو أثناء غيابك <br />
              عادةً ما يحدث السطو على المنازل في الغالب عندما لا يكون هناك أحد في المنزل. يعد اختيار برنامج تغطية خاص بك في حالة السطو على منزلك أثناء تواجدك بعيدًا قرارًا حكيمًا
              </div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            </div>
            
            <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

            <div class="tab-whykrinsu-pins-list-ar">
              <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار </a>
                  </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="travinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"} selected class="active">التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="travinsu-mob-main-ar">
        <h1 class="travinsu-banner-heading-ar">التأمين أثناء السفر</h1>
              <div class="travinsu-mob-img-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/travel_insurance_ar.png"} alt="التأمين أثناء السفر" />
              </div>
              <p class="travinsu-mob-txt-ar">هل أُرهَقت من الروتين اليومي المحموم، وتبحث عن عُطلة مريحة للتخلص من بعض الضغوطات؟ ولكن ينتابك القلق بشأن وقوع أي حوادث أثناء الرحلة. ربما تكون قد خططت لرحلة دولية إلى قارة أوروبا، أو الذهاب غربًا أو أي موقع رائع آخر داخل دول مجلس التعاون الخليجي. ومن ناحية أخرى يساورك القلق بشأن إمكانية وقوع أي حوادث غير محمودة في بداية الرحلة أو أثناءها. يمكن لأي شيء أن يحدُث؛ كضياع الأمتعة أثناء التنزه، أو أي إلغاء للرحلة في اللحظة الأخيرة أو حتى ما هو أسوأ – أو أي ظروف طبية طارئة ومفاجئة قبل أو أثناء الإجازة. كيف تتعامل مع هذا الموقف؟</p>

              <p class="travinsu-mob-txt-ar">توفّر كيمجي رامداس لخدمات التأمين حلول تأمينية متعددة لتغطية أي مخاطر قد تتعرض لها أثناء رحلتك، بما يُلائم احتياجاتك وميزانيتك</p>

              <h3 class="travinsu-subtitle-ar">التغطية الرئيسية/ التأمين أثناء السفر</h3>

              <p class="travinsu-mob-txt-ar">توقف عن القلق رجاءً وفكر في تأمين شركة كيمجي رامداس لخدمات التأمين لتزويدك بأفضل برنامج تغطية تأمينية أثناء السفر. تم وضع برنامج تأمين السفر المُقدم من قِبل شركة كيمجي رامداس لخدمات التأمين لضمان تقضيتك لإجازة مليئة بالمُتعة والراحة، بصرف النظر عن أي شكوك قد تساورك</p>

              <h4 class="travinsu-subtitle-ar">الميزات الرئيسية لتأمين السفر هي</h4>

              {/* QUESTION SECTION STARTS HERE */}
              <div class="travinsu-mob-keyfeatures-ar">
                <div class="travinsu-mob-keyfeatures-q-ar">تغطية جميع حالات الطوارئ الطبية.</div>
                <div class="travinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-ans-ar">يمكن للحوادث المؤسفة بإمكانها أن تحدث في أي وقت، كما يُحتمَل بنسبة كبيرة أن تهدد صحتك. لتتخيل أنك عالق مع عائلتك في أرض غريبة في موقف صعب. لذا عليك التأكد من حصولك على تغطية واسعة تعني بمصاريفك الطبية في العيادات الداخلية والخارجية</div>

                <div class="travinsu-mob-keyfeatures-q-ar">تغطية ضد فقدان الأمتعة المسجلة وفقدان جواز السفر</div>
                <div class="travinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-ans-ar">عليك تخيل وقوع شخص في محنة بوجوده في مكان جديد تمامًا فقط ليكتشف أن أمتعته قد ضاعت أو أن شخصًا فقد جواز سفره أثناء استكشاف الأماكن. إنك بالتأكيد لا تريد أن تتعثر في هذا الموقف! عليك أن تتأكد من حصولك على تأمين سفر يوفر لك تغطية لهذه الأشياء</div>
                
                <div class="travinsu-mob-keyfeatures-q-ar">التغطية ضد الحوادث الشخصية الخاصة بك</div>
                <div class="travinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-ans-ar">تأكد من أن تأمين السفر الخاص بك يوفر لك التغطية ضد الإصابة الجسدية أو الوفاة بفعل الحوادث</div>
              
                <div class="travinsu-mob-keyfeatures-q-ar">تغطية خاصة بك في حالة إلغاء الرحلة واختصارها</div>
                <div class="travinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-ans-ar">للتخيل أن يُصاب أحد أفراد أسرتك بأي مكروه فجأة. وذلك أثناء إجراء ترتيبات السفر الخاصة بك، حيث بالتأكيد لا يمكنك السفر. تأكد من أن تأمين السفر الذي تختاره يغطي تكلفة تقييدك للسفر أو إلغاء الرحلة في اللحظة الأخيرة</div>
                
                <div class="travinsu-mob-keyfeatures-q-ar">التغطية ضد عملية سطو أثناء غيابك.</div>
                <div class="travinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="travinsu-mob-keyfeatures-ans-ar">عادةً ما يحدث السطو على المنازل في الغالب عندما لا يكون هناك أحد في المنزل. يعد اختيار برنامج تغطية خاص بك في حالة السطو على منزلك أثناء تواجدك بعيدًا قرارًا حكيمًا</div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 class="travinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            
              
              <div class="travinsu-mob-whykrlist-ar">
                <div class="travinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="travinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="travinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="travinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                    <a href="/omanbr/contactus" className="askquto-btn">للإستفسار </a>
              </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARTravelInsurance;