import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arpersonalaccident.scss';

const ARPersonalAccident = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
     <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="personal-accinsu-tab-imagebannermain-ar">
          <div class="personal-accinsu-banner-con-ar">
            <div class="personal-accinsutab-banne-sec-ar">
              <h1 class="personal-accinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="personal-accinsu-tab-btn-ar">
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="personal-accinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="personal-accinsu-banner-con-ar">
          <div class="personal-accinsu-bdy-ar">
            <div class="personal-accinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="personal-accinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/personal_insurance_ar.png"} alt="التأمين الشخصي " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="personal-accinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">تأمين الحوادث الشخصية</h2>

                <p class="pg-txt-con-ar">الحياة مليئة بالشكوك، وأي ظروف مؤسفة يمكن أن تؤثر تأثيرًا شديدًا في الحياة، ولذلك يمكنك تأمين رفاهية عائلتك بمساعدة شركة كيمجي رامداس لخدمات التأمين وإعداد شبكة أمان لأحبائك من خلال ضمان رفاههم المالي. وقد يتسبب الحادث في الكثير من النفقات التي ربما لم تخطر بذهنك ومع ذلك، فإن تغطية هذه التكاليف يصبح ضرورة في ذلك الوقت. هل تعتقد أن لديك الإمكانيات الكافية لتغطية التكاليف؟، للحصول على تغطية ضد حوادث الوفاة والعجز مع تأمين الحوادث الشخصية، اشترِ أفضل برنامج من كيمجي رامداس لخدمات التأمين ووفر نفقاتك الطبية</p>

                <div class="peraccinsu-tab-lifinsu-ar">
                  <p class="peraccinsu-tab-lifinsu-item-ar">قارن البرامج العرضية من أفضل شركات التأمين
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                      </p>
                  <p class="peraccinsu-tab-lifinsu-item-ar">أقل قسط على تغطية الحوادث الشخصية
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    </p>
                  <p class="peraccinsu-tab-lifinsu-item-ar">بوليصة الحوادث الشخصية الفورية
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
                    </p>
                </div>
                    <h3 class="personal-accinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين ضد الحوادث الشخصية</h3>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
              <p class="personal-accinsu-txt-con-ar"><strong>تمتع بالمزايا التالية بموجب بوليصة التأمين هذه</strong></p>
                <div class="clearfix"></div>
              {/* QUESTION SECTION STARTS HERE */}
              <div class="personal-accinsu-dsk-keyfeatures-ar">
                <div class="personal-accinsu-dsk-keyfeatures-q-ar">التغطية التأمينة ضد الوفاة الناتجة عن حادث: المزايا المنصوص عليها في هذا البرنامج مستحقة الدفع في حالة الوفاة نتيجة حادث مؤسف</div>

                <div class="personal-accinsu-dsk-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-ar">التغطية التأمينة ضد الإعاقة العرضية: يشير إلى أن حامل بوليصة التأمين لديه إعاقة جزئية أو كلية</div>
                

              <div class="personal-accinsu-dsk-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-ar">التغطية التأمينة ضد قطع أوتار ناتج عن حادث: يشير إلى أن جزءًا من جسم حامل البوليصة قد تم قطعه أو قطع أوتاره. هذا يعني أنه إذا فقد حامل البوليصة يده أو ساقه أو عينيه في حادث مؤسف، فسيكون مؤهلاً للحصول على مطالبة بموجب تقطيع أوصاله</div>
                

              <div class="personal-accinsu-dsk-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-ar">التغطية التأمينة ضد الأعمال الإرهابية: تشمل أيضا الإصابات التي لحقت به بسبب الأعمال الإرهابية</div>
                

              <div class="personal-accinsu-dsk-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-dsk-keyfeatures-q-ar">تكاليف الإقامة بالمستشفى لتلقي العلاج: تحمل كافة تكاليف العلاج الخاصة بالمريض خلال خضوعه للعلاج بالمستشفى</div>
                <div class="personal-accinsu-dsk-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}
            
              <h3 class="personal-accinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              <div class="clearfix"></div>

              <div class="personal-accinsu-pins-list-ar">
              <div class="personal-accinsu-lt-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="personal-accinsu-lt-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="personal-accinsu-lt-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="personal-accinsu-lt-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>
              

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
      <div class="personal-accinsu-tab-imagebannermain-ar">
          <div class="personal-accinsu-banner-con-ar">
            <div class="personal-accinsutab-banne-sec-ar">
              <h1 class="personal-accinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="personal-accinsu-tab-btn-ar">
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="personal-accinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="personal-accinsu-tab-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="personal-accinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>تأمين الحوادث الشخصية</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/personal_insurance_ar.png"} alt="التأمين الشخصي " />

              <p>الحياة مليئة بالشكوك، وأي ظروف مؤسفة يمكن أن تؤثر تأثيرًا شديدًا في الحياة، ولذلك يمكنك تأمين رفاهية عائلتك بمساعدة شركة كيمجي رامداس لخدمات التأمين وإعداد شبكة أمان لأحبائك من خلال ضمان رفاههم المالي. وقد يتسبب الحادث في الكثير من النفقات التي ربما لم تخطر بذهنك ومع ذلك، فإن تغطية هذه التكاليف يصبح ضرورة في ذلك الوقت. هل تعتقد أن لديك الإمكانيات الكافية لتغطية التكاليف؟، للحصول على تغطية ضد حوادث الوفاة والعجز مع تأمين الحوادث الشخصية، اشترِ أفضل برنامج من كيمجي رامداس لخدمات التأمين ووفر نفقاتك الطبية</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">قارن البرامج العرضية من أفضل شركات التأمين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">أقل قسط على تغطية الحوادث الشخصية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">بوليصة الحوادث الشخصية الفورية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <h3 class="motorinsu-page-sbu-title-ar">التغطية الرئيسية / التأمين ضد الحوادث الشخصية</h3>

              <p><strong>تمتع بالمزايا التالية بموجب بوليصة التأمين هذه</strong></p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد الوفاة الناتجة عن حادث: المزايا المنصوص عليها في هذا البرنامج مستحقة الدفع في حالة الوفاة نتيجة حادث مؤسف</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد الإعاقة العرضية: يشير إلى أن حامل بوليصة التأمين لديه إعاقة جزئية أو كلية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد قطع أوتار ناتج عن حادث: يشير إلى أن جزءًا من جسم حامل البوليصة قد تم قطعه أو قطع أوتاره. هذا يعني أنه إذا فقد حامل البوليصة يده أو ساقه أو عينيه في حادث مؤسف، فسيكون مؤهلاً للحصول على مطالبة بموجب تقطيع أوصاله</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">التغطية التأمينة ضد الأعمال الإرهابية: تشمل أيضا الإصابات التي لحقت به بسبب الأعمال الإرهابية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تكاليف الإقامة بالمستشفى لتلقي العلاج: تحمل كافة تكاليف العلاج الخاصة بالمريض خلال خضوعه للعلاج بالمستشفى</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <h3 class="motorinsu-page-sbu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="personal-accinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
        <select class="form-select" onChange={(e) => window.location.href = e.target.value }>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"} selected class="active">تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="personal-accinsu-mob-main-ar">
        <h1 class="personal-accinsu-banner-heading-ar">تأمين الحوادث الشخصية</h1>
              <div class="personal-accinsu-mob-img-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/personal_insurance_ar.png"} alt="التأمين الشخصي " />
              </div>
              <p class="personal-accinsu-mob-txt-ar">الحياة مليئة بالشكوك، وأي ظروف مؤسفة يمكن أن تؤثر تأثيرًا شديدًا في الحياة، ولذلك يمكنك تأمين رفاهية عائلتك بمساعدة شركة كيمجي رامداس لخدمات التأمين وإعداد شبكة أمان لأحبائك من خلال ضمان رفاههم المالي. وقد يتسبب الحادث في الكثير من النفقات التي ربما لم تخطر بذهنك ومع ذلك، فإن تغطية هذه التكاليف يصبح ضرورة في ذلك الوقت. هل تعتقد أن لديك الإمكانيات الكافية لتغطية التكاليف؟، للحصول على تغطية ضد حوادث الوفاة والعجز مع تأمين الحوادث الشخصية، اشترِ أفضل برنامج من كيمجي رامداس لخدمات التأمين ووفر نفقاتك الطبية</p>
              
              
              <div class="personal-accinsu-tbpoints-ar">
                <div class="personal-accinsu-tbpoints-item-ar">قارن البرامج العرضية من أفضل شركات التأمين</div><div class="personal-accinsu-tbpoints-imgitem-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-tbpoints-item-ar">أقل قسط على تغطية الحوادث الشخصية</div>
                <div class="personal-accinsu-tbpoints-imgitem-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-tbpoints-item-ar">بوليصة الحوادث الشخصية الفورية</div>
                <div class="personal-accinsu-tbpoints-imgitem-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>
                
                <h3 class="personal-accinsu-subtitle-ar">التغطية الرئيسية / التأمين ضد الحوادث الشخصية</h3>

              <p class="personal-accinsu-mob-txt-ar"><strong>تمتع بالمزايا التالية بموجب بوليصة التأمين هذه</strong></p>

              {/* QUESTION SECTION STARTS HERE */}
              <div class="personal-accinsu-mob-keyfeatures-ar">
                <div class="personal-accinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد الوفاة الناتجة عن حادث: المزايا المنصوص عليها في هذا البرنامج مستحقة الدفع في حالة الوفاة نتيجة حادث مؤسف</div>

                <div class="personal-accinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد الإعاقة العرضية: يشير إلى أن حامل بوليصة التأمين لديه إعاقة جزئية أو كلية</div>
                

              <div class="personal-accinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد قطع أوتار ناتج عن حادث: يشير إلى أن جزءًا من جسم حامل البوليصة قد تم قطعه أو قطع أوتاره. هذا يعني أنه إذا فقد حامل البوليصة يده أو ساقه أو عينيه في حادث مؤسف، فسيكون مؤهلاً للحصول على مطالبة بموجب تقطيع أوصاله</div>
                

              <div class="personal-accinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-ar">التغطية التأمينة ضد الأعمال الإرهابية: تشمل أيضا الإصابات التي لحقت به بسبب الأعمال الإرهابية</div>
                

              <div class="personal-accinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="personal-accinsu-mob-keyfeatures-q-ar">تكاليف الإقامة بالمستشفى لتلقي العلاج: تحمل كافة تكاليف العلاج الخاصة بالمريض خلال خضوعه للعلاج بالمستشفى</div>
                <div class="personal-accinsu-mob-keyfeatures-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 class="personal-accinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            
            <div class="personal-accinsu-mob-whykrlist-ar">
                <div class="personal-accinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="personal-accinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="personal-accinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div class="personal-accinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARPersonalAccident;