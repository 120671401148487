import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import './arprojectinsurance.scss';

const ARProjectInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="projectinsu-tab-imagebannermain-ar">
          <div class="projectinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="projectinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="projectinsu-tab-btn-ar">
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="projectinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="projectinsu-banner-con-ar">
          <div class="projectinsu-bdy-ar">
            <div class="projectinsu-dflex-ar">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="projectinsu-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/project_insurance_ar.png"} alt="Project Insurance" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="projectinsu-dlex-lft-ar">
                <h2 class="pagetitle-ar">تأمين الأعمال </h2>
                <p class="projectinsu-txt-con-ar">تغطي بوليصة تأمين المشروع المخاطر التي تواجهها مشاريع البناء الجارية ومشاريع تركيب المعدات والآلات في العمليات التجارية</p>

                <p class="projectinsu-txt-con-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين أفضل حلول التأمين لتناسب متطلبات كل حدث خطر، وتغطي جميع جوانب مخاطر الهندسة والبناء</strong></p>

                <div class="projectinsu-keypoint-ar">
                  <div class="projectinsu-keypoint-item-ar">التأمين ضد جميع أخطار التركيب</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين ضد انقطاع الأعمال، التأخير في بدء التشغيل و خسارة الأرباح مقدمًا</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين ضد جميع مخاطر البناء / التأمين ضد المخاطر المحتملة لعمال البناء</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين ضد العيوب الكامنة و التأمين ضد العيوب المتأصلة</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين على معدات مصانع وآلات المقاول</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين على المعدات الإلكترونية</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين ضد انقطاع البرمجيات التجارية</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="projectinsu-keypoint-item-ar">التأمين ضد أعطال الآلات</div>
                  <div class="projectinsu-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="projectinsu-txt-con-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين أدناه حلول تأمين هندسية وانشائية ذات قيمة مضافة مذكورة أدناه لعملائها</strong></p>

            <div class="projectinsu-kpnt-ar">
              <div class="projectinsu-kpnt-item-ar">المنتج مصمم خصيصًا يهدف إلى تغطية أوسع</div>
              <div class="projectinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-kpnt-item-ar">فريق متخصص من المهندسين يساعد في تنفيذ تدابير إدارة المخاطر</div>
              <div class="projectinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-kpnt-item-ar">فريق متمرس من المتخصصين في المطالبات التي تحتوي على تفاصيل أعمق</div>
              <div class="projectinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-kpnt-item-ar">تواجدنا في كل جزء من دول مجلس التعاون الخليجي</div>
              <div class="projectinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-kpnt-item-ar">من الممكن، بالإضافة إلى ذلك، أن يكون لديك مشارك في البوليصة السنوية للرعاية الصادرة لجميع الأعمال التي يقوم بها المقاول</div>
              <div class="projectinsu-kpnt-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <h3 class="projectinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين المشروع</h3>


            <div class="projectinsu-pins-list-dsk-ar">
              <div class="projectinsu-pins-list-dsk-item-ar">التأمين ضد جميع المخاطر المتعلقة بالبناء وأعمال المقاولة وأي ضرر أو خسارة تحدث في موقع البناء. تغطية ضد أضرار الأطراف الأخرى</div>

              <div class="projectinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-pins-list-dsk-item-ar"> تغطية ضد أضرار الآلات التي يتم تشغيلها في موقع المشروع</div>

              <div class="projectinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-pins-list-dsk-item-ar"> تغطية ضد تعطل الآلات أو التلف الناتج عن حادث على جميع الآلات في المبنى</div>

              <div class="projectinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-pins-list-dsk-item-ar"> تغطية مجموعة متنوعة من الأجهزة الإلكترونية بما في ذلك المعدات السمعية والبصرية والمعدات الطبية</div>

              <div class="projectinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-pins-list-dsk-item-ar"> تغطية ضد الفقدان أو التلف فيما يخص جميع أنواع أجهزة الكمبيوتر وأي تكلفة يتم تكبدها لاستعادة البيانات المفقودة</div>
              <div class="projectinsu-pins-list-dsk-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <h3 class="projectinsu-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

            <div class="clearfix"></div>

            <div class="projectinsu-pins-list-dksp-ar">
              <div class="projectinsu-lt-two-dksp-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-lt-one-dksp-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-lt-two-dksp-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="projectinsu-lt-one-dksp-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="projectinsu-tab-imagebannermain-ar">
          <div class="projectinsu-banner-con-ar">
            <div class="propertyinsutab-banne-sec-ar">
              <h1 class="projectinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="projectinsu-tab-btn-ar">
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="projectinsu-tab-btn-item-active-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="projectinsu-tab-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="projectinsu-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2> تأمين المشاريع </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/project_insurance_ar.png"} alt="Project Insurance" />

              <p>تغطي بوليصة تأمين المشروع المخاطر التي تواجهها مشاريع البناء الجارية ومشاريع تركيب المعدات والآلات في العمليات التجارية</p>
              
              <p><strong>تقدم شركة كيمجي رامداس لخدمات التأمين أفضل حلول التأمين لتناسب متطلبات كل حدث خطر، وتغطي جميع جوانب مخاطر الهندسة والبناء</strong></p>

              <p className="txtimg-ar">التأمين ضد جميع أخطار التركيب <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg-ar">التأمين ضد انقطاع الأعمال، التأخير في بدء التشغيل و خسارة الأرباح مقدمًا <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg-ar">التأمين ضد جميع مخاطر البناء / التأمين ضد المخاطر المحتملة لعمال البناء <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg-ar">التأمين ضد العيوب الكامنة و التأمين ضد العيوب المتأصلة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg-ar">التأمين على معدات مصانع وآلات المقاول <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg-ar">التأمين على المعدات الإلكترونية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>
              <p className="txtimg-ar">التأمين ضد انقطاع البرمجيات التجارية</p>
              <p className="txtimg-ar">التأمين ضد أعطال الآلات <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>

              <p><strong>تقدم شركة كيمجي رامداس لخدمات التأمين أدناه حلول تأمين هندسية وانشائية ذات قيمة مضافة مذكورة أدناه لعملائها</strong></p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">المنتج مصمم خصيصًا يهدف إلى تغطية أوسع</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">فريق متخصص من المهندسين يساعد في تنفيذ تدابير إدارة المخاطر</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">فريق متمرس من المتخصصين في المطالبات التي تحتوي على تفاصيل أعمق.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تواجدنا في كل جزء من دول مجلس التعاون الخليجي</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">من الممكن، بالإضافة إلى ذلك، أن يكون لديك مشارك في البوليصة السنوية للرعاية الصادرة لجميع الأعمال التي يقوم بها المقاول</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / تأمين المشروع</h3>

              <div className="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">التأمين ضد جميع المخاطر المتعلقة بالبناء وأعمال المقاولة وأي ضرر أو خسارة تحدث في موقع البناء. تغطية ضد أضرار الأطراف الأخرى</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد أضرار الآلات التي يتم تشغيلها في موقع المشروع</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد تعطل الآلات أو التلف الناتج عن حادث على جميع الآلات في المبنى.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية مجموعة متنوعة من الأجهزة الإلكترونية بما في ذلك المعدات السمعية والبصرية والمعدات الطبية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية ضد الفقدان أو التلف فيما يخص جميع أنواع أجهزة الكمبيوتر وأي تكلفة يتم تكبدها لاستعادة البيانات المفقودة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
               <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
               
               <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="projectinsu-tab-imagebannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"} selected class="active">تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"}>تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>

        </div>
        <div class="projectinsu-mob-main-ar">
          <h1 class="projectinsu-banner-heading-ar">تأمين المشاريع </h1>
          <div class="projectinsu-mob-img-ar">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/project_insurance_ar.png"} alt="تأمين المشاريع" />
          </div>

          <p class="projectinsu-mob-txt-ar">تغطي بوليصة تأمين المشروع المخاطر التي تواجهها مشاريع البناء الجارية ومشاريع تركيب المعدات والآلات في العمليات التجارية.</p>

          <p class="projectinsu-mob-txt-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين أفضل حلول التأمين لتناسب متطلبات كل حدث خطر، وتغطي جميع جوانب مخاطر الهندسة والبناء</strong></p>

          <div class="projectinsu-pins-list-mob-ar">
            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد جميع أخطار التركيب</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد انقطاع الأعمال، التأخير في بدء التشغيل و خسارة الأرباح مقدمًا</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد جميع مخاطر البناء / التأمين ضد المخاطر المحتملة لعمال البناء</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد العيوب الكامنة و التأمين ضد العيوب المتأصلة</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">التأمين على معدات مصانع وآلات المقاول</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">التأمين على المعدات الإلكترونية</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>            

            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد انقطاع البرمجيات التجارية</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد أعطال الآلات</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
          </div>

          <p class="projectinsu-mob-txt-ar"><strong>تقدم شركة كيمجي رامداس لخدمات التأمين أدناه حلول تأمين هندسية وانشائية ذات قيمة مضافة مذكورة أدناه لعملائها</strong></p>

          <div class="projectinsu-pins-list-mob-ar">
            <div class="projectinsu-pins-list-mob-item-ar">المنتج مصمم خصيصًا يهدف إلى تغطية أوسع</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
            <div class="projectinsu-pins-list-mob-item-ar">فريق متخصص من المهندسين يساعد في تنفيذ تدابير إدارة المخاطر</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">فريق متمرس من المتخصصين في المطالبات التي تحتوي على تفاصيل أعمق.</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
            <div class="projectinsu-pins-list-mob-item-ar">تواجدنا في كل جزء من دول مجلس التعاون الخليجي</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>            

            <div class="projectinsu-pins-list-mob-item-ar">من الممكن، بالإضافة إلى ذلك، أن يكون لديك مشارك في البوليصة السنوية للرعاية الصادرة لجميع الأعمال التي يقوم بها المقاول</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
          </div>
          <h3 class="projectinsu-subtitle-ar">التغطية الرئيسية / تأمين المشروع</h3>

          <div class="projectinsu-pins-list-mob-ar">
            <div class="projectinsu-pins-list-mob-item-ar">التأمين ضد جميع المخاطر المتعلقة بالبناء وأعمال المقاولة وأي ضرر أو خسارة تحدث في موقع البناء. تغطية ضد أضرار الأطراف الأخرى</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">تغطية ضد أضرار الآلات التي يتم تشغيلها في موقع المشروع.</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>            

            <div class="projectinsu-pins-list-mob-item-ar">تغطية ضد تعطل الآلات أو التلف الناتج عن حادث على جميع الآلات في المبنى</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="projectinsu-pins-list-mob-item-ar">تغطية مجموعة متنوعة من الأجهزة الإلكترونية بما في ذلك المعدات السمعية والبصرية والمعدات الطبية</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>            

            <div class="projectinsu-pins-list-mob-item-ar">تغطية ضد الفقدان أو التلف فيما يخص جميع أنواع أجهزة الكمبيوتر وأي تكلفة يتم تكبدها لاستعادة البيانات المفقودة</div>
            <div class="projectinsu-pins-list-mob-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 class="projectinsu-whykrinsu-mob-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

          <div class="projectinsu-mob-whykrlist-ar">
            <div class="projectinsu-mob-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="projectinsu-mob-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="projectinsu-mob-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="projectinsu-mob-whykrlist-item-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/omanbr/contactus" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARProjectInsurance;